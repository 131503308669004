import '../Reaction';
import Reaction from '../Reaction';

import UserFactory from '../../factories/UserFactory';
import UserRepository from '../../repositories/UserRepository';

import PostDeletionFeature from '../PostDeletionFeature';
import PostDeletionSpecification from '../../specifications/PostDeletionSpecification';

import Post from '../Post';

import IncrementPostMetricService from '../../services/IncrementPostMetricService'
import IncrementPostReactionMetricService from '../../services/IncrementPostReactionMetricService'

import { createClient } from '@supabase/supabase-js';

import ContactService from '../../services/ContactService'
import Contact from '../Contact';
import ListContactsService from '../../services/ListContactsService';

import AuthService from '../../services/AuthService';
import CalendarEvent from '../event/CalendarEvent';
import Message from '../chat/Message';
import ChatRoomRepository from '../../repositories/chat/ChatRoomRepository';
import GetUserInfoCardsService from '../../services/GetUserInfoCardsService';

/**
 * A user model.
 * This is also a root aggregate.
 */
class User {
    constructor({
        id, firstName, lastName, username, email, birthday, countryCode, regionCode,
        photoURL, sex, bio, occupationCodes, createdAt, updatedAt, deletedAt, isModerator, languageCode}) {

            this.id = id;
            this.firstName = firstName;
            this.lastName = lastName;
            this.username = username;
            this.birthday = birthday;
            this.countryCode = countryCode;
            this.regionCode = regionCode || null;
            this.photoURL = photoURL || null;
            this.sex = sex;
            this.bio = bio || null;
            this.occupationCodes = occupationCodes || [];
            this.createdAt = createdAt;
            this.updatedAt = updatedAt;
            this.deletedAt = deletedAt || null;
            this.isModerator = isModerator || false
            this.languageCode = languageCode || 'en'

            const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
            const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
            this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    /**
     * A facade which creates a user (using the corresponding user factory) and adds it to the repository.
     * @param {File} photo - A photo file.
     * @param {string} password - A password
     * @param {object} data - Key-value data about the user. 
     * @return {User} Returns a Promise with the newly created user or rejects with an error.
     */
    static create(photo, password, data) {
        return new UserFactory().create(photo, password, data)
        .then((user) => {
            return UserRepository.getRepoState().add(user)
        })
    }

    static update(user, data) {
        return UserRepository.getRepoState().update(user, data)
    }

    static matching(criteria, pagination, order) {
        return UserRepository.getRepoState().matching(criteria, pagination, order)
    }

    /**
     * Fetches a user with matching id. 
     * @param {string} id - A string identifier used to filter the query response.
     * @return {User} Returns a Promise with a user matching the identifier or rejects with an error.
     */
    static findById(id) {
        return UserRepository.getRepoState().findById(id);
    }

    static findByUsername(username) {
        return UserRepository.getRepoState().findByUsername(username)
    }

    /**
     * Fetches a the current user in session. 
     * @return {User} Returns a Promise with the current user in session or rejects with an error.
     */
    static findBySession() {
        return UserRepository.getRepoState().findBySession();
    }

    static findByOccupationCode(occupationCode, regionCode, pagination, order) {
        return UserRepository.getRepoState().findByOccupationCode(occupationCode, regionCode, pagination, order)
    }

    /**
     * A method which creates adds a user to the current instantiated users list of preexisting contacts.
     * @param {User} other - A user to be added to the list of contacts
     * @return {Promise<boolean>} Returns a Promise with flag true if success or rejects with an error.
     */
    addToContacts(other) {
        return new ContactService().add(new Contact(this, other))
    }

    removeFromContacts(other) {
        return new ContactService().remove(new Contact(this, other))
    }

    getInfoCards() {
        let getUserInfoCards = new GetUserInfoCardsService()
        return getUserInfoCards.forCurrentUser(this)
    }

    /**
     * An operator which returns a list of contacts (paginated and ordered)
     * for a given user.
     * @param {User} user - A user.
     * @param {Pagination} pagination - A pagination value object.
     * @param {Order} order - A order value object.
     * @return {Promise<User[]>} Returns a list of contacts (users).
     */
    getContacts(pagination, order) {
        let listContacts = new ListContactsService()
        return listContacts.forUser(this, pagination, order)
    }

    sendMessage(chatRoom, content, attachments) {
        return Message.create(chatRoom, this, content, attachments)
    }

    createEvent(data) {
        let author = this
        let now = new Date()

        let updatedData = {
            createdAt: now,
            updatedAt: now,
            deletedAt: now,
            countryCode: this.countryCode,
            regionCode: this.regionCode,
            ...data,
        }

        return CalendarEvent.create(author, updatedData)
    }

    editEvent(event, data) {
        let author = this
        let now = new Date()
        let updatedData = {
            updatedAt: data.updatedAt,
            startDate: data.startDate,
            timezone: data.timezone,
            description: data.description,
            invited: data.invited,
            address: data.address,
            title: data.title,
            isOnline: data.isOnline,
            url: data.url,
            duration: data.duration,
            size: data.size,
            type: data.type,
            subtype: data.subtype
        }
        return CalendarEvent.update(event, updatedData)
    }


    /**
     * A method which creates a post for the given instantiated user.
     * @param {string} markdown - A markdown string.
     * @return {Post} Returns a Promise with the newly created post or rejects with an error.
     */
    createPost(guid, blocks, deletionSpecification) {
        let author = this;
        let now = new Date()
        
        let data = {
            createdAt: now,
            updatedAt: now,
            deletedAt: null,
            countryCode: this.countryCode,
            regionCode: this.regionCode,
            languageCode: "da-DK",
            inReplyToPost: null
        }
        return Post.create(guid, blocks, author, data, deletionSpecification)
    }

    editPost(post, guid, blocks, deleteSpecification) {
        let author = this;
        let now = new Date()
        let data = {
            updatedAt: now
        }
        return Post.update(post, guid, blocks, author, data, deleteSpecification)
    }

    /**
     * A method which reacts to a post with an emoji for the given instantiated user.
     * @param {Post} post - A post that is being reacted to
     * @param {string} emoji - An emoji.
     * @return {boolean} Returns a Promise with a boolean flag true on success or rejects with an error.
     */
    reactToPost(post, emoji) {
        let insertReactionQuery = this.supabase
        .from('reaction')
        .insert({
            userId: this.id,
            postId: post.id,
            emoji
        })

        let incrementPostMetric = new IncrementPostMetricService()
        let incrementPostReactionMetric = new IncrementPostReactionMetricService()

        return Promise.all([
            insertReactionQuery,
            incrementPostMetric.withArgs({postId: post.id, fieldName: 'reactions', x: 1}),
            incrementPostReactionMetric.withArgs({postId: post.id, emoji, x: 1})
        ])
        .then((values) => {
            return true
        })
    }

    /**
     * A method which replies to a post (with it's own post) for the given instantiated user.
     * @param {Post} post - A post that is being replied to.
     * @param {string} markdown - A markdown string for the reply post.
     * @return {Post} Returns the replied post or rejects with an error.
     */
    replyToPost(post, guid, blocks, deletionSpecification) {
        let author = this;
        let now = new Date()
        let data = {
            createdAt: now,
            updatedAt: now,
            deletedAt: null,
            countryCode: this.countryCode,
            regionCode: this.regionCode,
            languageCode: "da-DK",
            inReplyToPostId: post.id,
            inReplyToUsername: post.author.username
        }

        let incrementPostMetric = new IncrementPostMetricService()
        
        return Post.create(guid, blocks, author, data, deletionSpecification)
        .then(incrementPostMetric.withArgs({postId: post.id, fieldName: 'replies', x: 1}))
    }

    deletePost(post) {
        if (this.id !== post.author.id) {
            let error = new Error('You are not the author of the post and therefore don\'t have permission to delete it.')
            return Promise.reject(error)
        }
        return Post.delete(post)
    }

    deleteEvent(calendarEvent) {
        if (this.id !== calendarEvent.author.id) {
            let error = new Error('You are not the author of the calendar event and therefore don\'t have permission to delete it.')
            return Promise.reject(error)
        }
        return CalendarEvent.delete(calendarEvent)
    }

    leaveChatRoom(chatRoom) {
        let chatRoomRepository = ChatRoomRepository.getRepoState()

        let participantsExcludeSelf = chatRoom.participants.filter((x) => x.id !== this.id)
        let onlyOneInChatRoom = participantsExcludeSelf.length == 1
        
        if (onlyOneInChatRoom) {    
            return chatRoomRepository.delete(chatRoom)
        } else {
            return chatRoomRepository.update(chatRoom, {
                participants: [...participantsExcludeSelf]
            })
        }
    }

    deleteAccount(currentPassword) {
        let auth = new AuthService()
        auth.getUserEmail()
        .then((email) => {
            return auth.signIn(email, currentPassword)
        })
        .then(() => {
            return UserRepository.getRepoState().delete(this)
        })
    }
}

export default User;