import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import setModal from "../../redux/actions/set_modal"

import ConfirmDeleteModal from '../../components/modals/ConfirmDeleteModal'
import PreferencesModal from '../../components/modals/PreferencesModal'
import EventModal from '../../components/modals/EventModal'
import ChatModal from '../../components/modals/ChatModal'
import ReportModal from '../../components/modals/ReportModal'
import PostModal from '../../components/modals/PostModal'
import { useIntl } from 'react-intl'
import EventDetailsModal from '../../components/modals/EventDetailsModal'
import FeedbackModal from '../../components/modals/FeedbackModal'

class ModalLayer extends Component {
    constructor(props) {
        super(props);

        this.renderConfirmDeleteModal = this.renderConfirmDeleteModal.bind(this)
        this.renderPreferencesModal = this.renderPreferencesModal.bind(this)
        this.renderEventModal = this.renderEventModal.bind(this)
        this.renderPostModal = this.renderPostModal.bind(this)

        this.handleHideModal = this.handleHideModal.bind(this);
        this.destroyModal = this.destroyModal.bind(this);
    }

    handleHideModal(e, modalId) {
        this.props.setModal({ id: modalId, show: false, payload: null });
    }

    destroyModal() {
        this.props.setModal(null)
    }

    renderEventDetailsModal() {
        let { modal } = this.props
        let {payload} = modal

        if (!(payload)) {
            return
        }

        var calendarEvent = payload.calendarEvent ?? null

        return (
            <EventDetailsModal
                intl={this.props.intl}
                show={modal.show}
                title="Event"
                calendarEvent={calendarEvent}
                onDownload={(e) => {
                    modal.onAction('download', calendarEvent)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
            />
        )
    }

    renderReportModal() {
        let { modal } = this.props

        return (
            <ReportModal
                intl={this.props.intl}
                title={this.props.intl.formatMessage({id: "REPORT_MODAL_TITLE"})}
                show={modal.show}
                reasons={
                    modal.show ?
                    modal.payload.reasons ?? [] :
                    []
                }
                onCreate={(e, data) => {
                    modal.onAction('create', data)
                    this.handleHideModal(e, modal.id)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
            />
        )
    }

    renderChatModal() {
        let { modal } = this.props
        return (
            <ChatModal
                title={this.props.intl.formatMessage({id: "NEW_CHAT_MODAL_TITLE"})}
                show={modal.show}
                requestNextContacts={
                    modal.show ?
                    modal.payload.requestNextContacts ?? (() => Promise.resolve([])) :
                    null
                }
                onCreate={(e, data) => {
                    modal.onAction('create', data)
                    this.handleHideModal(e, modal.id)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
            />
        )
    }

    renderEventModal() {
        let { modal } = this.props
        let {payload} = modal

        if (!(payload)) {
            return
        }

        var event = payload.event ?? null
        var title = payload.title ?? this.props.intl.formatMessage({'id': 'CREATE_EVENT_MODAL_TITLE'});
        var submitBtnTitle = payload.submitBtnTitle ?? this.props.intl.formatMessage({'id': 'CREATE_BTN'})
        var getSizes = payload.getSizes
        var getTypes = payload.getTypes
        var getSubtypes = payload.getSubtypes

        return (
            <EventModal
                event={event}
                intl={this.props.intl}
                title={title}
                submitBtnTitle={submitBtnTitle}
                show={modal.show}
                getSizes={getSizes}
                getTypes={getTypes}
                getSubtypes={getSubtypes}
                onCreate={(e, data) => {
                    modal.onAction('create', data)
                    this.handleHideModal(e, modal.id)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
                
            />
        )
    }

    renderConfirmDeleteModal() {
        let { modal } = this.props;
        return (
            <ConfirmDeleteModal
                title={this.props.intl.formatMessage({id: "CONFIRM_DELETE_MODAL_TITLE"})}
                subtitle={this.props.intl.formatMessage({id: "CONFIRM_DELETE_MODAL_SUBTITLE"})}
                show={modal.show}
                onSubmit={(e) => {
                    modal.onAction('delete', null)
                    this.handleHideModal(e, modal.id)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
            />
        )
    }

    renderPreferencesModal() {
        let {modal} = this.props
        let {payload} = modal

        if (!(payload && "currentUser" in payload)) {
            return
        }

        var currentUser = payload.currentUser;

        return (
            <PreferencesModal
                intl={this.props.intl}
                show={modal.show}
                currentUser={currentUser}
                onSubmit={(e, sectionName, data) => {
                    modal.onAction('submit', {sectionName, data})
                    // this.handleHideModal(e, modal.id)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
            />
        )
    }

    renderPostModal() {
        let {modal} = this.props
        let {payload} = modal

        if (!(payload && "currentUser" in payload)) {
            return
        }

        var currentUser = payload.currentUser;
        var uploadImage = payload.uploadImage;
        var title = payload.title ?? this.props.intl.formatMessage({'id': 'CREATE_POST_MODAL_TITLE'});
        var submitBtnTitle = payload.submitBtnTitle ?? this.props.intl.formatMessage({'id': 'CREATE_BTN'})
        var getBlocks = payload.getBlocks;

        return (
            <PostModal
                intl={this.props.intl}
                title={title}
                submitBtnTitle={submitBtnTitle}
                show={modal.show}
                getBlocks={getBlocks}
                uploadImage={uploadImage}
                currentUser={currentUser}
                onSubmit={(e, data) => {
                    modal.onAction('submit', {...data})
                    this.handleHideModal(e, modal.id)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
            />
        )
    }

    renderFeedbackModal() {
        var {modal} = this.props
        let {payload} = modal

        if (!(payload && "currentUser" in payload)) {
            return
        }

        return (
            <FeedbackModal
                title={"Feedback"}
                show={modal.show}
                onSubmit={(e, data) => {
                    modal.onAction('submit', {...data})
                    this.handleHideModal(e, modal.id)
                }}
                onHide={(e) => {
                    modal.onAction('cancel', null)
                    this.handleHideModal(e, modal.id)
                }}
                onExited={(e) => setTimeout(() => this.destroyModal(), 20)}
            />
        )
    }

    render() {
        var { modal } = this.props;
        if (modal == null) {
            return null
        }
        switch (modal.id) {
            case 'confirmDeleteModal':
                return this.renderConfirmDeleteModal()
            case 'preferencesModal':
                return this.renderPreferencesModal()
            case 'eventModal':
                return this.renderEventModal()
            case 'chatModal':
                return this.renderChatModal()
            case 'reportModal':
                return this.renderReportModal()
            case 'postModal':
                return this.renderPostModal()
            case 'eventDetailsModal':
                return this.renderEventDetailsModal()
            case 'feedbackModal':
                return this.renderFeedbackModal()
        }
    }
}

function mapStateToProps(state) {
    return {
        modal: state.setModal,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setModal: setModal
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let intl = useIntl()
    return <ModalLayer {...props} intl={intl} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);