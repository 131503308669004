import { createClient } from "@supabase/supabase-js";

class UpdateUserInfoCardsService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    forCurrentUser(currentUser, userInfoCards) {
        return this.supabase
        .from('user_info_cards')
        .update({
            payload: userInfoCards.map((x) => {
                return {
                    title: x.title,
                    text: x.text,
                    value: x.value,
                    type: x.type
                }
            })
        })
        .eq('userId', currentUser.id)
    }
}

export default UpdateUserInfoCardsService