import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, Dropdown } from 'react-bootstrap'
import UserPopover from '../../UserPopover'
import Avatar from '../../Avatar'
import SelectTimeUnitService from '../../../services/SelectTimeUnitService'
import { FormattedMessage, FormattedRelativeTime } from 'react-intl'
import FormatRegionCodeService from '../../../services/FormatRegionCodeService'

// NOTE: Get rid of domain / app level knowledge. Treat it as a UI Component.
class PostCardHeader extends Component {
    constructor(props) {
        super(props)

        this.formatRegion = new FormatRegionCodeService()
    }

    render() {
        var { post, disabledWrite, showInReplyTo } = this.props
        showInReplyTo = showInReplyTo ?? true

        var userProps = {
            placement: "bottom-start",
            onMouseEnter: () => { },
            delay: 200,
            photo: post.author.photoURL,
            name: `${post.author.firstName} ${post.author.lastName}`,
            subtitle: `@${post.author.username}`,
            location: this.formatRegion.withValue(post.author.regionCode, 'long'),
            description: post.author.bio, // ?? no bio yet.
            link: '/me/' + post.author.username
        }

        return (
            <Card.Header onClick={(e) => e.stopPropagation()} className="border-bottom-0 w-100 mx-0">
                { showInReplyTo && post.inReplyToPostId &&
                    <div className="px-3">
                        <p className="text-muted n-line-1">
                            <i className="bi bi-reply me-0 mb-1"/>{' '}
                            <FormattedMessage
                                id="REPLIED_TO_USER_FORMAT"
                                values={{
                                    user: <Link to={`/me/${post.inReplyToUsername ? post.inReplyToUsername : 'unknown'}/post/${post.inReplyToPostId}`}>{post.inReplyToUsername ? post.inReplyToUsername : post.inReplyToPostId}</Link>
                                }}/>
                        </p>
                    </div>
                }
                <div className="d-flex justify-content-between align-items-center px-3 pb-3">
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                            <UserPopover {...userProps}>
                                <Link className="text-decoration-none pb-2" id="author-link" to={'/me/' + post.author.username}>
                                    <Avatar size="34px" src={post.author.photoURL} alt={post.author.firstName + ' ' + post.author.lastName}/>
                                </Link>
                            </UserPopover>
                        </div>
                        <div class="flex-grow-1 ms-3 n-lines-1">
                            <UserPopover {...userProps}>
                                <Link className="text-decoration-none text-dark" id="author-link" to={'/me/' + post.author.username}>
                                    <b>{`${post.author.firstName} ${post.author.lastName}`}</b>
                                </Link>
                            </UserPopover>
                            <span className="text-muted"> · {' '}
                                    <FormattedRelativeTime
                                    value={new SelectTimeUnitService().forDate(new Date(post.createdAt)).value}
                                    unit={new SelectTimeUnitService().forDate(new Date(post.createdAt)).unit}
                                    numeric="auto"/>
                            </span>
                        </div>
                    </div>
                    <div>
                        <Dropdown autoClose={true} className="align-self-center">
                            <Dropdown.Toggle variant="link" size="sm" className="text-dark btn-icon border-0 rounded-circle">
                                <i className="fs-lg bi bi-three-dots"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-macos shadow">
                                <Dropdown.Item onClick={(e) => {
                                    e.stopPropagation()
                                    this.props.onReport(e, post)}}>
                                        <FormattedMessage id="REPORT_DROPDOWN_ITEM"/>
                                    </Dropdown.Item>
                                {post.author.id && disabledWrite === false &&
                                    <Dropdown.Item onClick={(e) => this.props.onEdit(e, post)}>
                                        <FormattedMessage id="EDIT_DROPDOWN_ITEM"/>
                                    </Dropdown.Item>
                                }
                                {post.author.id && disabledWrite === false &&
                                    <Dropdown.Item onClick={(e) => this.props.onDelete(e, post)}>
                                        <FormattedMessage id="DELETE_DROPDOWN_ITEM"/>
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Card.Header>
        )
    }
}

export default PostCardHeader