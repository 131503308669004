import { createClient } from '@supabase/supabase-js'
import User from '../../models/User'
import ListOccupationsService from '../../services/ListOccupationsService'

import UploadUserPhotoService from '../../services/UploadUserPhotoService'

function includeKeysFromObject(keys, anObject) {
    return Object.fromEntries(
        Object.entries(anObject)
        .filter((x) => keys.includes(x[0]))
    )
}

class UserRepository {
    instance = undefined
    
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)

        this.uploadUserPhoto = new UploadUserPhotoService()
    }

    static getRepoState() {
        if (UserRepository.instance === undefined) {
            UserRepository.instance = new UserRepository()
        }
        return UserRepository.instance
    }

    add(user) {
        return this.supabase
        .from('user')
        .insert({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: user.firstName + ' ' + user.lastName,
            username: user.username,
            birthday: user.birthday,
            countryCode: user.countryCode,
            regionCode: user.regionCode,
            photoURL: user.photoURL,
            sex: user.sex,
            bio: user.bio,
            occupationCodes : user.occupationCodes,
            languageCode: user.languageCode,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt
        })
        .then((response) => {
            var {error} = response;
            if (error) {
                return Promise.reject(error);
            }
            // this doesn't work, not type User but Object.
            // var newUser = Object.assign({}, user);
            return user;
        })
    }

    update(user, data) {
        return this.uploadUserPhoto.forUserId(user.id, data.photo ?? null)
        .then((photoURL) => {
            var allowedData = includeKeysFromObject([
                'firstName', 'lastName', 'username',
                'birthday', 'countryCode', 'regionCode',
                'sex', 'bio', 'occupationCodes', 'languageCode'
            ], data)

            if (photoURL) {
                allowedData['photoURL'] = photoURL
            }

            if (allowedData["firstName"] && allowedData["lastName"]) {
                allowedData["fullName"] = allowedData["firstName"] + ' ' + allowedData["lastName"]
            }

            return this.supabase
            .from('user')
            .update({...allowedData})
            .eq('id', user.id)
            .select('*')
        })
        .then((response) => {
            var {error, data} = response
            if (error) {
                return Promise.reject(error)
            }
            let users = data
            if (users.length === 0) {
                return null
            }
            let userData = users[0]
            return new User({...userData})
        })
    }

    /**
     * Fetches a list of users matching the criteria
     * @param {Criteria} criteria - A criteria value object used to filter the query response.
     * @param {Pagination} pagination - A pagination value object used to range limit the query response.
     * @param {Order} order - An order value object used to order the query response.
     * @return {User[]} Returns a Promise with a list of users matching the criteria or rejects with an error.
     */
    matching(criteria, pagination, order) {
        var query = this.supabase
        .from('user')
        .select('*')
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        
        if (criteria.isEmpty() === false) {
            criteria.forEach((column, operator, value) => {
                if (operator == 'or') {
                    query = query.or(value)
                } else {
                    query = query.filter(column, operator, value)
                }
            })
        }
        
        return query
        .order(order.column, { ascending: order.ascending })
        .then((response) => {
            var {error, data} = response
            if (error) {
                return Promise.reject(error)
            }
            let users = data.map((x) => new User({...x}))
            return users
        })
    }

    findById(id) {
        return this.supabase
        .from('user')
        .select('*')
        .eq('id', id)
        .is('deletedAt', null)
        .limit(1)
        .then((response) => {
            var {error, data} = response
            if (error) {
                return Promise.reject(error)
            }
            let users = data;
            if (users.length === 0) {
                return null;
            }
            let userData = users[0]
            return new User({...userData})
        })
    }

    findByUsername(username) {
        return this.supabase
        .from('user')
        .select('*')
        .eq('username', username)
        .is('deletedAt', null)
        .limit(1)
        .then((response) => {
            var {error, data} = response
            if (error) {
                return Promise.reject(error)
            }
            let users = data
            if (users.length === 0) {
                return null
            }
            let userData = users[0]
            return new User({...userData})
        })
    }

    findBySession() {
        return this.supabase.auth
        .getSession()
        .then((response) => {
            let { data, error } = response;
            if (error) {
                return Promise.reject(error)
            }
            let session = data && data.session
            return session;
        })
        .then((session) => {
            let user = session && session.user
            if (user == null) {
                return null
            }
            return this.supabase
            .from('user')
            .select('*')
            .eq('id', user.id)
            .is('deletedAt', null)
            .limit(1)
        })
        .then((response) => {
            if (response == null) {
                return null
            }
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            if (data.length == 0) {
                return null;
            }
            let userData = data[0]
            return new User({...userData})
        })
    }

    findByOccupationCode(occupationCode, regionCode, pagination, order) {
        var occupationCodes = []

        if (occupationCode.toString().length < 4) {
            let listOccupations = new ListOccupationsService()
            var occupations = listOccupations.collectUnitsWithCode(occupationCode)
            occupationCodes = occupations.map((x) => parseInt(x.code))
        } else {
            occupationCodes = [occupationCode]
        }

        var query = this.supabase
        .from('user')
        .select('*')
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        .overlaps('occupationCodes', occupationCodes)
        .is('deletedAt', null)

        if (regionCode) {
            var fragments = regionCode.split('-')
            const isRegionCode = fragments.length === 2
            query = isRegionCode ? query.eq('regionCode', regionCode.toUpperCase()) : query.eq('countryCode', regionCode.toUpperCase())
        }

        return query
        .order(order.column, { ascending: order.ascending })
        .then((response) => {
            var {error, data} = response
            if (error) {
                return Promise.reject(error)
            }
            let users = data.map((x) => new User({...x}))
            return users
        })
    }

    delete(user) {
        return this.supabase
        .from('user')
        .update({deletedAt: new Date()})
        .eq('id', user.id)
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            return user.id
        })
    }
}

export default UserRepository;