/**
 * A value object used to provide basic pagination support.
 */
class Pagination {
    /**
     * @construct Pagination
     * @param {number} offset - An offset.
     * @param {number} limit - A limit (defaults to 12).
     */
    constructor(offset, limit) {
        this.offset = offset || 0; 
        this.limit = limit || 12;
    }

    setNext() {
        this.nextOffset = this.offset + this.limit
        return this
    }
}

export default Pagination