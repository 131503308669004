import React, { Component } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../../assets/logo.png'
import UserNotification from '../../../models/common/UserNotification'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import createNotification from '../../../redux/actions/create_notification'
import AuthService from '../../../services/AuthService'

class Forgot extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            isLoading: false,
            isValidated: false,
            errors: []
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        var { email } = this.state

        this.setState({
            isValidated: true,
            isLoading: true
        })

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isLoading: false })
            return;
        }

        let auth = new AuthService()
        auth.sendResetPassword(email)
        .then(() => {
            this.props.createNotification(new UserNotification({
                name: this.props.intl.formatMessage({id: "RESET_PASSWORD_EMAIL_SENT_NOTIFICATION"}),
                object: null,
                userInfo: {}
            }))
            this.props.navigate('/login')
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    render() {
        var { isLoading, isValidated, email, errors } = this.state
        return (
            <div className="page-wrapper vh-100">
                <div className="d-flex flex-column align-items-center position-relative h-100 px-3 pt-5">
        
                    <Link to="/" className="text-nav btn btn-icon bg-light border rounded-circle position-absolute top-0 end-0 p-0 mt-3 me-3 mt-sm-4 me-sm-4" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Back to home">
                        <img width="32" height="32" src={Logo} />
                    </Link>

                    <div className="mt-auto" style={{maxWidth: "700px"}}>
                        <h1 className="pt-3 pb-2 pb-lg-3">
                            <FormattedMessage id="FORGOT_YOUR_PASSWORD_HEADER"/>
                        </h1>
                        <p className="pb-2">
                            <FormattedMessage id="CHANGE_PASSWORD_TEXT"/>
                        </p>
                        <ul className="list-unstyled pb-2 pb-lg-0 mb-4 mb-lg-5">
                            <li className="d-flex mb-2"><span className="text-primary fw-semibold me-2">1.</span><FormattedMessage id="CHANGE_PASSWORD_STEP_1"/></li>
                            <li className="d-flex mb-2"><span className="text-primary fw-semibold me-2">2.</span><FormattedMessage id="CHANGE_PASSWORD_STEP_2"/></li>
                            <li className="d-flex mb-2"><span className="text-primary fw-semibold me-2">3.</span><FormattedMessage id="CHANGE_PASSWORD_STEP_3"/></li>
                        </ul>
                        <div className="card dark-mode border-0 bg-primary">
                            <Form onSubmit={this.handleSubmit} noValidate validated={isValidated} className="card-body">
                                { errors.length > 0 &&
                                    <Alert>
                                        <ul>
                                            {errors.map((error) =>
                                                <li>{error.message}</li>
                                            )}
                                        </ul>
                                    </Alert>
                                }
                                <div className="mb-4">
                                    <div className="position-relative">
                                        <Form.Control
                                            onChange={this.handleInputChange}
                                            name="email"
                                            size="lg"
                                            type="email"
                                            value={email}
                                            placeholder={this.props.intl.formatMessage({id: 'EMAIL_INPUT_PLACEHOLDER'})}
                                            required
                                            autoFocus
                                        />
                                    </div>
                                </div>
                                <Button variant="light" type="submit">
                                    {isLoading &&
                                        <Spinner
                                            variant="primary"
                                            className="me-2"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                    <FormattedMessage id="GET_NEW_PASSWORD_BTN"/>
                                </Button>
                            </Form>
                        </div>
                    </div>
                    <p className="w-100 fs-sm pt-5 mt-auto mb-5" style={{maxWidth: "700px"}}>
                        <span className="text-muted">
                            <FormattedMessage
                                id="ALL_RIGHTS_RESERVED_FORMAT"
                                values={{
                                    year: new Date().getFullYear(),
                                    brand: "Aria"
                                }}/>
                        </span>
                    </p>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let navigate = useNavigate();
    let intl = useIntl()
    return <Forgot {...props} navigate={navigate} intl={intl} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps)