import { combineReducers } from 'redux';
import ReducerChangedAuthState from './reducer_changed_auth_state.js';
import ReducerSetModal from './reducer_set_modal.js';
import ReducerNotification from './reducer_notification.js';
import ReducerRouteStateChanged from './reducer_route_state_changed.js';
import ReducerSetFallbackRegionCode from './reducer_set_fallback_region.js'

const rootReducer = combineReducers({
  changedAuthState: ReducerChangedAuthState,
  setModal: ReducerSetModal,
  notification: ReducerNotification,
  routeStateChanged: ReducerRouteStateChanged,
  setFallbackRegion: ReducerSetFallbackRegionCode,
});

export default rootReducer;
