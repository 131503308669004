import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

class SidebarButton extends Component {
    render() {
        return (
            <Button onClick={this.props.onClick} size="sm" variant="primary" className="d-lg-none fs-sm w-100 rounded-0 fixed-bottom" data-bs-toggle="offcanvas" data-bs-target="#sidebarBlog">
                <i className="bi bi-layout-sidebar-inset-reverse me-2"></i><FormattedMessage id="SIDEBAR_BTN"/>
            </Button>
        )
    }
}

export default SidebarButton