class Timezone {
    constructor({value, abbr, offset, isdst, text, utc}) {
        this.value = value
        this.abbr = abbr
        this.offset = offset
        this.isdst = isdst
        this.text = text
        this.utc = utc
    }
}

export default Timezone