import { SupabaseClient } from "@supabase/supabase-js";
import Metrics from "../../models/Metrics";
import Post from "../../models/Post";
import Reaction from "../../models/Reaction";
import User from "../../models/User";

function convertEntityToObject(x) {
    if (x.type === 'image') {
        return {
            type: x.type,
            src: x.src,
            alt: x.alt
        }
    } else if (x.type === 'video') {
        return {
            type: x.type,
            src: x.src
        }

    } else if (x.type === 'link') {
        return {
            type: x.type,
            url: x.url,
            text: x.text
        }
    } else if (x.type === 'embed') {
        return {
            type: x.type,
            caption: x.caption,
            embed: x.embed,
            height: x.height,
            service: x.service,
            source: x.source,
            width: x.width            
        }
    }
}

/**
 * A subroutine used in the add method which inserts a row in the post table.
 * @param {SupabaseClient} client - A supabase client
 * @param {Post} post - A post model instance
 * @return {Post} Returns a post
 */
export function insertPost(client, post) {
    return client
    .from('post')
    .insert({
        id: post.id,
        title: post.title,
        preview: post.preview,
        downloadURL: post.downloadURL,
        blocksURL: post.blocksURL,
        source: post.source,
        regionCode: post.regionCode,
        countryCode: post.countryCode,
        languageCode: post.languageCode,
        inReplyToPostId: post.inReplyToPostId,
        inReplyToUsername: post.inReplyToUsername,
        userId: post.author.id,
        entities: post.entities.map((entity) => convertEntityToObject(entity)),
        createdAt: post.createdAt,
        updatedAt: post.updatedAt,
        deletedAt: post.deletedAt
    })
    .select()
    .then((response) => {
        var { data, error } = response;
        if (error) {
            return Promise.reject(error);
        }
        if (data.length === 0) {
            return Promise.reject(new Error('No result was returned when inserting post into table. Did you use .select()'))
        }
        let result = data[0]
        return new Post({
            id: result.id,
            ...data[0]
        })
    })
}

/**
 * A subroutine used in the add method which inserts a row (for the corresponding post) in the metrics table (with defaulting zero values).
 * @param {SupabaseClient} client - A supabase client
 * @param {Post} post - A post model instance
 * @return {Post} Returns a post
 */
export function insertEmptyMetricsForPost(client, post) {
    return client
    .from('metrics')
    .insert({
        postId: post.id,
        views: 0,
        replies: 0,
        reactions: 0
    })
    .then((response) => {
        var { error } = response;
        if (error) {
            return Promise.reject(error);
        }
        return post
    })
}

/**
 * A subroutines that gives you the default post selection criteria required to construct a post model.
 * @return {string} Returns a string with a default post selection.
 */
export function getDefaultPostSelection() {
    return '*, author:userId(*), reactions:reaction(*), metrics(*)'
}

/**
 * A subroutine which converts a list of objects to a list of posts. 
 * @param {object[]} data - A list of objects that needs to be converted to a list of post models.
 * @return {Post[]} Returns a list a posts
 */
export function convertToPosts(data) {
    return data.map((result) => {
        var post = new Post({
            ...result,
            author: new User({...result.author}),
            metrics: result.metrics ? new Metrics(result.metrics.views, result.metrics.reactions, result.metrics.replies) : new Metrics() // Note: shouldn't be optional.
        })
        if (result.reactions) {
            var updatedReactions = []
            result.reactions.forEach((x) => {
                updatedReactions.push(new Reaction({
                    userId: post.author.id,
                    postId: post.id,
                    emoji: x.emoji
                }))
            })
            post.reactions = [...updatedReactions]
        }
        return post
    })
}

export function insertPostDeletionSpecification(client, post, deletionSpecification) {
    return Promise.resolve(post)
    
    if (deletionSpecification == null || deletionSpecification == undefined) {
        return Promise.resolve(post)
    }
    return client
    .from('post_deletion_specification')
    .insert({
        postId: post.id,
        feature: deletionSpecification.toJSON()
    })
    .then((response) => {
        var {error} = response
        if (error) {
            return Promise.reject(error)
        }
        return post
    })
}