import { createClient } from '@supabase/supabase-js';

class ReportingService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    addReport(report) {
        return this.supabase
        .from('report')
        .insert({
            createdAt: report.createdAt,
            updatedAt: report.updatedAt,
            reason: report.reason,
            description: report.description,
            objectType: report.objectType,
            objectId: report.objectId,
            userId: report.userId,
            isEnforced: report.isEnforced
        })
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            return true
        })
    }
}

export default ReportingService