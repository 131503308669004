/**
 * A criteria value object used as an auxillary to construct filter operations.
 * It is a postgres specific implementation. Compatible with the postgrest operators listed here: https://postgrest.org/en/stable/api.html?highlight=.sl#operators
 */
class Criteria {
    constructor() {
        this.clauses = []
    }

    /**
     * Checks whether a criteria instance is empty.
     * @return {boolean} Returns yes if criteria is empty else no.
     */
    isEmpty() {
        return this.clauses.length === 0
    }

    /**
     * Iterates through each of the criteria clauses.
     * @param {function(string, string, any)} callbackfn - A callback function which returns the three arguments: column, operator and value.
     */
    forEach(callbackfn) {
        this.clauses.forEach(([column, operator, value]) => {
            callbackfn(column, operator, value)
        })
    }

    toString() {
        return this.clauses.map((clause) => {
            let column = clause[0]
            let operator = clause[1]
            let value = clause[2]
            return `${column}.${operator}.${value}`
        })
    }

    or(criterias) {
        let value = criterias.map((x) => {
            let singleClause = x.clauses.length == 1
            if (singleClause) {
                return x.toString()
            }
            return `and(${x.toString()})`
        }).join(', ')
        this.clauses.push([null, 'or', value])
        return this
    }

    /**
     * Adds a generic where criteria.
     * @param {string} column - The target column
     * @param {string} operator - Any postgrest filter operator supported see: https://postgrest.org/en/stable/api.html?highlight=.sl#operators
     * @param {any} value - A target value
     * @return {Criteria} Returns the same criteria.
     */
    where(column, operator, value) {
        var filterOperators = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'like', 'ilike', 'is', 'in', 
            'cs', 'cd', 'sl', 'sr', 'nxl', 'nxr', 'adj', 'ov', 'fts', 'plfts', 'phfts', 'wfts']
        if (filterOperators.includes(operator) === false) {
            return
        }
        this.clauses.push([column, operator, value])
        return this
    }

    equalTo(column, value) {
        const operator = 'eq'
        return this.where(column, operator, value)
    }

    notEqualTo(column, value) {
        const operator = 'neq'
        return this.where(column, operator, value)
    }

    greaterThanEqual(column, value) {
        const operator = 'gte'
        return this.where(column, operator, value)
    }

    lessThan(column, value) {
        const operator = 'lt'
        return this.where(column, operator, value)
    }

    lessThanEqual(column, value) {
        const operator = 'lte'
        return this.wheree(column, operator, value)
    }

    like(column, value) {
        const operator = 'like'
        return this.where(column, operator, value)
    }

    ilike(column, value) {
        const operator = 'ilike'
        return this.where(column, operator, value)
    }

    is(column, value) {
        const operator = 'is'
        return this.where(column, operator, value)
    }

    in(column, value) {
        const operator = 'in'
        return this.where(column, operator, value)
    }

    contains(column, value) {
        const operator = 'cs'
        return this.where(column, operator, value)
    }

    containedIn(column, value) {
        const operator = 'cd'
        return this.where(column, operator, value)
    }

    strictlyLeft(column, value) {
        const operator = 'sl'
        return this.where(column, operator, value)
    }

    strictlyRight(column, value) {
        const operator = 'sr'
        return this.where(column, operator, value)
    }

    doesNotExtendToLeftOf(column, value) {
        const operator = 'nxl'
        return this.where(column, operator, value)
    }

    doesNotExtendToRightOf(column, value) {
        const operator = 'nxr'
        return this.where(column, operator, value)
    }

    isAdjacentTo(column, value) {
        const operator = 'adj'
        return this.where(column, operator, value)
    }

    overlaps(column, value) {
        const operator = 'ov'
        var formattedValue = '{' +  value.map((x) => `"${x}"`).join(",") + '}'
        return this.where(column, operator, formattedValue)
    }
    
    fullTextSearchUsingQuery(column, value) {
        const operator = 'fts'
        return this.where(column, operator, value)
    }

    fullTextSearchUsingPlain(column, value) {
        const operator = 'plfts'
        return this.where(column, operator, value)
    }

    fullTextSearchUsingPhrase(column, value) {
        const operator = 'phfts'
        return this.where(column, operator, value)
    }

    fullTextSearchUsingWebSearch(column, value) {
        const operator = 'wfts'
        return this.where(column, operator, value)
    }
}

export default Criteria