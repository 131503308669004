import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroller';

class DashboardList extends Component {
    renderLoading() {
        return (
            <div key="loading-container" className="text-center p-3">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    shouldCatchInfiniteCall() {
        // left empty
    }

    render() {
        var { variant } = this.props

        return (
            <InfiniteScroll
                style={{...this.props.style} ?? ""}
                isReverse={this.props.isReverse ?? false}
                initialLoad={false}
                className={"list-group list-group-flush " + this.props.className ?? ''}
                pageStart={0}
                loadMore={this.props.isLoading || this.props.hasMore === false ? this.shouldCatchInfiniteCall() : this.props.loadMore}
                hasMore={this.props.hasMore}
                loader={this.props.loader ?? this.renderLoading()}
            >
                {this.props.children}
            </InfiniteScroll>
        )
    }
}

export default DashboardList