import { createClient } from '@supabase/supabase-js';

class UploadAttachmentsService {
    constructor() {
        this.supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(this.supabaseUrl, supabaseKey)
    }

    forChatRoom(chatRoom, attachments) {    
        let bucket = 'chat_rooms'

        var uploads = []
        attachments.forEach((file) => {
            let upload = this.supabase
            .storage
            .from(bucket)
            .upload(`${chatRoom.roomId}/attachments/${file.name}`, file, {
                cacheControl: '3600',
                upsert: true
            })
            uploads.push(upload)
        })

        return Promise.all(uploads)
        .then((responses) => {
            var paths = []
            responses.map((response) => {
                var { data, error } = response
                if (error) {
                    return Promise.reject(error)
                }
                var { path } = data
                paths.push(path)
            })
            return paths
        })
    }
}

export default UploadAttachmentsService