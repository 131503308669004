import React, { Component } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {Card, ListGroup, Spinner, Button, Form, Tab, Col, Row, Nav, Dropdown} from 'react-bootstrap'
import {startOfDay, startOfMonth, isToday, isTomorrow, isThisWeek, isYesterday, isThisMonth, format, formatISO, toDate, getTime, getMilliseconds} from 'date-fns'
import { today, isLastWeek, isLastMonth, isNextWeek, isNextMonth, getNextMonth, getPreviousMonth } from '../Events/events-fns'
import Pagination from "../../models/common/Pagination";
import Criteria from "../../models/common/Criteria";
import Order from "../../models/common/Order";
import Dashboard from "../Dashboard";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import authStateChanged from "../../redux/actions/auth_state_changed";
import createNotification from '../../redux/actions/create_notification';
import setModal from '../../redux/actions/set_modal';
import Post from "../../models/Post";
import { handleReplyToPost, handleDeletePost, handleReactToPost,
    setHasMore, setNextPagination, handleSharePost, handleReport, handleEditPost, handleEventDetails, handleDeleteEvent, handleEditEvent, getMatchingLabelForEvent, handleFeedack } from '../Dashboard/dashboard-fns'
import PostCard from "../../components/PostCard"
import { SwiperSlide } from "swiper/react"
import SearchInput from '../../components/SearchInput';
import { handleInputChange } from '../../scenes/Dashboard/dashboard-fns';
import UserNotification from "../../models/common/UserNotification";
import Reaction from "../../models/Reaction";
import WritePermissionPolicy from "../../policies/WritePermissionPolicy";
import User from "../../models/User";
import Avatar from "../../components/Avatar";
import CalendarEvent from "../../models/event/CalendarEvent";
import GroupListService from "../../services/common/GroupListService";
import MiniSearch from 'minisearch'
import ListOccupationsService from "../../services/ListOccupationsService";
import PopularPostsCard from "../../components/PopularPostsCard";
import { FormattedList, FormattedMessage, FormattedNumber, FormattedPlural, FormattedTime, useIntl } from "react-intl";
import GetCountryService from "../../services/GetCountryService";
import SidebarButton from "../../components/SidebarButton";
import FormatRegionCodeService from "../../services/FormatRegionCodeService";

class Search extends Component {
    constructor(props) {
        super(props)

        const search = this.props.location.search;
        const searchParams = new URLSearchParams(search)
        const query = searchParams.get('q') || "";
        const activeTab =  searchParams.get('tab') || 'posts'
        const searchFilter = searchParams.get('filter') || 'your_region'

        this.initState = {
            isLoading: false,
            hasMore: true,
            posts: [],
            users: [],
            services: [],
            groupedEvents: new Map(),
            popularPosts: [],
            errors: [],
            query: query,
            searchFilter: searchFilter,
            activeTab: activeTab,
            showSidebar: false,
            noResultsFound: false 
        }

        this.state = {...this.initState}

        this.tabs = ['posts', 'people', 'events', 'services']
        this.searchFilterOptions = ['your_region', 'your_country', 'anywhere'];

        this.writePermission = new WritePermissionPolicy()
        this.groupList = new GroupListService()
        this.listOccupations = new ListOccupationsService()
        this.getCountry = new GetCountryService()
        this.formatRegionCode = new FormatRegionCodeService()

        this.pagination = new Pagination(0, 20)
        this.criteria = new Criteria()
        this.order = new Order('createdAt', false)

        this.getNextPosts = this.getNextPosts.bind(this)
        this.getNextUsers = this.getNextUsers.bind(this)
        this.getNextEvents = this.getNextEvents.bind(this)
        this.getNextServices = this.getNextServices.bind(this)

        this.getPopular = this.getPopular.bind(this)
        this.handleSearch = this.handleSearch.bind(this)

        this.updatePostWithReaction = this.updatePostWithReaction.bind(this)

        this.setSearchFilter = this.setSearchFilter.bind(this)

        this.miniSearch = this.setupMiniSearchForServices()
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)
    }

    handleCloseSidebar() {
        this.setState({showSidebar: false})
    }

    handleShowSidebar() {
        this.setState({showSidebar: true})
    }

    updatePostWithReaction(post, newReaction) {
        this.props.createNotification(new UserNotification({
            name: this.props.intl.formatMessage({id: 'REACTION_WAS_ADDED_NOTIFICATION'}),
            object: newReaction,
            userInfo: {}
        }))
        this.setState((state) => {
            var posts = [...state.posts]
            
            var postIndex = posts.findIndex((x) => x.id === post.id)
            var updatedPost = posts[postIndex]

            var updatedReactions = [newReaction, ...updatedPost.reactions]
            updatedPost.reactions = updatedReactions
            updatedPost.metrics.reactions += 1

            posts[postIndex] = updatedPost

            return {
                posts: posts
            }
        })
    }

    handleSearch(query) {
        var { popularPosts, searchFilter, activeTab } = this.state
        var { currentUser } = this.props

        let newState = {...this.initState}
        newState.query = query
        newState.searchFilter = searchFilter
        newState.activeTab = activeTab
        newState.popularPosts = popularPosts

        this.setState({...newState})

        window.history.pushState({} , '', `?q=${query}&tab=${activeTab}&filter=${searchFilter}`)

        this.pagination = new Pagination(0, 20)
        this.criteria = new Criteria()

        let shouldApplySearchFilter = currentUser && searchFilter !== 'anywhere'

        if (shouldApplySearchFilter) {
            switch (searchFilter) {
                case 'your_region':
                    this.criteria.equalTo('regionCode', currentUser.regionCode)
                    break;
                
                case 'your_country':
                    this.criteria.equalTo('countryCode', currentUser.countryCode)
                    break;
            }
        }

        this.setState({isLoading: true})
        
        var queryPromise = undefined
        switch (activeTab) {
            case 'posts':
                this.criteria.or([
                    new Criteria().ilike('title', `*${query}*`),
                    new Criteria().fullTextSearchUsingWebSearch('preview', query)
                ])
                this.criteria.is('deletedAt', null)
                this.order = new Order('createdAt', false)
                queryPromise = this.getNextPosts()
                break;
            case 'people':
                this.criteria.or([
                    new Criteria().ilike('username', `*${query}*`),
                    new Criteria().ilike('firstName', `*${query}*`),
                    new Criteria().ilike('lastName', `*${query}*`),
                    new Criteria().ilike('fullName', `*${query}*`)
                ])
                this.criteria.is('deletedAt', null)
                this.order = new Order('createdAt', false)
                queryPromise = this.getNextUsers()
                break;
            case 'events':
                this.criteria.or([
                    new Criteria().ilike('title', `*${query}*`),
                    new Criteria().ilike('description', `*${query}*`)
                ])
                this.criteria.is('deletedAt', null)
                this.criteria.greaterThanEqual('startDate', format(new Date(), 'yyyy-MM-dd HH:mm:ss'))
                this.criteria.is('deletedAt', null)
                
                this.order = new Order('startDate', true)
                queryPromise = this.getNextEvents()
                break
            case 'services':
                let occupations = this.miniSearch.search(query, {
                    boost: { "title": 2 },
                    fuzzy: 0.4
                }) || []
                let occupationCodes = occupations.map((x) => parseInt(x.code))
                this.criteria.overlaps('occupationCodes', occupationCodes)
                this.criteria.is('deletedAt', null)

                this.order = new Order('createdAt', false)
                queryPromise = this.getNextServices()
                break
            default:
                queryPromise = Promise.resolve([])
                break;
        }

        queryPromise
        .then(() => {
            this.setState({
                isLoading: false,
                errors: []
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    setupMiniSearchForServices() {
        let miniSearch = new MiniSearch({
            fields: ['title', 'code', 'definition'],
            storeFields: ['title', 'code', 'definition']
        })
        let documents = this.listOccupations.atLevel(4).map((x, i) => {
            return {
                id: x.code,
                title: x.title,
                definition: x.definition,
                code: parseInt(x.code)
            }
        })
        miniSearch.addAll(documents)
        return miniSearch
    }

    updateEditedPostIfNeeded(editedPost) {
        if (this.state.activeTab !== 'posts') {
            return
        }
        this.setState((state) => {
            var updatedPosts = [...state.posts]
            for (let i = 0; i < state.posts.length; i++) {
                const candidate = state.posts[i];
                if (candidate.id === editedPost.id) {
                    updatedPosts[i] = editedPost
                    break
                }
            }
            return {
                posts: updatedPosts
            }
        })
    }

    removePostIfNeeded(deletedPost) {
        if (this.state.activeTab !== 'posts') {
            return
        }
        this.setState((state) => {
            var updatedPosts = state.posts.filter((post) => post.id !== deletedPost.id)
            return {
                posts: updatedPosts
            }
        })
    }

    updatePostsWithReplyIfNeeded(newReply) {
        if (this.state.activeTab !== 'posts') {
            return
        }
        this.setState((state) => {
            var updatedPosts = [...state.posts]
            for (let i = 0; i < state.posts.length; i++) {
                var candidate = state.posts[i];
                if (candidate.id === newReply.inReplyToPostId) {
                var updatedPost = candidate
                updatedPost.metrics.replies += 1
                updatedPosts[i] = updatedPost
                break
                }
            }
            return {
                posts: updatedPosts
            }
        })
    }

    updateEventsIfNeeded(newEvent) {
        if (this.state.activeTab !== 'events') {
            return
        }
        var label = getMatchingLabelForEvent(this, newEvent)
        let eventGroupNotExists = this.state.groupedEvents.get(label) == undefined
        if (eventGroupNotExists) {
             return
        }
 
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [candidate, events] = value
                
                if (candidate === label) {
                    var updatedEvents = [newEvent, ...events].sort((a, b) => {
                        let dateA = getMilliseconds(a.startDate)
                        let dateB = getMilliseconds(b.startDate)
                        if (dateA < dateB) {
                            return -1
                        }
                        if (dateA > dateB) {
                            return 1
                        }
                        return 0
                    });
                    updatedGroupedEvents.set(label, updatedEvents)
                    break
                }
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }
 
    updateEditedEventIfNeeded(editedEvent) {
        if (this.state.activeTab !== 'events') {
            return
        }
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [label, events] = value
                var updatedEvents = [...events]

                for (let i = 0; i < events.length; i++) {
                    const candidate = events[i];
                    if (candidate.id === editedEvent.id) {
                        updatedEvents[i] = editedEvent
                        break
                    }
                }
                updatedGroupedEvents.set(label, updatedEvents);
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }
 
    removeEventIfNeeded(deletedEvent) {
        if (this.state.activeTab !== 'events') {
            return
        }
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [label, events] = value
                var updatedEvents = events.filter((x) => x.id !== deletedEvent.id)
                updatedGroupedEvents.set(label, updatedEvents);
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }

    handleNotificationChangeIfNeeded(prevProps) {
        if (prevProps.notification !== this.props.notification) {
            let notification = this.props.notification
            if (notification == null) {
                return
            }
            let {type} = notification.userInfo
            if (type == null) {
                return
            }
            if (type == 'POST_EDITED') {
                let editedPost = notification.object
                this.updateEditedPostIfNeeded(editedPost)
            } else if (type == 'POST_DELETED') {
                let deletedPost = notification.object
                this.removePostIfNeeded(deletedPost)
            } else if (type == 'REPLY_CREATED') {
                let newReply = notification.object
                this.updatePostsWithReplyIfNeeded(newReply)
            } else if (type == 'EVENT_CREATED') {
                let newEvent = notification.object
                this.updateEventsIfNeeded(newEvent)
            } else if (type == 'EVENT_EDITED') {
                let editedEvent = notification.object
                this.updateEditedEventIfNeeded(editedEvent)
            } else if (type == 'EVENT_DELETED') {
                let removedEvent = notification.object
                this.removeEventIfNeeded(removedEvent)
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.handleNotificationChangeIfNeeded(prevProps)
    }

    componentDidMount() {
        var { query } = this.state
        if (query.length === 0) {
            this.setState({
                isLoading: false,
                hasMore: false
            })
        } else {
            this.handleSearch(query)
        }
        this.getPopular()
    }

    getPopular() {
        var { currentUser } = this.props
        let countryCode = currentUser != null ? currentUser.countryCode : ['DK']
        return Post
        .popularByCountry(countryCode, new Pagination(0, 7), new Order('views', false))
        .then((popularPosts) => {
            this.setState({
                popularPosts: popularPosts
            })
        })
    }

    setSearchFilter(e) {
        var { query } = this.state

        const target = e.target
        const name = target.name
        const searchFilter = name

        this.setState({searchFilter}, () => {
            if (query.length == 0) {
                return
            }
            this.handleSearch(query)
        })
    }

    getNextUsers() {
        var { query } = this.state
        if (query.length === 0) {
            return Promise.resolve([])
        }
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }
        return User
        .matching(this.criteria, this.pagination, this.order)
        .then((newUsers) => {
            this.pagination = setNextPagination(this.pagination, newUsers.length)
            this.setState({
                hasMore: setHasMore(this.pagination, newUsers.length),
                users: this.state.users.concat(newUsers),
                noResultsFound: (this.state.users.length + newUsers.length) == 0
            })
        })
    }

    getNextServices() {
        var { query } = this.state
        if (query.length === 0) {
            return Promise.resolve([])
        }
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }
        return User.matching(this.criteria, this.pagination, this.order)
        .then((newUsers) => {
            this.pagination = setNextPagination(this.pagination, newUsers)
            this.setState({
                services: this.state.services.concat(newUsers),
                hasMore: setHasMore(this.pagination, newUsers),
                noResultsFound: (this.state.services + newUsers.length) == 0
            })
        })
    }

    getNextPosts() {
        var { query } = this.state
        if (query.length === 0) {
            return Promise.resolve([])
        }
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }
        return Post
        .matching(this.criteria, this.pagination, this.order)
        .then((newPosts) => {
            this.pagination = setNextPagination(this.pagination, newPosts.length)
            this.setState({
                hasMore: setHasMore(this.pagination, newPosts.length),
                posts: this.state.posts.concat(newPosts),
                noResultsFound: (this.state.posts.length + newPosts.length) == 0
            })
        })
    }

    getNextEvents() {
        var { query } = this.state
        if (query.length === 0) {
            return Promise.resolve([])
        }
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }

        var pagination = this.pagination

        return CalendarEvent
        .matching(this.criteria, this.pagination, this.order)
        .then((newEvents) => {
            this.setState((state) => {
                var newGroupedEvents = this.groupList.by(newEvents, (event) => {
                    let label = getMatchingLabelForEvent(this, event)
                    return label
                })

                this.pagination = setNextPagination(pagination, newEvents.length)
                return {
                    hasMore: setHasMore(pagination, newEvents.length),
                    groupedEvents: new Map([...state.groupedEvents, ...newGroupedEvents]),
                    noResultsFound: (state.groupedEvents.size + newGroupedEvents.size) == 0
                }
            })
        })
    }

    renderPostListItem(post) {
        let canWrite = this.writePermission.isGranted(this.props.currentUser, {
            type: 'POST', object: post
        })

        return (
            <ListGroup.Item action onClick={(e) => {
                this.props.navigate(`/me/${post.author.username}/post/${post.id}`)
            }} className="py-0">
            <PostCard flush>
                <PostCard.Header
                    post={post}
                    disabledWrite={canWrite === false}
                    onDelete={() => handleDeletePost(this, post)}
                    onReport={() => handleReport(this, post.id, 'POST')}
                    onEdit={() => handleEditPost(this, post)}
                />
                <PostCard.Body>
                <PostCard.Preview>
                    {post.preview}
                </PostCard.Preview>
                <PostCard.Gallery>
                { post.entities.filter((x) => x.type === 'image' || x.type === 'embed').map((x) => (
                    <SwiperSlide>
                    { x.type === 'image' &&
                        <img style={{objectFit: "cover"}}
                            className="w-100 h-100"
                            alt={x.alt ?? ""}
                            src={x.src}
                        />    
                    }
                    { x.type === 'embed' &&
                        <div className="ratio ratio-16x9">
                            <iframe src={x.embed} frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>
                        </div>
                    }
                    </SwiperSlide>
                ))}
                </PostCard.Gallery>
                </PostCard.Body>
                <PostCard.Footer
                post={post}
                onReact={(emoji, post) => {
                    handleReactToPost(this, emoji, post)
                    .then(() => {
                        let newReaction = new Reaction({userId: this.props.currentUser.id, postId: post.id, emoji})
                        this.updatePostWithReaction(post, newReaction)
                    })
                }}
                onReply={(e) => handleReplyToPost(this, post, this.deletionSpecification)}
                onShare={(e, action, post) => handleSharePost(this, action, post)}
                />
            </PostCard>
            </ListGroup.Item>
        )
    }
    
    renderPostList(isLoading, posts, hasMore) {
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => this.getNextPosts()}>
                {posts.map((post) =>
                    this.renderPostListItem(post)
                    )
                }
            </Dashboard.List>
        )
    }

    renderNoResultsFound() {
        var { query } = this.state
        if (query.length === 0) {
            return null
        }

        return (
            <div className="text-center px-3 py-5">
                <p><FormattedMessage id="NO_RESULTS_FOUND_MESSAGE"/></p>
                <Button onClick={(e) => this.handleSearch(query)}>
                    <FormattedMessage id="TRY_AGAIN_BTN"/>
                </Button>
            </div>
        )
    }

    renderEventItem(event) {
        var { currentUser } = this.props

        let weekday = CalendarEvent.getLocalizedWeekdays()[new Date(event.startDate).getDay()]
        let isOwn = currentUser && currentUser.id === event.author.id

        return (
            <ListGroup.Item action onClick={(e) => handleEventDetails(this, event)} className="d-flex justify-content-start p-3">
                <div style={{width: "65px", height: "65px"}} className="d-flex flex-column justify-content-center bg-light rounded text-dark border text-center me-4">
                    <h6 className="text-dark mb-0">{format(new Date(event.startDate), 'dd')}</h6>
                    <small className='text-danger'>{weekday.slice(0, 3)}</small>
                    
                </div>
                <div className="align-self-center">
                    <div class="fw-regular h6 mb-1 n-lines-1">{event.title}</div>
                    <p class="text-body fs-sm mb-0 n-lines-2">
                        <FormattedTime value={event.startDate}/> — <FormattedTime value={event.getEndDate()}/><br/>
                        {event.isOnline && event.timezone}
                        {event.isOnline === false && event.address}
                    </p>
                </div>
                <Dropdown
                    onToggle={(nextShow, meta) => {
                        let { originalEvent } = meta
                        originalEvent.stopPropagation()
                    }}
                    autoClose={true}
                    className="ms-auto align-self-center">
                    <Dropdown.Toggle variant="link" size="sm" className="text-dark btn-icon border-0 rounded-circle">
                        <i className="fs-lg bi bi-three-dots"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-macos shadow">
                        <Dropdown.Item onClick={(e) => handleReport(this, event.id, 'EVENT')}>
                            <FormattedMessage id="REPORT_DROPDOWN_ITEM"/>
                        </Dropdown.Item>

                        { isOwn &&
                            <Dropdown.Item onClick={(e) => handleEditEvent(this, event)}>
                                <FormattedMessage id="EDIT_DROPDOWN_ITEM"/>
                            </Dropdown.Item>
                        }

                        { isOwn &&
                            <Dropdown.Item onClick={(e) => handleDeleteEvent(this, event)}>
                                <FormattedMessage id="DELETE_DROPDOWN_ITEM"/>
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </ListGroup.Item>
        )
    }

    renderEventList(isLoading, groupedEvents, hasMore) {
        var { isLoading, hasMore } = this.state
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({isLoading: true})
                    this.getNextEvents()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}>
                {Array.from(groupedEvents, ([label, events]) => (
                    <div>
                        <div className="text-dark bg-secondary px-3 py-1">
                            <small className="text-dark n-lines-1">{label}</small>
                        </div>
                        <ListGroup variant="flush">        
                            {events.map((event) => (
                                this.renderEventItem(event)
                            ))}
                        </ListGroup>
                    </div>
                ))}
            </Dashboard.List>
        )
    }

    renderUserListItem(user) {
        return (
            <ListGroup.Item onClick={(e) => this.props.navigate(`/me/${user.username}`)} className="px-4 py-4" action>
                <div className="d-flex align-items-center mb-0">
                    <Avatar
                        size="40px"
                        src={user.photoURL}
                        alt={user.firstName + ' ' + user.lastName}
                    />
                    <div className="ps-3">
                        <h6 className="mb-0">{user.firstName + ' ' + user.lastName}</h6>
                        <small className="text-muted">{`@${user.username}`}</small>
                    </div>
                </div>
            </ListGroup.Item>
        )
    }

    renderUserList(isLoading, users, hasMore) {
        var { isLoading, hasMore } = this.state
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({isLoading: true})
                    this.getNextUsers()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}
            >
                {users.map((user) => (
                    this.renderUserListItem(user)
                ))}
            </Dashboard.List>
        )
    }

    renderServiceListItem(user) {
        let userOccupations = user.occupationCodes
        .map((code) => this.listOccupations.getNode(code))
        .filter((occupation) => occupation !== null)

        return (
            <ListGroup.Item onClick={(e) => this.props.navigate(`/me/${user.username}`)} className="px-4 py-4" action>
                <div className="d-flex align-items-center mb-3">
                    <Avatar
                        size="40px"
                        src={user.photoURL}
                        alt={user.firstName + ' ' + user.lastName}
                    />
                    <div className="ps-3">
                        <h6 className="mb-0 n-lines-1">{user.firstName + ' ' + user.lastName}</h6>
                        <small className="text-muted n-lines-1">{`@${user.username}`}</small>
                    </div>
                </div>
                <p class="card-body mb-2">{user.bio}</p>
                <small class="text-muted n-lines-2">
                    <FormattedMessage
                        id="OCCUPATIONS_FORMAT_LIST"
                        values={{
                            occupation: <b><FormattedMessage id="OCCUPATION_PREFIX"/></b>,
                            list: <FormattedList  type="conjunction" value={userOccupations.map((x) => x.title)} />
                        }}/>
                </small>
            </ListGroup.Item>
        )
    }

    renderServiceList(isLoading, services, hasMore) {
        var { isLoading, hasMore } = this.state
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({isLoading: true})
                    this.getNextServices()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}
            >
                {services.map((user) => (
                    this.renderServiceListItem(user)
                ))}
            </Dashboard.List>
        )
    }

    renderPopularCard(popularPosts) {
        if (popularPosts.length == 0) {
            return
        }
        
        var { currentUser } = this.props
        let countryCode = currentUser != null ? currentUser.countryCode : ['DK']
        let country = this.getCountry.byCode(countryCode)

        let title = this.props.intl.formatMessage({id: 'POPULAR_IN_COUNTRY_CARD_HEADER_FORMAT'}, {
            country: country ? country.name : ''
        })
        
        return (
            <PopularPostsCard
                title={title}
                listItems={popularPosts.map((post) =>
                    <ListGroup.Item className="bg-transparent border-bottom-0 w-100">
                        <article class="justify-content-between position-relative d-flex align-items-center mb-0">
                            <div class="ps-e">
                                <small className="text-muted fs-xs n-lines-1">{this.formatRegionCode.withValue(post.regionCode, 'short') ?? ""}</small>
                                <h4 class="n-lines-1 h6 mb-0">
                                    <Link class="stretched-link text-dark" to={`/me/${post.author.username}/post/${post.id}`}>{post.title || 'Untitled'}</Link>
                                </h4>
                                <span class="n-lines-1 fs-sm text-muted">
                                    <FormattedNumber value={post.metrics.views} notation={'compact'}/>
                                    {' '} <FormattedPlural value={post.metrics.views} one="view" other="views" />
                                </span>
                            </div>
                            { post.entities.filter((x) => x.type == 'image').length > 0 &&
                                <img
                                style={{width: "46px", height: "46px", objectFit: "cover"}}
                                className="rounded-1"
                                src={post.entities.filter((x) => x.type == 'image')[0].src}
                                alt={post.entities.filter((x) => x.type == 'image')[0].alt}
                                />
                            }
                        </article>
                    </ListGroup.Item>
                )}
            />
        )
    }
    
    renderSearchFilter() {
        var { searchFilter } = this.state
        return (
            <Card className="mt-3 card">
                <Card.Body>
                    <h5 className="card-title fw-bold">
                        <FormattedMessage id="SEARCH_FILTER_CARD_TITLE"/>
                    </h5>
                    <Form>
                        <Form.Group className="mb-0">
                            {this.searchFilterOptions.map((x) => (
                                <Form.Check
                                    type='radio'
                                    name={x.toLowerCase()}
                                    label={this.props.intl.formatMessage({id: `SEARCH_FILTER_OPTION_${x.toLocaleUpperCase()}`})}
                                    checked={x.toLowerCase() === searchFilter}
                                    onChange={this.setSearchFilter}
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    render() {
        var { isLoading, posts, users, groupedEvents, services, hasMore,
            popularPosts, query, activeTab, showSidebar, noResultsFound } = this.state
        return (
            <Dashboard childScene={this}>
                <Dashboard.Body>
                    <Dashboard.Navbar
                        showToggle={false}
                        goBack={-1}
                        customContent={
                            <SearchInput
                                className="w-100"
                                autoFocus={true}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    this.handleSearch(query)
                                }}
                                onChange={(e) => handleInputChange(this, e)}
                                query={query}
                            />
                        }
                        bg="light"
                        variant="dark"/>
                        <Tab.Container onSelect={(activeKey) => {
                            this.setState({activeTab: activeKey}, () => {
                                if (query.length == 0) {
                                    return
                                }
                                this.handleSearch(query)
                            })
                        }} defaultActiveKey={activeTab}>
                            <Nav variant="underline" className="border-top-0 border-bottom px-3 py-3 nav nav-tabs border-top mb-0">
                            { this.tabs.map((tab) =>
                                <Nav.Item>
                                    <Nav.Link className="rounded-pill px-4" eventKey={tab}>
                                        <FormattedMessage id={`NAV_ITEM_${tab.toUpperCase()}`}/>
                                    </Nav.Link>
                                </Nav.Item>
                            )} 
                            </Nav>
                            <Tab.Content>
                            { this.tabs.map((tab) => {
                                if (tab == 'posts') {
                                    return (
                                        <Tab.Pane eventKey={tab}>
                                            {this.renderPostList(isLoading, posts, hasMore)}
                                            {noResultsFound && this.renderNoResultsFound()}
                                        </Tab.Pane>
                                    )
                                } else if (tab == 'people') {
                                    return (
                                        <Tab.Pane eventKey={tab}>
                                            {this.renderUserList(isLoading, users, hasMore)}
                                            {noResultsFound && this.renderNoResultsFound()}
                                        </Tab.Pane>
                                    )
                                } else if (tab == 'events') {
                                    return (
                                        <Tab.Pane eventKey={tab}>
                                            {this.renderEventList(isLoading, groupedEvents, hasMore)}
                                            {noResultsFound && this.renderNoResultsFound()}
                                        </Tab.Pane>
                                    )
                                } else if (tab == 'services') {
                                    return (
                                        <Tab.Pane eventKey={tab}>
                                            {this.renderServiceList(isLoading, services, hasMore)}
                                            {noResultsFound && this.renderNoResultsFound()}
                                        </Tab.Pane>
                                    )
                                }
                            })}
                            </Tab.Content>
                        </Tab.Container>
                        <SidebarButton onClick={this.handleShowSidebar}/>
                </Dashboard.Body>
                <Dashboard.Context 
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    {this.renderSearchFilter()}
                    {this.renderPopularCard(popularPosts)}
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        notification: state.notification
    }
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let intl = useIntl()
    return <Search {...props} intl={intl} navigate={navigate} location={location}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps)