class SelectTimeUnitService {
    forDate(date) {
      const msPerMinute = 60 * 1000;
      const msPerHour = msPerMinute * 60;
      const msPerDay = msPerHour * 24;
      const msPerWeek = msPerDay * 7;
      const msPerMonth = msPerDay * 30;
      const msPerYear = msPerDay * 365;
      const current = Date.now();
      const elapsed = current - date;
      if (elapsed < msPerMinute) {
         return {
           value: -Math.floor(elapsed/1000),
           unit: 'second'
         }
      } else if (elapsed < msPerHour) {
         return {
           value: -Math.floor(elapsed/msPerMinute),
           unit: 'minute'
         }
      } else if (elapsed < msPerDay) {
        return {
          value: -Math.floor(elapsed/msPerHour),
          unit: 'hour'
        }
      } else if (elapsed < msPerWeek) {
        return {
          value: -Math.floor(elapsed/msPerDay),
          unit: 'day'
        }
      } else if (elapsed < msPerMonth) {
        return {
          value: -Math.floor(elapsed/msPerWeek),
          unit: 'week'
        }
      } else if (elapsed < msPerYear) {
        return {
          value: -Math.floor(elapsed/msPerMonth),
          unit: 'month'
        }
      } else {
        return {
          value: -Math.floor(elapsed/msPerYear),
          unit: 'year'
        }
      }
    }
  }
  
  export default SelectTimeUnitService;
  