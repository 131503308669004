import React, { Component, Fragment } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Card, Form, Button, ListGroup, Spinner, Dropdown, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Dashboard from '../../Dashboard'
import Message from '../../../models/chat/Message'
import { connect } from 'react-redux'
import ChatRoom from '../../../models/chat/ChatRoom'
import { handleFeedack, handleInputChange, setHasMore, setNextPagination } from '../../Dashboard/dashboard-fns'
import Pagination from '../../../models/common/Pagination'
import Order from '../../../models/common/Order'
import GroupListService from '../../../services/common/GroupListService'
import { getTime, intlFormat } from 'date-fns'
import DownloadAttachmentService from '../../../services/DownloadAttachmentService'
import PauseAutoScrollSpecification from '../../../specifications/PauseAutoScrollSpecification'
import ContactPolicy from '../../../policies/ContactPolicy'
import Avatar from '../../../components/Avatar'
import createNotification from '../../../redux/actions/create_notification'
import UserNotification from '../../../models/common/UserNotification'
import { bindActionCreators } from 'redux'
import EmojiPicker from 'emoji-picker-react'
import {Howl } from "howler";
import { FormattedMessage, useIntl } from 'react-intl'
import MiniSearch from 'minisearch'
import setModal from '../../../redux/actions/set_modal'

class ChatRoomDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: "",
            attachments: [],
            groupedMessages: new Map(),
            isLoading: false,
            hasMore: true,
            chatRoom: null,
            navbarHeight: null,
            participantsPagination: new Pagination(0, 3),
            showSidebar: false,
            showReactionDropdown: false,
            search: "",
            searchResults: []
        }

        this.newMessageSubscription = undefined

        this.pagination = new Pagination(0, 15)
        this.order = new Order('createdAt', false)

        this.attachmentsInputRef = React.createRef();
        this.listRef = React.createRef();

        this.miniSearch = this.setupMiniSearchForParticipants([])

        this.downloadAttachment = new DownloadAttachmentService()

        this.shouldPauseAutoScroll = false
        const thresholdY = 400
        this.pauseAutoScroll = new PauseAutoScrollSpecification(thresholdY)

        this.getChatRoom = this.getChatRoom.bind(this)
        this.handlePopoutWindow = this.handlePopoutWindow.bind(this)
        this.sendMessage = this.sendMessage.bind(this);
        this.appendMessage = this.appendMessage.bind(this);
        this.getPrevMessages = this.getPrevMessages.bind(this)
        
        this.focusAttachmentsInput = this.focusAttachmentsInput.bind(this)
        
        this.handleAttachmentsChange = this.handleAttachmentsChange.bind(this)
        this.handleDownloadAttachment = this.handleDownloadAttachment.bind(this)

        this.handleScroll = this.handleScroll.bind(this)
        this.scrollToLastMessageIfNeeded = this.scrollToLastMessageIfNeeded.bind(this)

        this.handleLeaveChat = this.handleLeaveChat.bind(this)
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)

        this.toggleEmojiPicker = this.toggleEmojiPicker.bind(this)
        this.handleSearchParticipants = this.handleSearchParticipants.bind(this)
    }

    setupMiniSearchForParticipants(participants) {
        let miniSearch = new MiniSearch({
            fields: ['id', 'firstName', 'lastName', 'username'],
            storeFields: ['id', 'firstName', 'lastName', 'username']
        })
        let documents = participants.map((x, i) => {
            return {
                id: x.id,
                firstName: x.firstName,
                lastName: x.lastName,
                username: x.username
            }
        })
        miniSearch.addAll(documents)
        return miniSearch
    }
    

    handleSearchParticipants(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        var { chatRoom } = this.state
        
        let results = this.miniSearch.search(value).map((x) => x.id)
        var searchResults = []
        if (results.length == 0) {
            searchResults = []
        } else {
            searchResults = chatRoom.participants.filter((x) => results.includes(x.id))
        }

        this.setState({
            [name]: value,
            searchResults
        })
    }

    toggleEmojiPicker(e) {
        if (this.state.showReactionDropdown) {
            this.setState({ showReactionDropdown: false });
        } else {
            this.setState({ showReactionDropdown: true });
        }
    }

    scrollToLastMessageIfNeeded(options) {
        var options = options || { behavior: "smooth" }
        if (this.shouldPauseAutoScroll) {
            return
        }
        if (this.listRef.current == null) {
            return
        }
        let parent = this.listRef.current.firstElementChild
        let lastChild = parent.lastElementChild
        if (lastChild == null || lastChild == undefined) {
            return
        }
        lastChild.scrollIntoView(options)
    }

    handleCloseSidebar() {
        this.setState({showSidebar: false})
    }

    handleShowSidebar() {
        this.setState({showSidebar: true})
    }

    handleDownloadAttachment(attachment) {
        this.downloadAttachment.inWindow(window, attachment)
        .catch((error) => {
            console.log("an error occurred trying to download attachment. surface to user.")
        })
    }

    focusAttachmentsInput() {
        this.attachmentsInputRef.current.click();
    }

    getChatRoom(roomId) {
        var { currentUser } = this.props

        let whitelisted = [currentUser.id]
        return ChatRoom.findByRoomId(roomId, whitelisted)
        .then((chatRoom) => {
            if (chatRoom === null) {
                return Promise.reject(new Error('Chat room not found.'));
            }
            this.setState({
                isLoading: false,
                errors: [],
                chatRoom: chatRoom,
                searchResults: chatRoom.participants.slice(0, 3)
            })
            this.miniSearch = this.setupMiniSearchForParticipants(chatRoom.participants)
            return chatRoom
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    adjustScrollHeightOnUpdates(oldHeight) {
        let newScrollHeight = document.body.scrollHeight
        window.scrollTo({
            top: newScrollHeight - oldHeight,
            left: 0,
            behavior: "instant",
        })
    }

    getPrevMessages() {
        let roomId = this.props.match.params.roomId
        return Message
        .findByRoomId(roomId, this.pagination, this.order)
        .then((prevMessages) => {
            let scrollHeight =  document.body.scrollHeight
            this.pagination = setNextPagination(this.pagination, prevMessages.length)
            this.setState((state) => {
                let m1 = [...state.groupedMessages].map(([name, messages]) => (messages)).flat(2)
                let groupedPrevMessages = new GroupListService().by([...prevMessages.reverse(), ...m1], (message) => {
                    let dateLabel = intlFormat(new Date(message.createdAt), {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    })
                    return dateLabel
                })
                return {
                    hasMore: setHasMore(this.pagination, prevMessages.length),
                    groupedMessages: groupedPrevMessages
                }
            }, () => {
                this.adjustScrollHeightOnUpdates(scrollHeight)
            })
            return prevMessages
        })
    }

    handleScroll() {
        this.shouldPauseAutoScroll = this.pauseAutoScroll.isSatisfiedBy(document)
    }

    subscribeToNewMessagesInChatRoom(chatRoom) {
        this.newMessageSubscription = Message.onNewMessageInChatRoom(chatRoom, (getMessage) => {
            getMessage.then((newMessage) => {
                var { currentUser } = this.props
                var isOwn = newMessage.user.id === currentUser.id
                if (isOwn == false) {
                    this.playNotificationSound()
                }
                this.appendMessage(newMessage)
            })
        })
    }

    componentDidMount() {
        var { currentUser } = this.props
        if (currentUser == null) {
            this.setState({ isLoading: false }, () => {
                this.props.navigate('/chats')
            })
            return
        }

        window.addEventListener("scroll", this.handleScroll)

        this.setState({isLoading: true})

        let roomId = this.props.match.params.roomId
        this.getChatRoom(roomId)
        .then((chatRoom) => {
            if (chatRoom == null) {
                this.props.createNotification(new UserNotification({
                    name: this.props.intl.formatMessage({id: "CHAT_ROOM_NOT_FOUND_NOTIFICATION"}),
                    object: null,
                    userInfo: {}
                }))
                this.setState({ isLoading: false }, () => {
                    this.props.navigate('/chats')
                })
                return
            }
            this.subscribeToNewMessagesInChatRoom(chatRoom)
            return this.getPrevMessages(roomId)
        })
        .then(() => {
            this.setState({
                isLoading: false,
                errors: []
            }, () => {
                this.scrollToLastMessageIfNeeded({
                    behavior: 'instant'
                })}
            )
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
        if (this.newMessageSubscription) {
            this.newMessageSubscription.unsubscribe();
        }
    }

    appendMessage(newMessage) {
        this.setState((state) => {
            let dateLabel = intlFormat(new Date(newMessage.createdAt), {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            })
            var updatedGroupedMessages = state.groupedMessages
            const messages = updatedGroupedMessages.get(dateLabel);
            if (!messages) {
                updatedGroupedMessages.set(dateLabel, [newMessage]);
            } else {
                messages.push(newMessage);
            }
            return {
                groupedMessages: updatedGroupedMessages,
                text: "",
                attachments: []
            }
        }, () => {
            this.scrollToLastMessageIfNeeded()
        })
    }

    handleAttachmentsChange(e) {
        let attachments = Array.from(e.target.files)
        this.setState({attachments}, () => {
            this.sendMessage(e)
        })
    }

    handleLeaveChat(e) {
        var { currentUser } = this.props
        var { chatRoom } = this.state
        if (currentUser == null) {
            return
        }
        currentUser.leaveChatRoom(chatRoom)
        .then(() => {
            this.props.createNotification(new UserNotification({
                name: 'You left the chat.',
                object: chatRoom,
                userInfo: {}
            }))
            this.props.navigate('/chats')
        })
        .catch((error) => {
            this.props.createNotification(new UserNotification({
                name: 'Something went wrong.',
                object: error,
                userInfo: {}
            }))
        })
    }

    playNotificationSound() {
        const src = 'https://bspodttrfjifboigqtfv.supabase.co/storage/v1/object/public/assets/notifications-sound-127856.mp3'
        const sound = new Howl({
            src: src,
            html5: true
        })
        sound.play()
    }

    sendMessage(e) {
        e.preventDefault();
        var { chatRoom, text, attachments } = this.state;
        var { currentUser } = this.props;
        
        currentUser.sendMessage(chatRoom, text, attachments)
        .catch((error) => {
            console.log("surface error message when sending message in chat =>", error)
        })
    }

    renderMessageContent(message, isOwn) {
        let isTextMessage = message.content.length > 0
        if (isTextMessage) {
            return (
                <div style={{wordBreak: 'break-word', wordWrap: 'balance'}} className={isOwn ? "message-box-end bg-primary text-white" : "message-box-start text-dark"}>
                    { message.content }
                </div>
            )
        }
        
        return (
            <div>
            { message.attachments.map((attachment, i) =>
                <div className={isOwn ? `message-box-end bg-primary text-white ${i < message.attachments.length - 1 ? 'mb-2' : ''}` : `message-box-start text-dark ${i < message.attachments.length - 1 ? 'mb-2' : ''}`}>
                    <a style={{cursor: 'pointer'}}
                        onClick={(e) => this.handleDownloadAttachment(attachment)}
                        className={`${isOwn ? 'text-light' : 'text-dark'} text-underline n-lines-1`}>
                            {this.trimmedFilename(attachment.name, 25)}
                    </a>
                </div>
            )}
            </div>
        )
    }

    trimmedFilename(filename, limit) {
        let spacer = '.'
        const split = filename.indexOf(".");
        const name = filename.substring(0, split);
        const ext = filename.substring(split);
        
        let trimName = name.substring(0, limit);
        
        if (name.length > trimName.length)
            trimName = trimName.padEnd(limit + 3, spacer);
        
        return trimName + ext;
    }

    renderMessage(message) {
        var { currentUser } = this.props
        var isOwn = message.user.id === currentUser.id
        if (isOwn) {
            return (
                <div className="ms-auto mb-3" style={{maxWidth: "392px"}}>
                    <div className="d-flex align-items-end mb-2">
                    { this.renderMessageContent(message, isOwn) }
                   
                    <div className="flex-shrink-0 ps-2 ms-1">
                        <Avatar size="24px" src={message.user.photoURL} alt={message.user.firstName + ' ' + message.user.lastName}/>
                    </div>
                    </div>
                    <div className="fs-xs text-muted">
                        <i className="bi bi-check fs-xl text-muted mt-n1 me-1"></i>
                        <span className="d-none">Sent &middot; </span>
                        <div className="d-none spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>

                        {new Date(message.createdAt).toLocaleTimeString()}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="mb-3" style={{maxWidth: "392px"}}>
                    <div className="d-flex align-items-end mb-2">
                        <div className="flex-shrink-0 pe-2 me-1">
                            <Avatar size="24px" src={message.user.photoURL} alt={message.user.firstName + ' ' + message.user.lastName}/>
                        </div>
                        { this.renderMessageContent(message, isOwn) }
                    </div>
                    <div className="fs-xs text-muted text-end">
                        <i className="d-none bi bi-check-circle text-primary fs-xl mt-n1 me-1"></i>
                        {new Date(message.createdAt).toLocaleTimeString()}
                    </div>
                </div>
            )
        }
    }

    renderMessages() {
        var { groupedMessages, isLoading, hasMore } = this.state

        return (
            <div className="h-100 w-100" ref={this.listRef}>
                <Dashboard.List
                    isReverse={true}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    variant="flush"
                    className="p-3"
                    loadMore={() => {
                        this.setState({isLoading: true})
                        let oldScrollHeight =  document.body.scrollHeight
                        this.getPrevMessages()
                        .then(() => {
                            this.adjustScrollHeightOnUpdates(oldScrollHeight)
                            this.setState({
                                isLoading: false,
                                errors: []
                            })
                        })
                        .catch((error) => {
                            this.setState({
                                isLoading: false,
                                errors: [error]
                            })
                        })
                        
                    }}>
                    {[...groupedMessages].map(([label, messages]) => (
                        <Fragment>
                            <div className="text-muted text-center mb-4">{label}</div>
                            {messages.map((message) => (
                                this.renderMessage(message)
                            ))}
                        </Fragment>
                    ))}
                </Dashboard.List>
            </div>
        )
    }

    renderBottomToolbar() {
        var { text, showReactionDropdown } = this.state 
        return (
            <div className="bg-light py-3 border-top w-100 mt-auto sticky-bottom">
                <Form onSubmit={this.sendMessage}>
                    <div className="card-footer w-100 border-0 mx-0">
                        <div className="d-flex align-items-end border rounded-3 pb-3 pe-3 mx-3">
                            <textarea
                                onKeyDownCapture={(e) => {
                                    var key = window.event.keyCode;
                                    var hitReturnKey = key === 13 
                                    if (hitReturnKey == false) {
                                        return
                                    }
                                    this.sendMessage(e)
                                }}
                                required
                                value={text}
                                name="text"
                                onChange={(e) => handleInputChange(this, e)}
                                className="form-control border-0" rows="3" placeholder={this.props.intl.formatMessage({id: "CHATROOM_TEXTAREA_PLACEHOLDER"})} style={{"resize": "none"}}></textarea>
                            <div className="nav flex-nowrap align-items-center">
                                <Form.Control ref={this.attachmentsInputRef} name="attachments" onChange={this.handleAttachmentsChange} multiple type="file" accept="*/*" hidden />
                                <OverlayTrigger overlay={<Tooltip><FormattedMessage id="ATTACHMENT_TOOLTIP"/></Tooltip>}>
                                    <Button variant="link" onClick={this.focusAttachmentsInput} className="nav-link text-muted p-1 me-1"><i className="bi bi-paperclip fs-xl"></i></Button>
                                </OverlayTrigger>

                                <OverlayTrigger overlay={<Tooltip><FormattedMessage id="EMOJI_TOOLTIP"/></Tooltip>}>
                                    <Dropdown onClick={(e) => e.stopPropagation()} show={showReactionDropdown} onToggle={(nextShow, meta) => this.setState({showReactionDropdown: nextShow})}>
                                        <Dropdown.Toggle variant="link" size="sm" className="nav-link text-muted p-1 rounded-circle">
                                            <i className="bi bi-emoji-smile fs-xl"></i>
                                        </Dropdown.Toggle>
                                        
                                        <Dropdown.Menu className="border-0 bg-transparent py-0 mb-2">
                                            <EmojiPicker
                                                theme='light'
                                                onEmojiClick={({emoji}, e) => {
                                                    this.toggleEmojiPicker(e)
                                                    this.setState({text: emoji}, () => {
                                                        this.sendMessage(e)
                                                    })
                                                }}
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </OverlayTrigger>
                                <button className="btn btn-sm btn-secondary ms-3" type="submit">
                                    <FormattedMessage id="SEND_BTN"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }

    handlePopoutWindow(e) {
        // let popup = window.open("/chats/b5756101-3114-4d1f-9a19-03a4ca9a8038", null, "height=800,width=650,status=yes,toolbar=no,menubar=no,location=no");
    }

    renderLoading() {
        return (
            <div className="text-center p-3">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    renderEmpty() {
        return (
            <div className="w-100 text-center px-3 py-5">
                <p className="text-muted">
                    <FormattedMessage id="EMPTY_CHATROOM_MESSAGE"/>
                </p>
            </div>
        )
    }

    renderEmptyAttachments() {
        return (
            <div className="w-100 text-center p-3">
                <p className="text-muted">
                    <FormattedMessage id="EMPTY_ATTACHMENTS_MESSAGE"/>
                </p>
            </div>
        )
    }

    renderEmptyParticipants() {
        return (
            <div className="w-100 text-center p-3">
                <p className="text-muted">
                    <FormattedMessage id="EMPTY_PARTICIPANTS_MESSAGE"/>
                </p>
            </div>
        )
    }

    renderParticipantItem(user) {
        return (
            <Link to={`/me/${user.username}`} className="d-flex align-items-center text-decoration-none pb-3" href="#">
                <div className="position-relative flex-shrink-0 my-1">
                    <Avatar size="48px" src={user.photoURL} alt={user.firstName + ' ' + user.lastName}/>
                </div>
                <div className="d-flex justify-content-between w-100 ps-2 ms-1 my-1">
                <div className="me-3">
                    <div className="h6 mb-1 n-line-1">{user.firstName + ' ' + user.lastName}</div>
                    <p className="text-body fs-sm mb-0 n-line-1">@{user.username}</p>
                </div>
                <div className="d-none align-self-center text-end">
                    <Button className="p-0" variant="link"><i className="bi bi-chat"></i></Button>
                </div>
                </div>
            </Link>
        )
    }

    renderParticipantsCard () {
        var { currentUser } = this.props
        var { chatRoom, isLoading, search, searchResults } = this.state

        var participants = []
        if (chatRoom && isLoading == false) {
            var { participantsPagination } = this.state
            let n = participantsPagination.offset + participantsPagination.limit
            participants = chatRoom.participants.filter((x) => x.id !== currentUser.id).slice(0,n)
        }

        return (
            <Card className="my-3">
                <Card.Header className="">
                    <div className="position-relative">
                        <Form.Control
                            name="search"
                            value={search}
                            onChange={(e) => this.handleSearchParticipants(e)}
                            placeholder={this.props.intl.formatMessage({id: 'SEARCH_PARTICIPANTS_PLACEHOLDER'})}
                            type="text"/>
                        <i className="bi bi-search fs-lg text-dark position-absolute top-50 end-0 translate-middle-y me-3"></i>
                    </div>
                </Card.Header>
                <Card.Body className="">
                { isLoading && this.renderLoading() }
                { isLoading === false && chatRoom != null && chatRoom.participants.length === 0 && this.renderEmptyParticipants() }
                { isLoading === false && chatRoom != null && search.length > 0 && searchResults.slice(0, 3).map((user) =>
                    this.renderParticipantItem(user)
                )}
                { isLoading === false && chatRoom != null && search.length == 0 && participants.slice(0, 3).map((user) =>
                    this.renderParticipantItem(user)
                )}
                </Card.Body>
            </Card>
        )
    }

    renderAttachmentItem(attachment) {
        return (
            <div className="d-flex align-items-center text-decoration-none pb-3">
                <div className="d-flex justify-content-between w-100 ps-0 ms-1 my-1">
                    <div className="me-3">
                        <div className="h6 mb-1 n-lines-1">{this.trimmedFilename(attachment.name, 10)}</div>
                        <p className="text-body fs-sm mb-0 n-line-1">{attachment.size}</p>
                    </div>
                    <div className="align-self-center text-end">
                        <Button onClick={(e) => this.handleDownloadAttachment(attachment)} className="p-0" variant="link"><i className="bi bi-download"></i></Button>
                    </div>
                </div>
            </div>
        )
    }

    renderAttachmentsCard() {
        var { groupedMessages, isLoading } = this.state

        var attachments = []
        if (isLoading == false) {
            attachments = [...groupedMessages].map(([label, messages]) => 
                messages.map((message) => message.attachments)).flat(3)
        }

        return (
            <Card className="my-3 card border-0 bg-secondary">
                <Card.Body>
                    <h5 className="fw-bold card-title">
                        <FormattedMessage id="ATTACHMENTS_CARD_TITLE"/>
                    </h5>
                    { isLoading && this.renderLoading() }
                    { isLoading === false && groupedMessages.size == 0 && this.renderEmptyAttachments() }
                    { isLoading === false && groupedMessages.size > 0 &&
                        <ListGroup className="border-top-0 border-bottom-0 w-100" variant="flush">
                            {attachments.map((attachment) =>
                                this.renderAttachmentItem(attachment)
                            )}
                        </ListGroup>
                    }
                </Card.Body>
            </Card>
        )
    }

    render() {
        var { isLoading, groupedMessages, showSidebar } = this.state
        return (
            <Dashboard childScene={this}>
                <Dashboard.Body shouldRemoveBottomMargin={true}>
                    <Dashboard.Navbar
                        title="Chat"
                        showToggle={false}
                        goBack={-1}
                        bg="light"
                        variant="dark"
                        nav={
                            <Nav className="d-flex flex-row ms-auto align-items-center pe-0">
                                <Button size="sm" onClick={this.handleShowSidebar} variant="outline-secondary" className="d-lg-none btn-icon border-0 rounded-circle me-2">
                                    <i className="bi bi-layout-sidebar-inset-reverse me-0"></i>
                                </Button>
                                <Dropdown className="dropdown me-0">
                                    <Dropdown.Toggle variant="outline-secondary" size="sm" className="btn-icon border-0 rounded-circle me-n2">
                                        <i className="fs-lg bi bi-three-dots fw-bold"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu flip={true} className="dropdown-menu dropdown-menu-end my-1">
                                        <Dropdown.Item style={{cursor: 'pointer'}} onClick={this.handleLeaveChat} className="dropdown-item">
                                            <FormattedMessage id="LEAVE_CHAT_DROPDOWN_ITEM"/>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="d-none dropdown-item" href="#">
                                            Block user
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        }
                        getNavbarHeight={(height) => {
                            if (this.state.navbarHeight !== null) {
                                return
                            }
                            this.setState({navbarHeight: height})
                        }}/>
                    <div style={{minHeight: `calc(100% - ${this.state.navbarHeight}px)`}} className="w-100 d-flex align-items-start justify-content-between flex-column">
                        {isLoading === false && groupedMessages.size === 0 && this.renderEmpty()}
                        {this.renderMessages()}
                        {this.renderBottomToolbar()}
                    </div>
                </Dashboard.Body>
                <Dashboard.Context
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    {this.renderParticipantsCard()}
                    {this.renderAttachmentsCard()}
                </Dashboard.Context>
            </Dashboard >
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createNotification: createNotification,
        setModal: setModal
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let params = useParams()
    let navigate = useNavigate()
    let intl = useIntl()
    return <ChatRoomDetails {...props} intl={intl} navigate={navigate} match={{params: params}} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);