import { createClient } from '@supabase/supabase-js';

class UploadImageService {
    constructor({guid}) {
        this.supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(this.supabaseUrl, supabaseKey)
        this.guid = guid
    }

    forUser(user, image) {
        if (image == null) {
            return Promise.resolve(null)
        }
        const bucket = 'users';
        return this.supabase
        .storage
        .from(bucket)
        .upload(`${user.id}/posts/${this.guid}/${image.name}`, image, {
            cacheControl: '3600',
            upsert: false
        })
        .then((response) => {
            var { data, error } = response;
            debugger;
            if (error) {
                return Promise.reject(error);
            }
            var { path } = data
            let publicUrl = `${this.supabaseUrl}/storage/v1/object/public/${bucket}/${path}`;
            return publicUrl
        })
    }
}

export default UploadImageService