import { Component } from "react"

class PostCardPreview extends Component {
    constructor(props) {
        super(props)
        
    }

    render() {
        return (
            <div className="px-3 overflow-hidden" dangerouslySetInnerHTML={{__html: this.props.children}} style={{ maxHeight: "35vh", wordBreak: 'break-word', wordWrap: 'balance' }}></div>
        )
    }
}

export default PostCardPreview