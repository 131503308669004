import Order from '../../models/common/Order'
import User from '../../models/User';

const { createClient } = require('@supabase/supabase-js')

class ListContactsService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    /**
     * An operator which returns a list of contacts (paginated and ordered)
     * for a given user.
     * @param {User} user - A user.
     * @param {Pagination} pagination - A pagination value object.
     * @param {Order} order - A order value object.
     * @return {Promise<User[]>} Returns a list of contacts (users).
     */
    forUser(user, pagination, order) {
        return this.supabase
        .from('contact')
        .select('*, other:otherId(*)')
        .eq('userId', user.id)
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset,
            pagination.offset + pagination.limit)
        .order(order.column, {ascending: order.ascending})
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            var contacts = data.map((x) => {
                let data = x.other
                return new User({...data})
            })
            return contacts
        })
    }
}

export default ListContactsService