import React, { Component } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import ListTimezonesService from '../../../services/ListTimezonesService'
import { addMinutes, differenceInHours, eachMinuteOfInterval, endOfDay, format, formatDuration, getHours, getMinutes, hoursToMilliseconds, intervalToDuration, setHours, setMinutes, startOfDay, startOfMonth } from 'date-fns'

import { getNextMonth, getPreviousMonth } from "../../../scenes/Events/events-fns";
import CalendarDropdownMenu from "../../CalendarDropdownMenu";
import { FormattedMessage } from "react-intl";

class EventModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showingYearMonth: startOfMonth(startOfDay(new Date())),
            showEndDatePicker: false,
            showStartDatePicker: false,
            isValidated: false,
            title: "",
            address: "",
            isAllDay: false,
            startDate: new Date(),
            startTime: this.dateToMinutes(setHours(new Date(), 9)),
            endDate: new Date(),
            endTime: this.dateToMinutes(setHours(new Date(), 10)),
            url: "",
            description: "",
            isOnline: false,
            timezone: "RDT", // how do you find a currentUser's timezone? -> user.getTimezone() ?
            size: '',
            type: '',
            subtype: ''
        }

        this.startDateInput = React.createRef()
        this.endDateInput = React.createRef()


        this.availableTimes = []
        this.listTimezones = new ListTimezonesService()

        this.handleInputChange = this.handleInputChange.bind(this)
        this.setUpdateDateIfNeeded = this.setUpdateDateIfNeeded.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)

        this.handleClickedPrevMonth = this.handleClickedPrevMonth.bind(this)
        this.handleClickedNextMonth = this.handleClickedNextMonth.bind(this)
        this.setExistingDataIfNeeded = this.setExistingDataIfNeeded.bind(this)
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
        this.setUpdateDateIfNeeded(name, value)
    }

    setUpdateDateIfNeeded(name, value) {
        let totalMinutes = parseInt(value)
        let hours = Math.floor(totalMinutes / 60)
        let minutes = totalMinutes % 60
        
        if (name === 'startTime') {
            let newStartDate = setHours(setMinutes(this.state.startDate, minutes), hours)
            this.setState({startDate: newStartDate})
        } else if (name === 'endTime') {
            let newEndDate = setHours(setMinutes(this.state.endDate, minutes), hours)
            this.setState({endDate: newEndDate})
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        const form = e.currentTarget
        
        this.setState({isValidated: true})

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }

        var { title, address, isAllDay, startDate, endDate,
            url, isOnline, description, timezone, size, type, subtype } = this.state

        this.props.onCreate(e, {
            title,
            address,
            startDate: isAllDay ? startOfDay(startDate) : startDate,
            endDate: isAllDay ? endOfDay(startDate) : endDate,
            url,
            isOnline,
            description,
            timezone,
            size,
            type,
            subtype
        })
    }

    setAvailableTimes({minuteStep}) {
        let date = new Date()
        this.availableTimes = eachMinuteOfInterval({
            start: startOfDay(date),
            end: endOfDay(date)
        }, {step: minuteStep})
    }

    componentDidMount() {
        this.setAvailableTimes({minuteStep: 15})
        this.setExistingDataIfNeeded()
    }

    setExistingDataIfNeeded() {
        var { event } = this.props
        if (event == null) {
            return
        }

        var {days, hours, minutes} = intervalToDuration({start: event.startDate, end: event.getEndDate()})
        var isAllDay = days == 1 || hours == 24 || hours == 23 && minutes == 59
        // var isAllDay = differenceInHours(event.getEndDate(), event.startDate) == 24

        this.setState({
            title: event.title,
            address: event.address,
            isAllDay: isAllDay,
            startDate: event.startDate,
            startTime: this.dateToMinutes(event.startDate),
            endDate: event.getEndDate(),
            endTime: this.dateToMinutes(event.getEndDate()),
            url: event.url,
            description: event.description,
            isOnline: event.isOnline,
            timezone: event.timezone,
            size: event.size.toLowerCase(),
            type: event.type.toLowerCase(),
            subtype: event.subtype.toLowerCase()
        })
    }

    dateToMinutes(date) {
        return (getHours(date) * 60) + getMinutes(date)
    }

    handleClickedPrevMonth(e) {
        var { showingYearMonth } = this.state
        let prevMonth = getPreviousMonth(showingYearMonth)
        this.setState({showingYearMonth: prevMonth})
    }

    handleClickedNextMonth() {
        var { showingYearMonth } = this.state
        let nextMonth = getNextMonth(showingYearMonth)
        this.setState({showingYearMonth: nextMonth})
    }

    renderForm() {
        var { isValidated, showingYearMonth, showStartDatePicker, showEndDatePicker, title, address, isAllDay, startDate, startTime, 
            endDate, endTime, url, isOnline, timezone, description, size, type, subtype } = this.state
        var { getSizes, getTypes, getSubtypes} = this.props

        return (
            <Form id="eventForm" onSubmit={this.handleSubmit} noValidate validated={isValidated}>
                <Form.Group className="mb-3">
                    <Form.Label>
                        <FormattedMessage id="EVENT_TITLE_LABEL"/>
                    </Form.Label>
                    <Form.Control
                        required
                        autofocus
                        name="title"
                        value={title}
                        onChange={this.handleInputChange}
                        type="text"
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>
                        <FormattedMessage id="EVENT_SIZE_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        value={size}
                        name="size"
                        onChange={this.handleInputChange}
                        required>
                        <option value="">
                            <FormattedMessage id="CHOOSE_EVENT_SIZE_OPTION"/>
                        </option>
                        {getSizes().map((x) => (
                            <option value={x}>
                                <FormattedMessage id={`EVENT_SIZE_${x.toUpperCase()}`}/>
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>
                        <FormattedMessage id="EVENT_TYPE_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        onChange={this.handleInputChange}
                        name="type"
                        value={type}
                        required>
                        <option value="">
                            <FormattedMessage id="CHOOSE_EVENT_TYPE_OPTION"/>
                        </option>
                        {getTypes().map((x) => (
                            <option value={x}>
                                <FormattedMessage id={`EVENT_TYPE_${x.toUpperCase()}`}/>
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>
                        <FormattedMessage id="EVENT_SUBTYPE_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        onChange={this.handleInputChange}
                        name="subtype"
                        value={subtype}
                        required>
                        <option value="">
                            <FormattedMessage id="EVENT_CHOOSE_SUBTYPE_OPTION"/>
                        </option>
                        {type !== '' && getSubtypes(type).map((x) => (
                            <option value={x}>
                                <FormattedMessage id={`EVENT_SUBTYPE_${x.toUpperCase()}`}/>
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>
                        <FormattedMessage id="EVENT_ADDRESS_LABEL"/>
                    </Form.Label>
                    <Form.Control
                        autofocus
                        name="address"
                        value={address}
                        onChange={this.handleInputChange}
                        type="text"
                    />
                </Form.Group>

                <Form.Check
                    type="switch"
                    label={this.props.intl.formatMessage({id: "EVENT_ALL_DAY_LABEL"})}
                    onChange={this.handleInputChange}
                    name="isAllDay"
                    checked={isAllDay}
                />

                <Row className="align-items-center">
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>
                            <FormattedMessage id="EVENT_STARTS_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            ref={this.startDateInput}
                            onFocus={(e) => this.setState({showStartDatePicker: true})}
                            onBlur={(e) => this.setState({showStartDatePicker: false})}
                            required
                            name="startDate"
                            value={format(startDate, 'MM/dd/yyyy')}
                        />
                        <CalendarDropdownMenu
                            show={showStartDatePicker}
                            className="border py-2"
                            currentDate={setMinutes(setHours(startDate, 0), 0)}
                            showingYearMonth={showingYearMonth}
                            onClickedPrevMonth={this.handleClickedPrevMonth}
                            onClickedNextMonth={this.handleClickedNextMonth}
                            onClickedDate={(date) => {
                                this.startDateInput.current.blur()
                                this.setState({
                                    showStartDatePicker: false,
                                    startDate: date
                                })}
                            }
                        />
                    </Form.Group>
                    { isAllDay === false &&
                        <Col className="mb-3">
                            <Form.Label>
                                <FormattedMessage id="EVENT_TIME_LABEL"/>
                            </Form.Label>
                            <Form.Select
                                required={isAllDay===false}
                                onChange={this.handleInputChange}
                                value={startTime}
                                name="startTime">
                                <option value="">
                                    <FormattedMessage id="CHOOSE_OPTION"/>
                                </option>
                                { this.availableTimes.map((date) => (
                                    <option value={this.dateToMinutes(date)}>{format(date, 'HH.mm')}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    }
                </Row>

                { isAllDay === false &&
                    <Row>
                        <Form.Group as={Col} className="mb-3">
                            <Form.Label>
                                <FormattedMessage id="EVENT_ENDS_LABEL"/>
                            </Form.Label>
                            <Form.Control
                                ref={this.endDateInput}
                                onFocus={(e) => this.setState({showEndDatePicker: true})}
                                onBlur={(e) => this.setState({showEndDatePicker: false})}
                                required
                                name="endDate"
                                value={format(endDate, 'MM/dd/yyyy')}
                            />
                            <CalendarDropdownMenu
                                show={showEndDatePicker}
                                className="border py-2"
                                currentDate={setMinutes(setHours(endDate, 0), 0)}
                                showingYearMonth={showingYearMonth}
                                onClickedPrevMonth={this.handleClickedPrevMonth}
                                onClickedNextMonth={this.handleClickedNextMonth}
                                onClickedDate={(date) => {
                                    this.endDateInput.current.blur()
                                    this.setState({
                                        showEndDatePicker: false,
                                        endDate: date
                                    })
                                }}
                            />
                            { startDate && endDate &&
                                <Form.Text className="text-muted mb-3">
                                    <FormattedMessage id="EVENT_DURATION_FORMAT" values={{
                                        duration: formatDuration(intervalToDuration({start: startDate, end: endDate}), { delimiter: ', ' })
                                    }}/>
                                </Form.Text>
                            }
                        </Form.Group>
                        
                        <Col className="mb-3">
                            <Form.Label>
                                <FormattedMessage id="EVENT_TIME_LABEL"/>
                            </Form.Label>
                            <Form.Select
                                required={isAllDay===false}
                                onChange={this.handleInputChange}
                                value={endTime}
                                name="endTime">
                                <option value="">
                                    <FormattedMessage id="CHOOSE_OPTION"/>
                                </option>
                                { this.availableTimes.map((date) => (
                                    <option value={this.dateToMinutes(date)}>{format(date, 'HH.mm')}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                }

                <Form.Check
                    type="switch"
                    label={this.props.intl.formatMessage({id: "EVENT_ONLINE_LABEL"})}
                    onChange={this.handleInputChange}
                    name="isOnline"
                    checked={isOnline}
                />

                { isOnline &&
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <FormattedMessage id="EVENT_URL_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            name="url"
                            value={url}
                            onChange={this.handleInputChange}
                            type="text"
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="EVENT_URL_TEXT"/>
                        </Form.Text>
                    </Form.Group>
                }

                { isOnline &&
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <FormattedMessage id="EVENT_TIMEZONE_LABEL"/>
                        </Form.Label>
                        <Form.Select
                            required
                            onChange={this.handleInputChange}
                            value={timezone}
                            name="timezone">
                            <option value="">
                                <FormattedMessage id="CHOOSE_TIMEZONE_OPTION"/>
                            </option>
                            { this.listTimezones.all().map((timezone) => (
                                <option value={timezone.abbr}>{timezone.text}</option>
                            ))}
                        </Form.Select>
                        <Form.Text className="text-muted">
                            <FormattedMessage id="EVENT_TIMEZONE_TEXT"/>
                        </Form.Text>
                    </Form.Group>
                }
                
                <Form.Group className="mb-3">
                    <Form.Label>
                        <FormattedMessage id="EVENT_DESCRIPTION_LABEL"/>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        value={description}
                        onChange={this.handleInputChange}
                        type="text"
                    />
                </Form.Group>
            </Form>
        )
    }

    render() {
        var { title, show, submitBtnTitle } = this.props
        return (
            <Modal
                backdrop='static'
                fullscreen="lg-down"
                show={show}
                onHide={this.props.onHide}
                onExited={this.props.onExited}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderForm()}
                </Modal.Body>
                <Modal.Footer className="">
                    <Button onClick={this.props.onHide} className="me-2" variant="secondary">
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button form="eventForm" type="submit" variant="primary">
                        {submitBtnTitle}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default EventModal