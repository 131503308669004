import React, { Component } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

class ConfirmDeleteModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAlert: (this.props.error != null)
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    componentDidMount() { }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                showAlert: (this.props.error != null)
            })
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleCloseAlert() {
        this.setState({
            showAlert: false
        })
    }

    render() {
        const { showAlert } = this.state;
        const { title, subtitle, show } = this.props;
        return (
            <Modal
                id="delete-modal"
                centered={true}
                show={show}
                onShow={this.props.onShow}
                onHide={this.props.onHide}
                onExited={this.props.onExited}>

                <Modal.Body className="text-center p-4">
                    <Modal.Title as="h5" className="fw-bold mb-0">
                        {title}
                    </Modal.Title>
                    <p class="mb-0">{subtitle}</p>
                </Modal.Body>
                <Modal.Footer className="flex-nowrap p-0">
                    <Button
                        size="lg"
                        variant="link"
                        className="fs-6 text-decoration-none col-6 m-0 rounded-0 border-end"
                        onClick={this.props.onHide}>
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button
                        size="lg"
                        variant="link"
                        className="fs-6 text-decoration-none col-6 m-0 rounded-0 text-danger"
                        onClick={this.props.onSubmit}>
                        <strong>
                            <FormattedMessage id="DELETE_BTN"/>
                        </strong>
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmDeleteModal;
