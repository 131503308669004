import { createClient } from "@supabase/supabase-js";
import UserInfoCard from "../../models/UserInfoCard";

class GetUserInfoCardsService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    forCurrentUser(currentUser) {
        return this.supabase
        .from('user_info_cards')
        .select('*')
        .eq('userId', currentUser.id)
        .limit(1)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            if (data.length == 0) {
                return []
            }
            let {payload} = data[0]
            let userInfoCards = payload.map((x) => {
                return new UserInfoCard({...x})
            })
            return userInfoCards
        })
    }
}

export default GetUserInfoCardsService