import React, { Component, createRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../Dashboard';
import { setNextPagination, setHasMore, createMobileNavDrawer, handleNewPost, handleNewChat, handleNewEvent, handlePreferences, handleSignOut, handleFeedack } from '../../Dashboard/dashboard-fns';
import { Link } from 'react-router-dom'
import { ListGroup, Card, Button, Spinner, Form } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux"
import authStateChanged from "../../../redux/actions/auth_state_changed"
import createNotification from '../../../redux/actions/create_notification'
import setModal from '../../../redux/actions/set_modal'

import ChatRoom from '../../../models/chat/ChatRoom';
import Avatar from '../../../components/Avatar';
import ListContactsService from '../../../services/ListContactsService';
import Order from '../../../models/common/Order';
import Pagination from '../../../models/common/Pagination'
import Message from '../../../models/chat/Message';
import { intlFormat } from 'date-fns';
import { FormattedList, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import SidebarButton from '../../../components/SidebarButton';
import SearchContactsService from '../../../services/SearchContactsService';
import UserNotification from '../../../models/common/UserNotification';

class Chats extends Component {
    constructor(props) {
        super(props)

        this.initState = {
            isLoading: false,
            hasMore: true,
            chatRooms: [],
            errors: [],
            contacts: [],
            isLoadingContacts: false,
            searchQuery: "",
            latestMessages: [],
            showSidebar: false,
            search: "",
            searchResults: []
        }
        this.state = {...this.initState}

        this.listContacts = new ListContactsService()
        this.searchContacts = new SearchContactsService()

        this.pagination = new Pagination(0, 10)
        this.order = new Order('createdAt', false)

        this.getNextChats = this.getNextChats.bind(this)
        this.getContacts = this.getContacts.bind(this)
        this.getNextLatestMessages = this.getNextLatestMessages.bind(this)

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)
        this.handleSearchContacts = this.handleSearchContacts.bind(this)
    }

    handleSearchContacts(e) {
        var { currentUser } = this.props
        if (currentUser == null) {
            return
        }

        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        var { chatRoom } = this.state

        this.setState({
            [name]: value
        })

        return this.searchContacts.forUser(currentUser, value)
        .then((contacts) => {
            this.setState({
                searchResults: [...contacts]
            })
        })
        .catch((error) => {
            // silent fail.
        })
    }

    handleCloseSidebar() {
        this.setState({showSidebar: false})
    }

    handleShowSidebar() {
        this.setState({showSidebar: true})
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        
        this.setState({
            [name]: value
        })
    }

    getNextChats() {
        var { currentUser } = this.props
        if (currentUser === null || currentUser === undefined) {
            return Promise([])
        }
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }
        return ChatRoom
        .findByUserId(currentUser.id, this.pagination, this.order)
        .then((newChatRooms) => {
            this.pagination = setNextPagination(this.pagination, newChatRooms)
            this.setState((state) => {
                return {
                    chatRooms: [...state.chatRooms, ...newChatRooms],
                    hasMore: setHasMore(this.pagination, newChatRooms.length)
                }
            })
            return newChatRooms
        })
        .catch((error) => {
            this.setState({
                errors: [error]
            })
        })
    }

    getContacts(searchQuery) {
        var { currentUser } = this.props

        if (currentUser === null || currentUser === undefined) {
            return Promise.resolve([])
        }

        return this.listContacts.forUser(currentUser, new Pagination(0, 2), new Order('createdAt', false))
        .then((newContacts) => {
            this.setState((state) => {
                return {
                    contacts: [...newContacts]
                }
            })
        })
    }

    getNextLatestMessages(newChats) {
        return Promise.all(newChats.map((x) => Message.latestMessageInChatRoom(x.roomId)))
        .then((newLatestMessages) => {
            this.setState((state) => {
                return {
                    latestMessages: [...state.latestMessages, ...newLatestMessages]
                }
            })
        })
    }

    handleAuthStateChanged(prevProps) {
        var { currentUser } = this.props
        if (prevProps.currentUser !== currentUser) {
            this.setState({
                ...this.initState
            }, () => {
                let signedIn = currentUser != null || currentUser != undefined
                if (signedIn === false) {
                    this.setState({
                        hasMore: false,
                        isLoading: false
                    })
                    return
                }

                this.setState({
                    isLoading: true,
                    isLoadingContacts: true
                })

                this.getContacts("")
                .then(() => {
                    this.setState({isLoadingContacts: false})
                })

                this.getNextChats()
                .then((newChats) => {
                    return this.getNextLatestMessages(newChats)
                })
                .then(() => {
                    this.setState({
                        errors: [],
                        isLoading: false,
                    })
                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                        errors: [error]
                    })
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // this.handleAuthStateChanged(prevProps)
    }

    componentDidMount() {
        var { currentUser } = this.props
        
        let signedIn = currentUser != null || currentUser != undefined
        if (signedIn === false) {
            this.setState({
                hasMore: false,
                isLoading: false
            })
            return
        }

        this.setState({
            isLoading: true,
            isLoadingContacts: true
        })

        this.getContacts("")
        .then(() => {
            this.setState({isLoadingContacts: false})
        })

        this.getNextChats()
        .then((newChats) => {
            return this.getNextLatestMessages(newChats)
        })
        .then(() => {
            this.setState({
                errors: [],
                isLoading: false,
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    renderLoading() {
        return (
            <div className="text-center p-3">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    renderEmptyChatRooms() {
        return (
            <div className="px-3 py-5 text-center">
                <p><FormattedMessage id="NO_CHATS_EMPTY_MESSAGE"/></p>
            </div>
        )
    }

    renderEmptyContacts() {
        return (
            <div className="p-3 text-center">
                <p><FormattedMessage id="EMPTY_CONTACTS_MESSAGE"/></p>
            </div>
        )
    }

    findOrCreateChatWithUser(user) {
        var { currentUser } = this.props
        if (currentUser == null) {
            return
        }

        var { chatRooms } = this.state

        let candidate = chatRooms
        .find((x) => x.participants.length === 2 && x.participants.filter((participant) => participant.id == user.id).length > 0)

        if (candidate != undefined) {
            this.props.navigate(`/chats/${candidate.roomId}`)
            return
        }
        return ChatRoom
        .findOrCreate([currentUser, user])
        .then((chatRoom) => {
            this.props.createNotification(new UserNotification({
                name: this.props.intl.formatMessage({id: "CHAT_CREATED_NOTIFICATION"}),
                object: chatRoom,
                userInfo: {}
            }))
            this.props.navigate(`/chats/${candidate.roomId}`)
        })
        .catch((error) => {
            debugger
            this.props.createNotification(new UserNotification({
                name: this.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
                object: error,
                userInfo: {}
            }))
        })
    }

    renderContactItem(user) {
        return (
            <Link to={`/me/${user.username}`} className="d-flex align-items-center text-decoration-none pb-3">
                <div className="position-relative flex-shrink-0 my-1">
                    <Avatar size="48px" src={user.photoURL} alt={user.firstName + ' ' + user.lastName}/>
                </div>
                <div className="d-flex justify-content-between w-100 ps-2 ms-1 my-1">
                <div className="me-3">
                    <div className="h6 mb-1 n-line-1">{user.firstName + ' ' + user.lastName}</div>
                    <p className="text-body fs-sm mb-0 n-line-1">@{user.username}</p>
                </div>
                <div className="align-self-center text-end">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            this.findOrCreateChatWithUser(user)
                            e.preventDefault();
                        }}
                        className="p-0"
                        variant="link">
                        <i className="bi bi-chat"></i>
                    </Button>
                </div>
                </div>
            </Link>
        )
    }

    renderContactsCard() {
        var { contacts, isLoadingContacts, search, searchResults } = this.state
        return (
            <Card className="mt-3">
                <Card.Header className="">
                    <div className="position-relative">
                        <Form.Control
                            name="search"
                            value={search}
                            onChange={(e) => this.handleSearchContacts(e)}
                            placeholder={this.props.intl.formatMessage({id: 'SEARCH_CONTACTS_PLACEHOLDER'})}
                            type="text"/>
                        <i className="bi bi-search fs-lg text-dark position-absolute top-50 end-0 translate-middle-y me-3"></i>
                    </div>
                </Card.Header>
                <Card.Body className="">
                { isLoadingContacts && this.renderLoading() }
                { isLoadingContacts === false && contacts.length === 0 && this.renderEmptyContacts() }
                { isLoadingContacts === false && search.length > 0 && searchResults.slice(0, 3).map((user) =>
                    this.renderContactItem(user)
                )}
                { isLoadingContacts === false && search.length == 0 && contacts.slice(0, 3).map((user) =>
                    this.renderContactItem(user)
                )}
                </Card.Body>
            </Card>
        )
    }

    renderChatRoomItem(chatRoom, latestMessage) {
        var { currentUser } = this.props

        if (currentUser == null) {
            return
        }

        let participants = chatRoom.participants.filter((x) => x.id !== currentUser.id)

        return (
            <Link to={`/chats/${chatRoom.roomId}`} className="list-group-item list-group-item-action d-flex align-items-center text-decoration-none px-4 py-3">
                <div className="position-relative flex-shrink-0 my-1">
                    <div>
                        { participants.length > 1 &&
                            <Avatar>
                                {'+'}<FormattedNumber value={participants.length} notation={'compact'}/>
                            </Avatar>
                        }
                        { chatRoom.participants.filter((x) => x.id !== currentUser.id).length === 1 &&
                            <div className="bg-light flex-shrink-0 rounded-circle position-relative zindex-4" style={{"padding": "3px"}}>
                                <Avatar
                                    size={"56px"}
                                    src={participants[0].photoURL}
                                    alt={ participants[0].firstName + ' ' +  participants[0].lastName}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-between w-100 ps-2 ms-1 my-1">
                    <div className="flex-shrink-1 me-3">
                        <div style={{wordBreak: 'break-word', wordWrap: 'balance'}} className="h6 mb-1 n-lines-1">
                            <FormattedList
                                type="conjunction"
                                value={participants.map((x) => x.firstName + ' ' + x.lastName)}/>
                        </div>
                        <p style={{wordBreak: 'break-word', wordWrap: 'balance'}} className="text-body fs-sm mb-0 n-lines-2">
                            { latestMessage == null && this.props.intl.formatMessage({id: 'EMPTY_CHAT_MESSAGE'}) }
                            { latestMessage && latestMessage.content.length > 0 &&
                                `${latestMessage.user.id === currentUser.id ? this.props.intl.formatMessage({id: 'YOU_MESSAGE_PREFIX'}) : latestMessage.user.firstName}: ${latestMessage.content}`
                            }
                            { latestMessage && latestMessage.content.length === 0 && latestMessage.attachments.length > 0 &&
                            <FormattedMessage
                                id="SENT_ATTACHMENT_FORMAT"
                                values={{
                                    user: latestMessage.user.id === currentUser.id ? this.props.intl.formatMessage({id: 'YOU_MESSAGE_PREFIX'}) : latestMessage.user.firstName
                                }}/>
                                
                            }
                        </p>
                        <Link className="d-none" to={`/chats/${chatRoom.roomId}`}>Room {chatRoom.createdAt}</Link>
                    </div>
                    <div className="flex-grow-1 text-end">
                        <span className="d-block fs-xs text-muted n-line-1">
                            { latestMessage == null && intlFormat(new Date(chatRoom.createdAt)) }
                            { latestMessage && intlFormat(new Date(latestMessage.createdAt)) }
                        </span>
                    </div>
                </div>
            </Link>
        )
    }

    renderChatRoomsList(chatRooms, latestMessages) {
        var { isLoading, hasMore } = this.state

        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({ isLoading: true })
                    this.getNextChats()
                    .then((newChats) => {
                        return this.getNextLatestMessages(newChats)
                    })
                    .then(() => {
                        this.setState({
                            errors: [],
                            isLoading: false,
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}
            >
                { chatRooms.map((chatRoom, i) =>
                    this.renderChatRoomItem(
                        chatRoom,
                        latestMessages.find((x) => x && x.roomId === chatRoom.roomId) ?? null
                    )
                )}
            </Dashboard.List>
        )
    }

    render() {
        var { chatRooms, latestMessages, isLoading, showSidebar } = this.state
        var { currentUser } = this.props

        return (
            <Dashboard childScene={this}>
                <Dashboard.Body>
                    <Dashboard.Navbar
                        title={this.props.intl.formatMessage({id: "NAV_TITLE_CHATS"})}
                        bg="light"
                        variant="dark"
                        offcanvasBody={createMobileNavDrawer({
                            currentUser,
                            onBehalfOfComponent: this,
                            onNewPost: handleNewPost,
                            onNewChat: handleNewChat,
                            onNewEvent: handleNewEvent,
                            onPreferences: handlePreferences,
                            onSignOut: handleSignOut
                        })}/>
                    { this.renderChatRoomsList(chatRooms, latestMessages) }
                    { isLoading === false && chatRooms.length === 0 && this.renderEmptyChatRooms() }
                    <SidebarButton onClick={this.handleShowSidebar}/>
                </Dashboard.Body>
                <Dashboard.Context
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    { this.renderContactsCard() }
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let navigate = useNavigate()
    let intl = useIntl()
    return <Chats {...props} navigate={navigate} intl={intl} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps)