import LatLon from 'geodesy/latlon-spherical.js';
import regions from '../../assets/ISO-3166-2.json'

class GetNearestRegionService {
    useNavigatorWithPermission(navigator) {
        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        }
        try {
            return navigator.permissions.query({
                name: 'geolocation'
            })
            .then((result) => {
                if (result.state === 'denied') {
                    let error = new Error('User denied Geolocation')
                    return Promise.reject(error)
                }
                return new Promise(function(resolve, reject) {  
                    navigator.geolocation.getCurrentPosition((pos) => {
                        const { latitude, longitude } = pos.coords;
                        
                        const p1 = new LatLon(latitude, longitude)
    
                        let allRegions = Object.values(regions)
                        .filter((x) => x.parent != undefined && (
                            x.division.toLowerCase() != 'country' || x.division.toLowerCase() != 'included for completeness'
                        ))
    
                        let nearestRegions = allRegions
                        .map((x) => {
                            if (x.lat && x.lng) {
                                let p2 = new LatLon(x.lat, x.lng)
                                let km = p1.distanceTo(p2) * 0.001
                                return {
                                    name: x.name,
                                    km,
                                    code: x.code
                                }
                            }
                            return Number.MAX_VALUE 
                        }).sort(function(a, b) {
                            return a.km - b.km;
                        }).filter((x) => x.km > 0)
    
                        if (nearestRegions.length === 0) {
                            resolve(null)
                        }
    
                        let regionCode = nearestRegions[0].code
                        resolve(regionCode)
                    }, reject, options);
                })
            })   
        } catch (error) {
            return Promise.resolve(null)
        }
    }
}

export default GetNearestRegionService