import { Component } from "react";
import { Link } from 'react-router-dom'

class NavDrawerBrand extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        var { to, subscript } = this.props
        return (
            <div className="d-flex justify-content-start mb-2">
                <Link to={to} className="text-decoration-none text-dark fw-bold">
                    {this.props.children}
                </Link>
                {subscript && <sub>{subscript}</sub>}
            </div>
        )
    }
}

export default NavDrawerBrand