import MessageRepository from "../../../repositories/chat/MessageRepository"
import MessageFactory from '../../../factories/MessageFactory';

class Message {
    constructor({id, roomId, createdAt, deletedAt, user, content, attachments}) {
        this.id = id || undefined
        this.roomId = roomId
        
        this.createdAt = createdAt || new Date()
        this.deletedAt = deletedAt || null

        this.user = user
        this.content = content

        this.attachments = attachments || []
    }

    static create(chatRoom, user, content, attachments) {
       return new MessageFactory().create(chatRoom, user, content, attachments)
        .then((message) => {
            return MessageRepository.getRepoState().add(message)
        })
    }

    static onNewMessageInChatRoom(chatRoom, callbackFn) {
        return MessageRepository.getRepoState().onNewMessageInChatRoom(chatRoom, callbackFn)
    }

    static findByRoomId(roomId, pagination, order) {
        return MessageRepository.getRepoState().findByRoomId(roomId, pagination, order)
    }

    static latestMessageInChatRoom(roomId) {
        return MessageRepository.getRepoState().latestMessageInChatRoom(roomId)
    }
}

export default Message