const { createClient } = require('@supabase/supabase-js')

class GetAssetFromStorageService {
    constructor() {
        this.supabase = createClient('https://bspodttrfjifboigqtfv.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc')
    }

    withRelativePath(path) {
        return this.supabase
        .storage
        .from('assets')
        .download(path)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            return data
        })
    }
}

export default GetAssetFromStorageService