import { Component, Fragment } from "react"
import { Link, NavLink } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import Avatar from "../../Avatar"

class NavDrawerProfile extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        var { photoURL, photoAlt, to, title, subtitle, moreDropdownMenu } = this.props

        return (
            <Dropdown>
                <div className="d-flex justify-content-between">
                    <Link to={to} className="nav-link d-flex align-items-center p-0" data-bs-toggle="dropdown" aria-expanded="false">
                        <Avatar className="me-2" size="35" src={photoURL} alt={photoAlt}/>
                        <div class="d-lg-none d-xl-block ps-2">
                            <div className="fs-sm dropdown-toggle n-lines-1">{title}</div>
                            <div className="fs-xs lh-1 opacity-60 n-lines-1">{subtitle}</div>
                        </div>
                    </Link>
                    <Dropdown.Toggle size="sm" variant="outline-secondary" className="d-lg-none d-xl-block btn-icon rounded-circle border-0 my-1" id="dropdown-basic">
                        <i className="fs-base bi bi-three-dots"></i>
                    </Dropdown.Toggle>
                    {moreDropdownMenu}
                </div>
            </Dropdown>
        )
    }
}

export default NavDrawerProfile