import regions from '../../assets/ISO-3166-2.json'

class ListCountriesService {
    constructor() {
        // structure { 'countryCode': 'localizedCountryName' }

        this.localFile = localStorage.getItem('countries') ?? null
        if (this.localFile) {
            this.countries = JSON.parse(this.localFile)
        } else {
            this.countries = []
        }
    }

    all() {
        return Object.keys(this.countries).map((code) => {
            return {
                code, name: this.countries[code]
            }
        })
    }
}

export default ListCountriesService;
