import { createClient } from '@supabase/supabase-js';

class IncrementPostReactionMetricService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey);
    }

    withArgs({postId, emoji, x}) {
        return this.supabase.rpc("increment_post_reaction_metric", {
            emoji,
            "post_id": postId,
            x
        })
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            return true
        })
    }
}

export default IncrementPostReactionMetricService