import React, { Component } from 'react'
import Dashboard from '../Dashboard'
import Calendar from '../../components/Calendar'
import {startOfDay, startOfMonth, isToday, isTomorrow, isThisWeek, isYesterday, isThisMonth, format, formatISO, toDate, getTime, getMilliseconds} from 'date-fns'
import CalendarEvent from '../../models/event/CalendarEvent'
import Pagination from '../../models/common/Pagination'
import { ListGroup, Spinner, Badge, NavLink,  Nav, NavDropdown, Button, Dropdown, Card, Form } from 'react-bootstrap'
import GroupListService from '../../services/common/GroupListService'
import { today, isLastWeek, isLastMonth, isNextWeek, isNextMonth, getNextMonth, getPreviousMonth } from './events-fns'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import RegionFilterDropdownMenu from '../../components/RegionFilterDropdownMenu'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import authStateChanged from "../../redux/actions/auth_state_changed"
import createNotification from '../../redux/actions/create_notification'
import setModal from '../../redux/actions/set_modal'
import FormatRegionCodeService from '../../services/FormatRegionCodeService'
import { createMobileNavDrawer, getFilterRegionCode, handleEventDetails, handleNewChat, handleNewEvent, handleNewPost, handlePreferences, handleSignOut, setHasMore, setNextPagination, handleReport, handleDeleteEvent, handleEditEvent, getMatchingLabelForEvent, handleFeedack } from '../Dashboard/dashboard-fns'
import Criteria from '../../models/common/Criteria'
import Order from '../../models/common/Order'
import SidebarButton from '../../components/SidebarButton'
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl'

class Events extends Component {
    constructor(props) {
        super(props)

        const search = this.props.location.search;
        const searchParams = new URLSearchParams(search)

        const start = searchParams.get('start') ?? ''
        let currentDate = start ? startOfDay(toDate(parseInt(start))) : startOfDay(today())

        const eventSize = searchParams.get('size') ?? ''
        const eventType = searchParams.get('type') ?? ''
        const eventSubtype = searchParams.get('subtype') ?? ''
        
        this.initState = {
            currentDate: currentDate,
            showingYearMonth: startOfMonth(currentDate),
            groupedEvents: new Map(),
            isLoading: false,
            hasMore: true,
            errors: [],
            eventSize: eventSize,
            eventType: eventType,
            eventSubtype: eventSubtype,
            showSidebar: false
        }

        this.state = {
            ...this.initState
        }

        this.getNextEvents = this.getNextEvents.bind(this)

        this.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        var { regionCode } = this.props.match.params
        var { currentUser } = this.props
        var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode
        this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)

        this.criteria = this.criteria = this.setUpdateCriteria(
            this.filterRegionCode, currentDate, eventSize, eventType, eventSubtype)

        this.pagination = new Pagination(0, 20)
        this.order = new Order('startDate', true)

        this.groupList = new GroupListService()
        this.formatRegionCode = new FormatRegionCodeService()

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleApplyFilter = this.handleApplyFilter.bind(this)
        this.handleClearFilter = this.handleClearFilter.bind(this)
        this.handleClearEventFilter = this.handleClearEventFilter.bind(this)

        this.handleClickedDate = this.handleClickedDate.bind(this)
        this.handleClickedPrevMonth = this.handleClickedPrevMonth.bind(this)
        this.handleClickedNextMonth = this.handleClickedNextMonth.bind(this)
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)
    }

    setUpdateCriteria(regionCode, startDate, eventSize, eventType, eventSubtype) {
        regionCode = regionCode ?? 'DK'
        startDate = startDate ?? new Date()
        eventSize = eventSize ?? ''

        var criteria = new Criteria().is('deletedAt', null)
        
        let isRegionCode = regionCode.split('-').length == 2
        if (isRegionCode) {
            criteria.equalTo('regionCode', regionCode)
        } else {
            criteria.equalTo('countryCode', regionCode)
        }

        criteria.greaterThanEqual('startDate', format(startDate, 'yyyy-MM-dd HH:mm:ss'))

        if (eventSize !== '') {
            criteria.equalTo('size', eventSize.toUpperCase().replaceAll(' ', '-'))
        }
        if (eventType !== '') {
            criteria.equalTo('type', eventType.toUpperCase().replaceAll(' ', '-'))
        }
        if (eventSubtype !== '') {
            criteria.equalTo('subtype', eventSubtype.toUpperCase().replaceAll(' ', '-'))
        }

        return criteria
    }

    handleCloseSidebar() {
        this.setState({showSidebar: false})
    }

    handleShowSidebar() {
        this.setState({showSidebar: true})
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        
        this.setState({
            [name]: value,
            groupedEvents: new Map()
        }, () => {
            var { currentDate, eventSize, eventType, eventSubtype } = this.state

            if (name === 'eventType') {
                eventSubtype = ''
            }

            this.criteria = this.setUpdateCriteria(
                this.filterRegionCode, currentDate, eventSize, eventType, eventSubtype)

            this.pagination = new Pagination(0, 20)
            
            let start = getTime(currentDate)
            var queryParams = {'start': start}
            if (eventSize !== '') {
                queryParams['size'] = eventSize
            }
            if (eventType !== '') {
                queryParams['type'] = eventType
            }
            if (eventSubtype !== '') {
                queryParams['subtype'] = eventSubtype
            }

            let queryParamStr = Object.entries(queryParams).map((entry) => entry.join('=')).join('&')
            this.setState({ eventSize, eventType, eventSubtype })
            window.history.pushState({} , '', `?${queryParamStr}`)

            this.setState({
                isLoading: true,
                hasMore: true
            })
            this.getNextEvents()
            .then(() => {
                this.setState({
                    isLoading: false,
                    errors: []
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    errors: [error]
                })
            })
        })
    }

    getNextEvents() {
        var { currentDate } = this.state
        
        if (this.filterRegionCode === undefined) {
            return
        }

        var pagination = this.pagination

        return CalendarEvent
        .matching(this.criteria, this.pagination, this.order)
        .then((newEvents) => {
            this.setState((state) => {
                var newGroupedEvents = this.groupList.by(newEvents, (event) => {
                    return getMatchingLabelForEvent(this, event)
                })

                this.pagination = setNextPagination(pagination, newEvents.length)
                return {
                    hasMore: setHasMore(pagination, newEvents.length),
                    groupedEvents: new Map([...state.groupedEvents, ...newGroupedEvents])
                }
            })
        }) 
    }

    handleClearFilter(e) {
        const to = '/events'
        this.props.navigate(to)
    }
    
    handleApplyFilter(e, {regionCode}) {
        const to = `/${regionCode.toLowerCase()}/events`
        this.props.navigate(to)
    }

    renderFilterDropdownMenu() {
        return (
            <RegionFilterDropdownMenu
                regionCode={this.filterRegionCode}
                onClear={this.handleClearFilter}
                onApply={this.handleApplyFilter}
            />
        )
    }

    updateRegionIfNeeded(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {
            var { regionCode } = this.props.match.params
            var { currentUser } = this.props
            var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode
            this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)
            
            let eventSize = ''
            let eventType = ''
            let eventSubtype = ''

            this.criteria = this.setUpdateCriteria(this.filterRegionCode, this.state.currentDate, eventSize, eventType, eventSubtype)
            this.pagination = new Pagination(0, 20)

            let updatedState = {...this.initState}
            updatedState.isLoading = true
            updatedState.eventSize = eventSize
            updatedState.eventType = eventType
            updatedState.eventSubtype = eventSubtype

            this.setState({...updatedState})

            this.getNextEvents()
            .then(() => {
                this.setState({
                    isLoading: false,
                    errors: []
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    errors: [error]
                })
            })
        }
    }

    updateEventsIfNeeded(newEvent) {
       var label = getMatchingLabelForEvent(this, newEvent)
       let eventGroupNotExists = this.state.groupedEvents.get(label) == undefined
       if (eventGroupNotExists) {
            return
       }

       this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [candidate, events] = value
                
                if (candidate === label) {
                    var updatedEvents = [newEvent, ...events].sort((a, b) => {
                        let dateA = getMilliseconds(a.startDate)
                        let dateB = getMilliseconds(b.startDate)
                        if (dateA < dateB) {
                            return -1
                        }
                        if (dateA > dateB) {
                            return 1
                        }
                        return 0
                    });
                    updatedGroupedEvents.set(label, updatedEvents)
                    break
                }
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
       })
    }

    updateEditedEventIfNeeded(editedEvent) {
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [label, events] = value
                var updatedEvents = [...events]

                for (let i = 0; i < events.length; i++) {
                    const candidate = events[i];
                    if (candidate.id === editedEvent.id) {
                        updatedEvents[i] = editedEvent
                        break
                    }
                }
                updatedGroupedEvents.set(label, updatedEvents);
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }

    removeEventIfNeeded(deletedEvent) {
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [label, events] = value
                var updatedEvents = events.filter((x) => x.id !== deletedEvent.id)
                updatedGroupedEvents.set(label, updatedEvents);
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }

    handleNotificationChangeIfNeeded(prevProps) {
        if (prevProps.notification !== this.props.notification) {
            let notification = this.props.notification
            if (notification == null) {
            return
            }
            let {type} = notification.userInfo
            if (type == null) {
            return
            }
            if (type == 'EVENT_CREATED') {
                let newEvent = notification.object
                this.updateEventsIfNeeded(newEvent)
            } else if (type == 'EVENT_EDITED') {
                let editedEvent = notification.object
                this.updateEditedEventIfNeeded(editedEvent)
            } else if (type == 'EVENT_DELETED') {
                let removedEvent = notification.object
                this.removeEventIfNeeded(removedEvent)
            }
        }
    }

    componentDidUpdate(prevProps) {
        this.updateRegionIfNeeded(prevProps)
        this.handleNotificationChangeIfNeeded(prevProps)
    }

    componentDidMount() {
        var { regionCode } = this.props.match.params
        var { currentUser } = this.props
        var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode
        this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)

        this.setState({isLoading: true})
        this.getNextEvents()
        .then(() => {
            this.setState({
                isLoading: false,
                errors: []
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    handleClickedDate(date) {
        var { eventSize, eventType, eventSubtype } = this.state

        let currentDate = new Date(date)
        
        this.pagination = new Pagination(0, 20)
        this.criteria = this.setUpdateCriteria(
            this.filterRegionCode, currentDate, eventSize, eventType, eventSubtype)

        let start = getTime(date)
        var queryParams = {'start': start}
        if (eventSize !== '') {
            queryParams['size'] = eventSize
        }
        if (eventType !== '') {
            queryParams['type'] = eventType
        }
        if (eventSubtype !== '') {
            queryParams['subtype'] = eventSubtype
        }
        
        let queryParamStr = Object.entries(queryParams).map((entry) => entry.join('=')).join('&')
        window.history.pushState({} , '', `?${queryParamStr}`)

        this.setState({
            hasMore: true,
            isLoading: true,
            groupedEvents: [],
            currentDate: currentDate,
            showingYearMonth: startOfMonth(currentDate)
        }, () => {
            this.getNextEvents()
            .then(() => {
                this.setState({
                    isLoading: false,
                    errors: []
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    errors: [error]
                })
            })
        })
    }

    handleClearEventFilter() {
        var { currentDate } = this.state
        let eventSize = ''
        let eventType = ''
        let eventSubtype = ''

        this.pagination = new Pagination(0, 20)
        this.criteria = this.setUpdateCriteria(
            this.filterRegionCode, currentDate, eventSize, eventType, eventSubtype)

        window.history.pushState({} , '', '?')
        this.setState({
            eventSize,
            eventType,
            eventSubtype,
            hasMore: true,
            isLoading: true,
            groupedEvents: []
        }, () => {
            this.getNextEvents()
            .then(() => {
                this.setState({
                    isLoading: false,
                    errors: []
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    errors: [error]
                })
            })
        })
    }

    handleClickedPrevMonth() {
        var { showingYearMonth } = this.state
        let prevMonth = getPreviousMonth(showingYearMonth)
        this.setState({showingYearMonth: prevMonth})
    }

    handleClickedNextMonth() {
        var { showingYearMonth } = this.state
        let nextMonth = getNextMonth(showingYearMonth)
        this.setState({showingYearMonth: nextMonth})
    }

    renderEmpty() {
        return (
            <div className="text-center px-3 py-5">
                <p className="text-muted">
                    <FormattedMessage id="EMPTY_EVENTS_MESSAGE"/>
                </p>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="p-3 text-center w-100">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    renderEventItem(event) {
        var { currentUser } = this.props

        let weekday = this.weekdays[new Date(event.startDate).getDay()]
        let isOwn = currentUser && currentUser.id === event.author.id

        return (
            <ListGroup.Item onClick={(e) => {handleEventDetails(this, event)}} action className="d-flex justify-content-start p-3">
                <div style={{width: "65px", height: "65px"}} className="d-flex flex-column justify-content-center bg-light rounded text-dark border text-center me-4">
                    <h6 className="text-dark mb-0">{format(new Date(event.startDate), 'dd')}</h6>
                    <small className='text-danger'>{weekday.slice(0, 3)}</small>
                </div>
                <div className="align-self-center">
                    <div class="fw-regular h6 mb-1 n-lines-1">{event.title}</div>
                    <p class="text-body fs-sm mb-0 n-lines-2">
                        <FormattedTime value={event.startDate}/> — <FormattedTime value={event.getEndDate()}/><br/>
                        {event.isOnline && event.timezone}
                        {event.isOnline === false && event.address}
                    </p>
                </div>
                <Dropdown
                    onToggle={(nextShow, meta) => {
                        let { originalEvent } = meta
                        originalEvent.stopPropagation()
                    }}
                    autoClose={true}
                    className="ms-auto align-self-center">
                    <Dropdown.Toggle variant="link" size="sm" className="text-dark btn-icon border-0 rounded-circle">
                        <i className="fs-lg bi bi-three-dots"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-macos shadow">
                        <Dropdown.Item onClick={(e) => handleReport(this, event.id, 'EVENT')}>
                            <FormattedMessage id="REPORT_DROPDOWN_ITEM"/>
                        </Dropdown.Item>

                        { isOwn &&
                            <Dropdown.Item onClick={(e) => handleEditEvent(this, event)}>
                                <FormattedMessage id="EDIT_DROPDOWN_ITEM"/>
                            </Dropdown.Item>
                        }

                        { isOwn &&
                            <Dropdown.Item onClick={(e) => handleDeleteEvent(this, event)}>
                                <FormattedMessage id="DELETE_DROPDOWN_ITEM"/>
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </ListGroup.Item>
        )
    }

    renderList(groupedEvents) {
        var { isLoading, hasMore } = this.state
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({isLoading: true})
                    this.getNextEvents()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}>
                {Array.from(groupedEvents, ([label, events]) => (
                    <div>
                        <div className="text-dark bg-secondary px-3 py-1">
                            <small className="text-dark n-lines-1">{label}</small>
                        </div>
                        <ListGroup variant="flush">        
                            {events.map((event) => (
                                this.renderEventItem(event)
                            ))}
                        </ListGroup>
                    </div>
                ))}
            </Dashboard.List>
        )
    }

    render() {
        var { isLoading, groupedEvents, currentDate, showingYearMonth, eventSize, eventType, eventSubtype, showSidebar } = this.state
        var { currentUser } = this.props

        return (
            <Dashboard childScene={this}>
                <Dashboard.Body
                    offsetHeight={20}>
                    <Dashboard.Navbar
                        title={this.props.intl.formatMessage({id: "NAV_DRAWER_ITEM_EVENTS"})}
                        subtitle={this.formatRegionCode.withValue(this.filterRegionCode)}
                        bg="light"
                        variant="dark"
                        nav={
                            <Nav className="ms-auto">
                                <Dropdown className="">
                                    <Dropdown.Toggle variant="outline-secondary" size="sm" className="btn-icon border-0 rounded-circle me-n2">
                                        <i className="fs-lg bi bi-filter-circle"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu flip={true} className="dropdown-menu dropdown-menu-end my-1">
                                        {this.renderFilterDropdownMenu()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                          }
                          offcanvasBody={createMobileNavDrawer({
                            currentUser,
                            onBehalfOfComponent: this,
                            onNewPost: handleNewPost,
                            onNewChat: handleNewChat,
                            onNewEvent: handleNewEvent,
                            onPreferences: handlePreferences,
                            onSignOut: handleSignOut
                        })}/>
                    {this.renderList(groupedEvents)}
                    {isLoading === false && Array.from(groupedEvents).length === 0 && this.renderEmpty()}
                    <SidebarButton onClick={this.handleShowSidebar}/>
                </Dashboard.Body>
                <Dashboard.Context 
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    <div className="d-flex justify-content-end">
                        <Button
                            className="my-3"
                            onClick={(e) => this.handleClickedDate(startOfDay(today())) }>
                            <FormattedMessage id="TODAY_BTN"/>
                        </Button>
                    </div>
                    <Calendar
                        className={"mb-3 border"}
                        currentDate={currentDate}
                        showingYearMonth={showingYearMonth}
                        onClickedPrevMonth={this.handleClickedPrevMonth}
                        onClickedNextMonth={this.handleClickedNextMonth}
                        onClickedDate={this.handleClickedDate}
                    />
                    <Card className="mt-3 card">
                        <Card.Body>
                            <h5 className="card-title fw-bold">
                                <FormattedMessage id="EVENT_FILTER_CARD_TITLE"/>
                            </h5>
                            <Form className="">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <FormattedMessage id="EVENT_SIZE_LABEL"/>
                                    </Form.Label>
                                    <Form.Select
                                        value={eventSize}
                                        name="eventSize"
                                        onChange={this.handleInputChange}>
                                        <option value="">
                                            <FormattedMessage id="CHOOSE_EVENT_SIZE_OPTION"/>
                                        </option>
                                        {CalendarEvent.getSizes().map((size) => (
                                            <option value={size}>
                                                <FormattedMessage id={`EVENT_SIZE_${size.toUpperCase()}`}/>
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <FormattedMessage id="EVENT_TYPE_LABEL"/>
                                    </Form.Label>
                                    <Form.Select
                                        onChange={this.handleInputChange}
                                        name="eventType"
                                        value={eventType}
                                    >
                                        <option value="">
                                            <FormattedMessage id="CHOOSE_EVENT_TYPE_OPTION"/>
                                        </option>
                                        {CalendarEvent.getTypes().map((x) => (
                                            <option value={x}>
                                                <FormattedMessage id={`EVENT_TYPE_${x.toUpperCase()}`}/>
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <FormattedMessage id="EVENT_SUBTYPE_LABEL"/>
                                    </Form.Label>
                                    <Form.Select
                                        onChange={this.handleInputChange}
                                        name="eventSubtype"
                                        value={eventSubtype}
                                    >
                                        <option value="">
                                            <FormattedMessage id="EVENT_CHOOSE_SUBTYPE_OPTION"/>
                                        </option>
                                        {eventType !== '' && CalendarEvent.getSubtypes(eventType).map((x) => (
                                            <option value={x}>
                                                <FormattedMessage id={`EVENT_SUBTYPE_${x.toUpperCase()}`}/>
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="d-flex justify-content-end mb-3">
                                    <Button
                                        onClick={this.handleClearEventFilter}
                                        variant="secondary">
                                        <FormattedMessage id="CLEAR_BTN"/>
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        fallbackRegion: state.setFallbackRegion,
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let params = useParams()
    let navigate = useNavigate()
    let location = useLocation()
    let intl = useIntl()
    return <Events {...props} intl={intl} navigate={navigate} match={{params: params}} location={location}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps)