import React, { Component, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import AppRoutes from './routes';

import User from './models/User';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import authStateChanged from "./redux/actions/auth_state_changed";
import routeStateChanged from './redux/actions/route_state_changed';
import setFallbackRegion from './redux/actions/set_fallback_region';

import NotificationLayer from './layers/NotificationLayer';
import ModalLayer from './layers/ModalLayer';

import ErrorBoundary from './components/boundaries/ErrorBoundary'
import Logo from './assets/logo.png';
import { Button } from 'react-bootstrap'

import GetNearestRegionService from './services/GetNearestRegionService';
import TranslationPolicy from './policies/TranslationPolicy';
import CookiesService from './services/CookiesService';
import AppearanceService from './services/AppearanceService';
import GetAssetFromStorageService from './services/GetAssetFromStorageService';
import SetLocalizedAssetsService from './services/SetLocalizedAssetsService';
import IncrementPostMetricService from './services/IncrementPostMetricService';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOnline: navigator.onLine,
      forcedInternalError: false
    }
    
    this.getNearestRegion = new GetNearestRegionService()
    this.translationPolicy = new TranslationPolicy()
    this.cookies = new CookiesService()
    this.appearance = new AppearanceService()
    this.setLocalizedAssets = new SetLocalizedAssetsService()

    this.locale = "en"
    this.messages = []
    
    this.handleUserSession = this.handleUserSession.bind(this)
    this.setNeedsFallbackRegionIfAllowed = this.setNeedsFallbackRegionIfAllowed.bind(this)
  }

  handleUserSession() {
    return User.findBySession()
      .then((currentUser) => {
        this.props.authStateChanged(currentUser)
        return currentUser
      })
  }

  setNeedsFallbackRegionIfAllowed() {
    return this.getNearestRegion.useNavigatorWithPermission(navigator)
    .then((regionCode) => {
        this.props.setFallbackRegion({regionCode})
        return regionCode
    })
    .catch((error) => {
      return null
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        isOnline: navigator.onLine
      })
    }
  }

  componentWillUnmount() {
    if (this.colorSchemeQueryList) {
      this.colorSchemeQueryList.removeEventListener()
    }
  }

  setThemeIfNeeded() {
    let value = this.cookies.get('theme')
    if (value == "") {
      return
    }
    var options = ['dark', 'light', 'auto']
    if (options.includes(value) == false) {
      return
    }
    if (value == 'auto') {
      this.colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)');
      this.colorSchemeQueryList.addEventListener('change', ((e) => {
        if (e.matches) {
          this.appearance.setTheme(document, 'dark')
        } else {
          this.appearance.setTheme(document, 'light')
        }
      }));
    } else {
      this.appearance.setTheme(document, value)
    }
  }

  componentDidMount() {
    this.setThemeIfNeeded()

    this.setState({ isLoading: true })

    this.handleUserSession()
    .then((currentUser) => {
      let foundUser = currentUser != null

      var { locale, messages } = this.translationPolicy.forCurrentUser(currentUser)
      this.locale = locale
      this.messages = messages
      
      if (foundUser) {
        return Promise.resolve()
      }

      return this.setNeedsFallbackRegionIfAllowed()
    })
    .then(() => {
      return this.setLocalizedAssets.forLocale(this.locale, new GetAssetFromStorageService())
    })
    .then(() => {
      this.setState({ isLoading: false })
    })
    .catch((error) => {
      this.setState({
        isLoading: false,
        forcedInternalError: true
      })
    })
  }

  renderLaunchScreen() {
    return (
      <div className="d-flex vh-100 p-3 align-items-center justify-content-center">
        <img src={Logo} width="80" height="80" alt="Aria5" />
      </div>
    )
  }

  renderInteralError() {
    return (
      <div className="d-flex vh-100 p-3 align-items-center flex-column justify-content-center text-center">
        <img className="mb-3" src={Logo} width="80" height="80" />
        <h3>Internal error</h3>
        <p>An internal error occurred. We're working hard to fix it.</p>
        <Button onClick={(e) => window.location.reload()} variant="outline-primary">
          Reload
        </Button>
      </div>
    )
  }

  renderOfflineScreen() {
    return (
      <div className="d-flex vh-100 p-3 align-items-center flex-column justify-content-center text-center">
        <img className="mb-3" src={Logo} width="80" height="80"/>
        <h3>Connect to the internet</h3>
        <p>You're offline. Check your connection.</p>
        <Button onClick={(e) => window.location.reload()} variant="outline-primary">
          Retry
        </Button>
      </div>
    )
  }

  render() {
    var { isLoading, isOnline, forcedInternalError } = this.state;

    if (isLoading) {
      return this.renderLaunchScreen()
    }

    if (isOnline == false) {
      return this.renderOfflineScreen()
    }

    /*
    if (forcedInternalError) {
      return this.renderInteralError()
    }
    */

    return (
      <ErrorBoundary fallback={this.renderInteralError()}>
        <IntlProvider key={"en"} locale={this.locale} messages={this.messages}>
          <div className="App page-wrapper">
              <AppRoutes />
              <NotificationLayer />
              <ModalLayer />
          </div>
        </IntlProvider>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.changedAuthState,
    routeState: state.routeStateChanged
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    authStateChanged,
    routeStateChanged,
    setFallbackRegion,
  }, dispatch);
}

/*
function incrementPostMetricsViews(postId) {
  return new IncrementPostMetricService().withArgs({
    postId, fieldName: 'views', x: 1
  })
}
*/

function BackwardsCompatibleProps(props) {
  let location = useLocation();
  useEffect(() => {
    // Google Analytics
    // ga('send', 'pageview');

    /*
    let fragments = location.pathname.split('/').filter((x) => x.length > 0)
    if (fragments.length == 4 && fragments[0] == 'me' && fragments[2] == 'post') {
      let postId = fragments[3]
      incrementPostMetricsViews(postId)
    }
    */

  }, [location])
  return <App {...props} location={location} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);
