import React, { Children, Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Form, Button, Row, Col, Alert, Spinner } from 'react-bootstrap';
import PasswordConfirmationPolicy from '../../../policies/PasswordConfirmationPolicy';
import User from '../../../models/User';
import AuthService from '../../../services/AuthService';

import ProfilePicture from '../../../components/ProfilePicture';

import ListCountriesService from '../../../services/ListCountriesService';
import ListRegionsService from '../../../services/ListRegionsService';

import ChildrensOnlinePrivacyProtectionRulePolicy from '../../../policies/ChildrensOnlinePrivacyProtectionRulePolicy';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import authStateChanged from "../../../redux/actions/auth_state_changed";
import MinimumAgeSpecification from '../../../specifications/MinimumAgeSpecification';

/*
import Globe from 'react-globe.gl';
<Globe
    className="w-100"
    width="100%"
    height="500"
    backgroundColor={"#f6f9fc"}
    animateIn={true}
    globeImageUrl={"https://unpkg.com/three-globe@2.25.4/example/img/earth-blue-marble.jpg"}
    bumpImageUrl={"https://unpkg.com/three-globe/example/img/earth-topology.png"}
    pointsData={[]}
/>
*/

import Logo from '../../../assets/logo.png'
import { FormattedMessage, useIntl } from 'react-intl';
import UsernamePolicy from '../../../policies/UsernamePolicy';

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isValidated: false,
            errors: [],

            photo: null,

            firstName: "",
            lastName: "",
            email: "",
            username: "",

            year: null,
            month: null,
            day: null,

            countryCode: "",
            regionCode: "",
            sex: ""
        };

        this.months = this.getLocalizedMonths()
        this.usernamePolicy = new UsernamePolicy()

        this.listCountries = new ListCountriesService();
        this.listRegions = new ListRegionsService();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePhotoChange = this.handlePhotoChange.bind(this);
    }

    getLocalizedMonths() {
        var months = []
        var locale = window.navigator.language
        for (let i = 0; i < 12; i++) {
            let d = new Date();
            d.setDate(1);
            d.setMonth(i);
            let month = d.toLocaleString(locale, { month: "long" })
            months.push(month)
        }
        return months;
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handlePhotoChange(e) {
        const photo = e.target.files[0];
        this.setState({
            photo: photo
        })
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            isValidated: true,
            isLoading: true
        })

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

            this.setState({ isLoading: false })
            return;
        }

        var { password, passwordConfirmation, photo, firstName, lastName, username, sex, email, countryCode, regionCode, year, month, day } = this.state;

        if (new PasswordConfirmationPolicy().ensureSame(password, passwordConfirmation) == false) {
            this.setState({
                isLoading: false,
                password: "",
                passwordConfirmation: "",
                errors: [new Error('Passwords did not match.')]
            })
            return;
        }

        let birthday = new Date(year, month, day)
        let currentYear = new Date().getFullYear()
        let yearOfBirth = birthday.getFullYear()

        let coppaPolicy = new ChildrensOnlinePrivacyProtectionRulePolicy()
        let legalAge = new MinimumAgeSpecification(coppaPolicy.getMinimumAge())
        let candidateAge = new MinimumAgeSpecification(currentYear - yearOfBirth)

        if (candidateAge.subsumes(legalAge) === false) {
            this.setState({
                isLoading: false,
                password: "",
                passwordConfirmation: "",
                errors: [new Error(`You must be at least ${coppaPolicy.getMinimumAge()} to create an account.`)]
            })
            return;
        }

        let data = {
            firstName,
            lastName,
            username,
            email,
            birthday: birthday,
            countryCode: countryCode,
            regionCode: regionCode,
            sex: sex,
            bio: "",
            languageCode: window.navigator.language
        };

        User
        .create(photo, password, data)
        .then((newUser) => {
            this.setState({ isLoading: false }, () => {
                this.props.authStateChanged(newUser)
                this.props.navigate('/discover')
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    renderForm() {
        var { isValidated, isLoading, errors, photo, firstName, lastName, username, sex, email, password,
            passwordConfirmation, countryCode, regionCode, year, month, day, bio, occupation } = this.state;
        return (
            <Form onSubmit={this.handleSubmit} noValidate validated={isValidated}>
                {errors.length > 0 &&
                    <Alert>
                        <ul>
                            {errors.map((error) =>
                                <li>{error.message}</li>
                            )}
                        </ul>
                    </Alert>
                }

                <div className="d-none">
                    <ProfilePicture
                        photo={photo}
                        onChange={this.handlePhotoChange}
                        onRemove={(e) => this.setState({ photo: null })}
                    />
                </div>

                <Row className="row-cols-1 row-cols-sm-2 mb-4">
                    <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Control
                            name="firstName"
                            value={firstName}
                            onChange={this.handleInputChange}
                            size="lg"
                            required
                            type="text"
                            placeholder={this.props.intl.formatMessage({id: "FIRST_NAME_INPUT_PLACEHOLDER"})}
                            autoFocus
                        />
                        <Form.Control.Feedback>
                            <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>                            
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Control
                            name="lastName"
                            value={lastName}
                            onChange={this.handleInputChange}
                            size="lg"
                            required
                            type="text"
                            placeholder={this.props.intl.formatMessage({id: "LAST_NAME_INPUT_PLACEHOLDER"})}
                        />
                        <Form.Control.Feedback>
                            <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>                            
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <div className="mb-4">
                    <Form.Control
                        pattern={this.usernamePolicy.getValidPattern()}
                        name="username"
                        value={username}
                        onChange={this.handleInputChange}
                        size="lg"
                        required
                        type="text"
                        placeholder={this.props.intl.formatMessage({id: "USERNAME_INPUT_PLACEHOLDER"})}
                        aria-describedby='basic-addon1'
                    />
                    <Form.Control.Feedback>
                        <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>
                    </Form.Control.Feedback>
                </div>

                <div className="mb-4">
                    <Form.Control
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        size="lg"
                        required
                        type="email"
                        placeholder={this.props.intl.formatMessage({id: "EMAIL_INPUT_PLACEHOLDER"})}
                    />
                    <Form.Control.Feedback>
                        <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>
                    </Form.Control.Feedback>
                </div>

                <div className="password-toggle mb-4">
                    <Form.Control
                        name="password"
                        value={password}
                        onChange={this.handleInputChange}
                        size="lg"
                        required
                        type="password"
                        placeholder={this.props.intl.formatMessage({id: "PASSWORD_INPUT_PLACEHOLDER"})}
                    />
                </div>
                <div className="password-toggle mb-4">
                    <Form.Control
                        name="passwordConfirmation"
                        value={passwordConfirmation}
                        onChange={this.handleInputChange}
                        size="lg"
                        required
                        type="password"
                        placeholder={this.props.intl.formatMessage({id: "PASSWORD_CONFIRMATION_INPUT_PLACEHOLDER"})}
                    />
                </div>

                <Form.Group className="mb-4">
                    <Form.Select
                        size="lg"
                        required
                        onChange={this.handleInputChange}
                        value={sex}
                        name="sex">
                        <option value=""><FormattedMessage id="CHOOSE_SEX_OPTION"/></option>
                        <option value="male"><FormattedMessage id="SEX_MALE_OPTION"/></option>
                        <option value="female"><FormattedMessage id="SEX_FEMALE_OPTION"/></option>
                    </Form.Select>
                </Form.Group>

                <Row className="row-cols-1 row-cols-sm-3 mb-4">
                    <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Label>
                            <FormattedMessage id="BIRTHDAY_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            name="year"
                            value={year}
                            onChange={this.handleInputChange}
                            size="lg"
                            required
                            type="number"
                            placeholder={this.props.intl.formatMessage({id: "YEAR_INPUT_PLACEHOLDER"})}
                        />
                        <Form.Control.Feedback>
                            <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label>{' '}</Form.Label>
                        <Form.Select
                            size="lg"
                            required
                            onChange={this.handleInputChange}
                            name="month"
                            value={month}>
                            {this.months.map((month, index) =>
                                    <option value={index.toString().padStart(2, '0')}>{month}</option>
                                )}
                        </Form.Select>
                        <Form.Control.Feedback>
                            <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label>{' '}</Form.Label>
                        <Form.Control
                            name="day"
                            value={day}
                            onChange={this.handleInputChange}
                            size="lg"
                            required
                            type="number"
                            placeholder={this.props.intl.formatMessage({id: "DAY_INPUT_PLACEHOLDER"})}
                        />
                        <Form.Control.Feedback>
                            <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Form.Group className="mb-4">
                    <Form.Select
                        size="lg"
                        required
                        onChange={this.handleInputChange}
                        value={countryCode}
                        name="countryCode">
                        <option value="">
                            <FormattedMessage id="CHOOSE_COUNTRY_OPTION"/>
                        </option>
                        {this.listCountries
                            .all()
                            .map((country) =>
                                <option value={country.code}>{country.name}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Select
                        size="lg"
                        onChange={this.handleInputChange}
                        name="regionCode"
                        value={regionCode}>
                        <option value="">
                            <FormattedMessage id="CHOOSE_REGION_OPTION"/>
                        </option>
                        {this.listRegions
                            .forCountryCode(countryCode)
                            .map((region) =>
                                <option value={region.code}>{region.name}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>

                <div className="pb-4">
                    <div className="form-check my-2">
                        <input name="agreed_toc" className="form-check-input" type="checkbox" id="terms" required />
                        <label className="form-check-label ms-1" for="terms">
                            <FormattedMessage
                                id="AGREE_TOC_FORMAT"
                                values={{
                                    link: <a target="_blank" href="/legal/terms"><FormattedMessage id="TERMS_AND_CONDITIONS_TEXT"/></a>
                                }}/>
                        </label>
                    </div>
                </div>
                <Button variant="primary" size="lg" className="w-100 mb-4" type="submit" disabled={isLoading}>
                    {isLoading &&
                        <Spinner
                            className="me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <FormattedMessage id="SIGNUP_BTN"/>
                </Button>
            </Form>
        )
    }

    render() {
        return (
            <div className="page-wrapper">
                <div className="d-lg-flex position-relative h-100">
                    <Link to="/" className="text-nav btn btn-icon bg-light border rounded-circle position-absolute top-0 end-0 p-0 mt-3 me-3 mt-sm-4 me-sm-4" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Back to home">
                        <img width="32" height="32" src={Logo} />
                    </Link>
                    <div className="d-flex flex-column align-items-center w-lg-50 h-100 px-3 px-lg-5 pt-5">
                        <div className="w-100 mt-auto" style={{ "maxWidth": "526px" }}>
                            <h1 className="fw-bold">
                                <FormattedMessage id="NO_ACCOUNT_HEADER"/>
                            </h1>
                            <p className="pb-3 mb-3 mb-lg-4">
                                <FormattedMessage
                                    id="HAVE_ACCOUNT_FORMAT"
                                    values={{
                                        link: <Link to="/login"><FormattedMessage id="LOGIN_HERE_TEXT"/></Link>
                                    }}/>
                            </p>
                            {this.renderForm()}
                        </div>
                        <p className="w-100 fs-sm pt-5 mt-auto mb-5" style={{ maxWidth: "526px" }}>
                            <span className="text-muted">
                                <FormattedMessage
                                    id="ALL_RIGHTS_RESERVED_FORMAT"
                                    values={{
                                        year: new Date().getFullYear(),
                                        brand: "Aria5"
                                    }}/>
                            </span>
                        </p>
                    </div>
                    <div
                        className="bg-dark w-50 bg-size-cover bg-repeat-0 bg-position-center"
                        style={{ backgroundImage: "url('https://bspodttrfjifboigqtfv.supabase.co/storage/v1/object/public/assets/pexels-sean-valentine-12879014.jpg')" }}>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let navigate = useNavigate()
    let intl = useIntl()
    return <SignUp {...props} intl={intl} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);