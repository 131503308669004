class WritePermissionPolicy {
    handlePostCase(user, post) {
        let isAuthor = user.id === post.author.id
        return isAuthor
    }

    // handleMessageCase(user, message)
    // handleEventCase(user, event)
    // handleUserCase(user, other)

    isGranted(user, {type, object}) {
        if (user === null || user === undefined) {
            return false
        }
        switch (type.toUpperCase()) {
            case 'POST':
                return this.handlePostCase(user, object)
            // case ...
            default:
                return false
        }
    }
}

export default WritePermissionPolicy