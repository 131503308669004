export default function(state = {
  show: false,
  id: null,
  payload: null
}, action) {
  switch (action.type) {
    case 'SET_MODAL':
      return action.payload
  }
  return state;
}
