import { Component } from "react";
import { NavLink } from 'react-router-dom'
import { Nav } from 'react-bootstrap'

class NavDrawerItem extends Component {
    constructor(props) {
        super(props)
    }

    getIconClassName(isActive, {icon, activeIcon}) {
        var className = "mb-2 me-3 inline-icon "
        className += ` ${isActive ? activeIcon : icon}`
        return className
    }

    getNavLinkClassName(isActive, {className, activeClassName}) {
        var className = isActive ? activeClassName : className
        className += ' '
        return className
    }

    render() {
        var { to, activeClassName, className, icon, activeIcon } = this.props
        return (
            <Nav.Item as="li">
                <h6 className="mb-2">
                    <NavLink
                        to={to}
                        className={(navigationData) => this.getNavLinkClassName(navigationData.isActive, {className, activeClassName})}
                        children={({ isActive }) => (
                            <div className="d-flex justify-content-start align-items-center">
                                <i style={{ fontSize: "26px" }} className={this.getIconClassName(isActive, {icon, activeIcon})}></i>
                                <span className="mb-1 d-lg-none d-xl-block">{this.props.children}</span>
                            </div>
                        )}
                    />
                </h6>
            </Nav.Item>
        )
    }
}

export default NavDrawerItem