class AppearanceService {
    setTheme(document, theme) {
        if (theme == 'dark') {
            document.body.classList.add('dark-mode')
        } else if (theme == 'light') {
            document.body.classList.remove(['dark-mode'])
        }
    }
}

export default AppearanceService