import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Form, Button, Spinner, Alert } from 'react-bootstrap';

import AuthService from '../../../services/AuthService';
import User from '../../../models/User';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import authStateChanged from "../../../redux/actions/auth_state_changed";

import Logo from '../../../assets/logo.png'
import { FormattedMessage, useIntl } from 'react-intl';

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            email: "",
            password: "",
            isValidated: false,
            isLoading: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            isValidated: true,
            isLoading: true
        })

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

            this.setState({ isLoading: false })
            return;
        }

        var { email, password } = this.state;

        let auth = new AuthService();
        auth.signIn(email, password)
            .then((userId) => {
                return User.findById(userId)
            })
            .then((currentUser) => {
                this.setState({ isLoading: false }, () => {
                    this.props.authStateChanged(currentUser);
                    this.props.navigate('/');
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    errors: [error]
                })
            })
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    renderForm() {
        var { email, password, isValidated, isLoading, errors } = this.state;
        return (
            <Form onSubmit={this.handleSubmit} noValidate validated={isValidated}>
                {errors.length > 0 &&
                    <Alert>
                        <ul>
                            {errors.map((error) =>
                                <li>{error.message}</li>
                            )}
                        </ul>
                    </Alert>
                }
                <div className="mb-4">
                    <Form.Control
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        size="lg"
                        required
                        type="email"
                        placeholder={this.props.intl.formatMessage({id: "EMAIL_INPUT_PLACEHOLDER"})}
                        aria-describedby='basic-addon1'
                    />
                </div>
                <div className="mb-4">
                    <Form.Control
                        name="password"
                        value={password}
                        onChange={this.handleInputChange}
                        size="lg"
                        required
                        type="password"
                        placeholder={this.props.intl.formatMessage({id: "PASSWORD_INPUT_PLACEHOLDER"})}
                        aria-describedby='basic-addon2'
                    />
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-start pb-4">
                    <Link className="fs-sm fw-semibold text-decoration-none my-1" to="/forgot">
                        <FormattedMessage id="FORGOT_PASSWORD_BTN"/>
                    </Link>
                </div>
                <Button variant="primary" size="lg" className="w-100 mb-4" type="submit" disabled={isLoading}>
                    {isLoading &&
                        <Spinner
                            className="me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <FormattedMessage id="LOGIN_BTN"/>
                </Button>
            </Form>
        )
    }

    render() {
        return (
            <div className="page-wrapper h-100">
                <div className="d-lg-flex position-relative h-100">
                    <Link to="/" className="text-nav btn btn-icon bg-light border rounded-circle position-absolute top-0 end-0 p-0 mt-3 me-3 mt-sm-4 me-sm-4" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Back to home">
                        <img width="32" height="32" src={Logo} />
                    </Link>
                    <div className="d-flex flex-column align-items-center w-lg-50 h-100 px-3 px-lg-5 pt-5">
                        <div className="w-100 mt-auto" style={{ "maxWidth": "526px" }}>
                            <h1 className="fw-bold">
                                <FormattedMessage id="SIGN_IN_HEADER"/>
                            </h1>
                            <p className="pb-3 mb-3 mb-lg-4">
                                <FormattedMessage
                                    id="NO_ACCOUNT_FORMAT"
                                    values={{
                                        link: <Link to="/signup"><FormattedMessage id="REGISTER_HERE"/></Link>
                                    }}/>
                            </p>
                            {this.renderForm()}
                        </div>
                        <p className="w-100 fs-sm pt-5 mt-auto mb-5" style={{ maxWidth: "526px" }}>
                            <span className="text-muted">
                                <FormattedMessage
                                    id="ALL_RIGHTS_RESERVED_FORMAT"
                                    values={{
                                        year: new Date().getFullYear(),
                                        brand: "Aria5"
                                    }}/>
                            </span>
                        </p>
                    </div>
                    <div
                        className="bg-dark w-50 bg-size-cover bg-repeat-0 bg-position-center"
                        style={{ backgroundImage: "url('https://bspodttrfjifboigqtfv.supabase.co/storage/v1/object/public/assets/pexels-nothing-ahead-3571551.jpg')" }}>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let navigate = useNavigate();
    let intl = useIntl()
    return <SignIn {...props} navigate={navigate} intl={intl} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);