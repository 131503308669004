import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
// import './custom.css'

import App from './App';
import reportWebVitals from './reportWebVitals';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import reducers from './redux/reducers/index.js';
import { BrowserRouter } from 'react-router-dom';
const store = applyMiddleware()(createStore)(reducers);

const root = ReactDOM.createRoot(document.getElementById('root'));

/* disabling strict mode in dev mode because it is 
causing calls to be fired twice in componentDidMount for instance */
// <React.StrictMode></React.StrictMode>

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
