import { Component } from "react";

class NavDrawerFooter extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="mt-auto">
                {this.props.children}
            </div>
        )
    }
}

export default NavDrawerFooter