class DownloadPostService {
    inWindow(window, post) {
        return fetch(post.downloadURL)
        .then((response) => {
            return response.text()
        })
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            link.download = `${post.title || "Untitled"}.html`;
            link.href = url;

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            return url
        })
    }
}

export default DownloadPostService