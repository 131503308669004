import { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";

import PasswordConfirmationPolicy from '../../../../../../../policies/PasswordConfirmationPolicy'

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isValidated: false
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault()
        const form = e.currentTarget
        this.setState({
            isValidated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }
        this.props.onSaveChanges(e)
    }

    handleCancel(e) {
        this.setState({isValidated: false})
        this.props.onCancel(e)
    }


    render() {
        var { isValidated } = this.state
        var { data } = this.props

        var { currentPassword, newPassword, newPasswordConfirmation} = data

        return (
            <Form
                noValidate
                validated={isValidated}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
            >
                <div className="row align-items-center g-3 g-sm-4 pb-3">
                    <Col sm={6}>
                        <Form.Label>
                            <FormattedMessage id="CURRENT_PASSWORD_INPUT_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            onChange={this.props.onChange}
                            value={currentPassword}
                            required
                            type="password"
                            name="currentPassword"/>
                    </Col>
                    <Col sm={6}>
                        <Link className="d-inline-block fs-sm fw-semibold text-decoration-none mt-sm-4" to="/forgot">
                            <FormattedMessage id="FORGOT_PASSWORD_BTN"/>
                        </Link>
                    </Col>
                    <div className="col-sm-6">
                        <Form.Label>
                            <FormattedMessage id="NEW_PASSWORD_INPUT_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            onChange={this.props.onChange}
                            value={newPassword}
                            required
                            type="password"
                            name="newPassword"/>
                    </div>
                    <div className="col-sm-6">
                        <Form.Label>
                            <FormattedMessage id="CONFIRM_NEW_PASSWORD_INPUT_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            onChange={this.props.onChange}
                            value={newPasswordConfirmation}
                            required
                            type="password"
                            name="newPasswordConfirmation"/>
                    </div>
                </div>

                <div className="alert alert-info d-flex my-3 my-sm-4"><i className="bi bi bi-info-circle fs-xl me-2"></i>
                    <p className="mb-0">
                        <FormattedMessage id="PASSWORD_HELPER_TEXT"/>
                    </p>
                </div>
                <div className="d-flex justify-content-end pt-3">
                    <Button className="me-3" onClick={this.props.onCancel} variant="secondary">
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button type="submit" variant="primary">
                        <FormattedMessage id="SAVE_BTN"/>
                    </Button>
                </div>
            </Form>
        )
    }
}

export default PasswordChangeForm