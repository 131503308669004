/*
* Usage:
* <PopoverStickOnHover
*    component={<div>Holy guacamole! I'm Sticky.</div>}
*    placement="top"
*    onMouseEnter={() => { }}
*    delay={200}
* >
*   <div>Show the sticky tooltip</div>
* </PopoverStickOnHover>
*/

import React from 'react'
import PropTypes from 'prop-types'
import { Overlay, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Avatar from '../Avatar';

class UserPopover extends React.Component {
  constructor(props) {
    super(props)

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.state = {
      showPopover: false,
    }
  }

  handleMouseEnter() {
    const { delay, onMouseEnter } = this.props

    this.setTimeoutConst = setTimeout(() => {
      this.setState({ showPopover: true }, () => {
        if (onMouseEnter) {
          onMouseEnter()
        }
      })
    }, delay);
  }

  handleMouseLeave() {
    clearTimeout(this.setTimeoutConst)
    this.setState({ showPopover: false })
  }

  componentWillUnmount() {
    if (this.setTimeoutConst) {
      clearTimeout(this.setTimeoutConst)
    }
  }

  render() {
    let { component, children, placement } = this.props
    var { photo, name, subtitle, location, description, link, className, showOnHover } = this.props;

    const child = React.Children.map(children, (child) => (
      React.cloneElement(child, {
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
        ref: (node) => {
          this._child = node
          const { ref } = child
          if (typeof ref === 'function') {
            ref(node);
          }
        }
      })
    ))[0]

    return (
      <React.Fragment>
        {child}
        <Overlay
          show={this.state.showPopover}
          placement={placement}
          target={this._child}
          shouldUpdatePosition={true}
        >
          <Popover
            style={{minWidth: "274px"}}
            onMouseEnter={() => {
              this.setState({ showPopover: true })
            }}
            onMouseLeave={this.handleMouseLeave}
            id='popover'
            className="shadow"
          >
            <Popover.Body className="p-0">
              <div className="p-3">
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    {
                      <Link className="text-decoration-none" to={link}>
                        <Avatar
                          size="56px"
                          src={photo}
                          alt={name}
                        />
                      </Link>
                    }
                  </div>
                  <div className="w-100 flex-grow-1 ms-3 n-lines-1">
                    <Link className="h6 text-decoration-none text-dark" id="author-link" to={link}>
                      {name}
                    </Link>
                    <br />
                    <p className="text-body fs-sm mb-0 n-lines-1">
                      {subtitle}
                    </p>
                  </div>
                </div>
                <span className="n-lines-2">
                  {description}
                </span>
              </div>
              <div className="border-top p-3 n-lines-1">
                <i className="bi bi-geo me-2"></i>
                {' '}{location}
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </React.Fragment>
    )
  }
}

UserPopover.defaultProps = {
  delay: 0
}

UserPopover.propTypes = {
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  children: PropTypes.element.isRequired,
  component: PropTypes.node.isRequired
}

export default UserPopover;
