const { createClient } = require('@supabase/supabase-js')

class CheckUsernameService {
    constructor() {
        this.supabase = createClient('https://bspodttrfjifboigqtfv.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc')
    }

    isAvailable(candidate) {
        return this.supabase
        .from('user')
        .select('username')
        .eq('username', candidate)
        .then((response) => {
            let { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            if (data.length > 0) {
                return false
            }
            return true
        })
    }
}

export default CheckUsernameService