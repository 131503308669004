import React, { useEffect, Component } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import Home from '../scenes/Home'
import Discover from '../scenes/Discover'
import PostDetails from '../scenes/PostDetails'
import Chats from '../scenes/chat/Chats'
import Groups from '../scenes/Groups'
import ChatRoomDetails from '../scenes/chat/ChatRoomDetails'

import SignUp from '../scenes/auth/SignUp'
import SignIn from '../scenes/auth/SignIn'
import Forgot from '../scenes/auth/Forgot'
import ResetPassword from '../scenes/auth/ResetPassword'

import Events from '../scenes/Events'
import UserDetails from '../scenes/UserDetails'
import Search from '../scenes/Search'
import Services from '../scenes/Services'


class AppRoutes extends Component {
  render() {
    return (
      <Routes>
          <Route path="/:regionCode?" element={<Home/>}/>
          <Route path="/me/:username/post/:postId" element={<PostDetails/>}/>
          <Route path="/me/:username" element={<UserDetails/>}/>

          <Route path="/search" element={<Search/>}/>

          <Route path="/discover" element={<Discover/>}/>

          <Route path="/:regionCode?/events" element={<Events/>}/>

          <Route path="/chats" element={<Chats/>}/>
          <Route path="/chats/:roomId" element={<ChatRoomDetails/>}/>

          <Route path="/:regionCode?/services" element={<Services/>}/>

          <Route path="/groups" element={<Groups/>}/>

          <Route path="/login" element={<SignIn/>}/>
          <Route path="/signup" element={<SignUp/>}/>
          <Route path="/forgot" element={<Forgot/>}/>
          <Route path="/update-password" element={<ResetPassword/>}/>
      </Routes>
    )
  }
}

export default AppRoutes;
