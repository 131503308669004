import { Component } from "react";

class NavDrawerAction extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="d-grid gap-2 my-3">
                {this.props.children}
            </div>
        )
    }
}

export default NavDrawerAction