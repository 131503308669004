// NOTE: Supports only json at this moment in time.
class SetLocalizedAssetsService {
    forLocale(locale, getAsset, forceUpdate=false) {
        var assets = [`isco-08/${locale}.json`, `countries/${locale}/index.json`]
        var updatedAssets = []
        
        if (forceUpdate == false) {
            for (let i = 0; i < assets.length; i++) {
                const asset = assets[i];
                if (asset.endsWith('.json') == false) {
                    continue
                }
                let assetName = asset.split('/')[0]
                let foundAsset = localStorage.getItem(assetName) ?? null
                if (foundAsset == null) {
                    updatedAssets.push(asset)
                }
            }
        }
        assets = [...updatedAssets]

        if (assets.length == 0) {
            return Promise.resolve(true)
        }

        return Promise.all(assets.map((x) => getAsset.withRelativePath(x)))
        .then((blobs) => {
            return Promise.all(blobs.map((x) => x.text()))
        })
        .then((jsonFiles) => {
            jsonFiles.forEach((jsonString, i) => {
                let asset = assets[i]
                let assetName = asset.split('/')[0]
                let json = JSON.parse(jsonString)
                localStorage.setItem(assetName, JSON.stringify(json))
            })
            return Promise.resolve(true)
        })
    }
}

export default SetLocalizedAssetsService