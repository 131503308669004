import { Component, createRef } from "react"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, useSwiper } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Button } from 'react-bootstrap'
import './index.scss'

class PostCardGallery extends Component {
    constructor(props) {
        super(props)

        this.swiper = createRef();
    }

    render() {
        return (
            <div>
                <Swiper
                    className="swiper"
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={16}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    navigation={{
                        nextEl: "#js-next1",
                        prevEl: "#js-prev1",
                    }}
                    onBeforeInit={(swiper) => {
                        this.swiper.current = swiper;
                    }}> 

                    <div className="ps-3 swiper-button-prev">
                        <Button
                            onClick={(e) => e.stopPropagation()}
                            id="js-prev1"
                            className="prev-btn rounded-circle btn-icon bg-dark btn-sm bg-opacity-50"
                            variant="link">
                            <i class="text-white bi bi-arrow-left"></i>
                        </Button>
                    </div>
                    <div className="pe-3 swiper-button-next">
                        <Button
                            onClick={(e) => e.stopPropagation()}
                            id="js-next1"
                            size="sm"
                            className="next-btn rounded-circle btn-icon bg-dark bg-opacity-50"
                            variant="link">
                            <i className="text-white bi bi-arrow-right"></i>
                        </Button>
                    </div>
                    {this.props.children}
                </Swiper>
            </div>
        )
    }
}

export default PostCardGallery