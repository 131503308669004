import React, { useState } from 'react';
import {Button, Form, Navbar} from 'react-bootstrap';
import MiniSearch from 'minisearch'

const OccupationsDropdownMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        debugger
        
        let miniSearch = new MiniSearch({
            fields: ['code', 'title'],
            storeFields: ['code', 'title']
        })
        if (miniSearch.documentCount == 0) {
            let documents = React.Children.toArray(children).map((child) => {
                return {
                    id: child.props.eventKey,
                    code: child.props.eventKey,
                    title: child.props.children
                }
            })
            miniSearch.addAll(documents)
        }

        let results = miniSearch.search(value, {
            boost: { title: 2 },
            fuzzy: 0.4
        })

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Navbar sticky="top" className="border-bottom px-2 py-0" bg="light" variant="dark">
                    <Form.Control
                        autoFocus
                        className="mx-3 my-3"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                </Navbar>
                <ul className="list-unstyled px-2 py-2">
                    { value.length == 0 &&
                        <div className="p-5"></div>
                    }
                    {value.length > 0 && 
                        React.Children.toArray(children).filter(
                            (child) => results.map((x) => x.id).includes(child.props.eventKey),
                        )
                    }
                </ul>
            </div>
        );
    },
);

export default OccupationsDropdownMenu