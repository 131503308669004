import { createClient } from '@supabase/supabase-js';
import Contact from '../../models/Contact';
import User from '../../models/User'

/**
 * A service which adds a user to another user's list of contacts.
 */
class ContactService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    /**
     * An operator which adds a another user to a users list of contacts
     * @param {Contact} contact - A contact
     * @return {Promise<boolean>} Returns a Promise with flag true if success or rejects with an error.
     */
    add(contact) {
        return this.supabase
        .from('contact')
        .insert({
            userId: contact.user.id,
            otherId: contact.other.id,
        })
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            return true
        })
    }

    remove(contact) {
        return this.supabase
        .from('contact')
        .delete()
        .eq('userId', contact.user.id)
        .eq('otherId', contact.other.id)
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            return true
        })

    }
}

export default ContactService