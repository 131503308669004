import { Component } from 'react'
import { Alert, Form, Row, Col, Button, Spinner } from 'react-bootstrap'

import ProfilePicture from '../../../../../../ProfilePicture'; 

import ListCountriesService from '../../../../../../../services/ListCountriesService';
import ListRegionsService from '../../../../../../../services/ListRegionsService';
import { FormattedMessage } from 'react-intl';
import UsernamePolicy from '../../../../../../../policies/UsernamePolicy';

class BasicInfoForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isValidated: false
        }

        this.months = this.getLocalizedMonths()

        this.listCountries = new ListCountriesService()
        this.listRegions = new ListRegionsService()

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault()
        const form = e.currentTarget
        this.setState({
            isValidated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }
        this.props.onSaveChanges(e)
    }

    getLocalizedMonths() {
        var months = []
        var locale = window.navigator.language
        for (let i = 0; i < 12; i++) {
            let d = new Date();
            d.setDate(1);
            d.setMonth(i);
            let month = d.toLocaleString(locale, { month: "long" })
            months.push(month)
        }
        return months;
    }

    render() {
        var {isValidated} = this.state
        var { isLoading, errors, data } = this.props
        
        var { photo, photoURL, firstName, lastName, username, sex, languageCode,
            countryCode, regionCode, year, month, day, bio } = data

        return (
            <Form onSubmit={this.handleSubmit} noValidate validated={isValidated}>
                {errors.length > 0 &&
                    <Alert>
                        <ul>
                            {errors.map((error) =>
                                <li>{error.message}</li>
                            )}
                        </ul>
                    </Alert>
                }

                <div className="mb-4">
                    <ProfilePicture
                        photo={photo}
                        photoURL={photoURL}
                        onChange={this.props.onPhotoChange}
                        onRemove={this.props.onPhotoRemove}
                    />
                </div>

                <Row className="row-cols-1 row-cols-sm-2 mb-4">
                    <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Label>
                            <FormattedMessage id="FIRST_NAME_INPUT_PLACEHOLDER"/>
                        </Form.Label>
                        <Form.Control
                            name="firstName"
                            value={firstName}
                            onChange={this.props.onChange}
                            required
                            type="text"
                            placeholder={this.props.intl.formatMessage({id: "FIRST_NAME_INPUT_PLACEHOLDER"})}
                        />
                        <Form.Control.Feedback>
                            <FormattedMessage id="LOOKS_GOOD_FEEDBACK"/>
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label>
                            <FormattedMessage id="LAST_NAME_INPUT_PLACEHOLDER"/>
                        </Form.Label>
                        <Form.Control
                            name="lastName"
                            value={lastName}
                            onChange={this.props.onChange}
                            required
                            type="text"
                            placeholder={this.props.intl.formatMessage({id: "LAST_NAME_INPUT_PLACEHOLDER"})}
                        />
                        <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <div className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="USERNAME_INPUT_PLACEHOLDER"/>
                    </Form.Label>
                    <Form.Control
                        pattern={new UsernamePolicy().getValidPattern()}
                        name="username"
                        value={username}
                        onChange={this.props.onChange}
                        required
                        type="text"
                        placeholder={this.props.intl.formatMessage({id: "USERNAME_INPUT_PLACEHOLDER"})}
                        aria-describedby='basic-addon1'
                    />
                    <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                </div>

                <div className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="LANGUAGE_INPUT_PLACEHOLDER"/>
                    </Form.Label>
                    <Form.Select
                        required
                        onChange={this.props.onChange}
                        value={languageCode}
                        name="languageCode">
                        <option value=""><FormattedMessage id="CHOOSE_LANGUAGE_OPTION"/></option>
                        <option value="da"><FormattedMessage id="LANGUAGE_OPTION_DANISH"/></option>
                        <option value="en"><FormattedMessage id="LANGUAGE_OPTION_ENGLISH"/></option>
                    </Form.Select>
                    <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                </div>

                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="SEX_INPUT_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        required
                        onChange={this.props.onChange}
                        value={sex}
                        name="sex">
                        <option value=""><FormattedMessage id="CHOOSE_SEX_OPTION"/></option>
                        <option value="male"><FormattedMessage id="SEX_MALE_OPTION"/></option>
                        <option value="female"><FormattedMessage id="SEX_FEMALE_OPTION"/></option>
                    </Form.Select>
                </Form.Group>

                <Row className="row-cols-1 row-cols-sm-3 mb-4">
                    <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Label>
                            <FormattedMessage id="BIRTHDAY_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            name="year"
                            value={year}
                            onChange={this.props.onChange}
                            required
                            type="number"
                            placeholder={this.props.intl.formatMessage({id: "YEAR_LABEL"})}
                        />
                        <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label></Form.Label>
                        <Form.Select
                            required
                            onChange={this.props.onChange}
                            name="month"
                            placeholder={this.props.intl.formatMessage({id: "MONTH_LABEL"})}
                            value={month}>
                            {this.months.map((month, index) =>
                                    <option value={index.toString().padStart(2, '0')}>{month}</option>
                                )}
                        </Form.Select>
                        <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label></Form.Label>
                        <Form.Control
                            name="day"
                            value={day}
                            onChange={this.props.onChange}
                            required
                            type="number"
                            placeholder={this.props.intl.formatMessage({id: "DAY_LABEL"})}
                        />
                        <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="COUNTRY_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        required
                        onChange={this.props.onChange}
                        value={countryCode}
                        name="countryCode">
                        <option value="">
                            <FormattedMessage id="COUNTRY_LABEL"/>
                        </option>
                        {this.listCountries
                            .all()
                            .map((country) =>
                                <option value={country.code}>{country.name}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="REGION_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        onChange={this.props.onChange}
                        name="regionCode"
                        value={regionCode}>
                        <option value="">Choose a region...</option>
                        {this.listRegions
                            .forCountryCode(countryCode)
                            .map((region) =>
                                <option value={region.code}>{region.name}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>

                <div className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="BIO_INPUT_LABEL"/>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        name="bio"
                        value={bio}
                        onChange={this.props.onChange}
                        size="lg"
                    />
                </div>

                <div className="col-12 d-flex justify-content-end pt-3">
                    <Button onClick={this.props.onCancel} className="me-3" variant="secondary">
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading &&
                            <Spinner
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                        <FormattedMessage id="SAVE_BTN"/>
                    </Button>  
                </div>
            </Form>
        )
    }
}

export default BasicInfoForm