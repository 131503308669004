import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import { useIntl, FormattedMessage } from 'react-intl';

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  render() {
    var { show } = this.state;
    var { query, suggestions, autoFocus } = this.props;
    query = query || "";
    suggestions = suggestions || [];
    autoFocus = autoFocus || false;
    return (
      <Dropdown className={`${this.props.className ? this.props.className : ''}`} role="menu" show={show} focusFirstItemOnShow={'keyboard'}>
        <Form onSubmit={(e) => this.props.onSubmit(e, query)}>
          <InputGroup className="rounded-pill">
            <span class="input-group-text">
              <i class="bi bi-search"></i>
            </span>
            <Form.Control
              autoFocus
              required
              onBlur={(e) => this.setState({show: false})}
              onFocus={(e) => this.setState({show: true})}
              autocomplete="off"
              onChange={this.props.onChange}
              value={query}
              name="query"
              type="text"
              placeholder={this.props.intl.formatMessage({id: 'SEARCH_INPUT_PLACEHOLDER'})}
            />
            <Button type="submit" className="rounded-pill" variant="primary">
              <FormattedMessage id="SEARCH_BTN"/>
            </Button>
          </InputGroup>
        </Form>
      </Dropdown>
    )
  }
}

function WithIntl(props) {
  let intl = useIntl();
  return <SearchInput {...props} intl={intl} />
}

export default WithIntl;
