import { Component } from 'react'
import BasicInfoForm from './components/BasicInfoForm'
import PasswordChangeForm from './components/PasswordChangeForm'
import DeleteAccountForm from './components/DeleteAccountForm'
import { FormattedMessage } from 'react-intl'

class GeneralPane extends Component {
    constructor(props) {
        super(props)

        var {currentUser} = this.props

        this.basicInfo = {
            photo: null,
            photoURL: currentUser.photoURL ?? null,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            languageCode: currentUser.languageCode,
            username: currentUser.username,
            sex: currentUser.sex,
            year: new Date(currentUser.birthday).getFullYear(),
            month: new Date(currentUser.birthday).getMonth(),
            day: new Date(currentUser.birthday).getDate(),
            countryCode: currentUser.countryCode,
            regionCode: currentUser.regionCode,
            bio: currentUser.bio
        }

        this.passwordChange = {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: ""
        }

        this.deleteAccount = {
            confirmation: false,
            currentPassword: ""
        }
        
        this.state = {
            ...this.basicInfo,
            ...this.passwordChange,
            ...this.deleteAccount
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handlePhotoChange = this.handlePhotoChange.bind(this)
        this.handlePhotoRemove = this.handlePhotoRemove.bind(this)

        this.handleCancel = this.handleCancel.bind(this)
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
    }

    handleCancel(e, sectionName) {
        switch (sectionName) {
            case 'basicInfo':
                this.setState({...this.basicInfo})
                break
            case 'passwordChange':
                this.setState({...this.passwordChange})
                break
            case 'deleteAccount':
                this.setState({...this.deleteAccount})
                break
        }
    }

    handlePhotoChange(e) {
        const photo = e.target.files[0]
        this.setState({photo})
    }

    handlePhotoRemove() {
        this.setState({photo: null})
    }

    renderBasicInfoForm(isLoading, errors, data) {
        return (
            <BasicInfoForm
                intl={this.props.intl}
                isLoading={isLoading}
                errors={errors}
                data={data}
                onChange={this.handleInputChange}
                onPhotoChange={this.handlePhotoChange}
                onCancel={(e) => this.handleCancel(e, 'basicInfo')}
                onSaveChanges={(e) => this.props.onSaveChanges(e, 'basicInfo', data)}
            />
        )
    }

    renderPasswordChangeForm(data) {
        return (
            <PasswordChangeForm
                intl={this.props.intl}
                data={data}
                onCancel={(e) => this.handleCancel(e, 'passwordChange')}
                onChange={this.handleInputChange}
                onSaveChanges={(e) => this.props.onSaveChanges(e, 'passwordChange', data)}
            />
        )
    }

    renderDeleteAccountForm(data) {
        return (
            <DeleteAccountForm
                intl={this.props.intl}
                data={data}
                onChange={this.handleInputChange}
                onSaveChanges={(e) => this.props.onSaveChanges(e, 'deleteAccount', data)}
            />
        )
    }

    includeKeysFromObject(keys, anObject) {
        return Object.fromEntries(
            Object.entries(anObject)
            .filter((x) => keys.includes(x[0]))
        )
    }

    render() {
        var isLoading = false
        var errors = []

        var basicInfoData = this.includeKeysFromObject(Object.keys(this.basicInfo), this.state)
        var passwordChangeData = this.includeKeysFromObject(Object.keys(this.passwordChange), this.state)
        var deleteAccountData = this.includeKeysFromObject(Object.keys(this.deleteAccount), this.state)

        return (
            <div>
                <div className="p-5">
                    <h4><FormattedMessage id="GENERAL_PANE_BASIC_INFO"/></h4>
                    {this.renderBasicInfoForm(isLoading, errors, basicInfoData)}
                </div>
                <hr/>

                <div className="p-5">
                    <h4><FormattedMessage id="GENERAL_PANE_PASSWORD_CHANGE"/></h4>
                    {this.renderPasswordChangeForm(passwordChangeData)}
                </div>
                <hr/>
                
                <div className="p-5">
                    <h4><FormattedMessage id="GENERAL_PANE_PASSWORD_CHANGE"/></h4>
                    {this.renderDeleteAccountForm(deleteAccountData)}
                </div>
            </div>
        )
    }
}

export default GeneralPane