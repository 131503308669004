import React, { Component } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import createNotification from '../../redux/actions/create_notification';

class NotificationLayer extends Component {
    constructor(props) {
        super(props);

        this.hideToast = this.hideToast.bind(this);
    }

    hideToast() {
        this.props.createNotification(null);
    }

    renderToastIfNeeded() {
        let { notification } = this.props;
        return (
            <div>
                {notification &&
                    <div className="fixed-bottom" style={{ zIndex: 9000, position: 'fixed' }}>
                        <ToastContainer className="p-3" position={"bottom-center"}>
                            <Toast
                                onClose={this.hideToast}
                                show={notification}
                                className="border-0 bg-primary text-white"
                                delay={9000}
                                autohide>
                                <Toast.Body className="p-3 d-flex justify-content-between">
                                    {notification.name}
                                    {notification.userInfo.link &&
                                        <Link className="text-white" to={notification.userInfo.link.to}>
                                            {notification.userInfo.link.text}
                                        </Link>
                                    }
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </div>
                }
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderToastIfNeeded()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createNotification: createNotification
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationLayer);