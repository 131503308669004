import ListCountriesService from "../ListCountriesService"
import ListRegionsService from "../ListRegionsService"

class FormatRegionCodeService {
    constructor() {
        this.listCountries = new ListCountriesService()
        this.listRegions = new ListRegionsService()
    }

    withValue(regionCode, format="long") {
        if (regionCode === null) {
            return null
        }
        let fragments = regionCode.split('-')
        let isRegion = fragments.length === 2
        let isCountry = fragments.length === 1
        
        let countryCode = fragments[0]
        let countryResults = this.listCountries.all().filter((x) => x.code === countryCode)

        if (countryResults.length === 0) {
            return null
        } 
        let country = countryResults[0]

        if (isRegion) {
            let regionResults = this.listRegions.forCountryCode(countryCode.toUpperCase())
                                                .filter((x) => x.code === regionCode)
            if (regionResults.length === 0) {
            return null
            }
            let region = regionResults[0]
            if (format == 'long') {
                return `${region.name}, ${country.name}`
            } else if (format="short") {
                return region.name
            }
        
        } else if (isCountry) {
            return country.name
        }
        return null
    }
}

export default FormatRegionCodeService