import { Component } from "react"
import { Card, Dropdown, Button, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import EmojiPicker from 'emoji-picker-react'
import { FormattedMessage, FormattedNumber, FormattedPlural, useIntl } from "react-intl"

function metricsPopOver(props, post) {
    return (
        <Popover id="metrics-popover">
        <Popover.Header as="h3">
            <FormattedMessage id="POST_METRICS_POPOVER_TITLE"/>
        </Popover.Header>
        <Popover.Body>
            <ul className="list-unstyled">
                <li>
                    <FormattedNumber value={post.metrics.views} notation={'compact'}/>
                    {' '} <FormattedPlural value={post.metrics.views} one={props.intl.formatMessage({id: "ONE_VIEW"})} other={props.intl.formatMessage({id: "OTHER_VIEWS"})} />
                </li>
                <li>
                    <FormattedNumber value={post.metrics.replies} notation={'compact'}/>
                    {' '} <FormattedPlural value={post.metrics.replies} one={props.intl.formatMessage({id: "ONE_REPLY"})} other={props.intl.formatMessage({id: "OTHER_REPLIES"})} />
                </li>
                <li>
                    <FormattedNumber value={post.metrics.reactions} notation={'compact'}/>
                    {' '} <FormattedPlural value={post.metrics.reactions} one={props.intl.formatMessage({id: "ONE_REACTION"})} other={props.intl.formatMessage({id: "OTHER_REACTIONS"})} />
                </li>
            </ul>
            <small>Lorem ipsum dolor sit amet, consectetur <u>adipiscing</u> elit.</small>
        </Popover.Body>
        </Popover>
    )
}

class PostCardFooter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showReactionDropdown: false
        }
        this.toggleDropdown = this.toggleDropdown.bind(this)
    }

    toggleDropdown(e) {
        if (this.state.showReactionDropdown) {
            this.setState({ showReactionDropdown: false });
        } else {
            this.setState({ showReactionDropdown: true });
        }
    }


    render() {
        var { showReactionDropdown } = this.state
        var { post, showReply, className } = this.props

        showReply = showReply ?? true
        className = className ?? 'px-3'

        var uniqueReactions = post.reactions.filter((value, index, self) =>
            index === self.findIndex((t) => (t.emoji === value.emoji))
        )

        return (
            <Card.Footer onClick={(e) => e.stopPropagation()} className="border-top-0 w-100 mx-0">
                <div className={`d-flex justify-content-between align-items-center ${className}`}>
                    <div className="pe-4">
                        <OverlayTrigger overlay={<Tooltip><FormattedMessage id="REACTIONS_TOOLTIP"/></Tooltip>}>
                            <Dropdown onClick={(e) => e.stopPropagation()} show={showReactionDropdown} onToggle={(nextShow, meta) => this.setState({showReactionDropdown: nextShow})}>
                                { uniqueReactions.length === 0 &&
                                    <Dropdown.Toggle variant="link" size="sm" className="text-muted btn-icon border-0 rounded-circle">
                                        <i class="bi bi-emoji-smile fs-xl"></i>
                                    </Dropdown.Toggle>
                                }
                                { uniqueReactions.length > 0 &&
                                    <Dropdown.Toggle className="rounded-pill" variant="outline-secondary">
                                        { uniqueReactions.slice(0, 3).map((reaction) =>
                                            <span className="me-1">{reaction.emoji}</span>
                                        )} <span className="ps-2 text-muted">
                                            + {' '} {post.metrics && <FormattedNumber value={post.metrics.reactions} notation={'compact'}/>}
                                            </span>
                                    </Dropdown.Toggle>
                                }
                                <Dropdown.Menu className="border-0 bg-transparent py-0 mb-2">
                                    <EmojiPicker
                                        className=""
                                        theme='light'
                                        onEmojiClick={({emoji}, e) => {
                                            this.props.onReact(emoji, post)
                                            this.toggleDropdown(e)
                                        }}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </OverlayTrigger>
                    </div>
                    <div className={`${showReply ? 'd-none d-md-block' : ''} pe-4 text-muted`}>
                        <OverlayTrigger trigger="hover" placement={"top"} overlay={metricsPopOver(this.props, post)}>
                            <Button className="text-muted" variant="link">
                                <i style={{ fontSize: "18px" }} className="bi bi-bar-chart inline-icon me-2"></i>
                                <span>{' '} {post.metrics && <FormattedNumber value={post.metrics.views} notation={'compact'}/>}</span>
                            </Button>
                            
                        </OverlayTrigger>
                    </div>
                    { showReply &&
                        <div className="pe-4 text-muted">
                            <OverlayTrigger overlay={<Tooltip><FormattedMessage id="REPLY_TOOLTIP"/></Tooltip>}>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.onReply(e, post)
                                    }}
                                    className="text-muted btn-icon border-0 rounded-circle"
                                    variant="link">
                                    <i style={{ fontSize: "18px" }} className="bi bi-reply me-2"></i>
                                    <span>{' '} {post.metrics && <FormattedNumber value={post.metrics.replies} notation={'compact'}/>}</span>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    }
                    <div className="text-muted">
                        <OverlayTrigger overlay={<Tooltip><FormattedMessage id="SHARE_TOOLTIP"/></Tooltip>}>
                            <Dropdown className="align-self-center">
                                <Dropdown.Toggle variant="link" size="sm" className="text-muted btn-icon border-0 rounded-circle">
                                    <i style={{ fontSize: "18px" }} class="bi bi-box-arrow-up"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-macos shadow">
                                    <Dropdown.Item onClick={(e) => this.props.onShare(e, 'COPY_LINK', post)}>
                                        <FormattedMessage id="COPY_LINK_DROPDOWN_ITEM"/>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => this.props.onShare(e, 'EMAIL', post)}>
                                        <FormattedMessage id="EMAIL_DROPDOWN_ITEM"/>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => this.props.onShare(e, 'SAVE_POST', post)}>
                                        <FormattedMessage id="SAVE_POST_DROPDOWN_ITEM"/>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </OverlayTrigger>
                        
                    </div>
                </div>
            </Card.Footer>
        )
    }
}

function BackwardsCompatibleProps(props) {
    let intl = useIntl()
    return <PostCardFooter {...props} intl={intl} />
}

export default BackwardsCompatibleProps