import { Component } from 'react'
import Calendar from '../Calendar'
import { Dropdown } from 'react-bootstrap'

class CalendarDropdownMenu extends Component {
    render() {
        var { show, currentDate, showingYearMonth } = this.props

        var className = this.props.className ?? 'w-100'

        return (
            <Dropdown.Menu
                onMouseDown={(e) => e.preventDefault()}
                show={show}
                rootCloseEvent={'click'}
                className={`bg-transparent border-0 shadow-sm py-0 ${className}`}
                style={{minWidth: "300px"}}>
                    <Calendar
                        className={className}
                        currentDate={currentDate}
                        showingYearMonth={showingYearMonth}
                        onClickedPrevMonth={this.props.onClickedPrevMonth}
                        onClickedNextMonth={this.props.onClickedNextMonth}
                        onClickedDate={this.props.onClickedDate}
                    />
            </Dropdown.Menu>
        )
    }
}

export default CalendarDropdownMenu