class VideoURLSpecification {
    isSatisfiedBy(candidate) {
        let url = new URL(candidate)
        
        const videoExtensions = ['mp4', 'mov']
        const extension = url.pathname.split(".")[1]
        return videoExtensions.includes(extension)
    }
}

export default VideoURLSpecification