import Order from '../../models/common/Order'
import User from '../../models/User';

const { createClient } = require('@supabase/supabase-js')

class SearchContactsService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    /**
     * An operator which searches a users contacts
     * for a given user.
     * @param {User} user - A user.
     * @param {Pagination} pagination - A pagination value object.
     * @param {Order} order - A order value object.
     * @return {Promise<User[]>} Returns a list of contacts (users).
     */
    forUser(user, query) {
        return this.supabase
        .from('contact')
        .select('*, contact:otherId!inner(*, firstName, lastName, username)')
        .eq('userId', user.id)
        .or(`firstName.fts.${query}, lastName.fts.${query}, username.fts.${query}`, {foreignTable: 'contact'})
        .limit(3)
        .then((response) => {
            var { data, error } = response
            console.log("data =>", data )
            console.log("error => ", error)
            if (error) {
                return Promise.reject(error)
            }
            var contacts = data.map((x) => {
                let data = x.contact
                return new User({...data})
            })
            return contacts
        })
    }
}

export default SearchContactsService