import { createClient } from '@supabase/supabase-js';
import Post from '../../models/Post';
import { insertPost, insertEmptyMetricsForPost, convertToPosts, getDefaultPostSelection, insertPostDeletionSpecification } from './post-repository-fns'
import IncrementPostMetricService from '../../services/IncrementPostMetricService';
import PostDeletionSpecification from '../../specifications/PostDeletionSpecification';

/**
 * A repository for the Post model.
 * Handles the middle and end of the Post model lifecycle.
 */
class PostRepository {
    instance = undefined

    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey);
    }

    static getRepoState() {
        if (PostRepository.instance === undefined) {
            PostRepository.instance = new PostRepository()
        }
        return PostRepository.instance
    }

    /**
     * Adds a post to the repository.
     * @param {Post} post - A post to be added to the repository (ie. persisted to the database)
     * @param {PostDeletionSpecification} deletionSpecification - A post deletion specification which holds instructions when and how to delete the post.
     * @return {string} Returns a Promise with an identifier for the newly added post or rejects with an error.
     */
    add(post, deletionSpecification) {
        return insertPost(this.supabase, post)
        .then((post) => insertEmptyMetricsForPost(this.supabase, post))
        .then((post) => insertPostDeletionSpecification(this.supabase, post, deletionSpecification))
        .then((post) => post.id)
    }

    update(post, data) {
        return this.supabase
        .from('post')
        .update({
            updatedAt: data.updatedAt,
            preview: data.preview,
            entities: data.entities,
            title: data.title
        })
        .eq('id', post.id)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            return post.id
        })
    }

    /**
     * Fetches a list of popular posts by country (ie. posts ordered by their metrics value object such as: views, replies or reactions)
     * @param {Pagination} pagination - A pagination value object used to range limit the query response.
     * @param {Order} order - An order value object used to order the query response.
     * @return {Post[]} Returns a Promise with a list of posts or rejects with an error.
     */
    popularByCountry(countryCode, pagination, order) {
        return this.supabase
        .from('metrics')
        .select('*, post!inner(*, author:userId(*), reactions:reaction(*), metrics(*))')
        .eq('post.countryCode', countryCode)
        .gt('views', 0)
        .is('post.deletedAt', null)
        .limit(pagination.limit)
        .order(order.column, {ascending: order.ascending})
        .then((response) => {
            var {data, error} = response
            if (error) {
                return Promise.reject(error)
            }
            return convertToPosts(data.map((x) => x.post))
        })
    }

    /**
     * Fetches a list of popular posts by user (ie. posts ordered by their metrics value object such as: views, replies or reactions)
     * @param {Pagination} pagination - A pagination value object used to range limit the query response.
     * @param {Order} order - An order value object used to order the query response.
     * @return {Post[]} Returns a Promise with a list of posts or rejects with an error.
     */
    popularByUser(userId, pagination, order) {
        return this.supabase
        .from('metrics')
        .select('*, post!inner(*, author:userId(*), reactions:reaction(*), metrics(*))')
        .eq('post.userId', userId)
        .gt('views', 0)
        .is('post.deletedAt', null)
        .limit(pagination.limit)
        .order(order.column, {ascending: order.ascending})
        .then((response) => {
            var {data, error} = response
            if (error) {
                return Promise.reject(error)
            }
            return convertToPosts(data.map((x) => x.post))
        })
    }

    /**
     * Fetches a list of posts matching the criteria
     * @param {Criteria} criteria - A criteria value object used to filter the query response.
     * @param {Pagination} pagination - A pagination value object used to range limit the query response.
     * @param {Order} order - An order value object used to order the query response.
     * @return {Post[]} Returns a Promise with a list of posts matching the criteria or rejects with an error.
     */
    matching(criteria, pagination, order) {
        var query = this.supabase
        .from('post')
        .select(getDefaultPostSelection())
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        .limit(3, {foreignTable: 'reactions'})
        
        if (criteria.isEmpty() === false) {
            criteria.forEach((column, operator, value) => {
                if (operator == 'or') {
                    query = query.or(value)
                } else {
                    query = query.filter(column, operator, value)
                }
            })
        }
        
        return query
        .order(order.column, { ascending: order.ascending })
        .then((response) => {
            var { error, data } = response
            if (error) {
                return Promise.reject(error)
            }
            return convertToPosts(data)
        })
    }

    /**
     * Fetches a post with matching id. 
     * @param {string} id - A string identifier used to filter the query response.
     * @return {Post} Returns a Promise with a post matching the identifier or rejects with an error.
     */
    findById(id) {
        return this.supabase
        .from('post')
        .select(getDefaultPostSelection())
        .eq('id', id)
        .is('deletedAt', null)
        .limit(1)
        .limit(3, {foreignTable: 'reactions'})
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            let post = convertToPosts(data)[0]
            return post
        })
    }

    /**
     * Fetches a list of replies matching a postId. 
     * @param {string} postId - A replied to postId used to filter the query response.
     * @return {Post[]} Returns a Promise with a list of replies to the postId or rejects with an error.
     */
    inReplyToPostId(postId, pagination, order) {
        return this.supabase
        .from('post')
        .select(getDefaultPostSelection())
        .eq('inReplyToPostId', postId)
        .is('deletedAt', null)
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        .limit(3, {foreignTable: 'reactions'})
        .order(order.column, { ascending: order.ascending })
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            return convertToPosts(data)
        })
    }

    /**
     * Deletes a post. 
     * @param {Post} post - A post which is to be deleted.
     * @return {string} Returns a Promise with the id of the post just deleted or rejects with an error.
     */
    delete(post) {
        return this.supabase
        .from('post')
        .update({ deletedAt: new Date() })
        .eq('id', post.id)
        .then((response) => {
            var {error} = response
            if (error) {
                return Promise.reject(error)
            }
            return post.id
        })
        .then(() => {
            if (post.inReplyToPostId) {
                return new IncrementPostMetricService().withArgs({
                    postId: post.inReplyToPostId, fieldName: 'replies', x: -1})
            }
            return Promise.resolve()
        })
        .then(() => post.id)
    }
}

export default PostRepository;