import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FormatRegionCodeService from '../../services/FormatRegionCodeService'
import Avatar from '../Avatar'

class UserCard extends Component {
    constructor(props) {
        super(props)

        // this shouldn't be here. this is the UI layer.
        this.formatRegionCode = new FormatRegionCodeService()
    }

    render() {
        var { user, className } = this.props
        className = className || " border"
        return (
            <div className={`card ` + className}>
                <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                        <Link className="text-decoration-none" to={this.props.to}>
                            <Avatar
                                size="60px"
                                src={user.photoURL}
                                alt={user.firstName + ' ' + user.lastName}
                            />
                        </Link>
                        <div className="ps-3">
                            <div className="h6 mb-1">
                                <Link className="text-decoration-hover text-dark" to={this.props.to}>{user.firstName + ' ' + user.lastName}</Link>
                            </div>
                            <div className="fs-sm text-muted">{this.formatRegionCode.withValue(user.regionCode)}</div>
                        </div>
                    </div>
                    <p className="card-text n-lines-5">{user.bio}</p>
                </div>
            </div>
        )
    }
}

export default UserCard