import { createClient } from "@supabase/supabase-js";

class SendFeedbackService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    fromUser(user, text) {
        return this.supabase
        .from('feedback')
        .insert({
            userId: user.id,
            text: text,
            url: window.location.href ?? ""
        })
        .select('id')
        .then((response) => {
            var { error, data } = response
            if (error) {
                return Promise.reject(error)
            }
            return data[0].id
        })
    }
}

export default SendFeedbackService