import messages_en from "../../translations/en.json";
import messages_da from "../../translations/da.json";

const DEFAULT_LANGUAGE_CODE = 'en';
const messages = {
    'en': messages_en,
    'da': messages_da
};

class TranslationPolicy {
  getBrowserLanguage() {
    var browserLang = navigator.language || navigator.userLanguage;
    browserLang = browserLang.split('-')[0];
    return browserLang;
  }

  forCurrentUser(currentUser) { // fallbackLanguageCode
    let isCurrentUser = (currentUser != null);
    let browserLang = this.getBrowserLanguage();
    var isSupportedLanguage = isCurrentUser ?
                              Object.keys(messages).includes(currentUser.languageCode) :
                              Object.keys(messages).includes(browserLang);
    let defaultPrefs = {
      locale: DEFAULT_LANGUAGE_CODE,
      messages: messages[DEFAULT_LANGUAGE_CODE]
    }
    const fallbackOnBrowser = (isCurrentUser == false);
    if (fallbackOnBrowser) {
      let browserPrefs = {
        locale: browserLang,
        messages: messages[browserLang]
      };
      return isSupportedLanguage ? browserPrefs : defaultPrefs;
    } else {
      let currentUserPrefs = {
        locale: currentUser.languageCode,
        messages: messages[currentUser.languageCode]
      };
      return isSupportedLanguage ? currentUserPrefs : defaultPrefs;
    }
  }
}

export default TranslationPolicy;