import timezones from '../../assets/timezones.json'
import Timezone from '../../models/common/Timezone'

class ListTimezonesService {
    all() {
        return timezones.map((x) => {
            return new Timezone({...x})
        })
    }
}

export default ListTimezonesService