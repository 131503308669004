import { createClient } from '@supabase/supabase-js';
import { format } from 'date-fns'
import CalendarEvent from '../../../models/event/CalendarEvent';
import User from '../../../models/User';

class CalendarEventRepository {
    instance = undefined

    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey);
    }

    static getRepoState() {
        if (CalendarEventRepository.instance === undefined) {
            CalendarEventRepository.instance = new CalendarEventRepository()
        }
        return CalendarEventRepository.instance
    }

    add(calendarEvent) {
        return this.supabase
        .from('calendar_event')
        .insert({
           createdAt: calendarEvent.createdAt,
           updatedAt: calendarEvent.updatedAt,
           startDate: calendarEvent.startDate,
           timezone: calendarEvent.timezone,
           description: calendarEvent.description,
           invited: calendarEvent.invited,
           userId: calendarEvent.author.id,
           address: calendarEvent.address,
           regionCode: calendarEvent.regionCode,
           countryCode: calendarEvent.countryCode,
           title: calendarEvent.title,
           isOnline: calendarEvent.isOnline,
           url: calendarEvent.url,
           duration: calendarEvent.duration,
           size: calendarEvent.size.toUpperCase(),
           type: calendarEvent.type.toUpperCase(),
           subtype: calendarEvent.subtype.toUpperCase()
        })
        .select()
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            if (data.length === 0) {
                return Promise.reject(new Error('No result was returned when inserting a calendar event.'))
            }
            let result = data[0]
            return new CalendarEvent({
                id: result.id,
                ...result,
                author: calendarEvent.author
            })
        })
    }

    update(calendarEvent, data) {
        return this.supabase
        .from('calendar_event')
        .update({
            updatedAt: data.updatedAt,
            startDate: data.startDate,
            timezone: data.timezone,
            description: data.description,
            invited: data.invited,
            address: data.address,
            title: data.title,
            isOnline: data.isOnline,
            url: data.url,
            duration: data.duration,
            size: data.size.toUpperCase(),
            type: data.type.toUpperCase(),
            subtype: data.subtype.toUpperCase()
        })
        .eq('id', calendarEvent.id)
        .then((response) => {
            var {data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            return calendarEvent.id
        })
    }

    /**
     * Fetches a list of calendar events matching the criteria
     * @param {Criteria} criteria - A criteria value object used to filter the query response.
     * @param {Pagination} pagination - A pagination value object used to range limit the query response.
     * @param {Order} order - An order value object used to order the query response.
     * @return {CalendarEvent[]} Returns a Promise with a list of calendar events matching the criteria or rejects with an error.
     */
    matching(criteria, pagination, order) {
        var query = this.supabase
        .from('calendar_event')
        .select('*, user(*)')
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        
        if (criteria.isEmpty() === false) {
            criteria.forEach((column, operator, value) => {
                if (operator == 'or') {
                    query = query.or(value)
                } else {
                    query = query.filter(column, operator, value)
                }
            })
        }
        
        return query
        .order(order.column, { ascending: order.ascending })
        .then((response) => {
            var {data, error} = response
            if (error) {
                return Promise.reject(error)
            }
            var events = data.map((x) => {
                var event = new CalendarEvent({...x})
                event.author = new User({...x.user})
                return event
            })
            return events
        })
    }

    startsAt(startDate, regionCode, pagination) {
        var query = this.supabase
        .from('calendar_event')
        .select('*, user(*)')
        .gte('startDate', format(startDate, 'yyyy-MM-dd HH:mm:ss'))
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        
        let fragments = regionCode.split('-')
        let isCountryCode = fragments.length === 1

        if (isCountryCode) {
            query = query.eq('countryCode', regionCode)
        } else {
            query = query.eq('regionCode', regionCode)
        }

        return query
        .order('startDate', { ascending: true })
        .then((response) => {
            var {data, error} = response
            if (error) {
                return Promise.reject(error)
            }
            var events = data.map((x) => {
                var event = new CalendarEvent({...x})
                event.author = new User({...x.user})
                return event
            })
            return events
        })
    }

    delete(calendarEvent) {
        return this.supabase
        .from('calendar_event')
        .update({ deletedAt: new Date() })
        .eq('id', calendarEvent.id)
        .then((response) => {
            var {error} = response
            if (error) {
                return Promise.reject(error)
            }
            return calendarEvent.id
        })
    }
}

export default CalendarEventRepository