class PauseAutoScrollSpecification {
    constructor(threshold) {
        this.threshold = threshold || 200
    }
    isSatisfiedBy(document) {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.body
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + (window.pageYOffset + this.threshold)
        const hasScrolledToBottom = windowBottom >= docHeight
        const shouldPauseAutoScroll = hasScrolledToBottom === false
        return shouldPauseAutoScroll
    }
}

export default PauseAutoScrollSpecification