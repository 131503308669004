import { createClient } from '@supabase/supabase-js';
import ChatRoom from '../../../models/chat/ChatRoom';
import User from '../../../models/User';

class ChatRoomRepository {
    instance = undefined

    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey);
    }

    static getRepoState() {
        if (ChatRoomRepository.instance === undefined) {
            ChatRoomRepository.instance = new ChatRoomRepository()
        }
        return ChatRoomRepository.instance
    }

    findOrCreate(chatRoom) {
        var newChatRoom = undefined
        var foundResponse = null

        return this.supabase
        .from('chat_room')
        .select('*, participants:chat_room_participant(*, user:userId(*))')
        .limit(1)
        .contains('whitelisted', chatRoom.whitelisted)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            foundResponse = data.length > 0 ? response : null
            return foundResponse
        })
        .then((foundResponse) => {
            if (foundResponse == null) {
                return this.supabase
                .from('chat_room')
                .insert({
                    createdAt: chatRoom.createdAt,
                    updatedAt: chatRoom.updatedAt,
                    whitelisted: chatRoom.whitelisted
                })
                .select()
            } else {
                return foundResponse
            }
        })
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            if (data.length === 0) {
                return Promise.reject(new Error('An unknown error occurred'))
            }
            let roomId = data[0].roomId
            newChatRoom = Object.assign({}, chatRoom);
            newChatRoom.roomId = roomId
            return newChatRoom
        })
        .then((newChatRoom) => {
            if (foundResponse == null) {
                return this.supabase
                .from('chat_room_participant')
                .upsert(newChatRoom.participants.map((participant) => {
                    return {
                        roomId: newChatRoom.roomId,
                        userId: participant.id
                    }
                }))
                .select('*')
            } else {
                return foundResponse
            }
        })
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            if (data.length === 0) {
                return Promise.reject(new Error('An unknown error occurred'))
            }
            var newParticipants = []
            newChatRoom.participants.forEach((participant) => {
                newParticipants.push(participant)
            })
            newChatRoom.participants = [...newParticipants]
            return newChatRoom
        })
    }

    update(chatRoom, data) {
        if (data.participants && data.participants.length == 0) {
            return Promise.resolve([])
        }

        let participants = new Set([...chatRoom.participants.map((x) => x.id)])
        let updatedParticipants = new Set([...data.participants.map((x) => x.id)])
        
        let toBeDeleted = new Set([...participants].filter((x) => updatedParticipants.has(x) === false));
        let toBeInserted = new Set([...updatedParticipants].filter((x) => participants.has(x) === false));

        var promises = []
        if (toBeDeleted.size > 0) {
            promises.push(
                this.supabase
                .from('chat_room_participant')
                .delete()
                .in('userId', Array.from(toBeDeleted))
                .eq('roomId', chatRoom.roomId)
            )
        }

        if (toBeInserted.size > 0) {
            promises.push(
                this.supabase
                .from('chat_room_participant')
                .insert(Array.from(toBeInserted).map((x) => {
                    return {
                        roomId: chatRoom.roomId,
                        participantId : x
                    }
                }))
                .eq('roomId', chatRoom.roomId)
            )
        }

        promises.push(
            this.supabase
            .from('chat_room')
            .update({ whitelisted: Array.from(updatedParticipants) })
            .eq('roomId', chatRoom.roomId)
        )

        return Promise.all(promises)
        .then(() => {
            // check the responses perhabs?
            return true
        })
    }

    findByRoomId(roomId, whitelisted) {
        return this.supabase
        .from('chat_room')
        .select('*, participants:chat_room_participant(*, user:userId(*))')
        .eq('roomId', roomId)
        .is('deletedAt', null)
        .contains('whitelisted', whitelisted)
        .limit(1)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            let chatRooms = data
            if (chatRooms.lengh == 0) {
                return null
            }
            let chatRoomData = chatRooms[0]
            chatRoomData.participants = chatRoomData.participants.map((x) => {
                return new User({...x.user})
            });
            return new ChatRoom({...chatRoomData})
        })
    }

    findByUserId(userId, pagination, order) {
        return this.supabase
        .from('chat_room')
        .select('*, participants:chat_room_participant(*, user:userId(*))')
        .contains('whitelisted', [userId])
        .is('deletedAt', null)
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        .order(order.column, { ascending: order.ascending }) // order by messages in the future?
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            var chatRooms = []
            data.forEach((chatRoomData) => {
                let updatedChatRoomData = {...chatRoomData};
                updatedChatRoomData.participants = updatedChatRoomData.participants.map((x) => {
                    return new User({...x.user})
                });
                chatRooms.push(new ChatRoom({...updatedChatRoomData}))
            })
            return chatRooms
        })
    }

    delete(chatRoom) {
        return this.supabase
        .from('chat_room')
        .update({ deletedAt: new Date() })
        .eq('roomId', chatRoom.roomId)
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            return chatRoom.roomId
        })
    }
}

export default ChatRoomRepository