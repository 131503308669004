export class Entity {
    constructor(type) {
        this.type = type
    }
}

export class ImageEntity extends Entity {
    constructor(src, alt) {
        super("image")
        this.src = src
        this.alt = alt || ""
    }
}

export class VideoEntity extends Entity {
    constructor(src) {
        super('video')
        this.src = src
    }
}

export class LinkEntity extends Entity {
    constructor(url, text) {
        super('link')
        this.url = url
        this.text = text || ""
    }
}

export class EmbedEntity extends Entity {
    constructor(caption, embed, height, service, source, width) {
        super('embed')
        this.caption = caption || ''
        this.embed = embed
        this.height = height
        this.service = service
        this.source = source
        this.width = width
    }
}