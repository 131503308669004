import React, { Component } from 'react';
import Dashboard from '../Dashboard';
import { ListGroup, Card } from 'react-bootstrap';
import ListCountriesService from '../../services/ListCountriesService'
import ListRegionsService from '../../services/ListRegionsService'
import Pagination from '../../models/common/Pagination';
import { Link } from 'react-router-dom';
import LatLon from 'geodesy/latlon-spherical.js';
import { countryCodeEmoji } from 'country-code-emoji';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux"
import authStateChanged from "../../redux/actions/auth_state_changed"
import createNotification from '../../redux/actions/create_notification'
import setModal from '../../redux/actions/set_modal'
import Post from '../../models/Post';
import Order from '../../models/common/Order';
import PopularPostsCard from '../../components/PopularPostsCard';
import { FormattedMessage, FormattedNumber, FormattedPlural, useIntl } from 'react-intl';
import GetCountryService from '../../services/GetCountryService';
import { createMobileNavDrawer, getFilterRegionCode, handleFeedack, handleNewChat, handleNewEvent, handleNewPost, handlePreferences, handleSignOut } from '../Dashboard/dashboard-fns';
import SidebarButton from '../../components/SidebarButton';
import FormatRegionCodeService from '../../services/FormatRegionCodeService';
import { Navigation, A11y } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react';

class Discover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nearestCountries: [],
            popularPosts: [],
            showSidebar: false
        }

        this.pagination = new Pagination(0, 10)

        this.listCountries = new ListCountriesService()
        this.listRegions = new ListRegionsService()
        this.getCountry = new GetCountryService()
        this.formatRegionCode = new FormatRegionCodeService()

        this.getNextRegions = this.getNextRegions.bind(this)
        this.getPopular = this.getPopular.bind(this)
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)
    }

    handleCloseSidebar() {
        this.setState({ showSidebar: false })
    }

    handleShowSidebar() {
        this.setState({ showSidebar: true })
    }

    getNextRegions() {
        this.pagination.setNext()
    }

    getNearestCountries(countryCode) {
        const foundCountry = this.listCountries.all().find(
            x => x.division === 'country' && x.code.toUpperCase() === countryCode
        );

        if (foundCountry === null) {
            return []
        }

        const p1 = new LatLon(foundCountry.lat, foundCountry.lng)

        let closest = this.listCountries.all().map((x) => {
            if (x.lat && x.lng && x.division === 'country') {
                let p2 = new LatLon(x.lat, x.lng)
                let km = p1.distanceTo(p2) * 0.001
                return {
                    name: x.name,
                    km,
                    code: x.code
                }
            }
            return Number.MAX_VALUE
        }).sort(function (a, b) {
            return a.km - b.km;
        }).filter((x) => x.km > 0)

        return closest
    }

    componentDidMount() {
        var { currentUser } = this.props

        this.getPopular()

        let countryCode = currentUser ? currentUser.countryCode : 'FR'
        this.setState({
            nearestCountries: [] // this.getNearestCountries(countryCode).slice(0, 4)
        })
    }

    renderListOfRegions() {
        return (
            <ListGroup variant="flush" className="p-0">
                <ListGroup.Item className="px-3">
                    <span className="fw-bold">Regions</span><br />
                    <small className="text-muted">See what's happening in other regions of your country.</small>
                </ListGroup.Item>
                {this.listRegions
                    .forCountryCode('DK')
                    .slice(this.pagination.offset, this.pagination.offset + this.pagination.limit)
                    .map((country) =>
                        <ListGroup.Item action className='px-3'>
                            {country.name}
                        </ListGroup.Item>
                    )}
                <ListGroup.Item action onClick={this.getNextRegions()} className="px-3 fw-bold">
                    Show More
                </ListGroup.Item>
            </ListGroup>
        )
    }

    renderTopics() {
        return (
            <ListGroup variant="flush">
                <ListGroup.Item className="px-3">
                    <Card className="text-white bg-success">
                        <h5 className="fw-bold mb-0 p-3">#Topic</h5>
                    </Card>
                </ListGroup.Item>
            </ListGroup>
        )
    }

    getPopular() {
        var { currentUser } = this.props
        let countryCode = currentUser != null ? currentUser.countryCode : ['DK']
        return Post
            .popularByCountry(countryCode, new Pagination(0, 7), new Order('views', false))
            .then((popularPosts) => {
                this.setState({
                    popularPosts: popularPosts
                })
            })
    }

    renderPopularCard(popularPosts) {
        if (popularPosts.length == 0) {
            return
        }
        var { currentUser } = this.props
        let countryCode = currentUser != null ? currentUser.countryCode : ['DK']
        let country = this.getCountry.byCode(countryCode)

        let title = this.props.intl.formatMessage({ id: 'POPULAR_IN_COUNTRY_CARD_HEADER_FORMAT' }, {
            country: country ? country.name : ''
        })

        return (
            <PopularPostsCard
                title={title}
                listItems={popularPosts.map((post) =>
                    <ListGroup.Item className="bg-transparent border-bottom-0 w-100">
                        <article className="justify-content-between position-relative d-flex align-items-center mb-0">
                            <div className="ps-e">
                                <small className="text-muted fs-xs n-lines-1">{this.formatRegionCode.withValue(post.regionCode, 'short') ?? ""}</small>
                                <h4 className="n-lines-1 h6 mb-0">
                                    <Link className="stretched-link text-dark" to={`/me/${post.author.username}/post/${post.id}`}>{post.title || 'Untitled'}</Link>
                                </h4>
                                <span className="n-lines-1 fs-sm text-muted">
                                    <FormattedNumber value={post.metrics.views} notation={'compact'} />
                                    {' '} <FormattedPlural value={post.metrics.views} one="view" other="views" />
                                </span>
                            </div>
                            {post.entities.filter((x) => x.type == 'image').length > 0 &&
                                <img
                                    style={{ width: "46px", height: "46px", objectFit: "cover" }}
                                    className="rounded-1"
                                    src={post.entities.filter((x) => x.type == 'image')[0].src}
                                    alt={post.entities.filter((x) => x.type == 'image')[0].alt}
                                />
                            }
                        </article>
                    </ListGroup.Item>
                )}
            />
        )
    }

    getCountryRegionsExcludeOwn(regionCode) {
        if (regionCode == null) {
            return []
        }
        let countryCode = regionCode.split('-')[0]

        return this.listRegions
        .forCountryCode(countryCode)
        .filter((x) => regionCode && x.code !== regionCode)
    }

    renderHero() {
        var { currentUser } = this.props
        var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode

        let regionCode = getFilterRegionCode(null, currentUser, fallbackRegionCode) || null

        return (
            <section>
                <div className="jarallax bg-dark min-vh-100 py-5 px-3">
                    <div className="position-absolute top-0 start-0 w-100 h-100 bg-black opacity-50"></div>

                    <div className="container position-relative zindex-5 py-sm-4 py-lg-5">
                        <div className="row pt-lg-2 pb-4 pb-sm-4 pb-xl-4 pb-xxl-5 mb-md-4 mb-lg-5">
                            <div className="col-md-12">
                                <h1 className="display-2 text-light pb-sm-2 pb-md-3">
                                    <FormattedMessage id="WELCOME_TO_ARIA_TITLE"/>
                                </h1>
                                <p className="text-light opacity-70 pb-3 pb-md-4 mb-3">
                                    <FormattedMessage id="WELCOME_TO_ARIA_TEXT_1"/>
                                </p>
                                
                                <p className="text-light opacity-70 pb-3 pb-md-4 mb-3">
                                    <FormattedMessage id="WELCOME_TO_ARIA_TEXT_2"/>
                                </p>

                                <p className="text-light opacity-70 pb-3 pb-md-4 mb-3">
                                    <FormattedMessage id="WELCOME_TO_ARIA_TEXT_3"/>
                                </p>
                                
                                <p className="text-light opacity-70 pb-3 pb-md-4 mb-3">
                                 <FormattedMessage id="WELCOME_TO_ARIA_TEXT_4"/>
                                </p>
                                
                                <p className="text-light opacity-70 pb-3 pb-md-4 mb-3">
                                    <FormattedMessage
                                        id="WELCOME_TO_ARIA_TEXT_5_FORMAT"
                                        values={{
                                            videoLink: <a target="_blank" className="text-white fw-bold" href="https://rumble.com/v3edwc4-introduktion-af-den-nye-social-medie-platform-aria5.html"><FormattedMessage id="INTRO_VIDEO_HERE"/></a>
                                        }}
                                    />
                                </p>

                                { currentUser &&
                                    <Link className="btn btn-outline-light" to="/">
                                        <FormattedMessage id="EXPLORE_REGION_BTN"/>
                                    </Link>
                                }

                                { currentUser == null &&
                                    <Link className="btn btn-outline-light" to="/signup">
                                        <FormattedMessage id="SIGNUP_BTN"/>
                                    </Link>
                                }
                            </div>
                        </div>


                        { regionCode &&
                            <React.Fragment>
                                <div className="d-flex align-items-center justify-content-between my-3">
                                    <div className="fw-medium text-light text-uppercase">
                                        <FormattedMessage id="OTHER_REGIONS_TEXT"/>
                                    </div>
                                    <div className="d-flex">
                                        <button className="btn btn-prev btn-icon btn-sm btn-outline-light rounded-circle ms-3" type="button" id="popular-prev" tabindex="0" aria-label="Previous slide" aria-controls="swiper-wrapper-a9efec8d3358310de"><i className="bi bi-arrow-left"></i></button>
                                        <button className="btn btn-next btn-icon btn-sm btn-outline-light rounded-circle ms-3" type="button" id="popular-next" tabindex="0" aria-label="Next slide" aria-controls="swiper-wrapper-a9efec8d3358310de"><i className="bi bi-arrow-right"></i></button>
                                    </div>
                                </div>       
                                        
                                <Swiper
                                    className="swiper py-0"
                                    modules={[Navigation, A11y]}
                                    spaceBetween={24}
                                    slidesPerView={1}
                                    navigation={{
                                        nextEl: "#popular-next",
                                        prevEl: "#popular-prev",
                                    }}
                                    breakpoint={{
                                        "500": {
                                            slidesPerView: 2
                                        },
                                        "860": {
                                            slidesPerView: 3
                                        },
                                        "1200": {
                                            slidesPerView: 4
                                        }
                                    }}>
                                    { this.getCountryRegionsExcludeOwn(regionCode).map((region) =>
                                        <SwiperSlide>
                                            <Link to={`/${region.code}`} className="card h-100 border-0 rounded-1 text-decoration-none px-xxl-1">
                                                <div className="card-body p-4 px-sm-3 px-md-4">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ps-3 ps-md-4">
                                                            <h3 className="fs-sm mb-2 n-lines-1">{region.name}</h3>
                                                            <p className="fs-sm mb-0 n-lines-1">{this.listCountries.all().find((country) => country.code == region.code.split('-')[0]).name ?? ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            </React.Fragment>
                        }
                        </div>
                        
                        <div id="jarallax-container-0">
                            <div class="jarallax-img" style={{ backgroundImage: "url(https://bspodttrfjifboigqtfv.supabase.co/storage/v1/object/public/assets/pexels-gustavo-cruz-1592119.jpg)", objectFit: "cover" }}>
                            </div>
                        </div>
                </div>
            </section>
        )
    }

    render() {
        var { nearestCountries, popularPosts, showSidebar } = this.state
        var { currentUser } = this.props

        return (
            <Dashboard childScene={this}>
                <Dashboard.Body>
                    <Dashboard.Navbar
                        title={this.props.intl.formatMessage({ id: 'NAV_DRAWER_ITEM_DISCOVER' })}
                        bg="light"
                        variant="dark"
                        offcanvasBody={createMobileNavDrawer({
                            currentUser,
                            onBehalfOfComponent: this,
                            onNewPost: handleNewPost,
                            onNewChat: handleNewChat,
                            onNewEvent: handleNewEvent,
                            onPreferences: handlePreferences,
                            onSignOut: handleSignOut
                        })} />

                    {this.renderHero()}


                    <div className="p-3">
                        <h2 className="d-none" style={{ "maxWidth": "440px" }}>Neighbors</h2>
                        {nearestCountries.map((country) =>
                            <div className="card border-0 bg-secondary rounded-4 position-relative mb-3" style={{ "maxWidth": "700px" }}>
                                <div className="card-body d-sm-flex align-items-start text-center text-sm-start">
                                    <div className="w-100 pe-sm-4 mb-3 mb-sm-0" style={{ "maxWidth": "27rem" }}>
                                        <h3 className="mb-2">{countryCodeEmoji(country.code)} {country.name}</h3>
                                        <p className="fs-lg mb-0">Valid for all types of classes</p>
                                    </div>
                                    <div className="w-100 pe-sm-3 mx-auto me-sm-0 mb-3 mb-sm-0" style={{ "maxWidth": "8rem" }}>
                                        <div className="h3 text-primary mb-0 mb-sm-2">+1.5K</div><span className="fs-sm">posts today</span>
                                    </div>
                                    <Link className="btn btn-sm btn-outline-primary stretched-link w-sm-auto" to={`/${country.code.toLowerCase()}`}>
                                        Explore
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                    <SidebarButton onClick={this.handleShowSidebar} />
                </Dashboard.Body>
                <Dashboard.Context
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    {this.renderPopularCard(popularPosts)}
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        notification: state.notification,
        fallbackRegion: state.setFallbackRegion
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let intl = useIntl()
    return <Discover {...props} intl={intl} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);