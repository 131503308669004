import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Button, Dropdown } from 'react-bootstrap';

class DashboardBody extends Component {
  render() {
    var { shouldRemoveBottomMargin } = this.props
    shouldRemoveBottomMargin = shouldRemoveBottomMargin ?? false

    var style = {}
    if (shouldRemoveBottomMargin) {
      style["marginBottom"] = "0px"
    }

    return (
      <div id="dashboard-body" style={style} className="col p-0">
          {this.props.children}
      </div>
    )
  }
}

function BackwardsCompatibleProps(props) {
  let navigate = useNavigate();
  return (
    <DashboardBody {...props} navigate={navigate} />
  )
}

export default BackwardsCompatibleProps;