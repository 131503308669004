import { Component } from 'react'
import { Button, Card, Dropdown, Form, ListGroup, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { useParams, Link, useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import ListOccupationsService from '../../services/ListOccupationsService'
import Dashboard from '../Dashboard'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux"
import authStateChanged from "../../redux/actions/auth_state_changed"
import createNotification from '../../redux/actions/create_notification'
import setModal from '../../redux/actions/set_modal'
import Pagination from '../../models/common/Pagination'
import Order from '../../models/common/Order'
import User from '../../models/User'
import RegionFilterDropdownMenu from '../../components/RegionFilterDropdownMenu'
import FormatRegionCodeService from '../../services/FormatRegionCodeService'
import Avatar from '../../components/Avatar'
import { createMobileNavDrawer, getFilterRegionCode, handleFeedack, handleNewChat, handleNewEvent, handleNewPost, handlePreferences, handleSignOut, setHasMore, setNextPagination } from '../Dashboard/dashboard-fns'
import SidebarButton from '../../components/SidebarButton'
import { FormattedList, FormattedMessage, useIntl } from 'react-intl'

class Services extends Component {
    constructor(props) {
        super(props)

        const search = this.props.location.search;
        const code = new URLSearchParams(search).get('code') || "";

        this.initState = {
            major: "",
            submajor: "",
            minor: "",
            unit: "",
            users: [],
            isLoading: false,
            hasMore: true,
            errors: [],
            code: code,
            showSidebar: false
        }

        let levels = ["major", "submajor", "minor", "unit"]

        let fragments = code.split('')
        if (fragments.length > 0) {
            var restoredState = {}
            for (let i = 0; i < fragments.length; i++) {
                let codeAtLevel = fragments.slice(0, i+1).join("")
                this.initState[levels[i]] = codeAtLevel
            }
        }

        this.state = { ...this.initState }

        var { regionCode } = this.props.match.params
        var { currentUser } = this.props
        var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode
        this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)

        this.pagination = new Pagination(0, 20)
        this.order = new Order('createdAt', false)

        this.formatRegionCode = new FormatRegionCodeService()
        this.listOccupations = new ListOccupationsService()

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleClearOccupation = this.handleClearOccupation.bind(this)

        this.getNextUsers = this.getNextUsers.bind(this)

        this.handleApplyFilter = this.handleApplyFilter.bind(this)
        this.handleClearFilter = this.handleClearFilter.bind(this)
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)
        this.toggleReadMore = this.toggleReadMore.bind(this)
    }

    handleCloseSidebar() {
        this.setState({showSidebar: false})
    }

    handleShowSidebar() {
        this.setState({showSidebar: true})
    }

    handleClearFilter(e) {
        var { code } = this.state

        const to = '/services'
        this.props.navigate({
            pathname: to,
            search: createSearchParams({code}).toString()
        });
    }

    handleApplyFilter(e, { regionCode }) {
        var { code } = this.state

        const to = `/${regionCode.toLowerCase()}/services`
        this.props.navigate({
            pathname: to,
            search: createSearchParams({code}).toString()
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params !== this.props.match.params && this.state.isLoading === false) {
            var { code } = this.state
            let { currentUser } = this.props
            let { regionCode } = this.props.match.params
            var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode
            this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)
            
            if (this.state.code.length === 0 || this.isRegionApplied(currentUser, regionCode) === false) {
                this.setState({
                    isLoading: false,
                    hasMore: false
                })
                return
            }

            this.setState({
                users: [],
                isLoading: true,
                hasMore: true
            })

            this.pagination = new Pagination(0, 20)
            this.order = new Order('createdAt', false)
            
            var { code } = this.state
            this.getNextUsers(code)
            .then(() => {
                this.setState({
                    isLoading: false,
                    errors: []
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    errors: [error]
                })
            })
        }
    }

    renderFilterDropdownMenu() {
        return (
            <RegionFilterDropdownMenu
            regionCode={this.filterRegionCode}
            onClear={this.handleClearFilter}
            onApply={this.handleApplyFilter}
            />
        )
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        
        this.setState({
            [name]: value,
            isLoading: true,
            errors: [],
            users: []
        }, () => {
            var { major, submajor, minor, unit } = this.state
            
            if (name === 'major') {
                submajor = ""
                minor = ""
                unit = ""
            } else if (name === 'submajor') {
                minor = ""
                unit = ""
            } else if (name === 'minor') {
                unit = ""
            }

            var code = ""
            var hasMore = true
            
            let occupation = this.getOccupation(major, submajor, minor, unit)
            if (occupation) {
                code = occupation.code.toString()
            } else {
                hasMore = false
            }
            /*
            if (value.length > 0) {
                code = value
            } else if (name !== 'major' && value.length == 0) {        
                let occupation = this.getOccupation(major, submajor, minor, unit)
                code = occupation.code.toString()   
            }
            */
            
            this.pagination = new Pagination(0, 20)
            this.setState({ major, submajor, minor, unit, code })
            window.history.pushState({} , '', `?code=${code}`)

            // let hasMore = name === 'major' && value === "" ? false : true

            this.setState({
                isLoading: true,
                hasMore: hasMore
            })
            this.getNextUsers(code)
            .then(() => {
                this.setState({
                    isLoading: false})
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    errors: [error]
                })
            })
        })
    }

    handleClearOccupation(e) {
        this.pagination = new Pagination(0, 20)
        this.order = new Order('createdAt', false)

        this.setState({
            major: "",
            submajor: "",
            minor: "",
            unit: "",
            code: "",
            users: [],
            isLoading: false,
            hasMore: false,
            errors: []
        })
        window.history.pushState({} , '', '?code=')
    }

    isRegionApplied(currentUser, regionCode) {
        return (currentUser != null || currentUser != undefined) || 
                (regionCode !== undefined || regionCode || null) === true
    }

    getNextUsers(occupationCode) {
        let { currentUser } = this.props
        let { regionCode } = this.props.match.params

        if (this.pagination === undefined) {
            return Promise.resolve([]);
        }
        if (occupationCode.toString().length == 0) {
            return Promise.resolve([])
        }

        if (this.isRegionApplied(currentUser, regionCode) === false) {
            return Promise.resolve([])
        }

        return User
        .findByOccupationCode(occupationCode, regionCode ?? currentUser.regionCode, this.pagination, this.order)
        .then((newUsers) => {
            this.pagination = setNextPagination(this.pagination, newUsers)
            this.setState((state) => {
                var updatedUsers = [...state.users, ...newUsers]
                return {
                    users: updatedUsers,
                    hasMore: setHasMore(this.pagination, newUsers)
                }
            })
            return newUsers
        })
    }

    componentDidMount() {
        var { code } = this.state
        let { currentUser } = this.props
        let { regionCode } = this.props.match.params
        var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode
        this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)

        if (code.length === 0 || this.isRegionApplied(currentUser, regionCode) === false) {
            this.setState({
                isLoading: false,
                hasMore: false
            })
            return
        }

        this.setState({isLoading: true})
        this.getNextUsers(code)
        .then(() => {
            this.setState({isLoading: false})
        })
        .catch((error) => {
            this.setState({
                errors: [error],
                isLoading: false
            })
        })
    }

    getOccupation(major, submajor, minor, unit) {
        var occupation = null
        if (unit !== "") {
            occupation = this.listOccupations.getNode(unit)
        } else if (minor !== "") {
            occupation = this.listOccupations.getNode(minor)
        } else if (submajor !== "") {
            occupation = this.listOccupations.getNode(submajor)
        } else if (major !== "") {
            occupation = this.listOccupations.getNode(major)
        }
        return occupation
    }

    renderOccupationItem(occupation) {
        return (
            <ListGroup.Item action className='px-3'>
                <Link to={`/services/${occupation.code}`} className="text-decoration-none text-dark" aria-current="true">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{occupation.title}</h5>
                    </div>
                    <p class="text-dark mb-1">{occupation.definition}</p>
                </Link>
            </ListGroup.Item>
        )
    }

    renderOccupationList(occupations) {
        return (
            <ListGroup variant="flush">
            { occupations.map((occupation) => (
                this.renderOccupationItem(occupation)
            ))}
            </ListGroup>
        )
    }

    renderEmptyUsers() {
        return (
            <div className="px-3 py-5 text-center">
                <p><FormattedMessage id="NO_SERVICES_EMPTY_MESSAGE"/></p>
            </div>
        )
    }

    toggleReadMore(e) {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");
        
        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = this.props.intl.formatMessage({id: "READ_MORE_BTN"});
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = this.props.intl.formatMessage({id: "READ_LESS_BTN"});
            moreText.style.display = "inline";
        }
    }

    renderDefinition(definition) {
        const limit = 400
        if (definition.length <= limit) {
            return (<div><p id="definition">{definition}</p></div>)
        }
        return (
            <div>
                <p id="definition">
                    {definition.slice(0, limit)}<span id="dots">...</span><span style={{display: 'none'}} id="more">{definition.slice(limit, definition.length)}</span>
                    <Button className="px-2 py-0" variant="link" onClick={this.toggleReadMore} id="myBtn">
                        <FormattedMessage id="READ_MORE_BTN"/>
                    </Button>
                </p>
            </div>
        )
    }

    renderHeader(occupation) {
        return (
            <div className="px-4 py-4 border-bottom">
                <h1 className="n-lines-2">{occupation.title}</h1>
                {this.renderDefinition(occupation.definition)}
            </div>
        )
    }

    renderListItem(user) {
        let userOccupations = user.occupationCodes
        .map((code) => this.listOccupations.getNode(code))
        .filter((occupation) => occupation !== null)
        
        return (
            <ListGroup.Item onClick={(e) => this.props.navigate(`/me/${user.username}`)} className="px-4 py-4" action>
                <div className="d-flex align-items-center mb-3">
                    <Avatar
                        size="40px"
                        src={user.photoURL}
                        alt={user.firstName + ' ' + user.lastName}
                    />
                    <div className="ps-3">
                        <h6 className="mb-0 n-lines-1">{user.firstName + ' ' + user.lastName}</h6>
                        <small className="text-muted n-lines-1">{`@${user.username}`}</small>
                    </div>
                </div>
                <p class="card-body mb-2">{user.bio}</p>
                <small class="text-muted n-lines-2">
                    <FormattedMessage
                        id="OCCUPATIONS_FORMAT_LIST"
                        values={{
                            occupation: <b><FormattedMessage id="OCCUPATION_PREFIX"/></b>,
                            list: <FormattedList type="conjunction" value={userOccupations.map((x) => x.title)} />
                        }}/>
                </small>
            </ListGroup.Item>
        )
    }

    renderList(users) {
        var { isLoading, hasMore } = this.state
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({isLoading: true})
                    this.getNextUsers()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}
            >
                {users.map((user) => (
                    this.renderListItem(user)
                ))}
            </Dashboard.List>
        )
    }

    render() {
        var { major, submajor, minor, unit, users, isLoading, showSidebar } = this.state
        var occupation = this.getOccupation(major, submajor, minor, unit)
        var { currentUser } = this.props

        let filteringEnabled = false

        return (
            <Dashboard childScene={this}>
                <Dashboard.Body>
                    <Dashboard.Navbar
                        title="Services"
                        subtitle={this.formatRegionCode.withValue(this.filterRegionCode)}
                        bg="light"
                        variant="dark"
                        nav={
                            <Nav className="ms-auto">
                                <Dropdown className="">
                                    <Dropdown.Toggle variant="outline-secondary" size="sm" className="btn-icon border-0 rounded-circle me-n2">
                                        <i className="fs-lg bi bi-filter-circle"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu flip={true} className="dropdown-menu dropdown-menu-end my-1">
                                        {this.renderFilterDropdownMenu()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        }
                        offcanvasBody={createMobileNavDrawer({
                            currentUser,
                            onBehalfOfComponent: this,
                            onNewPost: handleNewPost,
                            onNewChat: handleNewChat,
                            onNewEvent: handleNewEvent,
                            onPreferences: handlePreferences,
                            onSignOut: handleSignOut
                        })}/>
                
                    {occupation && this.renderHeader(occupation)}
                    {this.renderList(users)}
                    {isLoading === false && users.length === 0 && this.renderEmptyUsers()}
                    <SidebarButton onClick={this.handleShowSidebar}/>
                </Dashboard.Body>
                <Dashboard.Context
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    <Card className="mt-3 card">
                        <Card.Body>
                            <h5 className="card-title fw-bold">
                                <FormattedMessage id="OCCUPATION_FILTER_CARD_TITLE"/>
                            </h5>
                            <Form className="">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <FormattedMessage id="OCCUPATION_MAJOR_LABEL"/>
                                    </Form.Label>
                                    <Form.Select
                                        value={major}
                                        name="major"
                                        onChange={this.handleInputChange}>
                                        <option value="">Choose a major</option>
                                        {this.listOccupations.atLevel(1).map((occupation) => (
                                            <option value={occupation.code}>{occupation.title}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <FormattedMessage id="OCCUPATION_SUBMAJOR_LABEL"/>
                                    </Form.Label>
                                    <Form.Select
                                        onChange={this.handleInputChange}
                                        name="submajor"
                                        value={submajor}
                                    >
                                        <option value="">Choose a submajor</option>
                                        {major !== "" && this.listOccupations.forCode(major).map((occupation) => (
                                            <option value={occupation.code}>{occupation.title}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <FormattedMessage id="OCCUPATION_MINOR_LABEL"/>
                                    </Form.Label>
                                    <Form.Select
                                        onChange={this.handleInputChange}
                                        name="minor"
                                        value={minor}>
                                        <option value="">Choose a minor</option>
                                        {submajor !== "" && this.listOccupations.forCode(submajor).map((occupation) => (
                                            <option value={occupation.code}>{occupation.title}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                    <FormattedMessage id="OCCUPATION_UNIT_LABEL"/>
                                    </Form.Label>
                                    <Form.Select
                                        onChange={this.handleInputChange}
                                        name="unit"
                                        value={unit}>
                                        <option value="">Choose a unit</option>
                                        {minor !== "" && this.listOccupations.forCode(minor).map((occupation) => (
                                            <option value={occupation.code}>{occupation.title}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="d-flex justify-content-end mb-3">
                                    <Button
                                        onClick={this.handleClearOccupation}
                                        variant="secondary">
                                        <FormattedMessage id="CLEAR_BTN"/>
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function BackwardsCompatibleProps(props) {
    let location = useLocation()
    let params = useParams()
    let navigate = useNavigate()
    let intl = useIntl()
    return <Services {...props} intl={intl} match={{params: params}} location={location} navigate={navigate} />
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        fallbackRegion: state.setFallbackRegion,
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps)