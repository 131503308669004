import Attachment from "../../../models/chat/Attachment"
import Message from "../../../models/chat/Message"
import User from "../../../models/User"
const { createClient } = require('@supabase/supabase-js')

class MessageRepository {
    instance = undefined

    constructor() {
        this.supabase = createClient('https://bspodttrfjifboigqtfv.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc', {
            realtime: {
                params: {
                    eventsPerSecond: 10,
                },
            },
        })
    }

    static getRepoState() {
        if (MessageRepository.instance === undefined) {
            MessageRepository.instance = new MessageRepository()
        }
        return MessageRepository.instance
    }

    add(message) {
        return this.supabase
        .from('message')
        .insert({
            createdAt: message.createdAt,
            roomId: message.roomId,
            content: message.content,
            userId: message.user.id
        })
        .select()
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }

            let messageId = data[0].id

            var insertAttachments = []
            message.attachments.forEach((attachment) => {
                let insertAttachment = this.supabase
                .from('message_attachment')
                .insert({
                    messageId,
                    name: attachment.name,
                    type: attachment.type,
                    relativePath: attachment.relativePath,
                    size: attachment.size
                })
                insertAttachments.push(insertAttachment)
            })
            return Promise.all(insertAttachments)
        })
        .then((responses) => {
            return message
        })
    }

    latestMessageInChatRoom(roomId) {
        return this.supabase
        .from('message')
        .select('*, roomId, user:userId(*), attachments:message_attachment(*)')
        .eq('roomId', roomId)
        .order('createdAt', { ascending: false })
        .limit(1)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            if (data.length == 0) {
                return null
            }

            var updatedData = {... data[0]}            
            updatedData.user = new User({... data[0].user})
            updatedData.attachments =  data[0].attachments.map((x) => new Attachment({...x}))

            return new Message({...updatedData})
        })
    }

    findByRoomId(roomId, pagination, order) {
        return this.supabase
        .from('message')
        .select('*, roomId, user:userId(*), attachments:message_attachment(*)')
        .eq('roomId', roomId)
        .range(pagination.offset > 0 ? pagination.offset + 1 : pagination.offset, 
            pagination.offset + pagination.limit)
        .order(order.column, { ascending: order.ascending })
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            var messages = []
            data.forEach((x) => {
                var updated = {...x}
                updated.user = new User(x.user)
                var msgAttachments = x.attachments.map((y) => new Attachment({...y}))  
                messages.push(new Message({...updated, attachments: msgAttachments}))
            })
            return messages
        })
    }

    convertToMessage(data) {
        var updatedData = {...data}

        return this.supabase
        .from('message_attachment')
        .select('*')
        .eq('messageId', data.id)
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            let user = data.chatRoom.participants.filter((x) => x.id === data.userId)[0]
            updatedData.user = user
            
            let attachments = response.data
            let msgAttachments = attachments.map((y) => new Attachment({...y}))
            updatedData.attachments = msgAttachments

            return new Message({...updatedData})
        })
    }

    onNewMessageInChatRoom(chatRoom, callbackFn) {
        return this.supabase
        .channel('db-chatroom-messages-' + chatRoom.roomId)
        .on(
            'postgres_changes',
            {
            event: 'INSERT',
            schema: 'public',
            table: 'message',
            filter: `roomId=eq.${chatRoom.roomId}`,
            },
            (payload) => callbackFn(this.convertToMessage({chatRoom, ...payload['new']}))
        )
        .subscribe()
    }
}

export default MessageRepository