import { Component } from "react"
import { Card } from "react-bootstrap"

class PostCardBody extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Card.Body className="text-dark px-0 py-0">
                { this.props.children[0] }
                <div className="d-none gradient"></div>
                <div className="mt-2">
                    { this.props.children[1] }
                </div>
            </Card.Body>
        )
    }
}

export default PostCardBody