import { Component } from "react";
import { SwiperSlide } from 'swiper/react'

// Not yet in use.
class PostCardSlide extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <SwiperSlide>
                {this.props.children}
            </SwiperSlide>
        )
    }
}

export default PostCardSlide