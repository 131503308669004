import regions from '../../assets/ISO-3166-2.json'

class ListRegionsService {
    forCountryCode(code) {
        if (code === undefined) {
            return []
        }
        return Object.values(regions)
            .filter((x) => x.parent != undefined && x.parent == code.toUpperCase() && (
                x.division.toLowerCase() != 'country' || x.division.toLowerCase() != 'included for completeness'
            ));
    }
}

export default ListRegionsService;
