import AbstractSpecification from "../AbstractSpecification"

class PostDeletionSpecification extends AbstractSpecification {
    constructor(required) {
        super()
        this.requiredFeature = required
    }
    
    isSatisfiedBy(candidate) {
        return false
    }

    toJSON() {
        return {
            type: this.requiredFeature.type,
            key: this.requiredFeature.key,
            value: this.requiredFeature.vaule
        }
    }
}

export default PostDeletionSpecification