import React, { Component, Fragment, Suspense, useEffect } from 'react';
import { useNavigate, useParams, useMatch, useNavigation, useLocation, useMatches, matchPath, Link } from 'react-router-dom'

import Dashboard from '../Dashboard'
import { handleReplyToPost, handleDeletePost, handleReactToPost, handleSharePost, setHasMore, setNextPagination, handleReport, handleEditPost, createMobileNavDrawer, handleNewPost, handleNewChat, handleNewEvent, handleSignOut, handlePreferences, getFilterRegionCode, handleFeedack } from '../Dashboard/dashboard-fns';

import { ListGroup, Card, Spinner, NavDropdown, Nav, Dropdown, Button } from 'react-bootstrap'

import Post from '../../models/Post'
import PostCard from '../../components/PostCard'

import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import authStateChanged from "../../redux/actions/auth_state_changed"
import createNotification from '../../redux/actions/create_notification'
import setModal from '../../redux/actions/set_modal'

import Pagination from '../../models/common/Pagination';
import Criteria from '../../models/common/Criteria'
import Order from '../../models/common/Order';

import PostDeletionFeature from '../../models/PostDeletionFeature';
import PostDeletionSpecification from '../../specifications/PostDeletionSpecification';

import RegionFilterDropdownMenu from '../../components/RegionFilterDropdownMenu';
import { SwiperSlide } from 'swiper/react';
import UserNotification from '../../models/common/UserNotification';
import Reaction from '../../models/Reaction';
import WritePermissionPolicy from '../../policies/WritePermissionPolicy';
import FormatRegionCodeService from '../../services/FormatRegionCodeService';

import SavePostsStateService from '../../services/SavePostsStateService';
import RestorePostsStateService from '../../services/RestorePostsStateService';
import RestorePostsStateSpecification from '../../specifications/RestorePostsStateSpecification';
import { FormattedMessage, FormattedNumber, FormattedPlural, useIntl } from 'react-intl';
import PopularPostsCard from '../../components/PopularPostsCard';
import ListCountriesService from '../../services/ListCountriesService';
import GetCountryService from '../../services/GetCountryService';
import SidebarButton from '../../components/SidebarButton';

/**
 * The Home scene (component).
 * Extends the Dashboard master scene.
 */
class Home extends Component {
  constructor(props) {
    super(props);
    this.initState = {
      isLoading: false,
      hasMore: true,
      posts: [],
      popularPosts: [],
      errors: [],
      showSidebar: false
    }

    this.state = {
      ...this.initState
    }

    var { regionCode } = this.props.match.params
    var { currentUser } = this.props
    var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode

    this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)

    this.pagination = new Pagination(0, 4)
    this.order = new Order('createdAt', false)
    this.criteria = this.setCriteriaIfNeeded()

    this.writePermission = new WritePermissionPolicy()

    this.deletionSpecification = new PostDeletionSpecification(
      new PostDeletionFeature('TIME_ELAPSED', { 'DAY': 7 }))
    this.restorePostsStateSpec = new RestorePostsStateSpecification()

    this.formatRegionCode = new FormatRegionCodeService()
    this.savePostsState = new SavePostsStateService()
    this.restorePostsState = new RestorePostsStateService()
    this.getCountry = new GetCountryService();

    this.setCriteriaIfNeeded = this.setCriteriaIfNeeded.bind(this)

    this.handleApplyFilter = this.handleApplyFilter.bind(this)
    this.handleClearFilter = this.handleClearFilter.bind(this)

    this.getNextPosts = this.getNextPosts.bind(this)
    this.getPopular = this.getPopular.bind(this)

    this.updatePostWithReaction = this.updatePostWithReaction.bind(this)

    this.restorePosts = this.restorePosts.bind(this)
    
    this.handleShowSidebar = this.handleShowSidebar.bind(this)
    this.handleCloseSidebar = this.handleCloseSidebar.bind(this)

    this.updateRegionIfNeeded = this.updateRegionIfNeeded.bind(this)
  }

  handleCloseSidebar() {
    this.setState({showSidebar: false})
  }

  handleShowSidebar() {
      this.setState({showSidebar: true})
  }

  setCriteriaIfNeeded() {
    var updatedCriteria = new Criteria()

    var { regionCode } = this.props.match.params
    var { currentUser } = this.props
    var fallbackRegionCode = this.props.fallbackRegion && this.props.fallbackRegion.regionCode
    this.filterRegionCode = getFilterRegionCode(regionCode, currentUser, fallbackRegionCode)

    if (regionCode) {
      var fragments = this.filterRegionCode.split('-')

      const isRegionCode = fragments.length === 2
      const isCountryCode = fragments.length === 1

      if (isRegionCode) {
        updatedCriteria.equalTo('regionCode', this.filterRegionCode.toUpperCase())
      } else if (isCountryCode) {
        let countryCode = fragments[0].toUpperCase()
        updatedCriteria.equalTo('countryCode', countryCode)
      }

    } else if (currentUser && currentUser.regionCode) {
      updatedCriteria.equalTo('regionCode', currentUser.regionCode)
    } else if (currentUser && currentUser.countryCode) {
      updatedCriteria.equalTo('countryCode', currentUser.countryCode)
    }

    updatedCriteria.is('deletedAt', null)
    return updatedCriteria
  }

  updatePostWithReaction(post, newReaction) {
    this.props.createNotification(new UserNotification({
      name: this.props.intl.formatMessage({id: 'REACTION_WAS_ADDED_NOTIFICATION'}),
      object: newReaction,
      userInfo: {}
    }))
    this.setState((state) => {
      var updatedPosts = [...state.posts]
      
      var postIndex = updatedPosts.findIndex((x) => x.id === post.id)
      var updatedPost = updatedPosts[postIndex]

      var updatedReactions = [newReaction, ...updatedPost.reactions]
      updatedPost.reactions = updatedReactions
      updatedPost.metrics.reactions += 1

      updatedPosts[postIndex] = updatedPost

      return {
        posts: updatedPosts
      }
    })
  }

  /**
   * Fetches and concats the next set of posts.
   * Otherwise, if no more posts to fetch, it exits prematurely.
   */
  getNextPosts() {
    if (this.pagination === undefined) {
      return Promise.resolve([]);
    }
    this.setState({ isLoading: true })
    return Post
    .matching(this.criteria, this.pagination, this.order)
    .then((newPosts) => {
      this.pagination = setNextPagination(this.pagination, newPosts.length)
      this.setState((state) => {
        return {
          isLoading: false,
          errors: [],
          hasMore: setHasMore(this.pagination, newPosts.length),
          posts: state.posts.concat(newPosts)
        }
      })
    })
    .catch((error) => {
      this.setState({
        isLoading: false,
        errors: [error]
      })
    })
  }

  getPopular() {
    let regionCode = this.filterRegionCode
    let countryCode = regionCode ? regionCode.split('-')[0] : ['DK']
    return Post
      .popularByCountry(countryCode, new Pagination(0, 7), new Order('views', false))
      .then((popularPosts) => {
        this.setState({
          errors: [],
          popularPosts: popularPosts
        })
      })
      .catch((error) => {
        this.setState({
          errors: [error]
        })
      })
  }

  updatePostsIfNeeded(newPost) {
    this.setState((state) => {
      let inSameRegion = this.filterRegionCode == newPost.regionCode || this.filterRegionCode == newPost.countryCode
      if (inSameRegion == false) {
        return {}
      }
      return {
        posts: [newPost, ...state.posts]
      }
    })
  }

  updatePostsWithReplyIfNeeded(newReply) {
    this.setState((state) => {
      let inSameRegion = this.filterRegionCode == newReply.regionCode || this.filterRegionCode == newReply.countryCode
      if (inSameRegion == false) {
        return {}
      }
      var updatedPosts = [...state.posts]
      for (let i = 0; i < state.posts.length; i++) {
        var candidate = state.posts[i];
        if (candidate.id === newReply.inReplyToPostId) {
          var updatedPost = candidate
          updatedPost.metrics.replies += 1
          updatedPosts[i] = updatedPost
          break
        }
      }
      return {
        posts: [newReply, ...updatedPosts]
      }
    })
  }

  updateEditedPostIfNeeded(editedPost) {
    this.setState((state) => {
      var updatedPosts = [...state.posts]
      for (let i = 0; i < state.posts.length; i++) {
        let candidate = state.posts[i];
        if (candidate.id === editedPost.id) {
          updatedPosts[i] = editedPost
          break
        }
      }
      return {
        posts: updatedPosts
      }
    })
  }

  removePostIfNeeded(deletedPost) {
    this.setState((state) => {
      var updatedPosts = state.posts.filter((post) => post.id !== deletedPost.id)
      return {
        posts: updatedPosts
      }
    })
  }

  handleNotificationChangeIfNeeded(prevProps) {
    if (prevProps.notification !== this.props.notification) {
      let notification = this.props.notification
      if (notification == null) {
        return
      }
      let {type} = notification.userInfo
      if (type == null) {
        return
      }
      if (type == 'POST_CREATED') {
        let newPost = notification.object
        this.updatePostsIfNeeded(newPost)
      } else if (type == 'POST_EDITED') {
        let editedPost = notification.object
        this.updateEditedPostIfNeeded(editedPost)
      } else if (type == 'POST_DELETED') {
        let deletedPost = notification.object
        this.removePostIfNeeded(deletedPost)
      } else if (type == 'REPLY_CREATED') {
        let newReply = notification.object
        this.updatePostsWithReplyIfNeeded(newReply)
      }
    }
  }

  updateRegionIfNeeded(prevProps) {
    if (prevProps.match.params !== this.props.match.params && this.state.isLoading === false) {
      this.setState({ ...this.initState })

      this.criteria = this.setCriteriaIfNeeded()
      this.pagination = new Pagination(0, 4)
      this.order = new Order('createdAt', false)

      Promise.all([
        this.getNextPosts(),
        this.getPopular()
      ])
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateRegionIfNeeded(prevProps)
    this.handleNotificationChangeIfNeeded(prevProps)
  }

  restorePosts() {
    let restoredPosts = this.restorePostsState.fromLocalStorage()
    this.pagination = this.setNextPagination(restoredPosts)
    this.setState({
      posts: [...restoredPosts],
      isLoading: true
    })
  }

  componentDidMount() {
    var { routeState } = this.props
    /*
    if (this.restorePostsStateSpec.isSatisfiedBy(routeState[routeState.length - 1] ?? null)) {
      this.restorePosts()
    }
    */
    this.setState({isLoading: true})
    Promise.all([
      this.getNextPosts(),
      this.getPopular()
    ])
    .then(() => {
      this.setState({
        isLoading: false,
      errors: []
      })
    })
    .catch((errors) => {
      this.setState({
        isLoading: false,
        errors: []
      })
    })
  }
  
  renderListItem(post) {
    let canWrite = this.writePermission.isGranted(this.props.currentUser, {
      type: 'POST', object: post
    })

    return (
      <ListGroup.Item action onClick={(e) => {      
        this.savePostsState.toLocalStorage(this.state.posts)
        this.props.navigate(`/me/${post.author.username}/post/${post.id}`)

      }} className="py-0">
        <PostCard flush>
          <PostCard.Header
            disabledWrite={canWrite === false}
            post={post}
            onDelete={() => handleDeletePost(this, post)}
            onReport={() => handleReport(this, post.id, 'POST')}
            onEdit={() => handleEditPost(this, post)}
          />
          <PostCard.Body>
            <PostCard.Preview>
              {post.preview}
            </PostCard.Preview>
            <PostCard.Gallery>
            { post.entities.filter((x) => x.type === 'image' || x.type === 'embed').map((x) => (
              <SwiperSlide>
              { x.type === 'image' &&
                  <img style={{objectFit: "cover"}}
                      className="w-100 h-100"
                      alt={x.alt ?? ""}
                      src={x.src}
                  />    
              }
              { x.type === 'embed' &&
                  <div className="ratio ratio-16x9">
                      <iframe src={x.embed} frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>
                  </div>
              }
              </SwiperSlide>
            ))}
            </PostCard.Gallery>
          </PostCard.Body>
          <PostCard.Footer
            post={post}
            onReact={(emoji, post) => {
              handleReactToPost(this, emoji, post)
              .then(() => {
                let newReaction = new Reaction({userId: this.props.currentUser.id, postId: post.id, emoji})
                this.updatePostWithReaction(post, newReaction)
              })
            }}
            onReply={() => handleReplyToPost(this, post, this.deletionSpecification)}
            onShare={(e, action, post) => handleSharePost(this, action, post)}
            
          />
        </PostCard>
      </ListGroup.Item>
    )
  }

  renderList(posts) {
    var { isLoading, hasMore } = this.state

    return (
      <Dashboard.List
          isLoading={isLoading}
          hasMore={hasMore}
          variant="flush"
          loadMore={() => this.getNextPosts()}
        >
        {posts.map((post) => (
          this.renderListItem(post)
        ))}
      </Dashboard.List>
    )
  }

  renderLoading() {
    return (
      <div className="text-center p-3">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  renderPopularCard(popularPosts) {
    if (popularPosts.length == 0) {
      return
    }

    let regionCode = this.filterRegionCode
    let countryCode = regionCode ? regionCode.split('-')[0] : 'DK'
    let country = this.getCountry.byCode(countryCode)

    let title = this.props.intl.formatMessage({id: 'POPULAR_IN_COUNTRY_CARD_HEADER_FORMAT'}, {
      country: country ? country.name : ''
    })

    return (
      <PopularPostsCard
        title={title}
        listItems={popularPosts.map((post) =>
          <ListGroup.Item className="bg-transparent border-bottom-0 w-100">
              <article class="justify-content-between position-relative d-flex align-items-center mb-0">
                  <div class="ps-e">
                      <small className="text-muted fs-xs n-lines-1">{this.formatRegionCode.withValue(post.regionCode, 'short') ?? ""}</small>
                      <h4 class="n-lines-1 h6 mb-0">
                          <Link className="stretched-link text-dark" to={`/me/${post.author.username}/post/${post.id}`}>{post.title || 'Untitled'}</Link>
                      </h4>
                      <span class="n-lines-1 fs-sm text-muted">
                          <FormattedNumber value={post.metrics.views} notation={'compact'}/>
                          {' '} <FormattedPlural value={post.metrics.views} one="view" other="views" />
                      </span>
                  </div>
                  { post.entities.filter((x) => x.type == 'image').length > 0 &&
                      <img
                      style={{width: "46px", height: "46px", objectFit: "cover"}}
                      className="rounded-1"
                      src={post.entities.filter((x) => x.type == 'image')[0].src}
                      alt={post.entities.filter((x) => x.type == 'image')[0].alt}
                      />
                  }
              </article>
          </ListGroup.Item>
        )}
      />
    )
  }

  handleClearFilter(e) {
    const to = '/'
    this.props.navigate(to)
  }

  handleApplyFilter(e, { regionCode }) {
    const to = `/${regionCode.toLowerCase()}`
    this.props.navigate(to)
  }

  renderFilterDropdownMenu() {
    return (
      <RegionFilterDropdownMenu
        regionCode={this.filterRegionCode}
        onClear={this.handleClearFilter}
        onApply={this.handleApplyFilter}
      />
    )
  }

  renderEmpty() {
    return (
      <div className="text-center px-3 py-5">
        <p className="text-muted">
          <FormattedMessage id="EMPTY_POSTS_MESSAGE"/>
        </p>
      </div>
    )
  }

  render() {
    var { isLoading, posts, popularPosts, showSidebar } = this.state
    var { currentUser } = this.props

    let filteringEnabled = false

    return (
      <Dashboard childScene={this}>
        <Dashboard.Body>
            <Dashboard.Navbar
              title={this.props.intl.formatMessage({id: "NAV_TITLE_HOME"})}
              subtitle={this.formatRegionCode.withValue(this.filterRegionCode)}
              goBack={null}
              bg="light"
              variant="dark"
              offcanvasBody={createMobileNavDrawer({
                currentUser,
                onBehalfOfComponent: this,
                onNewPost: handleNewPost,
                onNewChat: handleNewChat,
                onNewEvent: handleNewEvent,
                onPreferences: handlePreferences,
                onSignOut: handleSignOut
              })}
              nav={
                  <Nav className="ms-auto">
                    <Dropdown className="">
                      <Dropdown.Toggle variant="outline-secondary" size="sm" className="btn-icon border-0 rounded-circle me-n2">
                        <i className="fs-lg bi bi-filter-circle"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu flip={true} className="dropdown-menu dropdown-menu-end my-1">
                        {this.renderFilterDropdownMenu()}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav>
              }/>
          {this.renderList(posts)}
          {isLoading === false && posts.length === 0 && this.renderEmpty()}

          <SidebarButton onClick={this.handleShowSidebar}/>
        
        </Dashboard.Body>
        <Dashboard.Context
          onShow={this.handleShowSidebar}
          onHide={this.handleCloseSidebar}
          show={showSidebar}
          onFeedback={(e) => handleFeedack(this)}>
            {this.renderPopularCard(popularPosts)}
        </Dashboard.Context>
      </Dashboard>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.changedAuthState,
    routeState: state.routeStateChanged,
    fallbackRegion: state.setFallbackRegion,
    notification: state.notification
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    authStateChanged: authStateChanged,
    setModal: setModal,
    createNotification: createNotification
  }, dispatch);
}

function BackwardsCompatibleProps(props) {
  let navigate = useNavigate()
  let params = useParams()
  let location = useLocation()
  let intl = useIntl();

  return <Home {...props} intl={intl} location={location} navigate={navigate} match={{ params: params }} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps)
