import { Component } from "react"
import { Navbar, Button, Nav, Offcanvas, NavItem } from 'react-bootstrap' 
import { useNavigate, NavLink } from "react-router-dom"

class DashboardNavbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
    }

    renderNavbarBrand() {
        const hasSubtitle = this.props.subtitle != null
        if (hasSubtitle === false) {
            return (
                <Navbar.Brand className="fw-bold text-dark" href="#">
                    {this.props.title}
                </Navbar.Brand>
            )
        } else {
            return (
                <Navbar.Brand bsPrefix="aria5" as={"p"} className="text-dark mb-0" href="#">
                    <span className="fw-bold">{this.props.title}</span>
                    <br/>
                    <small className="text-muted">
                        {this.props.subtitle}
                    </small>
                </Navbar.Brand>
            )
        }
    }

    renderBackButtonIfNeeded() {
        if (this.props.goBack === undefined || this.props.goBack === null) {
            return
        }
        return (
            <Button
                variant="link"
                size="sm"
                onClick={(e) => this.props.navigate(this.props.goBack, {replace: false})}
                className="btn-icon rounded-circle text-dark p-0 mb-0 me-3">
                    <i className="fs-lg bi bi-arrow-left" />
            </Button>
        )
    }

    handleClose() {
        this.setState({show: false})
    }

    handleShow() {
        this.setState({show: true})
    }

    render() {
        var { show } = this.state
        var { showToggle, offcanvasBody } = this.props

        showToggle = showToggle ?? true

        return (
            <Navbar ref={(node) => node && this.props.getNavbarHeight ? this.props.getNavbarHeight(node.offsetHeight) : (() => {})} collapseOnSelect={true} expand="lg" sticky="top" className={`w-100 text-dark border-bottom ${this.props.goBack ? 'ps-2' : 'ps-3'} pe-3`} bg="light" variant="light">
                <div className={`d-flex justify-content-start align-items-center ${this.props.customContent ? 'w-100' : ''}`}>
                    {this.renderBackButtonIfNeeded()}
                    {this.props.title && this.renderNavbarBrand() }
                    {this.props.customContent && this.props.customContent}
                </div>

                {this.props.nav && this.props.nav}
                {showToggle &&
                    <Navbar.Toggle onClick={this.handleShow} className="border-0" />
                }
                <Offcanvas placement={'start'} show={show} onHide={this.handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Aria5</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {offcanvasBody}
                    </Offcanvas.Body>
                </Offcanvas>
            </Navbar>
        )
    }
}

function BackwardsCompatibleProps(props) {
    let navigate = useNavigate()
    return <DashboardNavbar {...props} navigate={navigate}/>
}

export default BackwardsCompatibleProps