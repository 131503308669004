import { createClient } from '@supabase/supabase-js';
import User from '../../models/User';

class AuthService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    signUp(email, password) {
        return this.supabase.auth.signUp({
            email: email,
            password: password,
        })
        .then((response) => {
            var { data, error } = response;
            if (error) {
                return Promise.reject(error);
            }
            var { session, user } = data;
            return user.id;
        })
    }

    signIn(email, password) {
        return this.supabase.auth.signInWithPassword({
            email: email,
            password: password
        })
        .then((response) => {
            var { data, error } = response;
            if (error) {
                return Promise.reject(error);
            }
            var {session, user} = data;
            return user.id;
        })
        .then((userId) => {
            return this.supabase
            .from('user')
            .select('*')
            .eq('id', userId)
            .is('deletedAt', null)
            .limit(1)
        })
        .then((response) => {
            var {error, data} = response
            if (error) {
                return Promise.reject(error)
            }
            let users = data;
            if (users.length === 0) {
                return Promise.reject(new Error("User is deleted"));
            }
            let userData = users[0]
            return userData.id
        })
    }

    signOut() {
        return this.supabase.auth.signOut();
    }

    changePassword(currentUser, currentPassword, newPassword) {
        return this.supabase.auth.signInWithPassword({
            email: currentUser.email,
            password: currentPassword,
        })
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            var { user } = data
            return user.id
        })
        .then(() => {
            return this.supabase.auth.updateUser({ password: newPassword })
        })
    }

    sendResetPassword(email) {
        return this.supabase.auth.resetPasswordForEmail(email, {
            redirectTo: 'http://localhost:3000/update-password',
        })
    }

    updatePassword(newPassword) {
        return this.supabase.auth.updateUser({ password: newPassword })
    }

    getUserEmail() {
        return this.supabase.auth.getUser()
        .then((response) => {
            var  { error, data: { user } } = response
            if (error) {
                return Promise.reject(error)
            }
            return user.email
        })
    }

    // Note: Triggers multiple times don't use.
    /*
        listenForAuthStateChanges(event, session) {
            var { coldStartLaunched } = this.state;
            if (coldStartLaunched == true) {
            return;
            }

            this.setState({coldStartLaunched: true})
            
            switch (event) {
            case 'SIGNED_IN':
                {
                var { user } = session;
                let userId = user.id;
                User.findById(userId)
                .then((currentUser) => {
                    this.props.authStateChanged(currentUser)
                })
                }
                break;

            case 'SIGNED_OUT':
                {
                this.props.authStateChanged(null)
                }
                break;
            }
    }
    */
    onStateChange(callbackFn) {
        return this.supabase.auth.onAuthStateChange(callbackFn)
    }
}

export default AuthService;