import React, { Component } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import Dashboard from '../Dashboard';
import { handleReactToPost, handleReplyToPost, handleDeletePost, setNextPagination, setHasMore, handleSharePost, handleReport, handleEditPost, handleNewPost, handleNewChat, handleNewEvent, handlePreferences, handleSignOut, createMobileNavDrawer, handleFeedack } from "../Dashboard/dashboard-fns";
import Post from '../../models/Post';

import { Spinner, Dropdown, Button, ListGroup } from 'react-bootstrap';

import axios from 'axios';

import Pagination from '../../models/common/Pagination';
import PostCard from "../../components/PostCard";

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import authStateChanged from "../../redux/actions/auth_state_changed";
import createNotification from '../../redux/actions/create_notification';
import setModal from '../../redux/actions/set_modal';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedPlural, FormattedTime, useIntl } from 'react-intl';
import IncrementPostMetricService from "../../services/IncrementPostMetricService";

import { SwiperSlide } from "swiper/react"
import PostDeletionSpecification from "../../specifications/PostDeletionSpecification";
import PostDeletionFeature from "../../models/PostDeletionFeature";
import UserCard from "../../components/UserCard";
import Reaction from "../../models/Reaction";
import UserNotification from "../../models/common/UserNotification";
import WritePermissionPolicy from "../../policies/WritePermissionPolicy";
import Order from "../../models/common/Order";
import Avatar from "../../components/Avatar";
import SidebarButton from "../../components/SidebarButton";

class PostDetails extends Component {
    constructor(props) {
        super(props);

        this.initState = {
            isLoading: false,
            post: null,
            html: null,
            replies: [],
            hasMore: true,
            errors: [],
            showSidebar: false
        }
        this.state = {...this.initState}

        this.triggerIncrementViewCount = true
        
        this.writePermission = new WritePermissionPolicy()

        this.pagination = new Pagination(0, 4)
        this.order = new Order('createdAt', false)

        this.incrementPostMetric = new IncrementPostMetricService()
        this.deletionSpecification = new PostDeletionSpecification(
            new PostDeletionFeature('TIME_ELAPSED', {'DAY': 7}))

        this.getPost = this.getPost.bind(this)
        this.getNextReplies = this.getNextReplies.bind(this)
        this.updatePostWithReaction = this.updatePostWithReaction.bind(this)

        this.handleNotificationChangeIfNeeded = this.handleNotificationChangeIfNeeded.bind(this)
        this.updatePostIfNeeded = this.updatePostIfNeeded.bind(this)
        this.updateRepliesIfNeeded = this.updateRepliesIfNeeded.bind(this)
        this.handleChangeOrder = this.handleChangeOrder.bind(this)
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)
    }

    handleCloseSidebar() {
        this.setState({showSidebar: false})
    }

    handleShowSidebar() {
        this.setState({showSidebar: true})
    }

    handleChangeOrder(e, to) {
        switch (to.toUpperCase()) {
            case 'NEWEST':
                this.order = new Order('createdAt', false)
            break;
            case 'OLDEST':
                this.order = new Order('createdAt', true)
            break;
            default:
                return
        }
        
        this.pagination = new Pagination(0, 4)
        
        this.setState({
            isLoading: true,
            replies: []
        })

        let postId = this.props.match.params.postId
        this.getNextReplies(postId)
        .then(() => {
            this.setState({isLoading: false})
        })
        .catch(() => {
            this.setState({isLoading: false})
        })
    }

    updatePostWithReaction(post, newReaction) {
        this.props.createNotification(new UserNotification({
            name: this.props.intl.formatMessage({id: 'REACTION_WAS_ADDED_NOTIFICATION'}),
            object: newReaction,
            userInfo: {}
        }))
        this.setState((state) => {
            if (state.post.id == post.id) {
                var updatedPost = state.post
                var updatedReactions = [newReaction, ...updatedPost.reactions]
                updatedPost.reactions = updatedReactions
                updatedPost.metrics.reactions += 1

                return {
                    post: updatedPost
                }

            } else {
                var updatedReplies = [...state.replies]
            
                var postIndex = updatedReplies.findIndex((x) => x.id === post.id)
                var updatedPost = updatedReplies[postIndex]

                var updatedReactions = [newReaction, ...updatedPost.reactions]
                updatedPost.reactions = updatedReactions
                updatedPost.metrics.reactions += 1

                updatedReplies[postIndex] = updatedPost

                return {
                    replies: updatedReplies
                }
            }
        })
    }

    updateRepliesIfNeeded(newReply) {
        var pagination = this.pagination
        this.setState((state) => {
            const newest = this.order.ascending === false
            
            var updatedPost = state.post
            if (updatedPost) {
                updatedPost.metrics.replies += 1
            }

            if (newest) {
                return {
                    post: updatedPost,
                    replies: [newReply, ...state.replies]
                }
            } else if (state.replies.length < pagination.limit) {
                return {
                    post: updatedPost,
                    replies:  [...state.replies, newReply]
                }
            }
        })
    }

    updateEditedPostIfNeeded(editedPost) {
        this.setState((state) => {
            if (state.post.id === editedPost.id) {
                return {
                    post: editedPost
                }
            } else {
                var updatedReplies = [...state.replies]
                for (let i = 0; i < state.replies.length; i++) {
                    const candidate = state.replies[i];
                    if (candidate.id === editedPost.id) {
                        updatedReplies[i] = editedPost
                        break
                    }
                }
                return {
                    replies: updatedReplies
                }
            }
        })
    }

    removePostIfNeeded(deletedPost) {
        this.setState((state) => {
            if (state.post.id === deletedPost.id) {
                this.props.navigate(-1)
                return {}
            } else {
                var updatedReplies = state.replies.filter((reply) => reply.id !== deletedPost.id)
                var updatedPost = state.post
                updatedPost.metrics.replies -= 1
                return {
                    post: updatedPost,
                    replies: updatedReplies
                }
            }
        })
    }

    handleNotificationChangeIfNeeded(prevProps) {
        if (prevProps.notification != this.props.notification) {
            let notification = this.props.notification;
            if (notification == null) {
              return;
            }
            let {type} = notification.userInfo
            if (type == 'REPLY_CREATED') {
                let newReply = notification.object;
                this.updateRepliesIfNeeded(newReply)
            } else if (type == 'POST_EDITED') {
                let editedPost = notification.object
                this.updateEditedPostIfNeeded(editedPost)
            } else if (type == 'POST_DELETED') {
                let deletedPost = notification.object
                this.removePostIfNeeded(deletedPost)
            }
        }
    }

    updatePostIfNeeded(prevProps) {
        let postId = this.props.match.params.postId
        if (prevProps.match.params.postId !== postId) {
            this.setState({...this.initState})
            this.pagination = new Pagination(0, 4)
            
            this.setState({isLoading: true})
            Promise.all([
                this.getPost(postId),
                this.getNextReplies(postId),
                this.incrementPostMetric.withArgs({postId, fieldName: 'views', x: 1})
            ])
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            })
        }
    }

    componentDidUpdate(prevProps) {
        this.updatePostIfNeeded(prevProps)
        this.handleNotificationChangeIfNeeded(prevProps)
    }

    getPost(postId) {
        var post = null;
        return Post
        .findById(postId)
        .then((_post) => {
            post = _post;
            return axios.get(post.downloadURL)
        })
        .then((response) => {
            const html = response.data;
            this.setState({
                errors: [],
                post,
                html
            })
        })
        .catch((error) => {
            this.setState({
                errors: this.state.errors.push(error)
            })
        })
    }

    getNextReplies(postId) {
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }
        return Post
        .inReplyToPostId(postId, this.pagination, this.order)
        .then((newReplies) => {
            this.pagination = setNextPagination(this.pagination, newReplies.length)
            this.setState({
                errors: [],
                replies: this.state.replies.concat(newReplies),
                hasMore: setHasMore(this.pagination, newReplies.length)
            })
        })
    }

    componentDidMount() {
        let postId = this.props.match.params.postId;
        
        this.setState({isLoading: true})
        this.getPost(postId)
        .then(this.incrementPostMetric.withArgs({postId, fieldName: 'views', x: 1}))
        .then(() => {
            this.getNextReplies(postId)
        })
        .then(() => {
            this.setState({
                isLoading: false,
                errors: []
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    renderPost(isLoading, post, html) {
        if (post == null) {
            return null
        }

        let canWrite = this.writePermission.isGranted(this.props.currentUser, {
            type: 'POST', object: post
        })

        return (
            <div key={post.id} className="w-100 p-3">
                { post.inReplyToPostId &&
                    <div className="">
                        <p className="text-muted n-line-1">
                            <i className="bi bi-reply me-0 mb-1"/>
                            {' '}
                            <FormattedMessage
                                id="REPLIED_TO_USER_FORMAT"
                                values={{
                                    user: <Link to={`/me/${post.inReplyToUsername ? post.inReplyToUsername : 'unknown'}/post/${post.inReplyToPostId}`}>{post.inReplyToUsername ? post.inReplyToUsername : post.inReplyToPostId}</Link>
                                }}/>
                        </p>
                    </div>
                }
                <div className="d-flex justify-content-start align-items-center px-0 pb-3 w-100">
                    <Link className="text-decoration-none" to={`/me/${post.author.username}`}>
                        <Avatar
                            className="me-3"
                            size="40px"
                            src={post.author.photoURL}
                            alt={post.author.firstName + ' ' + post.author.lastName}
                        />
                    </Link>
                    <div>
                        <b className="n-line-1"><Link className="text-decoration-none text-dark" to={`/me/${post.author.username}`}>{post.author.firstName + ' ' + post.author.lastName}</Link></b>
                        <br/>
                        <small className="text-muted n-line-1">{`@${post.author.username}`}</small>
                    </div>
                    
                    <Dropdown className="ms-auto align-self-center">
                        <Dropdown.Toggle style={{ cursor: 'pointer', fontSize: "1.1em" }} className="text-dark px-0" bsPrefix="123" as="a" variant="primary" id="dropdown-basic">
                            <i className="bi bi-three-dots inline-icon me-2"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-macos shadow">
                            <Dropdown.Item onClick={(e) => handleReport(this, post.id, 'POST')}>
                                <FormattedMessage id="REPORT_DROPDOWN_ITEM"/>
                            </Dropdown.Item>
                            {post.author.id && canWrite &&
                                <Dropdown.Item onClick={(e) => handleEditPost(this, post)}>
                                    <FormattedMessage id="EDIT_DROPDOWN_ITEM"/>
                                </Dropdown.Item>
                            }
                            {post.author.id && canWrite &&
                                <Dropdown.Item onClick={(e) => handleDeletePost(this, post)}>
                                    <FormattedMessage id="DELETE_DROPDOWN_ITEM"/>
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div style={{wordBreak: 'break-word', wordWrap: 'balance'}} className="w-100" dangerouslySetInnerHTML={{__html: html}}></div>
                
                <div className="pb-3">
                    <small className="text-muted">
                        { <FormattedTime value={new Date(post.createdAt)} /> }
                        </small>
                        <span className="text-muted">{' · '}</span>
                        <small className="text-muted">
                        {
                        <FormattedDate
                            value={new Date(post.createdAt)}
                            year="numeric"
                            month="long"
                            day="2-digit"
                        />
                        }
                    </small>
                </div>

                <div className="border-top px-0 pt-4 pb-2">
                    <PostCard.Footer
                        className="px-0"
                        showReply={false}
                        post={post}
                        onReact={(emoji, post) => {
                            handleReactToPost(this, emoji, post)
                            .then(() => {
                                let newReaction = new Reaction({userId: this.props.currentUser.id, postId: post.id, emoji})
                                this.updatePostWithReaction(post, newReaction)
                            })
                        }}
                        onShare={(e, action, post) => handleSharePost(this, action, post)}
                    />
                </div>

            </div>
        )
    }

    renderReplyToolbar(post) {
        var replies = 0
        if (post && post.metrics) {
            replies = post.metrics.replies
        }
        return (
            <div className="border-top border-bottom bg-light">
                <div className="d-flex justify-content-between align-items-center p-3">
                    <div className="d-flex justify-content-start align-items-center me-auto">
                        <h5 className="fw-bold me-3 mb-0">
                            <FormattedNumber value={replies} notation={'compact'}/>
                            {' '} <FormattedPlural value={replies} one={this.props.intl.formatMessage({id: "ONE_REPLY"})} other={this.props.intl.formatMessage({id: "OTHER_REPLIES"})} />
                        </h5>
                        <Dropdown>
                            <Dropdown.Toggle style={{ cursor: 'pointer' }} bsPrefix="123" as="a" variant="link" className="text-decoration-none">
                                <FormattedMessage id="ORDER_BY_BTN"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-macos shadow">
                                <Dropdown.Item active={this.order.ascending === false} onClick={(e) => this.handleChangeOrder(e, 'NEWEST')}>
                                    <FormattedMessage id="NEWEST_DROPDOWN_ITEM"/>
                                </Dropdown.Item>
                                <Dropdown.Item active={this.order.ascending} onClick={(e) => this.handleChangeOrder(e, 'OLDEST')}>
                                    <FormattedMessage id="OLDEST_DROPDOWN_ITEM"/>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Button onClick={(e) => handleReplyToPost(this, post, this.deletionSpecification)} variant="primary">
                        <FormattedMessage id="REPLY_BTN"/>
                    </Button>
                </div>
            </div>
        )
    }

    renderListItem(post) {
        let canWrite = this.writePermission.isGranted(this.props.currentUser, {
            type: 'POST', object: post
        })

        return (
            <ListGroup.Item key={post.id} action onClick={(e) => {
                this.props.navigate(`/me/${post.author.username}/post/${post.id}`)
            }} className="py-0">
            <PostCard flush>
                <PostCard.Header
                    showInReplyTo={false}
                    disabledWrite={canWrite === false}
                    post={post}
                    onDelete={() => handleDeletePost(this, post)}
                    onReport={() => handleReport(this, post.id, 'POST')}
                    onEdit={() => handleEditPost(this, post)}
                />
                <PostCard.Body>
                <PostCard.Preview>
                    {post.preview}
                </PostCard.Preview>
                <PostCard.Gallery>
                { post.entities.filter((x) => x.type === 'image' || x.type === 'embed').map((x) => (
                    <SwiperSlide>
                    { x.type === 'image' &&
                        <img style={{objectFit: "cover"}}
                            className="w-100 h-100"
                            alt={x.alt ?? ""}
                            src={x.src}
                        />    
                    }
                    { x.type === 'embed' &&
                        <div className="ratio ratio-16x9">
                            <iframe src={x.embed} frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>
                        </div>
                    }
                    </SwiperSlide>
                ))}
                </PostCard.Gallery>
                </PostCard.Body>
                <PostCard.Footer
                post={post}
                onReact={(emoji, post) => {
                    handleReactToPost(this, emoji, post)
                    .then(() => {
                        let newReaction = new Reaction({userId: this.props.currentUser.id, postId: post.id, emoji})
                        this.updatePostWithReaction(post, newReaction)
                    })
                }}
                onReply={(e) => handleReplyToPost(this, post, this.deletionSpecification)
                }
                onShare={(e, action, post) => handleSharePost(this, action, post)}
                />
            </PostCard>
            </ListGroup.Item>
        )
    }
    
    renderReplyList(isLoading, posts, hasMore) {
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => this.getNextReplies(this.props.match.params.postId)}>
                    {posts.map((post) =>
                        this.renderListItem(post)
                    )}
            </Dashboard.List>
        )
    }

    renderLoading() {
        return (
            <div className="text-center p-3">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    renderReplies(isLoading, post, replies, hasMore) {
        return (
            <div>
                {post && this.renderReplyToolbar(post)}
                {this.renderReplyList(isLoading, replies, hasMore)}
                {isLoading === false && replies.length === 0 && this.renderEmptyReplies()}
            </div>
        )
    }

    renderPostNotFound() {
        return (
            <div className="px-3 py-5 text-center">
                <p><FormattedMessage id="EMPTY_POST_DETAIL_MESSAGE"/></p>           
            </div>
        )
    }

    renderEmptyReplies() {
        return (
            <div className="px-3 py-5 text-center">
                <p><FormattedMessage id="EMPTY_REPLIES_MESSAGE"/></p>
            </div>
        )
    }

    render() {
        var { isLoading, post, replies, hasMore, html, showSidebar } = this.state
        var { currentUser } = this.props

        return (
            <Dashboard childScene={this}>
                <Dashboard.Body>
                    <Dashboard.Navbar
                        title={this.props.intl.formatMessage({id: 'NAV_TITLE_POST_DETAILS'})}
                        goBack={-1}
                        bg="light"
                        variant="dark"
                        offcanvasBody={createMobileNavDrawer({
                            currentUser,
                            onBehalfOfComponent: this,
                            onNewPost: handleNewPost,
                            onNewChat: handleNewChat,
                            onNewEvent: handleNewEvent,
                            onPreferences: handlePreferences,
                            onSignOut: handleSignOut
                        })}/>
                    <div>
                        {this.renderPost(isLoading, post, html)}
                        {isLoading === false && post === null && this.renderPostNotFound()}
                        {this.renderReplies(isLoading, post, replies, hasMore)}
                    </div>
                    <SidebarButton onClick={this.handleShowSidebar}/>
                </Dashboard.Body>
                <Dashboard.Context
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    {isLoading == false && post &&
                        <UserCard
                            to={`/me/${post.author.username}`}
                            className="mt-3"
                            user={post.author}
                        />
                    }
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        notification: state.notification
    }
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let intl = useIntl()
    let params = useParams()
    let navigate = useNavigate()
    return <PostDetails {...props} intl={intl} navigate={navigate} match={{params: params}} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);