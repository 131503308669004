import {VideoEntity, ImageEntity, LinkEntity, EmbedEntity} from '../../models/Entity'
import VideoURLSpecification from "../../specifications/VideoURLSpecification";
import edjsHTML from 'editorjs-html'

export function uploadHTML(client, guid, html, author, cachePolicy) {
    const bucket = 'users'
    return client
    .storage
    .from(bucket)
    .upload(`${author.id}/posts/${guid}/body.html`, html, {
        cacheControl: `${cachePolicy.getTotalSeconds()}`,
        upsert: true
    })
    .then((response) => {
        var { data, error } = response;
        if (error) {
            return new Promise.reject(error);
        }
        let { path } = data
        let publicUrl = `${"https://bspodttrfjifboigqtfv.supabase.co"}/storage/v1/object/public/${bucket}/${path}`;
        return publicUrl
    })
}

export function uploadBlocks(client, guid, blocks, author, cachePolicy) {
    const bucket = 'users'
    return client
    .storage
    .from(bucket)
    .upload(`${author.id}/posts/${guid}/blocks.json`, JSON.stringify(blocks), {
        cacheControl: `${cachePolicy.getTotalSeconds()}`,
        upsert: true
    })
    .then((response) => {
        var { data, error } = response;
        if (error) {
            return new Promise.reject(error);
        }
        let { path } = data
        let publicUrl = `${"https://bspodttrfjifboigqtfv.supabase.co"}/storage/v1/object/public/${bucket}/${path}`;
        return publicUrl
    })
}

/**
 * A subroutine that generates a preview (short version) of the full markdown
 * @param {array} blocks - A blocks array
 * @return {string} Returns a preview of the full input blocks array as html
 */
export function generatePreview(blocks) {
    if (blocks.length === 0) {
        return ""
    }

    const keepBlockTypes = ['header', 'paragraph']
    var blocksFiltered = blocks.filter((block) => keepBlockTypes.includes(block.type))
    
    blocksFiltered = blocksFiltered.map((block) => {
        if (block.type == 'header') {
            var header = {...block} 
            header.data.level = 5
            return header
        }
        return block
    })

    return convertBlocksToHTML(blocksFiltered)
}

function imageParser({data}) {
    return `<img class="w-100 img-fluid py-3" src="${data.file.url}" alt="${data.caption}"/>`
}

function embedParser({data}) {
    switch (data.service) {
        case 'rumble':
            return `<div class="ratio ratio-16x9 mb-3"><iframe class="rumble" src="${data.embed}" scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe></div>`
        case 'spotify':
            return `<div class="mb-3"><iframe style="border-radius:12px" src="https://open.spotify.com/embed/track/2dphvmoLEXdk8hOYxmHlI3?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe></div>`
        default:
            return `<div class="ratio ratio-16x9 mb-3"><iframe src="${data.embed}" frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe></div>`
    }
}

function tableParser({data}) {
    var { content } = data
    var html = '<table class="table mb-3"><tbody>'
    content.forEach((row) => {
        var td = "<tr>"
        row.forEach((column) => {
            td += "<td>" + column + "</td>"
        })
        td += "</tr>"
        html += td
    })
    html += "</tbody>"
    return html
}

function checklistParser({data}) {
    var { items } = data
    var html = '<div class="mb-3">'
    items.forEach(({text, checked}) => {
        if (checked) {
            html += `<div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked disabled>
                <label class="form-check-label" for="flexCheckDefault">
                    ${text}
                </label>
            </div>`
        } else {
            html += `<div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled>
                <label class="form-check-label" for="flexCheckDefault">
                    ${text}
                </label>
            </div>`
        }
    })
    html += "</div>"
    return html
}

export function convertBlocksToHTML(blocks) {
    // NOTE: custom parser for checkList, table and perhabs others.
    const edjsParser = edjsHTML({
        table: tableParser,
        image: imageParser,
        embed: embedParser,
        checklist: checklistParser
    })
    let html = blocks.map((block) => edjsParser.parseBlock(block)).join('')
    return html
}

function setImageEntity(block) {
    return new ImageEntity(block.data.file.url, block.data.caption)
}

function setLinkEntity(block) {
    return new LinkEntity(block.data.link, block.data.link)
}

function setEmbedEntity(block) {
    var data = block.data
    return new EmbedEntity(data.caption, data.embed, data.height, data.service, data.source, data.width)
}


/**
 * A subroutine that generates an entity graph from a markdown
 * @param {array} blocks - A blocks array
 * @return {Entity[]} Returns a list of entities
 */
export function generateEntites(blocks) {
    var entities = []
    
    let videoURLSpec = new VideoURLSpecification()

    blocks.forEach((block) => {
        if (block.type == 'image') {
            entities.push(setImageEntity(block))
        } else if (block.type == 'linkTool' && videoURLSpec.isSatisfiedBy(block.data.link) === false) {
            entities.push(setLinkEntity(block))
        } else if (block.type == 'embed') {
            entities.push(setEmbedEntity(block))
        }
    })

    return entities
}
/**
 * A subroutine that generates a title for a markdown
 * @param {string} blocks - Blocks array
 * @return {string} Returns a title
 */
export function generateTitle(blocks) {
    const untitled = null

    if (blocks.length === 0) {
        return untitled
    }

    var candidate = undefined;

    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        if (block.type === 'header') {
            candidate = block
            break
        }
    }

    if (candidate == undefined) {
        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            if (block.type === 'paragraph') {
                candidate = block
                break
            }
        }
        return untitled
    }

    if (candidate == undefined) {
        return untitled
    }

    const maxLength = 100
    let title = candidate.data.text.slice(maxLength)
    return title
}