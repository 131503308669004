import { createClient } from '@supabase/supabase-js';
import Attachment from '../../models/chat/Attachment';
import Message from '../../models/chat/Message';
import UploadAttachmentsService from '../../services/UploadAttachmentsService';

class MessageFactory {
    constructor() {
        this.supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(this.supabaseUrl, supabaseKey)

        this.uploadAttachments = new UploadAttachmentsService()
    }
    
    create(chatRoom, user, content, attachments) {
        return this.uploadAttachments.forChatRoom(chatRoom, attachments)
        .then((relativePaths) => {
            let now = new Date();

            var msgAttachments = []

            relativePaths.forEach((relativePath, i) => {
                var file = attachments[i]
                msgAttachments.push(new Attachment({
                    name: file.name, type: file.type,
                    relativePath, size: file.size
                }))
            })

            return new Message({
                createdAt: now,
                roomId: chatRoom.roomId,
                user,
                content,
                attachments: msgAttachments
            })
        })
    }
}

export default MessageFactory;