import regions from '../../assets/ISO-3166-2.json'

class GetCountryService {
    byCode(countryCode) {
        let candidates = Object.values(regions)
                                .filter((x) => x.code == countryCode)
        if (candidates.length == 0) {
            return null
        }
        let country = candidates[0]
        return country;
    }
}

export default GetCountryService;
