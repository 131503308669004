import { Component } from "react"
import { Form, Col, Row, Button, Spinner, Collapse, Dropdown } from 'react-bootstrap'
import { FormattedMessage } from "react-intl"
import UserInfoCard from "../../../../../models/UserInfoCard"
import {default as UserInfoCardComponent} from "../../../../UserInfoCard"
import ListOccupationsService from "../../../../../services/ListOccupationsService"
import OccupationsDropdownMenu from "../../../../OccupationsDropdownMenu"

class WorkPane extends Component {
    constructor(props) {
        super(props)

        this.initialState = {
            occupations: [],
            cards: [],
            open: false,
            type: "",
            title: "",
            text: "",
            value: "",
            isValidated: false,
            isLoading: false,
            errors: []
        }

        this.state = {...this.initialState}

        this.maxOccupationsAllowed = 30
        this.maxCardsAllowed = 15

        this.listOccupations = new ListOccupationsService()
        this.allOccupations = this.listOccupations.atLevel(4) || []

        this.getExistingsCardsIfNeeded = this.getExistingsCardsIfNeeded.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleCancelNewCard = this.handleCancelNewCard.bind(this)
        this.handleAddNewCard = this.handleAddNewCard.bind(this)
        this.handleRemoveCard = this.handleRemoveCard.bind(this)
        this.handleRemoveOccupation = this.handleRemoveOccupation.bind(this)
        this.handleAddOccupation = this.handleAddOccupation.bind(this)
        this.setNeedsExistingOccupations = this.setNeedsExistingOccupations.bind(this)
    }

    handleCancel(e) {
        this.setState({...this.initialState})
    }

    handleCancelNewCard(e) {
        this.setState({
            open: false,
            isValidated: false,
            type: "",
            title: "",
            text: "",
            value: ""
        })
    }

    handleRemoveCard(e, removeAtindex) {
        this.setState((state) => {
            return {
                cards: state.cards.filter((x, index) => removeAtindex !== index)
            }
        }, () => {
            this.props.onSaveChanges(e, 'userInfoCards', {cards: this.state.cards})
        })
    }

    handleAddNewCard(e) {
        e.preventDefault()
        const form = e.currentTarget

        var { type, title, text, value} = this.state
        
        this.setState({isValidated: true})

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }

        this.setState((state) => {
            return {
                cards: state.cards.concat(new UserInfoCard({
                    title, text, value, type
                })),
                isValidated: false,
                open: false,
                type: "",
                title: "",
                text: "",
                value: ""
            }
        }, () => {
            this.props.onSaveChanges(e, 'userInfoCards', {cards: this.state.cards})
        })
    }
    
    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
    }

    findMatchingOccupationWithCode(code) {
        let matchingOccupations = this.allOccupations.filter((x) => x.code == code)
        if (matchingOccupations.length == 0) {
            return null
        }
        return matchingOccupations[0]
    }

    handleAddOccupation(code) {
        var { occupations } = this.state
        
        let alreadyAdded = occupations.filter((x) => x.code == code).length > 0
        if (alreadyAdded) {
            return
        }

        let occupation = this.findMatchingOccupationWithCode(code)
        if (occupation == null) {
            return
        }

        this.setState((state) => {
            return {
                occupations: state.occupations.concat(occupation)
            }
        }, () => {
            this.props.onSaveChanges(null, 'occupations', {occupations: this.state.occupations})
        })
    }

    handleRemoveOccupation(e, occupation) {
        this.setState((state) => {
            return {
                occupations: state.occupations.filter((x) => x.code != occupation.code)
            }
        }, () => {
            this.props.onSaveChanges(null, 'occupations', {occupations: this.state.occupations})
        })
    }

    setNeedsExistingOccupations() {
        var { currentUser } = this.props
        if (currentUser == null) {
            return
        }

        var occupations = currentUser.occupationCodes
        .map((code) => this.findMatchingOccupationWithCode(code))
        .filter((x) => x !== null)

        this.setState({
            occupations: occupations
        })
    }

    getExistingsCardsIfNeeded() {
        var { currentUser } = this.props
        if (currentUser == null) {
            return
        }
        return currentUser.getInfoCards()
        .then((cards) => {
            this.setState({cards: cards})
            return cards
        })
    }

    componentDidMount() {
        this.setState({isLoading: true})
        this.setNeedsExistingOccupations()
        this.getExistingsCardsIfNeeded()
        .then(() => {
            this.setState({
                isLoading: false,
                errors: []
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    renderForm() {
        var { isValidated, type, title, text, value } = this.state
        return (
            <Form onSubmit={this.handleAddNewCard} noValidate validated={isValidated}>
                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="TYPE_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        required
                        onChange={this.handleInputChange}
                        name="type"
                        value={type}>
                        <option value="">Choose a type...</option>
                        {['WEBSITE', "EMAIL", "PHONE", "DONATIONS", "NEWSLETTER", "CRYPTO", "BANK"].map((x) =>
                            <option value={x}>
                                <FormattedMessage id={`USER_INFO_CARD_TYPE_${x.toUpperCase()}`}/>
                            </option>
                        )}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="TITLE_LABEL"/>
                    </Form.Label>
                    <Form.Control
                        name="title"
                        value={title}
                        onChange={this.handleInputChange}
                        required
                        type="text"
                        placeholder=""
                    />
                    <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="VALUE_LABEL"/>
                    </Form.Label>
                    <Form.Control
                        name="value"
                        value={value}
                        onChange={this.handleInputChange}
                        required
                        type="text"
                        placeholder=""
                    />
                    <Form.Control.Feedback><FormattedMessage id="LOOKS_GOOD_FEEDBACK"/></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="TEXT_LABEL"/>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        name="text"
                        value={text}
                        onChange={this.handleInputChange}
                        size="lg"
                        required
                    />
                </Form.Group>

                <div className="col-12 d-flex justify-content-end pt-3">
                    <Button onClick={this.handleCancelNewCard} className="me-3" variant="secondary">
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button variant="primary" type="submit">
                        <FormattedMessage id="ADD_BTN"/>    
                    </Button>  
                </div>

            </Form>
        )
    }

    renderInfoCards() {
        var { cards, open } = this.state
        return (
            <div className="w-100">
                { cards.length >= this.maxCardsAllowed &&
                    <div class="alert alert-warning d-flex mb-4">
                        <p class="mb-0">
                            <FormattedMessage id="MAXIMUM_CARDS_ALLOWED_ALERT"/>
                        </p>
                    </div>
                }
                { cards.length < this.maxCardsAllowed &&
                    <div className="card h-100 justify-content-center align-items-center border-dashed rounded-3 py-5 px-3 px-sm-4">
                        { open == false &&         
                        <Button
                            disabled={cards.length >= this.maxCardsAllowed}
                            variant="link"
                            onClick={(e) => {
                                this.setState((state) => {
                                    return {open: !state.open
                                    }
                                })
                            }}
                            className="stretched-link d-flex align-items-center fw-semibold text-decoration-none">
                            <i className="bi bi-plus-circle fs-xl me-2"></i>
                            {' '}<FormattedMessage id="ADD_NEW_CARD_BTN"/>
                        </Button>
                        }
                        <Collapse in={open}>
                            <div className="w-100">
                                {this.renderForm()}
                            </div>
                        </Collapse>
                    </div>
                }

                { cards.map((card, index) =>
                    <UserInfoCardComponent
                        info={card}
                        isEditing={true}
                        onRemove={(e) => this.handleRemoveCard(e, index)}
                    />
                )}
            </div>
        )
    }

    renderAddBadge() {
        return (
            <Dropdown
                onSelect={(occupationCode) => {this.handleAddOccupation(occupationCode) }}
                className="badge d-flex p-2 align-items-center text-bg-primary rounded-pill text-white mt-3 ms-3">
                <Dropdown.Toggle className="btn-icon rounded-circle" size="xs" as={Button} id="dropdown-custom-components">
                    <i className="bi bi-plus fs-lg"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{width: "300px"}} className="shadow-sm" as={OccupationsDropdownMenu}>
                { this.allOccupations.map((x) => (
                    <Dropdown.Item className="n-lines-1" eventKey={x.code}>
                        {x.title}
                    </Dropdown.Item>
                ))}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    renderOccupationBadges() {
        var { occupations } = this.state
        return (
            <div className="d-flex flex-wrap mt-n3 ms-n3">
                {this.renderAddBadge() }    
                { occupations.map((occupation, i) =>
                    <span className="badge d-flex p-2 align-items-center text-bg-primary rounded-pill text-white mt-3 ms-3 n-lines-1">
                        <span className="px-1 fs-xs n-lines-1">{occupation.title}</span>
                        <Button className="text-white btn-icon rounded-circle" size="xs" onClick={(e) => this.handleRemoveOccupation(e, occupation)}>
                            <i className="bi bi-x-circle-fill"></i>
                        </Button>
                    </span>
                )}
            </div>
        )
    }
 
    render() {
        var { cards, occupations } = this.state

        return (
            <div>
                <div className="p-5">
                    <h4>
                        <FormattedMessage
                            id="OCCUPATIONS_FORMAT"
                            values={{
                                count: occupations.length
                            }}
                        />
                    </h4>
                    { this.renderOccupationBadges() }
                </div>
                <hr/>
                <div className="p-5">
                    <h4>
                        <FormattedMessage
                            id="INFO_CARDS_FORMAT"
                            values={{
                                count: cards.length
                            }}
                        />
                    </h4>
                    { this.renderInfoCards() }
                </div>
            </div>
        )
    }
}

export default WorkPane