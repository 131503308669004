import UserNotification from '../../models/common/UserNotification'
import Pagination from '../../models/common/Pagination'
import User from '../../models/User'
import AuthService from '../../services/AuthService'
import PasswordConfirmationPolicy from '../../policies/PasswordConfirmationPolicy'
import { format, formatISODuration, intervalToDuration, isThisMonth, isToday, isTomorrow, isYesterday, isThisWeek } from 'date-fns'
import Order from '../../models/common/Order'
import ChatRoom from '../../models/chat/ChatRoom'
import DownloadPostService from '../../services/DownloadPostService'
import ReportingService from '../../services/ReportingService'
import Report from '../../models/Report'
import UploadImageService from '../../services/UploadImageService'
import axios from 'axios';
import CalendarEvent from '../../models/event/CalendarEvent'
import { Link, NavLink } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import NavDrawer from '../../components/NavDrawer'
import navItems from '../../assets/navItems.json'
import Logo from '../../assets/logo.png'
import { FormattedMessage, useIntl } from 'react-intl'
import DownloadCalendarEventService from '../../services/DownloadCalendarEventService'
import { isLastMonth, isLastWeek, isNextMonth, isNextWeek } from '../Events/events-fns'
import UpdateUserInfoCardsService from '../../services/UpdateUserInfoCardsService'
import CookiesService from '../../services/CookiesService'
import AppearanceService from '../../services/AppearanceService'
import SendFeedbackService from '../../services/SendFeedbackService'
import Moderator from '../../models/Moderator'
import SetLocalizedAssetsService from '../../services/SetLocalizedAssetsService'
import GetAssetFromStorageService from '../../services/GetAssetFromStorageService'

export function handleSignOut(onBehalfOfComponent) {
  let auth = new AuthService();
  auth.signOut()
  .then(() => {
    onBehalfOfComponent.props.authStateChanged(null);
  })
}

export function handleInputChange(onBehalfOfComponent, e) {
  const target = e.target
  const value = target.type === 'checkbox' ? target.checked : target.value
  const name = target.name
  onBehalfOfComponent.setState({
    [name]: value
  })
}

export function setHasMore(pagination, newItemCount) {
  return pagination ? newItemCount >= pagination.limit : false
}

export function setNextPagination(pagination, newPostsCount) {
  if (newPostsCount === 0) {
      return undefined
  }
  let nextOffset = pagination.offset + pagination.limit
  return new Pagination(nextOffset, pagination.limit)
}

export function handleNewChat(onBehalfOfComponent) {
  var { currentUser } = onBehalfOfComponent.props
  if (currentUser === null) {
    return
  }

  var createNotification = onBehalfOfComponent.props.createNotification

  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'chatModal',
    payload: {
      currentUser,
      requestNextContacts: (pagination, order) => {
        return currentUser.getContacts(pagination, order) 
      }
    },
    onAction(action, response) {
      if (action === 'cancel') {
        return
      }
      var { selected } = response
      let participants = [...selected]

      ChatRoom
      .findOrCreate([currentUser, ...participants])
      .then((chatRoom) => {
        onBehalfOfComponent.props.navigate(`/chats/${chatRoom.roomId}`)
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "CHAT_CREATED_NOTIFICATION"}),
          object: chatRoom,
          userInfo: {}
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: {}
        }))
      })
    }
  })
}

// See: https://stackoverflow.com/questions/6860853/generate-random-string-for-div-id
export function guidGenerator() {
  var S4 = function() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export function handleNewPost(onBehalfOfComponent) {
  var { currentUser } = onBehalfOfComponent.props
  if (currentUser === null) {
    return
  } 

  var guid = guidGenerator()

  var createNotification = onBehalfOfComponent.props.createNotification
  var uploadImage = new UploadImageService({guid});
  
  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'postModal',
    payload: {
      title: onBehalfOfComponent.props.intl.formatMessage({id: 'CREATE_POST_MODAL_TITLE'}),
      submitBtnTitle: onBehalfOfComponent.props.intl.formatMessage({id: 'CREATE_BTN'}),
      currentUser,
      uploadImage
    },
    onAction(action, response) {
      if (action === 'cancel') {
        return
      }
      
      var { blocks } = response

      let deleteSpecification = null
      currentUser.createPost(guid, blocks, deleteSpecification)
      .then((newPost) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: 'POST_WAS_CREATED_NOTIFICATION'}),
          object: newPost,
          userInfo: {
            type: 'POST_CREATED',
            /*link: {
              to: `/me/${newPost.author.username}/post/${newPost.id}`,
              text: 'View'
            }*/
          }
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: {}
        }))
      })
    }
  })
}

export function handleNewEvent(onBehalfOfComponent) {
  var { currentUser } = onBehalfOfComponent.props
  if (currentUser === null) {
    return
  }

  var createNotification = onBehalfOfComponent.props.createNotification

  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'eventModal',
    payload: {
      title: onBehalfOfComponent.props.intl.formatMessage({id: 'NEW_EVENT_MODAL_TITLE'}),
      submitBtnTitle: onBehalfOfComponent.props.intl.formatMessage({id: 'CREATE_BTN'}),
      currentUser,
      getSizes: CalendarEvent.getSizes,
      getTypes: CalendarEvent.getTypes,
      getSubtypes: CalendarEvent.getSubtypes
    },
    onAction(action, response) {
      if (action === 'cancel') {
        return
      }
      
      var { title, address, startDate, endDate,
        url, isOnline, description, timezone, size, type, subtype } = response
      
      let duration = formatISODuration(intervalToDuration({start: startDate, end: endDate}))

      currentUser.createEvent({title, startDate, address, duration, invited: [],
        url, isOnline, description, timezone, size, type, subtype})
        .then((newEvent) => {
          createNotification(new UserNotification({
            name: onBehalfOfComponent.props.intl.formatMessage({id: 'EVENT_WAS_CREATED_NOTIFICATION'}),
            object: newEvent,
            userInfo: {
              type: 'EVENT_CREATED'
            }
          }))
        })
        .catch((error) => {
          createNotification(new UserNotification({
            name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
            object: error,
            userInfo: {}
          }))
        })
    }
  })
}

export function handleEditEvent(onBehalfOfComponent, event) {
  var { currentUser } = onBehalfOfComponent.props
  if (currentUser === null) {
    return
  }

  var canEdit = currentUser.id === event.author.id;
  if (canEdit === false) {
    return
  }

  var createNotification = onBehalfOfComponent.props.createNotification
  
  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'eventModal',
    payload: {
      title: onBehalfOfComponent.props.intl.formatMessage({id: 'EDIT_EVENT_MODAL_TITLE'}),
      submitBtnTitle: onBehalfOfComponent.props.intl.formatMessage({id: 'UPDATE_BTN'}),
      currentUser,
      event,
      getSizes: CalendarEvent.getSizes,
      getTypes: CalendarEvent.getTypes,
      getSubtypes: CalendarEvent.getSubtypes
    },
    onAction(action, response) {
      if (action === 'cancel') {
        return
      }

      var { startDate, endDate, timezone, description, invited, address, title, isOnline,
        url, size, type, subtype } = response

      let duration = formatISODuration(intervalToDuration({start: startDate, end: endDate}))
      
      currentUser.editEvent(event, {
        startDate, timezone, description, invited, address, title, isOnline,
        url, duration, size, type, subtype
      })
      .then((newEvent) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: 'EVENT_WAS_EDITED_NOTIFICATION'}),
          object: newEvent,
          userInfo: {
            type: 'EVENT_EDITED'
          }
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: {}
        }))
      })
    }
  })
}

export function handlePreferences(onBehalfOfComponent) {
  var { currentUser } = onBehalfOfComponent.props
  var createNotification = onBehalfOfComponent.props.createNotification
  
  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'preferencesModal',
    payload: { currentUser },
    onAction(action, response) {
      if (action === 'cancel') {
        return
      }
      var { sectionName, data } = response
      switch (sectionName) {
        case 'theme':
        {
          let cookies = new CookiesService()
          let appearance = new AppearanceService()

          var { theme } = data

          var options = ['dark', 'light', 'auto']
          if (options.includes(theme) == false) {
            return
          }
          
          cookies.set('theme', theme, 30)
          appearance.setTheme(document, theme)

        }
          break
          
        case 'primaryColor':
        {
          var { primaryColor } = data
          document.documentElement.style.setProperty('--ar-primary', primaryColor.toLowerCase())
        }
          break
        
        case 'userInfoCards':
        {
          let { cards } = data
          
          let updateUserInfoCards = new UpdateUserInfoCardsService()
          updateUserInfoCards.forCurrentUser(currentUser, cards)
          .then(() => {
            createNotification(new UserNotification({
              name: onBehalfOfComponent.props.intl.formatMessage({id: "USER_INFO_CARDS_UPDATED_NOTIFICATION"}),
              object: cards,
              userInfo: {
                type: "UPDATED_USER_INFO_CARDS"
              }
            }))
          })
          .catch((error) => {
            createNotification(new UserNotification({
              name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
              object: error,
              userInfo: {}
            }))
          })
        }
          break

        case 'occupations':
        {
          let { occupations } = data

          User.update(currentUser, {
            occupationCodes: occupations.map((x) => parseInt(x.code))
          })
          .then(() => {
            currentUser.occupationCodes = [...occupations.map((x) => parseInt(x.code))]
            onBehalfOfComponent.props.authStateChanged(currentUser)
            createNotification(new UserNotification({
              name: onBehalfOfComponent.props.intl.formatMessage({id: "UPDATED_USER_OCCUPATIONS_NOTIFICATION"}),
              object: occupations,
              userInfo: {
                type: "UPDATED_USER_OCCUPATIONS"
              }
            }))
          })
          .catch((error) => {
            createNotification(new UserNotification({
              name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
              object: error,
              userInfo: {}
            }))
          })
        }
          break

        case 'basicInfo':
        {
          let locale = data.languageCode ?? 'en'
          let forceUpdate = true
          let setLocalizedAssets = new SetLocalizedAssetsService()
          return setLocalizedAssets.forLocale(locale, new GetAssetFromStorageService(), forceUpdate)
          .then(() => {
            return User
            .update(currentUser, data)
          })
          .then((user) => {
            onBehalfOfComponent.props.authStateChanged(user)
            createNotification(new UserNotification({
              name: "Profile was updated.",
              object: null,
              userInfo: {}
            }))
          })
          .catch((error) => {
            createNotification(new UserNotification({
              name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
              object: error,
              userInfo: {}
            }))
          })
        }
          break

        case 'passwordChange':
        {
          var { currentPassword, newPassword, newPasswordConfirmation } = data

          if (new PasswordConfirmationPolicy().ensureSame(newPassword, newPasswordConfirmation) === false) {
            return
          }

          let auth = new AuthService()
          
          auth.changePassword(currentUser, currentPassword, newPassword)
          .then(() => {
            createNotification(new UserNotification({
              name: onBehalfOfComponent.props.intl.formatMessage({id: "PASSWORD_CHANGED_NOTIFICATION"}),
              object: null,
              userInfo: {}
            }))
          })
          .catch((error) => {
            createNotification(new UserNotification({
              name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
              object: error,
              userInfo: {}
            }))
          })
        }
          break

        case 'deleteAccount':
        {
          var { currentPassword } = data

          currentUser.deleteAccount(currentPassword)
          .then(() => {
            onBehalfOfComponent.props.authStateChanged(null)
            onBehalfOfComponent.props.navigate('/login')
            createNotification(new UserNotification({
              name: "Account was deleted.",
              object: null,
              userInfo: {}
            }))
          })
          .catch((error) => {
            createNotification(new UserNotification({
              name: error.message,
              object: error,
              userInfo: {}
            }))
          })
        }
         break
      }
    }
  })
}

export function handleDeletePost(onBehalfOfComponent, post) {
    var { currentUser } = onBehalfOfComponent.props;
    var createNotification = onBehalfOfComponent.props.createNotification;

    onBehalfOfComponent.props.setModal({
      show: true,
      id: 'confirmDeleteModal',
      payload: post,
      onAction(action, response) {
        if (action == 'cancel') {
          return
        }
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: 'POST_DELETING_NOTIFICATION'}),
          object: null,
          userInfo: {}
        }))
        currentUser.deletePost(post)
        .then(() => {
          createNotification(new UserNotification({
            name: onBehalfOfComponent.props.intl.formatMessage({id: 'POST_WAS_DELETED_NOTIFICATION'}),
            object: post,
            userInfo: {
              type: 'POST_DELETED'
            }
          }))
        })
        .catch((error) => {
          createNotification(new UserNotification({
            name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
            object: null,
            userInfo: {}
          }))
        })
      }
    })
}

export function handleDeleteEvent(onBehalfOfComponent, calendarEvent) {
  var { currentUser } = onBehalfOfComponent.props;
  var createNotification = onBehalfOfComponent.props.createNotification;

  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'confirmDeleteModal',
    payload: calendarEvent,
    onAction(action, response) {
      if (action == 'cancel') {
        return
      }
      createNotification(new UserNotification({
        name: onBehalfOfComponent.props.intl.formatMessage({id: 'EVENT_DELETING_NOTIFICATION'}),
        object: null,
        userInfo: {}
      }))
      currentUser.deleteEvent(calendarEvent)
      .then(() => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: 'EVENT_WAS_DELETED_NOTIFICATION'}),
          object: calendarEvent,
          userInfo: {
            type: "EVENT_DELETED"
          }
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: null,
          userInfo: {}
        }))
      })
    }
  })
}

export function handleReplyToPost(onBehalfOfComponent, post, deleteSpecification) {
  var { currentUser } = onBehalfOfComponent.props
  if (currentUser === null) {
    return
  } 

  var guid = guidGenerator()

  var createNotification = onBehalfOfComponent.props.createNotification
  var uploadImage = new UploadImageService({guid});
  
  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'postModal',
    payload: {
      title: onBehalfOfComponent.props.intl.formatMessage({id: 'REPLY_TO_USER_FORMAT'}, {
        user: post.author.firstName + ' ' + post.author.lastName
      }),
      submitBtnTitle: onBehalfOfComponent.props.intl.formatMessage({id: 'REPLY_BTN'}),
      currentUser,
      uploadImage,
      post
    },
    onAction(action, response) {
      if (action === 'cancel') {
        return
      }
      
      var { blocks } = response

      currentUser.replyToPost(post, guid, blocks, deleteSpecification)
      .then((newReply) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: 'REPLY_WAS_CREATED_NOTIFICATION'}),
          object: newReply,
          userInfo: {
            type: 'REPLY_CREATED'
            /*link: {
              to: `/me/${newReply.author.username}/post/${newReply.id}`,
              text: 'View'
            }*/
          }
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: {}
        }))
      })
    }
  })
}

export function handleEditPost(onBehalfOfComponent, post) {
  var { currentUser } = onBehalfOfComponent.props
  if (currentUser === null) {
    return
  }

  var canEdit = currentUser.id === post.author.id;
  if (canEdit === false) {
    return
  }

  let fragments = post.downloadURL.split('/')
  var guid = fragments[fragments.length - 2]

  var createNotification = onBehalfOfComponent.props.createNotification
  var uploadImage = new UploadImageService({guid})
  var getBlocks = axios.get(post.blocksURL)
  
  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'postModal',
    payload: {
      title: onBehalfOfComponent.props.intl.formatMessage({id: 'EDIT_POST_MODAL_TITLE'}),
      submitBtnTitle: onBehalfOfComponent.props.intl.formatMessage({id: 'UPDATE_BTN'}),
      currentUser,
      uploadImage,
      post,
      getBlocks
    },
    onAction(action, response) {
      if (action === 'cancel') {
        return
      }
      
      var { blocks } = response

      let deleteSpecification = null
      
      currentUser.editPost(post, guid, blocks, deleteSpecification)
      .then((editedPost) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: 'POST_WAS_EDITED_NOTIFICATION'}),
          object: editedPost,
          userInfo: {
            type: 'POST_EDITED',
            /*link: {
              to: `/me/${editedPost.author.username}/post/${editedPost.id}`,
              text: 'View'
            }*/
          }
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: {}
        }))
      })
    }
  })
}

export function handleReactToPost(onBehalfOfComponent, emoji, post) {
    var { currentUser } = onBehalfOfComponent.props
    if (currentUser === null) {
        return
    }
    return currentUser.reactToPost(post, emoji)
}

export function handleSharePost(onBehalfOfComponent, action, post) {
  var createNotification = onBehalfOfComponent.props.createNotification;
  let link = `${window.location.host}/me/${post.author.username}/post/${post.id}`

  switch (action) {
    case 'COPY_LINK':
      navigator.clipboard.writeText(link);
      createNotification(new UserNotification({
        name: onBehalfOfComponent.props.intl.formatMessage({id: "SAVED_TO_CLIPBOARD_NOTIFICATION"}),
        object: link,
        userInfo: { link: null }
      }));
      break;

    case 'EMAIL':
      let email = `mailto:?body=${encodeURI(link)}`
      window.open(email, '_blank');
      break;

    case 'SAVE_POST':
      let downloadPost = new DownloadPostService()
      downloadPost.inWindow(window, post)
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: { link: null }
        }));
      })
      break;
  
    default:
      break;
  }
}

// NOTE: TURN THIS INTO A REPORT MODAL OPENING.
export function handleReport(onBehalfOfComponent, objectId, objectType) {
  var { currentUser } = onBehalfOfComponent.props;
  var createNotification = onBehalfOfComponent.props.createNotification;

  if (currentUser == null) {
    return
  }

  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'reportModal',
    payload: {
      reasons: [
        "SEXUAL_CONTENT",
        "SPAM",
        "INCITEMENT_TO_VIOLENCE",
        "OTHER"
      ]
    },
    onAction(action, response) {
      if (action == 'cancel') {
        return
      }

      var { reason, description } = response

      var report = new Report({
        reason, description, userId: currentUser.id,
        objectType, objectId
      })

      var moderatorPromise = Promise.resolve()
      report.isEnforced = currentUser.isModerator

      if (currentUser.isModerator) {
          let moderator = new Moderator(currentUser)
          moderatorPromise = moderator.removeContent(objectType, objectId)
      }

      moderatorPromise
      .then(() => {
        let reportingService = new ReportingService()
        reportingService.addReport(report)
      })
      .then(() => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "REPORT_WAS_ADDED_NOTIFICATION"}),
          object: report,
          userInfo: {}
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: {}
        }))
      })
    }
  })
}

export function handleEventDetails(onBehalfOfComponent, calendarEvent) {
  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'eventDetailsModal',
    payload: { calendarEvent },
    onAction(action) {
      if (action == 'cancel') {
        return
      }
      let downloadCalendarEvent = new DownloadCalendarEventService()
      let url = downloadCalendarEvent.inWindow(window, calendarEvent)
    }
  })
}

export function handleFeedack(onBehalfOfComponent) {
  var { currentUser } = onBehalfOfComponent.props;
  var createNotification = onBehalfOfComponent.props.createNotification;

  if (currentUser == null) {
    return
  }
  onBehalfOfComponent.props.setModal({
    show: true,
    id: 'feedbackModal',
    payload: { currentUser },
    onAction(action, response) {
      if (action == 'cancel') {
        return
      }
      
      var { text } = response
      let sendFeedback = new SendFeedbackService()
      sendFeedback.fromUser(currentUser, text)
      .then(() => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "FEEDBACK_WAS_ADDED_NOTIFICATION"}),
          object: text,
          userInfo: {}
        }))
      })
      .catch((error) => {
        createNotification(new UserNotification({
          name: onBehalfOfComponent.props.intl.formatMessage({id: "GENERIC_ERROR_MESSAGE"}),
          object: error,
          userInfo: {}
        }))
      })
    }
  })
}

export function createNavDrawerAuthOptions() {
  return (
    <div className="d-flex flex-column bd-highlight mb-3">
        <div className="py-2 bd-highlight">
          <NavLink as="a" to="/login" className="">
            <FormattedMessage id="LOGIN_BTN"/>
          </NavLink>
        </div>
        <div className="py-2 bd-highlight">
          <NavLink to="/signup" type="button" className="btn btn-primary">
            <FormattedMessage id="SIGNUP_BTN"/>
          </NavLink>
        </div>
      </div>
  )
}

export function createNavDrawerFooter({currentUser, onBehalfOfComponent, onPreferences, onSignOut}) {
    if (currentUser) {
      return (
        <NavDrawer.Footer>
          <NavDrawer.Profile
              to={`/me/${currentUser.username}`}
              photoURL={currentUser.photoURL}
              photoAlt={currentUser.firstName + ' ' + currentUser.lastName}
              title={currentUser.firstName + ' ' + currentUser.lastName}
              subtitle={'@' + currentUser.username}
              moreDropdownMenu={
                <Dropdown.Menu className="shadow-sm">
                    <Dropdown.Item onClick={(e) => onBehalfOfComponent.props.navigate("/me/" + currentUser.username)}>
                      <FormattedMessage id="PROFILE_DROPDOWN_ITEM"/>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(e) => onPreferences(onBehalfOfComponent)}>
                      <FormattedMessage id="PREFERENCES_DROPDOWN_ITEM"/>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item variant="danger" onClick={(e) => onSignOut(onBehalfOfComponent)} className="dropdown-item-danger">
                        <FormattedMessage id="SIGN_OUT_DROPDOWN_ITEM"/>
                    </Dropdown.Item>
                </Dropdown.Menu>
            }/>
          </NavDrawer.Footer>
      )
    } else {
      return (
        <NavDrawer.Footer>
          {createNavDrawerAuthOptions()}
        </NavDrawer.Footer>
      )
    }
}

export function createNavDrawerBrand({currentUser, logo}) {
  return (
    <NavDrawer.Brand
        subscript={currentUser && currentUser.countryCode.toUpperCase()}
        to="/">
        <img
          style={{ "position": "relative", "left": "-5px" }}
          src={logo}
          width="35"
          height="35"/>
      </NavDrawer.Brand>
  )
}

export function createNavDrawerList({currentUser, navItems}) {
  return (
    <NavDrawer.List>
        { navItems.map((item) => {
          if (item.id === 'profile') {
            return (
              <NavDrawer.Item
                key={item.id}
                className="text-dark px-0 nav-link fw-regular"
                activeClassName="text-dark px-0 nav-link fw-bold"
                icon={item.icon.normal}
                activeIcon={item.icon.active}
                to={currentUser ? `/me/${currentUser.username}` : '/login'}>
                <FormattedMessage id={`NAV_DRAWER_ITEM_${item.id.toUpperCase()}`} />
            </NavDrawer.Item>
            )
          } else {
            return (
              <NavDrawer.Item
                key={item.id}
                className="text-dark px-0 nav-link fw-regular"
                activeClassName="text-dark px-0 nav-link fw-bold"
                icon={item.icon.normal}
                activeIcon={item.icon.active}
                to={item.to}>
                <FormattedMessage id={`NAV_DRAWER_ITEM_${item.id.toUpperCase()}`} />
            </NavDrawer.Item>
            )
          }
          
        })}
    </NavDrawer.List>
  )
}

export function createNavDrawerAction({onBehalfOfComponent, onNewPost, onNewChat, onNewEvent}) {
  return (
    <NavDrawer.Action>
      <Dropdown>
          <Dropdown.Toggle
            className={"rounded-pill btn-icon w-100"}
            style={{ "font-size": "1.0em" }}
            variant="primary"
            size="lg">
              <i className="d-none d-md-none d-lg-block d-xl-none fw-bold bi bi-plus fs-xl pe-xl-2"></i>
              <span className="d-lg-none d-xl-block"><FormattedMessage id="NEW_BTN"/></span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-sm">
            <Dropdown.Item onClick={(e) => onNewPost(onBehalfOfComponent)}>
              <FormattedMessage id="NEW_POST_DROPDOWN_ITEM"/>
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => onNewChat(onBehalfOfComponent)}>
              <FormattedMessage id="NEW_CHAT_DROPDOWN_ITEM"/>
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => onNewEvent(onBehalfOfComponent)}>
              <FormattedMessage id="NEW_EVENT_DROPDOWN_ITEM"/>
            </Dropdown.Item>
          </Dropdown.Menu>
      </Dropdown>
    </NavDrawer.Action>
  )
}

export function createMobileNavDrawer({currentUser, onBehalfOfComponent, onNewPost, onNewChat, onNewEvent, onPreferences, onSignOut}) {
    return (
      <NavDrawer isMobile={true}>
        {createNavDrawerList({currentUser, navItems})}
        {currentUser && createNavDrawerAction({onBehalfOfComponent, onNewPost, onNewChat, onNewEvent})}
        {createNavDrawerFooter({currentUser, onBehalfOfComponent, onPreferences, onSignOut})}
      </NavDrawer>
    )
}

export function getFilterRegionCode(regionCode, currentUser, fallbackRegionCode) {
  var filterRegionCode = undefined

  if (regionCode) {
    filterRegionCode = regionCode
  } else if (currentUser && currentUser.regionCode) {
      filterRegionCode = currentUser.regionCode
  } else if (currentUser && currentUser.countryCode) {
      filterRegionCode = currentUser.countryCode
  } else if (fallbackRegionCode != null) {
      filterRegionCode = fallbackRegionCode
  }

  if (filterRegionCode === undefined) {
      return ""
  }

  return filterRegionCode.toUpperCase()
}

export function getMatchingLabelForEvent(onBehalfOfComponent, event) {
  var label = undefined
  if (isLastMonth(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_LAST_MONTH'})
  } else if (isLastWeek(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_LAST_WEEK'})
  } else if (isYesterday(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_YESTERDAY'})
  } else if (isToday(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_TODAY'})
  } else if (isTomorrow(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_TOMMORROW'})
  } else if (isThisWeek(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_THIS_WEEK'})
  } else if (isNextWeek(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_NEXT_WEEK'})
  } else if (isThisMonth(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_THIS_MONTH'})
  } else if (isNextMonth(new Date(event.startDate))) {
      label = onBehalfOfComponent.props.intl.formatMessage({id: 'CALENDAR_LABEL_NEXT_MONTH'})
  } else {
      label = format(new Date(event.startDate), "MMMM")
  }
  return label
}