import { createClient } from '@supabase/supabase-js'
import Post from "../../models/Post"
import CachePolicy from '../../policies/CachePolicy';
import {convertBlocksToHTML, uploadHTML, uploadBlocks, generatePreview, generateEntites, generateTitle} from './post-factory-fns'

/**
 * A factory for the Post model.
 * Handles the start of the Post model lifecycle.
 */
class PostFactory {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    /**
     * Creates a post
     * @param {array} blocks - EditorJS blocks array
     * @param {User} author - An author of the post
     * @param {object} data - A key-value object with post data
     * @return {Post} Returns a Promise with an instantiated post or rejects with an error.
     */
    create(guid, blocks, author, data) {
        /* check minLength and maxLength for markdown
        and reject with error if need be.*/

        var downloadURL = undefined
        var blocksURL = undefined

        let html = convertBlocksToHTML(blocks)

        return Promise.all([
            uploadHTML(this.supabase, guid, html, author, new CachePolicy()),
            uploadBlocks(this.supabase, guid, blocks, author, new CachePolicy())
        ])
        .then(([_downloadURL, _blocksURL]) => {
            downloadURL = _downloadURL
            blocksURL = _blocksURL
            let fns = [generatePreview, generateEntites, generateTitle]
            return Promise.all(fns.map((f) => f(blocks)))
        })
        .then((values) => {
            let keys = ['preview', 'entities', 'title']
            var results = keys.reduce((acc, item, i) => {
                acc[item] = values[i]
                return acc
            }, {})

            return new Post({
                downloadURL,
                blocksURL,
                author,
                ...results,
                ...data
            })
        })
    }
}

export default PostFactory