import React, { Component } from "react";
import { Modal, Button, ListGroup, Spinner, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class FeedbackModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            text: ""
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
    }

    handleSubmit(e) {
        e.preventDefault()
        const form = e.currentTarget
        
        this.setState({isValidated: true})

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }

        var { text } = this.state
        if (text.length === 0) {
            return
        }
        this.props.onSubmit(e, { text })
    }

    render() {
        var { title, show } = this.props
        var { text, isValidated } = this.state
        return (
            <Modal
                fullscreen="md-down"
                scrollable={true}
                show={show}
                onHide={this.props.onHide}
                onExited={this.props.onExited}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Form id="feedbackForm" onSubmit={this.handleSubmit} noValidate validated={isValidated}>
                        <Form.Label>
                            <FormattedMessage id="FEEDBACK_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            name="text"
                            value={text}
                            onChange={this.handleInputChange}
                            size="lg"
                            rows={9}
                            required
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide} className="me-2" variant="secondary">
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button onClick={this.handleSubmit} disabled={text.length === 0} form="feedbackForm" type="submit" variant="primary">
                        <FormattedMessage id="SEND_BTN"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default FeedbackModal