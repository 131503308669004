import React, { Component } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import { FormattedNumber, FormattedPlural } from 'react-intl'
import { Link } from 'react-router-dom'
import FormatRegionCodeService from '../../services/FormatRegionCodeService'
import Avatar from '../Avatar'

class PopularPostsCard extends Component {
    constructor(props) {
        super(props)

        // this shouldn't be here. this is the UI layer.
        this.formatRegionCode = new FormatRegionCodeService()
    }

    render() {
        var { title, posts, className, listItems } = this.props
        title = title || 'Popular posts'
        className = className || " my-3 card border-0 bg-secondary"
        listItems = listItems || []
        return (
            <Card className={className}>
                <Card.Body>
                    <h5 className="fw-bold card-title">{title}</h5>
                    <ListGroup className="border-top-0 border-bottom-0 w-100" variant="flush">
                    { this.props.listItems}
                    </ListGroup>
                </Card.Body>
            </Card>
        )
    }
}

export default PopularPostsCard