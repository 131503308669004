import ChatRoomRepository from "../../../repositories/chat/ChatRoomRepository"

class ChatRoom {
    constructor({roomId, createdAt, updatedAt, deletedAt, whitelisted, participants}) {
        this.roomId = roomId || undefined
        this.createdAt = createdAt || new Date()
        this.updatedAt = updatedAt || new Date()
        this.deletedAt = deletedAt || null
        this.whitelisted = whitelisted || participants.map((participant) => participant.id)
        this.participants = participants
        // code -> to enter the chatroom?
        // name -> a specific name for the chatroom.
    }

    static findOrCreate(participants, whitelisted) {
        let chatRoom = new ChatRoom({participants, whitelisted})
        return ChatRoomRepository.getRepoState().findOrCreate(chatRoom)
    }

    static update(roomId, data) {
        return ChatRoomRepository.getRepoState().update(roomId, data)
    }

    static findByRoomId(roomId, whitelisted) {
        return ChatRoomRepository.getRepoState().findByRoomId(roomId, whitelisted)
    }

    static findByUserId(userId, pagination, order) {
        return ChatRoomRepository.getRepoState().findByUserId(userId, pagination, order)
    }
}

export default ChatRoom