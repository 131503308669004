const { createClient } = require('@supabase/supabase-js')

class DownloadAttachmentService {
    constructor() {
        this.supabase = createClient('https://bspodttrfjifboigqtfv.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc')
    }

    inWindow(window, attachment) {
        return this.supabase
        .storage
        .from('chat_rooms')
        .download(attachment.relativePath)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            const url = window.URL.createObjectURL(new Blob([data]));
            
            const link = document.createElement('a');
            link.download = attachment.name;
            link.href = url;

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            return url
        })
    }
}

export default DownloadAttachmentService