import React, { Component } from 'react'
import './index.scss'
import NavDrawerItem from './NavDrawerItem'
import NavDrawerList from './NavDrawerList'
import NavDrawerBrand from './NavDrawerBrand'
import NavDrawerAction from './NavDrawerAction'
import NavDrawerFooter from './NavDrawerFooter'
import NavDrawerProfile from './NavDrawerProfile'

class NavDrawer extends Component {
  static Brand = NavDrawerBrand
  static Item = NavDrawerItem
  static List = NavDrawerList
  static Action = NavDrawerAction
  static Footer = NavDrawerFooter
  static Profile = NavDrawerProfile

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    var { isMobile } = this.props
    var isMobile = isMobile ?? false

    var className = "sidebar-sticky sidebar-pills nav-sidebar"
    var innerClassName = "nav-sidebar-container d-flex flex-column"
    var style = {}
    var innerStyle = {}

    if (isMobile) {
      className += ' mh-100 px-0 py-0'
      innerClassName += " h-100"
    } else {
      className += ' d-none d-lg-block col px-0'
      innerClassName += ' border-end p-3 container'
      style = { width: "90px", maxWidth: "275px" }
      innerStyle = {height: '100vh'}
    }

    return (
      <div className={className}>
        <div className={innerClassName} style={innerStyle}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default NavDrawer;
