import { createClient } from '@supabase/supabase-js';

class IncrementPostMetricService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey);
    }

    withArgs({postId, fieldName, x}) {
        let validFieldNames = ['views', 'replies', 'reactions']
        if (validFieldNames.includes(fieldName) === false) {
            return Promise.reject(
                new Error(`fieldName/columnName '${fieldName}' is not suppored in Metrics value objects table.`)
            )
        }
        return this.supabase.rpc("increment_post_metric", {
            "field_name": fieldName,
            "post_id": postId,
            "x": x
        })
        .then((response) => {
            var { error } = response
            if (error) {
                return Promise.reject(error)
            }
            return true
        })
    }
}

export default IncrementPostMetricService