import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { createReactEditorJS } from 'react-editor-js'
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
// import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
// import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
// import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import { FormattedMessage } from "react-intl";

const ReactEditorJS = createReactEditorJS()

class PostModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            blocks: [],
            error: null
        }

        this.editorCore = React.createRef(null)

        this.setupTools = this.setupTools.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    setupTools() {
        var { currentUser, uploadImage } = this.props;
        return {
            embed: {
              class: Embed,
              inlineTool: true,
              config: {
                services: {
                  facebook: true,
                  instagram: true,
                  youtube: true,
                  twitch: true,
                  miro: true,
                  vimeo: true,
                  gfycat: true,
                  aparat: true,
                  "Yandex.Music": true,
                  coub: true,
                  codepen: true,
                  pinterest: true,
                  rumble: {
                    regex: /https?:\/\/rumble\.com\/embed\/([^\/\?\&]+)\/\?pub=4/,
                    embedUrl: 'https://rumble.com/embed/<%= remote_id %>/',
                    html: "<iframe class='rumble' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>",
                    height: 300,
                    width: 600,
                    id: (groups) => groups.join('/')
                  },
                  bitchute: {
                    regex: /https?:\/\/www\.bitchute\.com\/video\/([^\/\?\&]*)\//,
                    embedUrl: 'https://bitchute.com/embed/<%= remote_id %>',
                    html: "<iframe scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>",
                    height: 300,
                    width: 600,
                    id: (groups) => groups.join('/')
                  },
                  odysee: {
                    regex: /https?:\/\/odysee\.com\/([^\/\?\&]*)\/([^\/\?\&]*)/,
                    embedUrl: 'https://odysee.com/$/embed/<%= remote_id %>?r=BhpAtxerT22M7gkDwKb5F6GpksoKYgq5',
                    html: "<iframe scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>",
                    height: 300,
                    width: 600,
                    id: (groups) => groups.join('/')
                  },
                 spotify: {
                    regex: /https?:\/\/open\.spotify\.com\/track\/([^\/\?\&]*)\/?.*/,
                    embedUrl: 'https://open.spotify.com/embed/track/<%= remote_id %>?utm_source=generator',
                    html: "<iframe scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>",
                    height: 300,
                    width: 600,
                    id: (groups) => groups.join('/')
                 }
                }
              }
            },
            table: Table,
            list: List,
            // warning: Warning,
            code: Code,
            linkTool: LinkTool,
            image: {
              class: Image,
              config: {
                uploader: {
                  uploadByFile(file) {
                    return uploadImage.forUser(currentUser, file)
                    .then((downloadURL) => {
                      return {
                        success: 1,
                        file: {
                          url: downloadURL,
                          size: file.size
                        }
                      };
                    });
                  }
                }
              }
            },
            // raw: Raw,
            header: Header,
            quote: Quote,
            marker: Marker,
            checklist: CheckList,
            delimiter: Delimiter,
            inlineCode: InlineCode,
            simpleImage: SimpleImage,
          }
    }

    componentDidMount() {
        var { getBlocks } = this.props
        this.setState({isLoading: true})
        if (getBlocks) {
          getBlocks.then((response) => {
            const blocks = response.data;
            this.setState({
              blocks,
              isLoading: false,
              error: null
            })
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              error: error
            })
          })
        } else {
          this.setState({isLoading: false})
        }
    }

    handleSubmit(e) {
        e.preventDefault()

        if (this.editorCore == null) {
            return
        }

        this.editorCore.current.save()
        .then((savedData) => {
            let blocks = savedData.blocks
            if (blocks.length == 0) {
              return
            }
            this.props.onSubmit(e, { blocks: savedData.blocks })
        })
    }

    renderEditor(blocks) {
      return (
        <ReactEditorJS
          holder="editorjs"
          defaultValue={{
            time: new Date().valueOf(),
            blocks: [...blocks],
          }}
          onInitialize={(instance) => this.editorCore.current = instance}
          tools={this.setupTools()}
        />
      )
    }

    render() {
        var { title, submitBtnTitle, show } = this.props
        var { blocks, isLoading } = this.state

        if (isLoading) {
          return <p>isLoading...</p>
        } 

        return (
            <Modal
                backdrop='static'
                size="lg"
                fullscreen="lg-down"
                scrollable={true}
                show={show}
                onHide={this.props.onHide}
                onExited={this.props.onExited}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={(ref) => { this.scrollParentRef = ref }} className="">
                    { isLoading == false && this.renderEditor(blocks) }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide} className="me-2" variant="secondary">
                      <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button onClick={this.handleSubmit} disabled={false} type="submit" variant="primary">{submitBtnTitle}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default PostModal