export default class AbstractSpecification {
    and(other) {
        return new AndSpecification(this, other)
    }

    or(other) {
        return new OrSpecification(this, other)
    }

    not() {
        return new NotSpecification(this)
    }
}

export class AndSpecification extends AbstractSpecification {
    constructor(x, y) {
        super()
        this.one = x
        this.other = y
    }

    isSatisfiedBy(candidate) {
        return this.one.isSatisfiedBy(candidate) &&
            this.other.isSatisfiedBy(candidate)
    }

    toJSON() {
        return {
            AND: [
                this.one.toJSON(),
                this.other.toJSON()
            ]
        }
    }
}

export class OrSpecification extends AbstractSpecification {
    constructor(x, y) {
        super()
        this.one = x
        this.other = y
    }

    isSatisfiedBy(candidate) {
        return this.one.isSatisfiedBy(candidate) ||
            this.other.isSatisfiedBy(candidate)
    }

    toJSON() {
       return {
            OR: [
                this.one.toJSON(),
                this.other.toJSON()
            ]
       }
    }
}

export class NotSpecification extends AbstractSpecification {
    constructor(x) {
        super()
        this.wrapped = x
    }

    isSatisfiedBy(candidate) {
        return this.wrapped.isSatisfiedBy(candidate) === false
    }

    toJSON() {
        return {
            NOT: this.wrapped.toJSON()
        }
    }
}