import React, { Component, Fragment } from "react"
import { useParams, useNavigate, useLocation, Link } from "react-router-dom"
import {startOfDay, startOfMonth, isToday, isTomorrow, isThisWeek, isYesterday, isThisMonth, format, formatISO, toDate, getTime, getMilliseconds} from 'date-fns'
import { today, isLastWeek, isLastMonth, isNextWeek, isNextMonth, getNextMonth, getPreviousMonth } from '../Events/events-fns'
import Dashboard from "../Dashboard"
import { ListGroup, Card, Alert, Spinner, Button, Tab, Nav, Dropdown } from "react-bootstrap"
import Post from "../../models/Post"
import Pagination from "../../models/common/Pagination"
import Order from "../../models/common/Order"
import User from "../../models/User"
import Criteria from "../../models/common/Criteria"

import PostCard from "../../components/PostCard"
import { handleReplyToPost, handleDeletePost, handleReactToPost, setNextPagination, setHasMore, handlePreferences, handleSharePost, handleReport, handleEditPost, createMobileNavDrawer, handleNewPost, handleNewChat, handleNewEvent, handleSignOut, handleEventDetails, handleEditEvent, handleDeleteEvent, getMatchingLabelForEvent, handleFeedack } from '../Dashboard/dashboard-fns';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";

import authStateChanged from "../../redux/actions/auth_state_changed";
import createNotification from '../../redux/actions/create_notification';
import setModal from '../../redux/actions/set_modal';
import IsContactListedService from "../../services/IsContactListedService"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination as SwiperPagination } from 'swiper'

import Reaction from "../../models/Reaction"
import UserNotification from "../../models/common/UserNotification"
import WritePermissionPolicy from "../../policies/WritePermissionPolicy"
import ListOccupationsService from "../../services/ListOccupationsService"
import FormatRegionCodeService from "../../services/FormatRegionCodeService"
import Avatar from "../../components/Avatar"
import PopularPostsCard from "../../components/PopularPostsCard"
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedPlural, FormattedTime, useIntl } from "react-intl"
import CalendarEvent from "../../models/event/CalendarEvent"
import GroupListService from "../../services/common/GroupListService"
import SidebarButton from "../../components/SidebarButton"
import {default as UserInfoCardComponent} from "../../components/UserInfoCard"

class UserDetails extends Component {
    constructor(props) {
        super(props)

        const search = this.props.location.search
        const searchParams = new URLSearchParams(search)
        const activeTab = searchParams.get('tab') || 'posts'

        this.initState = {
            isLoading: false,
            hasMore: true,
            user: null,
            userInfoCards: [],
            posts: [],
            popularPosts: [],
            errors: [],
            isUserContact: false,
            userOccupations: [],
            activeTab: activeTab,
            groupedEvents: new Map(),
            showSidebar: false
        }

        this.state = { ...this.initState }

        this.tabs = ['posts', 'events']

        this.writePermission = new WritePermissionPolicy()

        this.listOccupations = new ListOccupationsService();
        this.groupList = new GroupListService()

        this.pagination = new Pagination(0, 4)
        this.criteria = new Criteria()
        this.order = new Order('createdAt', false)

        this.getUser = this.getUser.bind(this)
        this.getNextPosts = this.getNextPosts.bind(this)
        this.getNextEvents = this.getNextEvents.bind(this)
        this.getPopular = this.getPopular.bind(this)

        this.handleAddToContacts = this.handleAddToContacts.bind(this)
        this.handleRemoveFromContacts = this.handleRemoveFromContacts.bind(this)

        this.isContactListed = new IsContactListedService()
        this.setIsUserContact = this.setIsUserContact.bind(this)

        this.formatRegionCode = new FormatRegionCodeService()

        this.updatePostWithReaction = this.updatePostWithReaction.bind(this)
        this.handleShowSidebar = this.handleShowSidebar.bind(this)
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this)

        this.toggleReadMore = this.toggleReadMore.bind(this)
    }

    handleCloseSidebar() {
        this.setState({showSidebar: false})
    }

    handleShowSidebar() {
        this.setState({showSidebar: true})
    }

    handleAddToContacts(e) {
        var { currentUser } = this.props
        var { user } = this.state

        if (currentUser === null || user === null) {
            return
        }

        currentUser
        .addToContacts(user)
        .then(() => {
            this.setState({ isUserContact: true })
        })
    }

    handleRemoveFromContacts(e) {
        var { currentUser } = this.props
        var { user } = this.state

        if (currentUser === null || user === null) {
            return
        }
        currentUser
        .removeFromContacts(user)
        .then(() => {
            this.setState({ isUserContact: false })
        })
    }

    getPopular() {
        return Post
        .popular(new Pagination(0, 5), new Order('views', false))
        .then((popularPosts) => {
            this.setState({popularPosts})
        })
    }

    getUser(username) {
        return User.findByUsername(username)
        .then((user) => {
            this.setState({ user: user })
            return user
        })
        .then((user) => {
            if (user == null) {
                return Promise.resolve(null)
            }
            let userOccupations = user.occupationCodes
            .map((code) => this.listOccupations.getNode(code))
            .filter((occupation) => occupation !== null)
            this.setState({ userOccupations })
            return user
        })
    }

    getNextPosts() {
        if (this.criteria === undefined || this.pagination === undefined) {
            return Promise.resolve([])
        }
        return Post
        .matching(this.criteria, this.pagination, this.order)
        .then((newPosts) => {
            this.pagination = setNextPagination(this.pagination, newPosts.length)
            this.setState({
                hasMore: setHasMore(this.pagination, newPosts.length),
                posts: this.state.posts.concat(newPosts)
            })
        })
    }

    getNextEvents() {
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }

        var pagination = this.pagination

        return CalendarEvent
        .matching(this.criteria, this.pagination, this.order)
        .then((newEvents) => {
            this.setState((state) => {
                var newGroupedEvents = this.groupList.by(newEvents, (event) => {
                    var label = getMatchingLabelForEvent(this, event)
                    return label
                })

                this.pagination = setNextPagination(pagination, newEvents.length)
                return {
                    hasMore: setHasMore(pagination, newEvents.length),
                    groupedEvents: new Map([...state.groupedEvents, ...newGroupedEvents])
                }
            })
        })
    }

    updatePostWithReaction(post, newReaction) {
        this.props.createNotification(new UserNotification({
            name: this.props.intl.formatMessage({id: 'REACTION_WAS_ADDED_NOTIFICATION'}),
            object: newReaction,
            userInfo: {}
        }))
        this.setState((state) => {
            var posts = [...state.posts]

            var postIndex = posts.findIndex((x) => x.id === post.id)
            var updatedPost = posts[postIndex]

            var updatedReactions = [newReaction, ...updatedPost.reactions]
            updatedPost.reactions = updatedReactions
            updatedPost.metrics.reactions += 1

            posts[postIndex] = updatedPost

            return {
                posts: posts
            }
        })
    }

    setIsUserContact(other) {
        var { currentUser } = this.props
        if (currentUser === null || other === null) {
            return Promise.resolve()
        }
        return this.isContactListed.forUser(currentUser, other)
        .then((result) => {
            this.setState({
                isUserContact: result
            })
        })
    }

    getUserInfoCards(user) {
        return user.getInfoCards()
        .then((cards) => {
            this.setState({userInfoCards: cards})
            return cards
        })
    }

    updateUserIfNeeded(prevProps) {
        if (prevProps.match.params.username !== this.props.match.params.username && this.state.isLoading === false) {
            var { activeTab } = this.state
            
            this.setState({ ...this.initState }, () => {
                this.setState({isLoading: true})

                let username = this.props.match.params.username
                this.getUser(username)
                .then((user) => {
                    let contentPromise = undefined

                    switch (activeTab) {
                        case 'posts':
                            this.pagination = new Pagination(0, 4)
                            this.criteria = new Criteria().equalTo('userId', user.id).is('deletedAt', null)
                            contentPromise = this.getNextPosts
                            break
                        case 'events':
                            this.pagination = new Pagination(0, 20)
                            this.criteria = new Criteria().greaterThanEqual('startDate', format(new Date(), 'yyyy-MM-dd HH:mm:ss')).is('deletedAt', null)
                            contentPromise = this.getNextEvents
                            break
                        default:
                            break;
                    }

                    return Promise.all([
                        this.setIsUserContact(user),
                        this.getUserInfoCards(user),
                        contentPromise(),
                        this.getPopular(user)
                    ])
                })
                .then(() => {
                    this.setState({
                        isLoading: false,
                        errors: []
                    })
                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                        errors: [error]
                    })
                })
            })
        }
    }

    updatePostsIfNeeded(newPost) {
        if (this.state.activeTab !== 'posts') {
            return
        }
        let isOwn = this.state.user.id == newPost.author.id
        if (isOwn == false) {
            return
        }
        this.setState((state) => {
            return {
                posts: [newPost, ...state.posts]
            }
        })
    }

    updateEditedPostIfNeeded(editedPost) {
        if (this.state.activeTab !== 'posts') {
            return
        }
        let isOwn = this.state.user.id == editedPost.author.id
        if (isOwn == false) {
            return
        }
        this.setState((state) => {
            var updatedPosts = [...state.posts]
            for (let i = 0; i < state.posts.length; i++) {
                let candidate = state.posts[i];
                if (candidate.id === editedPost.id) {
                    updatedPosts[i] = editedPost
                    break
                }
            }
            return {
                posts: updatedPosts
            }
        })
    }

    removePostIfNeeded(deletedPost) {
        if (this.state.activeTab !== 'posts') {
            return
        }
        let isOwn = this.state.user.id == deletedPost.author.id
        if (isOwn == false) {
            return
        }
        this.setState((state) => {
            var updatedPosts = state.posts.filter((post) => post.id !== deletedPost.id)
            return {
                posts: updatedPosts
            }
        })
    }

    updatePostsWithReplyIfNeeded(newReply) {
        if (this.state.activeTab !== 'posts') {
            return
        }
        let isOwn = this.state.user.id == newReply.author.id
        if (isOwn == false) {
            return
        }
        this.setState((state) => {
            var updatedPosts = [...state.posts]
            for (let i = 0; i < state.posts.length; i++) {
            var candidate = state.posts[i];
            if (candidate.id === newReply.inReplyToPostId) {
                var updatedPost = candidate
                updatedPost.metrics.replies += 1
                updatedPosts[i] = updatedPost
                break
            }
            }
            return {
            posts: [newReply, ...updatedPosts]
            }
        })
    }

    updateEventsIfNeeded(newEvent) {
        if (this.state.activeTab !== 'events') {
            return
        }
        var label = getMatchingLabelForEvent(this, newEvent)
        let eventGroupNotExists = this.state.groupedEvents.get(label) == undefined
        if (eventGroupNotExists) {
             return
        }
 
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [candidate, events] = value
                
                if (candidate === label) {
                    var updatedEvents = [newEvent, ...events].sort((a, b) => {
                        let dateA = getMilliseconds(a.startDate)
                        let dateB = getMilliseconds(b.startDate)
                        if (dateA < dateB) {
                            return -1
                        }
                        if (dateA > dateB) {
                            return 1
                        }
                        return 0
                    });
                    updatedGroupedEvents.set(label, updatedEvents)
                    break
                }
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }
 
    updateEditedEventIfNeeded(editedEvent) {
        if (this.state.activeTab !== 'events') {
            return
        }
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [label, events] = value
                var updatedEvents = [...events]

                for (let i = 0; i < events.length; i++) {
                    const candidate = events[i];
                    if (candidate.id === editedEvent.id) {
                        updatedEvents[i] = editedEvent
                        break
                    }
                }
                updatedGroupedEvents.set(label, updatedEvents);
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }
 
    removeEventIfNeeded(deletedEvent) {
        if (this.state.activeTab !== 'events') {
            return
        }
        this.setState((state) => {
            let updatedGroupedEvents = new Map([...state.groupedEvents])

            let iterator = state.groupedEvents.entries()
            while (true) {
                var {value, done} = iterator.next()
                if (done) {
                    break
                }
                var [label, events] = value
                var updatedEvents = events.filter((x) => x.id !== deletedEvent.id)
                updatedGroupedEvents.set(label, updatedEvents);
            }
            return {
                groupedEvents: updatedGroupedEvents
            }
        })
    }

    updateUserInfoCardsIfNeeded(updatedInfoCards) {
        var { currentUser } = this.props
        var { user } = this.state
        if (currentUser.id !== user.id) {
            return
        }
        this.setState({
            userInfoCards: updatedInfoCards
        })
    }

    updateUserOccupationsIfNeeded(occupations) {
        this.setState({
            userOccupations: occupations
        })
    }

    handleNotificationChangeIfNeeded(prevProps) {
        if (prevProps.notification !== this.props.notification) {
            let notification = this.props.notification
            if (notification == null) {
                return
            }
            let {type} = notification.userInfo
            if (type == null) {
                return
            }
            if (type == 'POST_CREATED') {
                let newPost = notification.object
                this.updatePostsIfNeeded(newPost)
            } else if (type == 'POST_EDITED') {
                let editedPost = notification.object
                this.updateEditedPostIfNeeded(editedPost)
            } else if (type == 'POST_DELETED') {
                let deletedPost = notification.object
                this.removePostIfNeeded(deletedPost)
            } else if (type == 'REPLY_CREATED') {
                let newReply = notification.object
                this.updatePostsWithReplyIfNeeded(newReply)
            } else if (type == 'EVENT_CREATED') {
                let newEvent = notification.object
                this.updateEventsIfNeeded(newEvent)
            } else if (type == 'EVENT_EDITED') {
                let editedEvent = notification.object
                this.updateEditedEventIfNeeded(editedEvent)
            } else if (type == 'EVENT_DELETED') {
                let removedEvent = notification.object
                this.removeEventIfNeeded(removedEvent)
            } else if (type == 'UPDATED_USER_INFO_CARDS') {
                let updatedInfoCards = notification.object
                this.updateUserInfoCardsIfNeeded(updatedInfoCards)
            } else if (type == 'UPDATED_USER_OCCUPATIONS') {
                let occupations = notification.object
                this.updateUserOccupationsIfNeeded(occupations)
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateUserIfNeeded(prevProps)
        this.handleNotificationChangeIfNeeded(prevProps)
    }

    componentDidMount() {
        var { activeTab } = this.state

        let username = this.props.match.params.username
        this.setState({isLoading: true})
        this.getUser(username)
        .then((user) => {
            if (user == null) {
                return Promise.resolve()
            }
            let contentPromise = undefined

            switch (activeTab) {
                case 'posts':
                    this.criteria.equalTo('userId', user.id).is('deletedAt', null)
                    contentPromise = this.getNextPosts
                    break
                case 'events':
                    this.criteria.greaterThanEqual('startDate', format(new Date(), 'yyyy-MM-dd HH:mm:ss')).equalTo('userId', user.id).is('deletedAt', null)
                    contentPromise = this.getNextEvents
                    break
                default:
                    break;
            }

            return Promise.all([
                this.setIsUserContact(user),
                this.getUserInfoCards(user),
                contentPromise(),
                this.getPopular(user)
            ])
        })
        .then(() => {
            this.setState({
                isLoading: false,
                errors: []
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errors: [error]
            })
        })
    }

    getPopular(user) {
        return Post
        .popularByUser(user.id, new Pagination(0, 7), new Order('views', false))
        .then((popularPosts) => {
            this.setState({
                popularPosts: popularPosts
            })
        })
    }

    renderPopularCard(popularPosts) {
        var { user } = this.state

        if (popularPosts.length == 0) {
            return
        }

        if (user == null) {
            return;
        }

        let title = this.props.intl.formatMessage({id: 'POPULAR_BY_USER_CARD_HEADER_FORMAT'}, {
            user: `${user.firstName} ${user.lastName}`
        })

        return (
            <PopularPostsCard
                title={title}
                listItems={popularPosts.map((post) =>
                    <ListGroup.Item className="bg-transparent border-bottom-0 w-100">
                        <article class="justify-content-between position-relative d-flex align-items-center mb-0">
                            <div class="ps-e">
                                <small className="text-muted fs-xs n-lines-1">
                                    <FormattedDate value={new Date(post.createdAt)}/>
                                </small>
                                <h4 class="n-lines-1 h6 mb-0">
                                    <Link class="stretched-link text-dark" to={`/me/${post.author.username}/post/${post.id}`}>{post.title || 'Untitled'}</Link>
                                </h4>
                                <span class="n-lines-1 fs-sm text-muted">
                                    <FormattedNumber value={post.metrics.views} notation={'compact'}/>
                                    {' '} <FormattedPlural value={post.metrics.views} one="view" other="views" />
                                </span>
                            </div>
                            { post.entities.filter((x) => x.type == 'image').length > 0 &&
                                <img
                                style={{width: "46px", height: "46px", objectFit: "cover"}}
                                className="rounded-1"
                                src={post.entities.filter((x) => x.type == 'image')[0].src}
                                alt={post.entities.filter((x) => x.type == 'image')[0].alt}
                                />
                            }
                        </article>
                    </ListGroup.Item>
                )}
            />
        )
    }

    toggleReadMore(e) {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = this.props.intl.formatMessage({id: "READ_MORE_BTN"}); 
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = this.props.intl.formatMessage({id: "READ_LESS_BTN"});
            moreText.style.display = "inline";
        }
    }

    renderBio(bio) {
        const limit = 200
        if (bio.length <= limit) {
            return (<div><p id="bio">{bio}</p></div>)
        }
        return (
            <div>
                <p id="bio">
                    {bio.slice(0, limit)}<span id="dots">...</span><span style={{display: 'none'}} id="more">{bio.slice(limit, bio.length)}</span>
                    <Button className="px-2 py-0" variant="link" onClick={this.toggleReadMore} id="myBtn">
                        <FormattedMessage id="READ_MORE_BTN"/>
                    </Button>
                </p>
            </div>
        )
    }

    renderHeader(user) {
        var { currentUser } = this.props
        var { isUserContact, userOccupations, userInfoCards } = this.state

        if (user === null) {
            return null
        }

        return (
            <div className="border-bottom">
                <div className="px-3 pt-3">
                    <Avatar size="100px" src={user.photoURL} alt={user.firstName + ' ' + user.lastName}/>
                
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className="mt-3 mb-0 n-line-1">{user.firstName + ' ' + user.lastName}</h5>
                            <p className="text-muted n-line-1">@{user.username}</p>
                        </div>
                        <div>
                            {currentUser && user.id === currentUser.id &&
                                <Button
                                    size="lg"
                                    className="text-dark"
                                    onClick={(e) => handlePreferences(this)}
                                    variant="link">
                                    <i className="bi bi-gear"></i>
                                </Button>
                            }
                            {currentUser && user.id !== currentUser.id && isUserContact === false &&
                                <Button
                                    size="sm"
                                    className=""
                                    variant="outline-success"
                                    onClick={this.handleAddToContacts}>
                                    <i className="bi bi-person-fill-check fs-lg me-2"></i>
                                    Contacts
                                </Button>

                            }
                            {currentUser && user.id !== currentUser.id && isUserContact &&
                                <Button
                                    size="sm"
                                    className=""
                                    variant="outline-danger"
                                    onClick={this.handleRemoveFromContacts}>
                                    <i class="bi bi-person-fill-dash fs-lg me-2"></i>
                                    Contacts
                                </Button>
                            }
                        </div>
                    </div>
                    { this.renderBio(user.bio ?? this.props.intl.formatMessage({id: 'EMPTY_BIO_MESSAGE'})) }
                    <div>
                        <ul class="list-inline text-muted mb-0">
                            <li className="my-3">
                                <i className="bi bi-geo mb-1 me-2"></i>
                                {this.formatRegionCode.withValue(user.regionCode ?? user.countryCode)}
                            </li>
                            { userOccupations.length > 0 &&
                                <li class="my-2">
                                    <p className="">
                                        <i class="bi bi-briefcase mb-1 me-2"></i>
                                        <FormattedMessage id="OCCUPATIONS_HEADER"/>:
                                    </p>
                                    <div class="d-flex flex-wrap mt-n3 ms-n3 mb-lg-5 mb-4 pb-3 pb-lg-0">
                                        { userOccupations.map((occupation) =>
                                            <span class="badge border border-primary text-primary fs-xs mt-3 ms-3 n-lines-1">
                                                <Link className="text-decoration-none" to={`/services?code=${occupation.code}`}>
                                                    { occupation.title }
                                                </Link>
                                            </span>
                                            
                                        )}
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>   
                </div>             

                { userInfoCards.length > 0 &&
                    <div className="pb-3">
                        <Swiper
                            className="swiper"
                            modules={[SwiperPagination]}
                            pagination={{ clickable: true }}>
                            { userInfoCards.map((card) => 
                                <SwiperSlide className="px-3">
                                    <UserInfoCardComponent info={card}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                }
            </div>
        )
    }

    renderPostListItem(post) {
        let canWrite = this.writePermission.isGranted(this.props.currentUser, {
            type: 'POST', object: post
        })

        return (
            <ListGroup.Item action onClick={(e) => {
                this.props.navigate(`/me/${post.author.username}/post/${post.id}`)
            }} className="py-0">
                <PostCard flush>
                    <PostCard.Header
                        disabledWrite={canWrite === false}
                        post={post}
                        onDelete={() => handleDeletePost(this, post)}
                        onReport={() => handleReport(this, post.id, 'POST')}
                        onEdit={() => handleEditPost(this, post)}
                    />
                    <PostCard.Body>
                        <PostCard.Preview>
                            {post.preview}
                        </PostCard.Preview>
                        <PostCard.Gallery>
                            {post.entities.filter((x) => x.type === 'image' || x.type === 'embed').map((x) => (
                                <SwiperSlide>
                                    {x.type === 'image' &&
                                        <img style={{ objectFit: "cover" }}
                                            className="w-100 h-100"
                                            alt={x.alt ?? ""}
                                            src={x.src}
                                        />
                                    }
                                    { x.type === 'embed' &&
                                        <div className="ratio ratio-16x9">
                                            <iframe src={x.embed} frameborder='no' allowtransparency='true' allowfullscreen='true'></iframe>
                                        </div>
                                    }
                                </SwiperSlide>
                            ))}
                        </PostCard.Gallery>
                    </PostCard.Body>
                    <PostCard.Footer
                        post={post}
                        onReact={(emoji, post) => {
                            handleReactToPost(this, emoji, post)
                                .then(() => {
                                    let newReaction = new Reaction({ userId: this.props.currentUser.id, postId: post.id, emoji })
                                    this.updatePostWithReaction(post, newReaction)
                                })
                        }}
                        onReply={(e) => handleReplyToPost(this, post, this.deletionSpecification)}
                        onShare={(e, action, post) => handleSharePost(this, action, post)}
                    />
                </PostCard>
            </ListGroup.Item>
        )
    }

    renderPostList(isLoading, posts, hasMore) {
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({isLoading: true})
                    this.getNextPosts()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}
            >
                {posts.map((post) =>
                    this.renderPostListItem(post)
                )}
            </Dashboard.List>
        )
    }

    renderEmptyPosts() {
        return (
            <div className="text-center px-3 py-5">
                <p><FormattedMessage id="EMPTY_POSTS_MESSAGE"/></p>
            </div>
        )
    }

    renderEmptyEvents() {
        return (
            <div className="text-center px-3 py-5">
                <p><FormattedMessage id="EMPTY_EVENTS_MESSAGE"/></p>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="text-center p-3">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    renderErrorsIfNeeded(errors) {
        return (
            <div>
                {errors.length > 0 &&
                    <Alert>
                        <ul>
                            {errors.map((error) =>
                                <li>{error.message}</li>
                            )}
                        </ul>
                    </Alert>
                }
            </div>
        )
    }

    renderEventItem(event) {
        var { currentUser } = this.props

        let weekday = CalendarEvent.getLocalizedWeekdays()[new Date(event.startDate).getDay()]
        let isOwn = currentUser && currentUser.id === event.author.id

        return (
            <ListGroup.Item action onClick={(e) => handleEventDetails(this, event)} className="d-flex justify-content-start p-3">
                <div style={{width: "65px", height: "65px"}} className="d-flex flex-column justify-content-center bg-light rounded text-dark border text-center me-4">
                    <h6 className="text-dark mb-0">{format(new Date(event.startDate), 'dd')}</h6>
                    <small className='text-danger'>{weekday.slice(0, 3)}</small>
                    
                </div>
                <div className="align-self-center">
                    <div class="fw-regular h6 mb-1 n-lines-1">{event.title}</div>
                    <p class="text-body fs-sm mb-0 n-lines-2">
                        <FormattedTime value={event.startDate}/> — <FormattedTime value={event.getEndDate()}/><br/>
                        {event.isOnline && event.timezone}
                        {event.isOnline === false && event.address}
                    </p>
                </div>
                <Dropdown
                    onToggle={(nextShow, meta) => {
                        let { originalEvent } = meta
                        originalEvent.stopPropagation()
                    }}
                    autoClose={true}
                    className="ms-auto align-self-center">
                    <Dropdown.Toggle variant="link" size="sm" className="text-dark btn-icon border-0 rounded-circle">
                        <i className="fs-lg bi bi-three-dots"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-macos shadow">
                        <Dropdown.Item onClick={(e) => handleReport(this, event.id, 'EVENT')}>
                            <FormattedMessage id="REPORT_DROPDOWN_ITEM"/>
                        </Dropdown.Item>

                        { isOwn &&
                            <Dropdown.Item onClick={(e) => handleEditEvent(this, event)}>
                                <FormattedMessage id="EDIT_DROPDOWN_ITEM"/>
                            </Dropdown.Item>
                        }

                        { isOwn &&
                            <Dropdown.Item onClick={(e) => handleDeleteEvent(this, event)}>
                                <FormattedMessage id="DELETE_DROPDOWN_ITEM"/>
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </ListGroup.Item>
        )
    }

    renderEventList(isLoading, groupedEvents, hasMore) {
        var { isLoading, hasMore } = this.state
        return (
            <Dashboard.List
                isLoading={isLoading}
                hasMore={hasMore}
                variant="flush"
                loadMore={() => {
                    this.setState({isLoading: true})
                    this.getNextEvents()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}>
                {Array.from(groupedEvents, ([label, events]) => (
                    <div>
                        <div className="text-dark bg-secondary px-3 py-1">
                            <small className="text-dark n-lines-1">{label}</small>
                        </div>
                        <ListGroup variant="flush">        
                            {events.map((event) => (
                                this.renderEventItem(event)
                            ))}
                        </ListGroup>
                    </div>
                ))}
            </Dashboard.List>
        )
    }

    renderEmptyUser() {
        return (
            <div className="text-center px-3 py-5">
                <p><FormattedMessage id="USER_NOT_FOUND_EMPTY_MESSAGE"/></p>
            </div>
        )
    }

    render() {
        var { popularPosts, errors, user, isLoading, posts, groupedEvents, hasMore, activeTab, showSidebar } = this.state
        var { currentUser } = this.props

        return (
            <Dashboard childScene={this}>
                <Dashboard.Body>
                    <Dashboard.Navbar
                        title={this.props.intl.formatMessage({id: 'PROFILE_NAV_TITLE'})}
                        goBack={-1}
                        bg="light"
                        variant="dark"
                        offcanvasBody={createMobileNavDrawer({
                            currentUser,
                            onBehalfOfComponent: this,
                            onNewPost: handleNewPost,
                            onNewChat: handleNewChat,
                            onNewEvent: handleNewEvent,
                            onPreferences: handlePreferences,
                            onSignOut: handleSignOut
                        })}/>
                    {this.renderErrorsIfNeeded(errors)}
                    {this.renderHeader(user)}
                    {isLoading === false && user == null && this.renderEmptyUser()}
                    {isLoading && user == null && this.renderLoading()}
                    {user != null &&
                        <Tab.Container onSelect={(activeKey) => {
                            window.history.pushState({} , '', `?tab=${activeKey}`)
                            this.setState({
                                posts: [],
                                groupedEvents: new Map(),
                                isLoading: true,
                                activeTab: activeKey,
                                errors: []
                            }, () => {
                                let contentPromise = undefined
                                switch (activeKey) {
                                    case 'posts':
                                        contentPromise = this.getNextPosts
                                        this.criteria = new Criteria().equalTo('userId', user.id).is('deletedAt', null)
                                        this.pagination = new Pagination(0, 4)
                                        this.order = new Order('createdAt', false)
                                        break
                                
                                    case 'events':
                                        contentPromise = this.getNextEvents
                                        this.criteria = new Criteria().greaterThanEqual('startDate', format(new Date(), 'yyyy-MM-dd HH:mm:ss')).equalTo('userId', user.id).is('deletedAt', null)
                                        this.pagination = new Pagination(0, 20)
                                        this.order = new Order('startDate', true)
                                        break
                                }
                                contentPromise()
                                .then(() => {
                                    this.setState({
                                        isLoading: false,
                                        errors: []
                                    })
                                })
                                .catch((error) => {
                                    this.setState({
                                        isLoading: false,
                                        errors: [error]
                                    })
                                })
                            })
                        }} defaultActiveKey={activeTab}>
                            { user != null &&
                                <Nav variant="underline" className="border-top-0 border-bottom px-3 py-3 nav nav-tabs border-top mb-0">
                                {this.tabs.map((tab) =>
                                    <Nav.Item>
                                        <Nav.Link className="rounded-pill px-4" eventKey={tab}>
                                            <FormattedMessage id={`NAV_ITEM_${tab.toUpperCase()}`}/>
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                </Nav>
                            }
                            <Tab.Content>
                            { this.tabs.map((tab) => {
                                if (tab == 'posts') {
                                    return (
                                        <Tab.Pane eventKey={tab}>
                                            {this.renderPostList(isLoading, posts, hasMore)}
                                            {isLoading === false && posts.length === 0 && this.renderEmptyPosts()}
                                        </Tab.Pane>
                                    )
                                } else if (tab == 'events') {
                                    return (
                                        <Tab.Pane eventKey={tab}>
                                            {this.renderEventList(isLoading, groupedEvents, hasMore)}
                                            {isLoading === false && groupedEvents.size === 0 && this.renderEmptyEvents()}
                                        </Tab.Pane>
                                    )
                                }
                            })}
                            </Tab.Content>
                        </Tab.Container>
                    }
                    
                    <SidebarButton onClick={this.handleShowSidebar}/>
                </Dashboard.Body>
                <Dashboard.Context
                    onShow={this.handleShowSidebar}
                    onHide={this.handleCloseSidebar}
                    show={showSidebar}
                    onFeedback={(e) => handleFeedack(this)}>
                    {this.renderPopularCard(popularPosts)}
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let params = useParams()
    let navigate = useNavigate()
    let location = useLocation()
    let intl = useIntl()
    return <UserDetails {...props} intl={intl} location={location} navigate={navigate} match={{ params: params }} />
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps)