const { createClient } = require('@supabase/supabase-js')

class IsContactListedService {
    constructor() {
        const supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(supabaseUrl, supabaseKey)
    }

    forUser(user, other) {
        return this.supabase
        .from('contact')
        .select('*')
        .eq('userId', user.id)
        .eq('otherId', other.id)
        .limit(1)
        .then((response) => {
            var { data, error } = response
            if (error) {
                return Promise.reject(error)
            }
            let result = data.length > 0
            return result
        })
    }
}

export default IsContactListedService