import CalendarEventRepository from "../../../repositories/event/CalendarEventRepository"

import { add, addDays, addHours, addMilliseconds, eachDayOfInterval, eachWeekOfInterval, parseISO, startOfWeek } from 'date-fns'
import { getTimezoneOffset, utcToZonedTime } from "date-fns-tz";
import { parse } from 'tinyduration';

class CalendarEvent {
    constructor({id, author, startDate, duration, timezone, description, title, invited, 
        createdAt, updatedAt, deletedAt, address, isOnline, url, regionCode, countryCode, size, type, subtype}) {
        let now = new Date()

        this.id = id || undefined
        this.author = author
        this.startDate = typeof startDate == 'string' ? parseISO(startDate) : startDate
        this.duration = duration
        this.timezone = timezone
        this.title = title
        this.description = description || ""
        this.invited = invited || []
        this.address = address || null
        this.isOnline = isOnline || false
        this.url = url || null
        this.regionCode = regionCode
        this.countryCode = countryCode

        this.size = size && size.toUpperCase().replaceAll(' ', '_') || ''
        this.type = type && type.toUpperCase().replaceAll(' ', '_') || ''
        this.subtype = subtype && subtype.toUpperCase().replaceAll(' ', '_') || ''

        this.createdAt = createdAt || now
        this.updatedAt = updatedAt || now
        this.deletedAt = deletedAt
    }

    static getSizes() {
        return ['local', 'major', 'hallmark', 'mega']
    }

    static getTypes() {
        return ['business', 'leisure', 'social', 'sport', 'cultural', 'community']
    }

    static getLocalizedWeekdays() {
        let start = startOfWeek(new Date());
        let week = eachDayOfInterval({
            start: start,
            end: addDays(start, 6)
        })
        let locale = window.navigator.language
        return week.map((date) => date.toLocaleString(locale, {weekday: 'short'}))
    }

    static getLocalizedMonths() {
        var months = []
        var locale = window.navigator.language
        for (let i = 0; i < 12; i++) {
            let d = new Date();
            d.setDate(1);
            d.setMonth(i);
            let month = d.toLocaleString(locale, { month: "long" })
            months.push(month)
        }
        return months;
    }

    static getSubtypes(type) {
        let subtypes = {
            'business': [
                'corporate_event',
                'seminars',
                'networking',
                'conferences',
                'tradeshows',
                'workshops',
                'team_building',
                'product_launches',
                'product_demonstrations',
                'lectures',
                'award_night'
            ],
            'leisure': [
                'social_event',
                'sports_event',
                'cultural_event',
                'community_event'
            ],
            'social': [
                'private_party',
                'wedding',
                'reunion',
                'nightclub_event'
            ],
            'sport': [
                'participation_sports_event',
		        'spectator_sports_event'
            ],
            'cultural': [
                'music_concerts_festivals',
		        'food_festivals'
            ],
            'community': [
                'community_event',
                'charity_event'
            ]
        }
        if (!(type in subtypes)) {
            return []
        }
        return subtypes[type]
    }

    static create(author, data) {
        var newEvent = new CalendarEvent({author, ...data})
        return CalendarEventRepository.getRepoState().add(newEvent)
        .then((eventId) => {
            newEvent.id = eventId
            return newEvent
        })
    }

    static matching(criteria, pagination, order) {
        return CalendarEventRepository.getRepoState().matching(criteria, pagination, order)
    }

    static startsAt(startDate, regionCode, pagination) {
        return CalendarEventRepository.getRepoState().startsAt(startDate, regionCode, pagination)
    }

    static update(calendarEvent, data) {
        return CalendarEventRepository.getRepoState().update(calendarEvent, data)
        .then((eventId) => {
            var updatedEvent = new CalendarEvent({
                id: eventId,
                author: calendarEvent.author,
                createdAt: calendarEvent.createdAt,
                updatedAt: calendarEvent.updatedAt,
                regionCode: calendarEvent.regionCode,
                countryCode: calendarEvent.countryCode,
                ...data
            })
            return updatedEvent
        })
    }

    static delete(calendarEvent) {
        return CalendarEventRepository.getRepoState().delete(calendarEvent)
    }

    getEndDate() {
        let endDate = add(this.startDate, {...parse(this.duration)})
        return endDate
    }

    getTimezone() {
        return "Europe/Copenhagen"
    }
}

export default CalendarEvent