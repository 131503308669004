import { createClient } from '@supabase/supabase-js';
import User from "../../models/User";
import AuthService from "../../services/AuthService";
import CheckUsernameService from '../../services/CheckUsernameService';
import UploadUserPhotoService from '../../services/UploadUserPhotoService';

class UserFactory {
    constructor() {
        this.supabaseUrl = "https://bspodttrfjifboigqtfv.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJzcG9kdHRyZmppZmJvaWdxdGZ2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3OTAxODQsImV4cCI6MTk5MjM2NjE4NH0.hDbECh51LSGihFrMhuLGHtNmcd1ufxPgR3QweIXlHjc";
        this.supabase = createClient(this.supabaseUrl, supabaseKey)

        this.uploadUserPhoto = new UploadUserPhotoService()
        this.checkUsername = new CheckUsernameService()
    }
    
    create(photo, password, data) {
        var userId = undefined;
        return this.checkUsername.isAvailable(data.username)
        .then((isAvailable) => {
            if (isAvailable === false) {
                let error = new Error(`Username ${data.username} is already taken.`)
                return Promise.reject(error)
            }
            let email = data.email
            let auth = new AuthService()
            return auth.signUp(email, password)
        })
        .then((id) => {
            userId = id;
            return this.uploadUserPhoto.forUserId(userId, photo ?? null)
        })
        .then((photoURL) => {
            let now = new Date();
            return new User({
                id: userId,
                ...data,
                photoURL,
                createdAt: now,
                updatedAt: now
            });
        })
    }
}

export default UserFactory;