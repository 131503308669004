import Occupation from '../../models/common/Occupation'

class ListOccupationsService {
    constructor() {
        this.localFile = localStorage.getItem('isco-08') ?? null;
        if (this.localFile) {
            this.occupations = JSON.parse(this.localFile)
        } else {
            this.occupations = []
        }
    }

    getNode(id) {
        let candidates = this.occupations["ISCO-08 EN Struct and defin"]
        .filter((x) => {
            let code = x["ISCO 08 Code"]
            return parseInt(id) === parseInt(code)
        })
        if (candidates.length === 0) {
            return null
        }
        let result = candidates[0]
        let code = result["ISCO 08 Code"]
        let title = result["Title EN"]
        let definition = result["Definition"]

        return new Occupation(title, code, definition)
    }

    atLevel(level) {
        if (level < 1 || level > 4) {
            return []
        }
        return this.occupations["ISCO-08 EN Struct and defin"]
        .filter((x) => x["Level"] === level.toString())
        .map((x) => {
            let code = x["ISCO 08 Code"]
            let title = x["Title EN"]
            let definition = x["Definition"]
            return new Occupation(title, code, definition)
        })
    }

    forCode(code) {
        let level = code.length
        if (level < 1 || level > 4) {
            return []
        }
        return this.occupations["ISCO-08 EN Struct and defin"]
        .filter((x) => {
            let someCode = x["ISCO 08 Code"]
            return new String(someCode).startsWith(code)
        })
        .filter((x) => x["Level"] === `${code.length + 1}`.toString())
        .map((x) => {
            let code = x["ISCO 08 Code"]
            let title = x["Title EN"]
            let definition = x["Definition"]
            return new Occupation(title, code, definition)
        })
    }

    collectUnitsWithCode(code) {
        let level = code.toString().length
        if (level === 0) {
            return []
        }
        return this.occupations["ISCO-08 EN Struct and defin"]
        .filter((x) => x["ISCO 08 Code"].toString().startsWith(code.toString()))
        .map((x) => {
            let code = x["ISCO 08 Code"]
            let title = x["Title EN"]
            let definition = x["Definition"]
            return new Occupation(title, code, definition)
        })
        .filter((x) => x.code.toString().length === 4)
    }
}

export default ListOccupationsService