/**
 * A policy which returns the minimum age, legally allowed in the US, to register a user.
 * This is called the Childrens Online Privacy Protection rule
 * (COPPA for short). See: https://www.ftc.gov/legal-library/browse/rules/childrens-online-privacy-protection-rule-coppa
 */
class ChildrensOnlinePrivacyProtectionRulePolicy {
    /**
     * Returns the minimum age, legally allowed in the US, to register a user.
     * Also known as the COPPA rule.
     * @return {number} Returns minimum age.
     */
    getMinimumAge() {
        let minimumAge = 13
        return minimumAge
    }
}

export default ChildrensOnlinePrivacyProtectionRulePolicy;