import { Component } from "react";
import { SwiperSlide } from 'swiper/react'

// Not yet in use.
class PostCardVideoSlide extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        var { src } = this.props
        return (
            <div className="ratio ratio-16x9">
                <video controls>
                    <source src={src} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
        )
    }
}

export default PostCardVideoSlide