import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

class UserInfoCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            flashCopiedToClipboard: false
        }
        this.copyToClipboard = this.copyToClipboard.bind(this)
    }

    copyToClipboard(e, value) {
        if (value) {
            navigator.clipboard.writeText(value);
        }
        this.setState({
            flashCopiedToClipboard: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    flashCopiedToClipboard: false
                })
            }, 1500);
        })
    }

    renderEmailCard(info, isEditing) {
        return (
            <div className="shadow-sm card text-center my-3">
                <div className="card-header text-dark">
                    <FormattedMessage id="USER_INFO_CARD_TYPE_EMAIL"/>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{info.title}</h4>
                    <p className="card-text">
                        {info.text}<br/>
                        {info.value}
                    </p>
                    <a  href={`mailto:${info.value}`} className="btn btn-primary">
                        <i className="bi bi-envelope me-2"></i>
                        {' '}<FormattedMessage id="EMAIL_BTN"/>
                    </a>
                </div>
                { isEditing &&
                    <div className="card-footer fs-sm text-muted">
                        <Button onClick={this.props.onRemove} variant="link" className="text-danger fw-bold text-decoration-none">
                            <FormattedMessage id="REMOVE_BTN"/>
                        </Button>
                    </div>
                }
            </div>
        )
    }

    renderWebsiteCard(info, isEditing) {
        return (
            <div className="shadow-sm card text-center my-3">
                <div className="card-header text-dark">
                    <FormattedMessage id="USER_INFO_CARD_TYPE_WEBSITE"/>
                </div>
                <div className="card-body">
                    <img className="border rounded-2 mb-3" width="65" src={`https://www.google.com/s2/favicons?domain=${info.value}&sz=256`}/>
                    <h4 className="card-title">{info.title}</h4>
                    <p className="card-text">
                        {info.text}<br/>
                        {info.value}
                    </p>
                    <a target="_blank" href={info.value} className="btn btn-primary">Visit</a>
                </div>
                { isEditing &&
                    <div className="card-footer fs-sm text-muted">
                        <Button onClick={this.props.onRemove} variant="link" className="text-danger fw-bold text-decoration-none">
                            <FormattedMessage id="REMOVE_BTN"/>
                        </Button>
                    </div>
                }
            </div>
        )
    }

    renderPhoneCard(info, isEditing) {
        return (
            <div className="shadow-sm card text-center my-3">
                <div className="card-header text-dark">
                    <FormattedMessage id="USER_INFO_CARD_TYPE_PHONE"/>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{info.title}</h4>
                    <p className="card-text">
                        {info.text}<br/>
                        {info.value}
                    </p>
                    <a href={`tel:${info.value}`} className="btn btn-primary">
                        <i className="bi bi-telephone me-2"></i>
                        {' '}<FormattedMessage id="CALL_BTN"/>
                    </a>
                </div>
                { isEditing &&
                    <div className="card-footer fs-sm text-muted">
                        <Button onClick={this.props.onRemove} variant="link" className="text-danger fw-bold text-decoration-none">
                            <FormattedMessage id="REMOVE_BTN"/>
                        </Button>
                    </div>
                }
            </div>
        )
    }

    renderDonationsCard(info, isEditing) {
        return (
            <div className="shadow-sm card text-center my-3">
                <div className="card-header text-dark">
                    <FormattedMessage id="USER_INFO_CARD_TYPE_DONATIONS"/>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{info.title}</h4>
                    <p className="card-text">
                        {info.text}<br/>
                        {info.value}
                    </p>
                    <a target="_blank" href={info.value} className="btn btn-primary">
                        <i className="bi bi-heart me-2"></i>
                        {' '}<FormattedMessage id="DONATE_BTN"/>
                    </a>
                </div>
                { isEditing &&
                    <div className="card-footer fs-sm text-muted">
                        <Button onClick={this.props.onRemove} variant="link" className="text-danger fw-bold text-decoration-none">
                            <FormattedMessage id="REMOVE_BTN"/>
                        </Button>
                    </div>
                }
            </div>
        )
    }

    renderNewsletterCard(info, isEditing) {
        return (
            <div className="shadow-sm card text-center my-3">
                <div className="card-header text-dark">
                    <FormattedMessage id="USER_INFO_CARD_TYPE_NEWSLETTER"/>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{info.title}</h4>
                    <p className="card-text">
                        {info.text}<br/>
                        {info.value}
                    </p>
                    <a target="_blank" href={info.value} className="btn btn-primary">
                        <i className="bi bi-inbox me-2"></i>
                        {' '}<FormattedMessage id="SUBSCRIBE_BTN"/>
                    </a>
                </div>
                { isEditing &&
                    <div className="card-footer fs-sm text-muted">
                        <Button onClick={this.props.onRemove} variant="link" className="text-danger fw-bold text-decoration-none">
                            <FormattedMessage id="REMOVE_BTN"/>
                        </Button>
                    </div>
                }
            </div>
        )
    }

    renderCryptoCard(info, isEditing) {
        var { flashCopiedToClipboard } = this.state

        var biIconName = 'bi-clipboard'
        if (flashCopiedToClipboard) {
            biIconName = "bi-check"
        }

        return (
            <div className="shadow-sm card text-center my-3">
                <div className="card-header text-dark">
                    <FormattedMessage id="USER_INFO_CARD_TYPE_CRYPTO"/>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{info.title}</h4>
                    <p className="card-text">
                        {info.text}<br/>
                        {info.value}
                    </p>
                    <Button onClick={(e) => this.copyToClipboard(e, info.value)} variant="outline-secondary">
                        <i className={`bi ${biIconName} me-2`}></i>
                        {' '}<FormattedMessage id="COPY_BTN"/>
                    </Button>
                </div>
                { isEditing &&
                    <div className="card-footer fs-sm text-muted">
                        <Button onClick={this.props.onRemove} variant="link" className="text-danger fw-bold text-decoration-none">
                            <FormattedMessage id="REMOVE_BTN"/>
                        </Button>
                    </div>
                }
            </div>
        )
    }

    renderBankCard(info, isEditing) {
        var { flashCopiedToClipboard } = this.state

        var biIconName = 'bi-clipboard'
        if (flashCopiedToClipboard) {
            biIconName = "bi-check"
        }

        return (
            <div className="shadow-sm card text-center my-3">
                <div className="card-header text-dark">
                    <FormattedMessage id="USER_INFO_CARD_TYPE_BANK"/>                    
                </div>
                <div className="card-body">
                    <h4 className="card-title">{info.title}</h4>
                    <p className="card-text">
                        {info.text}<br/>
                        {info.value}
                    </p>
                    <Button onClick={(e) => this.copyToClipboard(e, info.value)} variant="outline-secondary">
                        <i className={`bi ${biIconName} me-2`}></i>
                        {' '}<FormattedMessage id="COPY_BTN"/>
                    </Button>
                </div>
                { isEditing &&
                    <div className="card-footer fs-sm text-muted">
                        <Button onClick={this.props.onRemove} variant="link" className="text-danger fw-bold text-decoration-none">
                            <FormattedMessage id="REMOVE_BTN"/>
                        </Button>
                    </div>
                }
            </div>
        )
    }

    render() {
        var { info, isEditing } = this.props
        info = info || null
        isEditing = isEditing || false

        if (info == null) {
            return
        }
        
        switch (info.type.toUpperCase()) {
            case 'EMAIL':
                return this.renderEmailCard(info, isEditing)
            case 'WEBSITE':
                return this.renderWebsiteCard(info, isEditing)
            case 'PHONE':
                return this.renderPhoneCard(info, isEditing)
            case 'DONATIONS':
                return this.renderDonationsCard(info, isEditing)
            case 'NEWSLETTER':
                return this.renderNewsletterCard(info, isEditing)
            case 'CRYPTO':
                return this.renderCryptoCard(info, isEditing)
            case 'BANK':
                return this.renderBankCard(info, isEditing)
            default:
                return
        }
    }
}

export default UserInfoCard