import React, { Component } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Dashboard from '../Dashboard';
import { createMobileNavDrawer, handleFeedack, handleNewChat, handleNewEvent, handleNewPost, handlePreferences, handleSignOut } from '../Dashboard/dashboard-fns';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import authStateChanged from "../../redux/actions/auth_state_changed";
import routeStateChanged from '../../redux/actions/route_state_changed';
import setFallbackRegion from '../../redux/actions/set_fallback_region';
import createNotification from '../../redux/actions/create_notification'
import setModal from '../../redux/actions/set_modal'

class Groups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nearestCountries: [],
            popularPosts: [],
            showSidebar: false
        }
    }

    renderComingSoon() {
        return (
            <div className="px-3 py-5 text-center">
                <p className="text-muted">
                    <FormattedMessage id="GROUPS_COMING_SOON_MESSAGE"/>
                </p>
            </div>
        )
    }

    render() {
        var { currentUser } = this.props

        return (
            <Dashboard childScene={this}>
                <Dashboard.Body>
                    <Dashboard.Navbar
                        title={this.props.intl.formatMessage({id: "GROUPS_NAV_TITLE"})}
                        bg="light"
                        variant="dark"
                        offcanvasBody={createMobileNavDrawer({
                            currentUser,
                            onBehalfOfComponent: this,
                            onNewPost: handleNewPost,
                            onNewChat: handleNewChat,
                            onNewEvent: handleNewEvent,
                            onPreferences: handlePreferences,
                            onSignOut: handleSignOut
                        })}/>
                    {this.renderComingSoon()}
                </Dashboard.Body>
                <Dashboard.Context onFeedback={(e) => handleFeedack(this)}>
                </Dashboard.Context>
            </Dashboard>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.changedAuthState,
        notification: state.notification
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        authStateChanged: authStateChanged,
        setModal: setModal,
        createNotification: createNotification
    }, dispatch);
}

function BackwardsCompatibleProps(props) {
    let intl = useIntl()
    return <Groups {...props} intl={intl}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(BackwardsCompatibleProps);