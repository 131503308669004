import { Component } from "react";
import { Dropdown, Form, Button, NavDropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ListCountriesService from "../../services/ListCountriesService";
import ListRegionsService from "../../services/ListRegionsService";

class RegionFilterDropdownMenu extends Component {
    constructor(props) {
        super(props)

        this.initState = {
            isValidated: false,
            countryCode: "",
            regionCode: ""
        }

        this.state = {
            ...this.initState
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChangeCountry = this.handleChangeCountry.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setUpdateRegion = this.setUpdateRegion.bind(this)

        this.listCountries = new ListCountriesService()
        this.listRegions = new ListRegionsService()
    }

    setUpdateRegion() {
        var { regionCode } = this.props
        regionCode = regionCode || ""
        
        var fragments = regionCode.split('-')
        let countryCode = fragments[0]

        this.setState({
            countryCode,
            regionCode
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.regionCode !== this.props.regionCode) {
            this.setUpdateRegion()
        }
    }

    componentDidMount() {
        var { regionCode } = this.props
        this.setUpdateRegion(regionCode)
    }

    handleSubmit(e) {
        e.preventDefault()

        this.setState({isValidated: true})

        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }

        var { countryCode, regionCode } = this.state

        this.props.onApply(e, {
            regionCode: regionCode.length > 0 ? regionCode : countryCode
        })
    }

    handleChangeCountry(e) {
        const target = e.target
        const value = target.value
        const name = target.name
        
        if (name !== 'countryCode') {
            return
        }

        this.setState({
            [name]: value,
            regionCode: ""
        })
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    renderForm() {
        var { isValidated, countryCode, regionCode } = this.state
        return (
            <Form className="px-4 py-3"
                onSubmit={this.handleSubmit}
                noValidate
                validated={isValidated}>

                <Form.Group className="mb-3">
                    <Form.Label>
                        <FormattedMessage id="COUNTRY_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        required
                        onChange={this.handleChangeCountry}
                        value={countryCode}
                        name="countryCode">
                        <option value="">
                            <FormattedMessage id="CHOOSE_COUNTRY_OPTION"/>
                        </option>
                        {this.listCountries
                            .all()
                            .map((country) =>
                                <option value={country.code}>{country.name}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>
                        <FormattedMessage id="REGION_LABEL"/>
                    </Form.Label>
                    <Form.Select
                        onChange={this.handleInputChange}
                        name="regionCode"
                        value={regionCode}>
                        <option value="">
                            <FormattedMessage id="CHOOSE_REGION_OPTION"/>
                        </option>
                        {this.listRegions
                            .forCountryCode(countryCode)
                            .map((region) =>
                                <option value={region.code}>{region.name}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>

                <Form.Group className="d-flex justify-content-end">
                    <Button
                        onClick={this.props.onClear}
                        variant="link"
                        className="text-muted me-3">
                            <FormattedMessage id="CLEAR_BTN"/>
                    </Button>
                    <Button
                        variant="primary"
                        type="submit">
                            <FormattedMessage id="APPLY_BTN"/>
                    </Button>
                </Form.Group>
            </Form>
        )
    }

    render() {

        return (
            <div style={{width: "300px"}}>
                {this.renderForm()}
            </div>
        )
    }
}

export default RegionFilterDropdownMenu