import React, { Component } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

class ProfilePicture extends Component {
    constructor(props) {
        super(props);

        this.photoRef = React.createRef();
        this.focusPhotoInput = this.focusPhotoInput.bind(this);
    }

    focusPhotoInput() {
        this.photoRef.current.click();
    }

    render() {
        var { photo, photoURL } = this.props;

        return (
            <div className="d-flex align-items-center mb-4">
                <Dropdown>
                    <Dropdown.Toggle
                        as="a"
                        className="p-0 d-flex flex-column justify-content-end position-relative overflow-hidden rounded-circle bg-size-cover bg-position-center flex-shrink-0 border"
                        style={{
                            cursor: 'pointer',
                            backgroundPosition: 'center',
                            backgroundSize: "cover",
                            backgroundRepeat: 'no-repeat',
                            width: '80px',
                            height: '80px',
                            backgroundImage: `url(${photo ? URL.createObjectURL(photo) : photoURL})`
                        }}>
                        <span className="d-block text-light text-center lh-1 pb-1 w-100" style={{ backgroundColor: "rgba(0,0,0,.5)" }}>
                            <i className="bi bi-camera-fill"></i>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="my-1 shadow-sm">
                        <Form.Control ref={this.photoRef} name="photo" onChange={this.props.onChange} type="file" accept="image/*" hidden />
                        <Dropdown.Item style={{ cursor: "pointer" }} className="dropdown-item fw-normal" onClick={this.focusPhotoInput}>
                            <FormattedMessage id="UPLOAD_NEW_PHOTO_DROPDOWN_ITEM"/>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item text-danger fw-normal" onClick={this.props.onRemove}>
                            <FormattedMessage id="DELETE_PHOTO_DROPDOWN_ITEM"/>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <div className="ps-3">
                    <h3 className="h6 mb-1">
                        <FormattedMessage id="PROFILE_PICTURE_LABEL"/>
                    </h3>
                    <p className="fs-sm text-muted mb-0">
                        <FormattedMessage id="PROFILE_PICTURE_TEXT"/>
                    </p>
                </div>
            </div>
        )
    }

}

export default ProfilePicture;