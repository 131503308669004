import { addWeeks, endOfWeek, startOfWeek, subWeeks, subMonths, addMonths, startOfMonth, endOfMonth, setHours, addDays } from "date-fns";

export function today() {
    return new Date()
}

export function isLastWeek(date) {
    let lastWeek = subWeeks(today(), 1)
    return date >= startOfWeek(lastWeek) && date <= endOfWeek(lastWeek)
}

export function isLastMonth(date) {
        let lastMonth = subMonths(today(), 1)
        return date >= startOfMonth(lastMonth) && date <= endOfMonth(lastMonth)
    }

export function isNextWeek(date) {
    let nextWeek = addWeeks(today(), 1)
    return date >= startOfWeek(nextWeek) && date <= endOfWeek(nextWeek)
}

export function isNextMonth(date) {
    let nextMonth = addMonths(today(), 1)
    return date >= startOfMonth(nextMonth) && date <= endOfMonth(nextMonth)
}

export function getNextMonth(yearMonth) {
    return addMonths(setHours(addDays(startOfMonth(yearMonth), 1), 1), 1)
}

export function getPreviousMonth(yearMonth) {
    return subMonths(setHours(addDays(startOfMonth(yearMonth), 1), 1), 1)
}
