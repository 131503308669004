import React, { Component } from 'react'
import { Card } from 'react-bootstrap'

import PostCardHeader from './Header'
import PostCardBody from './Body'
import PostCardFooter from './Footer'
import PostCardPreview from './Preview'
import PostCardGallery from './Gallery'
import PostCardSlide from './Slide'
import PostCardImageSlide from './ImageSlide'
import PostCardVideoSlide from './VideoSlide'

class PostCard extends Component {
    static Header = PostCardHeader
    static Body = PostCardBody
    static Footer = PostCardFooter
    static Gallery = PostCardGallery
    // static Slide = PostCardSlide
    // static ImageSlide = PostCardImageSlide
    // static VideoSlide = PostCardVideoSlide
    static Preview = PostCardPreview
    
    constructor(props) {
        super(props);
    }

    render() {
        const flush = this.props.flush != null || (this.props.flush && this.props.flush == true);
        var className = "w-100";
        className += flush ? " rounded-0 border-0" : "";

        return (
            <Card className={className}>
                { this.props.children }
            </Card>
        )
    }
}

export default PostCard;