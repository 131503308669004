import { format } from "date-fns";

class DownloadCalendarEventService {
    inWindow(window, calendarEvent) {
        let result = `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VEVENT
SUMMARY:${calendarEvent.title}
DTSTART;TZID=${calendarEvent.getTimezone()}:${format(calendarEvent.startDate, "yyyyMMdd'T'HHmmss")}
DTEND;TZID=${calendarEvent.getTimezone()}:${format(calendarEvent.getEndDate(), "yyyyMMdd'T'HHmmss")}
LOCATION:${calendarEvent.address}
DESCRIPTION:${calendarEvent.description}
END:VEVENT
END:VCALENDAR`

        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.download = `${calendarEvent.title || "Untitled"}.ics`;
        link.href = url;

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        return url
    }
}

export default DownloadCalendarEventService