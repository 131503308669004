class PostDeletionFeature2 {
    timeElapsed(scope, x) {
        if (['DAY', 'MONTH', 'YEAR'].includes(scope.toUpperCase()) === false) {
            return this
        }
        let select = `ABS(EXTRACT(${scope.toUpperCase()} FROM "createdAt"-now())) as time_elapsed`
        let from = 'post'
        let where = `time_elapsed >= ${x}`
        return {select, from, where}
    }

    metricHasReached(metric, n) {
        if (['views', 'replies', 'reactions'].includes(metric) === false) {
            return this
        }
        let select = `metrics.${metric}`
        let from = 'metrics'
        let where = `metrics.${metric} >= ${n}`
        return {select, from, where}
    }
}

class PostDeletionFeature {
    constructor(type, {key, value}) {
        this.type = type
        this.key = key
        this.value = value
    }
}

export default PostDeletionFeature