import { Component } from "react";
import { SwiperSlide } from 'swiper/react'

// Not yet in use.
class PostCardImageSlide extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        var { src, alt } = this.props
        return (
            <img style={{objectFit: "cover"}}
                className="w-100"
                alt={alt ?? ""}
                src={src}
            />   
        )
    }
}

export default PostCardImageSlide