import Post from "../../models/Post"
import Reaction from "../../models/Reaction"
import User from "../../models/User"

class RestorePostsStateService {
    fromLocalStorage() {
        let data = JSON.parse(localStorage.getItem('posts_restoration'))
        if (data === null) {
            return []
        }
        let restoredPosts = data.map((x) => {
            let author = new User({...x.author})
            let reactions = x.reactions.map((y) => new Reaction({...y}))
            return new Post({...x, author, reactions})
        })
        return restoredPosts
    }
}

export default RestorePostsStateService