import { Component } from "react";

import { Modal, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import Report from "../../../models/Report";


class ReportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reason: "",
            description: "",
            showAlert: (this.props.error != null),
            validated: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
    }

    handleHide(e) {
        this.setState({
            reason: "",
            description: "",
            validated: false
        }, () => {
            this.props.onHide(e)
        })
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleCloseAlert() {
        this.setState({
            showAlert: false
        })
    }

    handleCreate(e) {
        e.preventDefault();
        this.setState({
            validated: true
        });
        const form = e.currentTarget;
        if (form.checkValidity() == false) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        var { reason, description } = this.state;
        this.props.onCreate(e, {reason, description});
    }

    render() {
        const { reason, description, showAlert, validated } = this.state;
        const { reasons, show, error } = this.props;
        return (
            <Modal
                size="md"
                fullscreen="lg-down"
                show={show}
                onHide={this.props.onHide}
                onExited={this.props.onExited}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="REPORT_MODAL_TITLE"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        showAlert &&
                        <Alert variant="danger" onClose={this.handleCloseAlert} dismissible>
                            <span>
                                {error.message}
                            </span>
                        </Alert>
                    }
                    <Form id="reportForm" noValidate validated={validated} onSubmit={this.handleCreate}>
                        <Form.Group className="mb-3">
                            {
                                reasons.map((x) =>
                                    <Form.Check
                                        required
                                        name="reason"
                                        value={x}
                                        onChange={this.handleInputChange}
                                        checked={reason == x}
                                        type={"radio"}
                                        label={this.props.intl.formatMessage({id: `REPORT_REASON_TYPE_${x}`})}
                                    />
                                )
                            }
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>
                                <FormattedMessage id="REPORT_DESCRIPTION_LABEL"/>
                            </Form.Label>
                            <Form.Control
                                required
                                onChange={this.handleInputChange}
                                value={description}
                                name="description"
                                as="textarea"
                                rows={6}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="REPORT_BODY_INVALID_FORM_FEEDBACK" />
                            </Form.Control.Feedback>
                            <Form.Text>
                                <FormattedMessage id="REPORT_DESCRIPTION_TEXT"/>
                            </Form.Text>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="">
                    <Button onClick={this.props.onHide} className="me-2" variant="secondary">
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button onClick={this.handleSubmit} form="reportForm" type="submit" variant="primary">
                        <FormattedMessage id="SEND_BTN"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ReportModal