class RestorePostsStateSpecification {
    isSatisfiedBy(routeState) {
        if (routeState === null) {
            return false
        }
        if (routeState.historyAction !== 'POP') {
            return false
        }
        return true
    }
}

export default RestorePostsStateSpecification