import PostRepository from "../../repositories/PostRepository";
import Reaction from '../Reaction';
import Metrics from '../Metrics';
import PostFactory from "../../factories/PostFactory";

/**
 * A post model.
 * This is also a root aggregate.
 */
class Post {
    constructor({id, title, preview, downloadURL, blocksURL, source, regionCode,
        countryCode, languageCode, inReplyToPostId, inReplyToUsername, author, metrics,
        reactions, entities, createdAt, updatedAt, deletedAt}) {

        let now = new Date();

        this.id = id || undefined;
        this.title = title
        this.preview = preview;
        this.downloadURL = downloadURL;
        this.blocksURL = blocksURL || ""; // should not be optional, only during trasition.
        this.source = source;
        this.regionCode = regionCode || null;
        this.countryCode = countryCode;
        this.languageCode = languageCode;
        this.inReplyToPostId = inReplyToPostId || null;
        this.inReplyToUsername = inReplyToUsername || null;
        this.author = author;
        this.metrics = metrics || new Metrics();
        this.reactions = reactions || [];
        this.entities = entities || [];
        this.createdAt = createdAt || now;
        this.updatedAt = updatedAt || now;
        this.deletedAt = deletedAt || null;
    }

    /*
    Turn these into policies instead?
    isNSWF() {
        var subTags = []
        return subTags.every((i => v => i = this.sensitivityTags.indexOf(v, i) + 1)(0))
    }

    isNSFL() {
        var subTags = []
        return subTags.every((i => v => i = this.sensitivityTags.indexOf(v, i) + 1)(0))
    }
    */

    /**
     * A facade which creates a post (using the corresponding post factory) and adds it to the repository.
     * @param {string} markdown - The body of the post in the markdown format.
     * @param {User} author - An author (of type user model) of the post.
     * @param {object} data - Key-value data about the post. 
     * @return {Post} Returns a Promise with the newly created post or rejects with an error.
     */
    static create(guid, blocks, author, data, deleteSpecification) {
        var post = undefined
        return new PostFactory().create(guid, blocks, author, data)
        .then((newPost) => {
            post = newPost 
            return PostRepository.getRepoState().add(newPost, deleteSpecification)
        })
        .then((postId) => {
            post.id = postId
            return post
        })
    }

    static update(post, guid, blocks, author, data, deleteSpecification) {
        var updatedPost = undefined
        return new PostFactory().create(guid, blocks, author, data)
        .then((newPost) => {
            updatedPost = newPost
            return PostRepository.getRepoState().update(post, {
                deleteSpecification: deleteSpecification,
                updatedAt: updatedPost.updatedAt,
                preview: updatedPost.preview,
                entities: updatedPost.entities,
                title: updatedPost.title
            })
        })
        .then((postId) => {
            updatedPost.id = postId
            return updatedPost
        })
    }
    
    /**
     * A facade (thin wrapper) for the corresponding post repository method findById.
     * @param {string} id - A string identifier used to filter the query response.
     * @return {Post} Returns a Promise with a post matching the identifier or rejects with an error.
     */
    static findById(id) {
        return PostRepository.getRepoState().findById(id)
    }

    /**
     * A facade (thin wrapper) for the corresponding post repository method inReplyToPostId.
     * @param {string} postId - A replied to postId used to filter the query response.
     * @return {Post[]} Returns a Promise with a list of replies to the postId or rejects with an error.
     */
    static inReplyToPostId(postId, pagination, order) {
        return PostRepository.getRepoState().inReplyToPostId(postId, pagination, order)
    }

    static popularByCountry(countryCode, pagination, order) {
        return PostRepository.getRepoState().popularByCountry(countryCode, pagination, order)
    }

    static popularByUser(userId, pagination, order) {
        return PostRepository.getRepoState().popularByUser(userId, pagination, order)
    }

    static matching(criteria, pagination, order) {
        return PostRepository.getRepoState().matching(criteria, pagination, order)
    }

    static delete(post) {
        return PostRepository.getRepoState().delete(post)
    }
}

export default Post;