import { Component } from "react";

class Avatar extends Component {
    constructor(props) {
        super(props)
    }

    getInitials(str) {
        if (str.length === 0) {
            return ""
        }
        var initials = undefined
        let fragments = str.split(' ')
        if (fragments.length === 1) {
            let fst = fragments[0]
            initials = fst[0]
        } else {
            let fst = fragments[0]
            let snd = fragments[fragments.length - 1]
            initials = fst[0] + snd[0]
        }
        return initials.toUpperCase()
    }

    render() {
        var { alt, src, size } = this.props

        alt = alt || ""
        src = src || null
        size = size || "56px"

        let initials = this.getInitials(alt)

        if (src === null) {
            return (
                <div className={"d-flex align-items-center justify-content-center position-relative flex-shrink-0 rounded-circle text-primary fs-lg fw-semibold my-1 " + this.props.className}
                style={{"width": `${size}`, "height": `${size}`, "backgroundColor": "rgba(var(--ar-primary-rgb), .15)"}}>
                    {this.props.children ?? initials}
                </div>
            )
        }

        return (
            <img className={"rounded-circle " + this.props.className} src={src} width={size} height={size} alt={alt}/>
        )
    }
}

export default Avatar