/**
 * A specification which checks if one age is subsumed (contained)
 * by another age.
 */
class MinimumAgeSpecification {
    
    constructor(threshold) {
        this.threshold = threshold
    }
    
    /**
     * Checks if an age is greater or equal to a threshold (age).
     * @param {number} age - An age.
     * @return {boolean} Returns a boolean. True if age is greater or equal to threshold, otherwise false.
     */
    isSatisfiedBy(age) {
        return age >= this.threshold
    }

    /**
     * Checks if an age minimum age specification is subsumes by another (minimum age specification).
     * @param {MinimumAgeSpecification} other - A minimum age specification to check against.
     * @return {boolean} Returns a boolean. True if instantiated minimum age specification is greater or equal to input other minimum age specification, otherwise false.
     */
    subsumes(other) {
        return this.threshold >= other.threshold
    }
}

export default MinimumAgeSpecification