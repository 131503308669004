import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, ListGroup, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';

class DashboardContext extends Component {
  
    render() {
      var { show, onShow, onHide } = this.props
      var show = show || false

      return (
        
          <aside className="border-start d-none d-lg-block col sidebar-sticky sidebar-pills p-3" style={{"max-width": "350px"}}>
            <Offcanvas placement="end" responsive="lg" show={show} onShow={onShow} onHide={onHide}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <FormattedMessage id="SIDEBAR_OFFCANVAS_TITLE"/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="d-flex justify-content-end">
                  <Button onClick={this.props.onFeedback} className="me-2" size="sm" variant="outline-secondary">
                    <FormattedMessage id="FEEDBACK_BTN"/>
                  </Button>
                  
                  <Dropdown>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip><FormattedMessage id="QUESTIONS_BTN"/></Tooltip>}>
                      <Dropdown.Toggle className="me-2" size="sm" variant="outline-secondary position-relative">
                        <i className="bi bi-question-circle"/>
                      </Dropdown.Toggle>
                    </OverlayTrigger>

                    <Dropdown.Menu align="start" className="text-center p-3">
                      <small className="text-muted">
                        <FormattedMessage id="QUESTIONS_EMPTY_FUTURE_MESSAGE"/>
                      </small>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip><FormattedMessage id="NOTIFICATION_BTN"/></Tooltip>}>
                      <Dropdown.Toggle className="me-2" size="sm" variant="outline-secondary position-relative">
                        <i className="bi bi-bell"/>
                          <span className="d-none position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            99+ <span className="visually-hidden">unread messages</span>
                          </span>
                      </Dropdown.Toggle>
                    </OverlayTrigger>
                    <Dropdown.Menu align="start" className="text-center p-3">
                      <small className="text-muted">
                        <FormattedMessage id="NOTIFICATIONS_EMPTY_FUTURE_MESSAGE"/>
                      </small>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
      
                { this.props.children }
      
                <footer className="mt-3 text-50 text-start text-small">
                    <small className="mb-1">{`© ${new Date().getFullYear()} ARIA5.CO`}</small>
                    <ul className="list-inline">
                      <li className="list-inline-item"><small><a className="text-muted" target="_blank" href="/">About</a></small></li>
                      <li className="list-inline-item"><small><a className="text-muted" href="/developer">Developer</a></small></li>
                      <li className="list-inline-item"><small><a className="text-muted" href="/status">Status</a></small></li>
                      <li className="list-inline-item"><small><a className="text-muted" href="https://twitter.com/twitter">Twitter</a></small></li>
                      <li className="list-inline-item"><small><a className="text-muted" href="https://t.me/aria3712">Telegram</a></small></li>
                      <li className="list-inline-item"><small><a className="text-muted" href="https://t.me/policy">Policy</a></small></li>
                      <li className="list-inline-item"><small><a className="text-muted" href="https://t.me/privacy">Privacy</a></small></li>
                    </ul>
                </footer>
                </Offcanvas.Body>
            </Offcanvas>
          </aside>
      )
    }
  }

  function BackwardsCompatibleProps(props) {
    let navigate = useNavigate()
    return <DashboardContext {...props} navigate={navigate} />
  }

  export default BackwardsCompatibleProps;