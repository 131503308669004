import React, { Component } from "react";
import { Modal, Button, ListGroup, Spinner, Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import { FormattedMessage } from "react-intl";
import Order from "../../../models/common/Order";
import Pagination from "../../../models/common/Pagination";
import { setHasMore, setNextPagination } from "../../../scenes/Dashboard/dashboard-fns";
import Avatar from "../../Avatar";

class ChatModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            contacts: [],
            errors: [],
            isLoading: false,
            hasMore: true,
            selected: []
        }

        this.pagination = new Pagination(0, 10)
        this.order = new Order('createdAt', true)

        this.getNextContacts = this.getNextContacts.bind(this)
        this.handleSelectedChanged = this.handleSelectedChanged.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
        this.setUpdateDateIfNeeded(name, value)
    }

    getNextContacts() {
        if (this.pagination === undefined) {
            return Promise.resolve([])
        }
        return this.props.requestNextContacts(this.pagination, this.order)
        .then((newContacts) => {
            this.setState((state) => {
                this.pagination = setNextPagination(this.pagination, newContacts)
                return {
                    contacts: [...state.contacts, ...newContacts],
                    hasMore: setHasMore(this.pagination, newContacts.length)
                }
            })
            return newContacts
        })
    }

    componentDidMount() {
        this.setState({isLoading: true})
        this.getNextContacts()
        .then(() => {
            this.setState({isLoading: false})
        })
    }

    handleSelectedChanged(e) {
        var { contacts, selected } = this.state

        var updatedSelected = [...selected];

        const name = e.target.name;
        const checked = e.target.checked;

        contacts.forEach((contact) => {
            var idx = updatedSelected.map((x) => x.id).indexOf(name)
            if (idx > -1 && name === contact.id && checked === false) {
                updatedSelected.splice(idx, 1);
            } else if (name === contact.id && checked) {
                updatedSelected.push(contact)
            }
        })

        this.setState({
            selected: updatedSelected
        })
    }

    handleSubmit(e) {
        e.preventDefault()
        var { selected } = this.state
        if (selected.length === 0) {
            return
        }
        this.props.onCreate(e, { selected })
    }

    renderListItem(user) {
        var { selected } = this.state
        return (
            <ListGroup.Item as="label" className="d-flex justify-content-start align-items-center px-0 w-100">
                    <div className="position-relative flex-shrink-0 my-1">
                        <Avatar size="48px" src={user.photoURL} alt={user.firstName + ' ' + user.lastName}/>
                    </div>
                    <div className="d-flex justify-content-between w-100 ps-2 ms-1 my-1">
                        <div className="me-3">
                            <div className="h6 mb-1">{user.firstName + ' ' + user.lastName}</div>
                            <p className="text-body fs-sm mb-0">@{user.username}</p>
                        </div>
                        <div className="align-self-center text-end">
                            <input
                                name={user.id}
                                onChange={this.handleSelectedChanged}
                                className="form-check-input ms-auto"
                                type="checkbox"
                                checked={selected.map((user) => user.id).includes(user.id)}
                                value="">
                            </input>
                        </div>
                    </div>
            </ListGroup.Item>
        )
    }

    renderList(contacts) {
        var { isLoading, hasMore } = this.state

        return (
            <InfiniteScroll
                className="list-group list-group-flush w-100"
                initialLoad={false}
                isLoading={isLoading}
                hasMore={hasMore}
                pageStart={0}
                loader={this.renderLoading()}
                useWindow={false}
                getScrollParent={() => this.scrollParentRef}
                loadMore={() => {
                    if (isLoading || hasMore === false) {
                        return
                    }
                    this.setState({isLoading: true})
                    this.getNextContacts()
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            errors: []
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            errors: [error]
                        })
                    })
                }}>
                { contacts.map((user) => (
                    this.renderListItem(user)
                ))}
            </InfiniteScroll>
        )
    }

    renderLoading() {
        return (
            <div className="p-4 text-center w-100">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    renderEmpty() {
        return (
            <div className="text-center p-4">
            <p className="text-muted">
                <FormattedMessage id="EMPTY_CONTACTS_MESSAGE"/>
            </p>
            </div>
        )
    }

    render() {
        var { title, show } = this.props
        var { isLoading, contacts, selected } = this.state
        return (
            <Modal
                fullscreen="md-down"
                scrollable={true}
                show={show}
                onHide={this.props.onHide}
                onExited={this.props.onExited}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={(ref) => { this.scrollParentRef = ref }} className="">
                    <div className="d-none">
                        <div className="position-relative">
                            <input name="searchQuery" value={""} className="form-control pe-5" type="text" placeholder="Search contacts"/>
                            <i className="bi bi-search fs-lg text-nav position-absolute top-50 end-0 translate-middle-y me-3"></i>
                        </div>
                    </div>
                    {this.renderList(contacts)}
                    {isLoading === false && contacts.length === 0 && this.renderEmpty()}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide} className="me-2" variant="secondary">
                        <FormattedMessage id="CANCEL_BTN"/>
                    </Button>
                    <Button onClick={this.handleSubmit} disabled={selected.length === 0} form="eventForm" type="submit" variant="primary">
                        <FormattedMessage id="CREATE_BTN"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ChatModal