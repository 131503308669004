import { Component } from "react"
import { Nav } from "react-bootstrap"

class NavDrawerList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Nav as="ul" className="px-0 py-0 text-start nav flex-column">
                {this.props.children}
            </Nav>
        )
    }
}

export default NavDrawerList