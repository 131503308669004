import RegionalModeratorPolicy from "../../policies/RegionalModeratorPolicy";
import Criteria from "../common/Criteria";
import Order from "../common/Order";
import Pagination from "../common/Pagination";
import CalendarEvent from "../event/CalendarEvent";
import Post from "../Post";

class Moderator {
    constructor(user) {
        this.regionalModeratorPolicy = new RegionalModeratorPolicy()
        this.user = user
    }

    removeContent(contentType, contentId) {
        if (contentType.toUpperCase() == 'POST') {
            return this.removePostById(contentId)
        } else if (contentType.toUpperCase() == 'EVENT') {
            return this.removeEventById(contentId)
        }
    }

    removePostById(postId) {
        return Post.findById(postId)
        .then((post) => {
            if (post == null) {
                return Promise.reject(new Error('Could not find a matching post.'))
            }
            if (this.regionalModeratorPolicy.canRemoveContent(this.user, 'POST', post) == false) {
                return Promise.reject(new Error('User does not have moderator privelege to remove this event.'))
            }
            return Post.delete(post)
        })
    }

    removeEventById(eventId) {
        let criteria = new Criteria().equalTo('id', eventId)
        return CalendarEvent.matching(criteria, new Pagination(0, 1), new Order('createdAt', false))
        .then((results) => {
            if (results.length == 0) {
                return Promise.reject(new Error('Could not find a matching event.'))
            }
            let event = results[0]
            if (this.regionalModeratorPolicy.canRemoveContent(this.user, 'EVENT', event) == false) {
                return Promise.reject(new Error('User does not have moderator privelege to remove this event.'))
            }
            return CalendarEvent.delete(event)  
        })
    }
}

export default Moderator