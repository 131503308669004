import React, { Component } from 'react'
import { getMonth, startOfMonth, eachDayOfInterval, endOfMonth, subMonths, addDays, setHours, isEqual, startOfWeek } from 'date-fns';
import { isToday } from 'date-fns/esm';

class Calendar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            daysInMonth: null,
            prevMonthInDays: null
        }

        this.weekdays = this.getLocalizedWeekdays()
        this.months = this.getLocalizedMonths()

        this.setNeedsUpdate = this.setNeedsUpdate.bind(this)
    }

    getLocalizedWeekdays() {
        let start = startOfWeek(new Date(), { weekStartsOn: 0 });
        let week = eachDayOfInterval({
            start: start,
            end: addDays(start, 6)
        })
        let locale = window.navigator.language
        return week.map((date) => date.toLocaleString(locale, {weekday: 'short'}))
    }

    getLocalizedMonths() {
        var months = []
        var locale = window.navigator.language
        for (let i = 0; i < 12; i++) {
            let d = new Date();
            d.setDate(1);
            d.setMonth(i);
            let month = d.toLocaleString(locale, { month: "long" })
            months.push(month)
        }
        return months;
    }

    setNeedsUpdate() {
        var { showingYearMonth } = this.props

        var datesInMonth = eachDayOfInterval({
            start: startOfMonth(showingYearMonth),
            end: endOfMonth(showingYearMonth)
        })
        var firstWeekDayOfMonth = datesInMonth[0].getDay()

        let prevMonth = subMonths(setHours(addDays(startOfMonth(showingYearMonth), 1), 1), 1)

        var prevMonthInDates = eachDayOfInterval({
            start: startOfMonth(prevMonth),
            end: endOfMonth(prevMonth)
        }).reverse().slice(0, firstWeekDayOfMonth).reverse()

        this.setState({
            daysInMonth: datesInMonth,
            prevMonthInDays: prevMonthInDates
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // this does not work, why?
        if (prevProps.showingYearMonth !== this.props.showingYearMonth) {
            this.setNeedsUpdate()
        }
    }

    componentDidMount() {
        this.setNeedsUpdate()
    }

    render() {
        var { currentDate, showingYearMonth, className } = this.props
        var { daysInMonth, prevMonthInDays, } = this.state

        if (daysInMonth == null || prevMonthInDays == null) {
            return
        }

        return (
            <div className={`d-block position-static p-2 bg-light rounded-3 ${className}`}>
                <div className="d-grid gap-1">
                    <div className="cal">
                        <div className="cal-month">
                            <button
                                onClick={(e) => {
                                    this.props.onClickedPrevMonth(e)
                                    this.setNeedsUpdate()
                                }}
                                className="btn cal-btn text-dark"
                                type="button">
                                <i className="bi bi-arrow-left-short"></i>
                            </button>
                            <span className="cal-month-name">
                                <b>{this.months[getMonth(addDays(showingYearMonth, 0))]}</b> {showingYearMonth.getFullYear()}
                            </span>
                            <select className="form-select cal-month-name d-none">
                                {this.months.map((month) =>
                                    <option value="month">{month}</option>
                                )}
                            </select>
                            <button
                                onClick={(e) => {
                                    this.props.onClickedNextMonth()
                                    this.setNeedsUpdate()
                                }}
                                className="btn cal-btn text-dark"
                                type="button">
                                <i className="bi bi-arrow-right-short"></i>
                            </button>
                        </div>
                        <div className="cal-weekdays text-muted">
                            { this.weekdays.map((weekday) =>
                                <div className="cal-weekday">{weekday.slice(0, 3)}</div>
                            )}
                        </div>
                        <div className="cal-days">
                            {prevMonthInDays.map((date) =>
                                <button
                                    onClick={(e) => {
                                        this.props.onClickedPrevMonth()
                                        this.setNeedsUpdate()
                                    }}
                                    className="btn cal-btn text-muted border-0"
                                    type="button">
                                        {date.getDate()}
                                </button>
                            )}
                            {daysInMonth.map((date) =>
                                <button
                                    onClick={(e) => this.props.onClickedDate(date)}
                                    className={`btn cal-btn ${isEqual(date, currentDate) ? 'border-danger' : ''} ${isToday(date) ? 'border-danger btn-danger' : 'text-dark'}`}
                                    type="button">
                                        {date.getDate()}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Calendar