import React, { Component } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import ListTimezonesService from '../../../services/ListTimezonesService'
import { eachMinuteOfInterval, endOfDay, format, formatDuration, getHours, getMinutes, intervalToDuration, setHours, setMinutes, startOfDay, startOfMonth } from 'date-fns'

import { getNextMonth, getPreviousMonth } from "../../../scenes/Events/events-fns";
import CalendarDropdownMenu from "../../CalendarDropdownMenu";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { Link } from "react-router-dom";
import Avatar from "../../Avatar";

class EventDetailsModal extends Component {
    constructor(props) {
        super(props)

        this.startDateInput = React.createRef()
        this.endDateInput = React.createRef()


        this.availableTimes = []
        this.listTimezones = new ListTimezonesService()

        this.handleInputChange = this.handleInputChange.bind(this)
        this.setUpdateDateIfNeeded = this.setUpdateDateIfNeeded.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)

        this.handleClickedPrevMonth = this.handleClickedPrevMonth.bind(this)
        this.handleClickedNextMonth = this.handleClickedNextMonth.bind(this)
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
        this.setUpdateDateIfNeeded(name, value)
    }

    // debug this.
    setUpdateDateIfNeeded(name, value) {
        let date = new Date(value)

        if (name === 'startTime') {
            let newStartDate = setHours(setMinutes(this.state.startDate, getMinutes(date)), getHours(date))
            this.setState({startDate: newStartDate})
        } else if (name === 'endTime') {
            let newEndDate = setHours(setMinutes(this.state.endDate, getMinutes(date)), getHours(date))
            this.setState({endDate: newEndDate})
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        const form = e.currentTarget
        
        this.setState({isValidated: true})

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }

        var { title, address, isAllDay, startDate, endDate,
            url, isOnline, description, timezone, size, type, subtype } = this.state

        this.props.onCreate(e, {
            title,
            address,
            startDate: isAllDay ? startOfDay(startDate) : startDate,
            endDate: isAllDay ? endOfDay(startDate) : endDate,
            url,
            isOnline,
            description,
            timezone,
            size,
            type,
            subtype
        })
    }

    setAvailableTimes({minuteStep}) {
        let date = new Date()
        this.availableTimes = eachMinuteOfInterval({
            start: startOfDay(date),
            end: endOfDay(date)
        }, {step: minuteStep})
    }

    componentDidMount() {
        this.setAvailableTimes({minuteStep: 15})
    }

    handleClickedPrevMonth(e) {
        var { showingYearMonth } = this.state
        let prevMonth = getPreviousMonth(showingYearMonth)
        this.setState({showingYearMonth: prevMonth})
    }

    handleClickedNextMonth() {
        var { showingYearMonth } = this.state
        let nextMonth = getNextMonth(showingYearMonth)
        this.setState({showingYearMonth: nextMonth})
    }

    renderDescriptionList(calendarEvent) {
        var {days, hours, minutes} = intervalToDuration({start: calendarEvent.startDate, end: calendarEvent.getEndDate()})
        var isAllDay = days == 1 || hours == 24 || hours == 23 && minutes == 59
        // var isAllDay = differenceInHours(event.getEndDate(), event.startDate) == 24

        return (
            <dl className="row">
                <dt className="col-sm-3">
                    <FormattedMessage id="EVENT_HOST_LABEL"/>
                </dt>
                <dd className="col-sm-9">{calendarEvent.author.firstName} {calendarEvent.author.lastName}</dd>

                <dt className="col-sm-3">
                    <FormattedMessage id="EVENT_TITLE_LABEL"/>
                </dt>
                <dd className="col-sm-9">{calendarEvent.title}</dd>

                <dt className="col-sm-3">
                    <FormattedMessage id="EVENT_ADDRESS_LABEL"/>
                </dt>
                <dd className="col-sm-9">{calendarEvent.address}</dd>

                <dt className="col-sm-3">
                    <FormattedMessage id="EVENT_DATE_LABEL"/>
                </dt>
                <dd className="col-sm-9">
                    <FormattedDate value={calendarEvent.startDate}/>
                    { isAllDay && ` (${this.props.intl.formatMessage({id: 'ALL_DAY_LABEL'})})` }
                </dd>

                { isAllDay == false &&
                    <React.Fragment>
                        <dt className="col-sm-3">
                            <FormattedMessage id="EVENT_TIME_LABEL"/>
                        </dt>
                        <dd className="col-sm-9">
                            <FormattedTime value={calendarEvent.startDate}/> — <FormattedTime value={calendarEvent.getEndDate()}/>
                        </dd>
                    </React.Fragment>
                }

                <dt className="col-sm-3">
                    <FormattedMessage id="EVENT_DESCRIPTION_LABEL"/>
                </dt>
                <dd className="col-sm-9">
                    <p className="mb-2">{calendarEvent.description}</p>
                </dd>

                { calendarEvent.type !== "" &&
                    <React.Fragment>
                        <dt className="col-sm-3">
                            <FormattedMessage id="EVENT_OTHER_LABEL"/>
                        </dt>
                        <dd className="col-sm-9">
                            <dl className="row">
                                <dt className="col-sm-4">
                                    <FormattedMessage id="EVENT_SIZE_LABEL"/>
                                </dt>
                                <dd className="col-sm-8">
                                    <FormattedMessage id={`EVENT_SIZE_${calendarEvent.size.toUpperCase()}`}/>
                                </dd>
                                <dt className="col-sm-4">
                                    <FormattedMessage id="EVENT_TYPE_LABEL"/>
                                </dt>
                                <dd className="col-sm-8">
                                    <FormattedMessage id={`EVENT_TYPE_${calendarEvent.type.toUpperCase()}`}/>
                                </dd>
                                <dt className="col-sm-4">
                                    <FormattedMessage id="EVENT_SUBTYPE_LABEL"/>
                                </dt>
                                <dd className="col-sm-8">
                                    <FormattedMessage id={`EVENT_SUBTYPE_${calendarEvent.subtype.toUpperCase()}`}/>
                                </dd>
                            </dl>
                        </dd>
                    </React.Fragment>
                }
                
                { calendarEvent.isOnline &&
                    <React.Fragment>
                        <dt className="col-sm-3">
                            <FormattedMessage id="EVENT_ONLINE_LABEL"/>
                        </dt>
                        <dd className="col-sm-9">
                            <dl className="row">
                                <dt className="col-sm-4">
                                    <FormattedMessage id="EVENT_URL_LABEL"/>
                                </dt>
                                <dd className="col-sm-8">
                                    <a target="_blank" href={calendarEvent.url}>{calendarEvent.url}</a>
                                </dd>
                                <dt className="col-sm-4">
                                    <FormattedMessage id="EVENT_TIMEZONE_LABEL"/>
                                </dt>
                                <dd className="col-sm-8">{calendarEvent.timezone}</dd>
                            </dl>
                        </dd>
                    </React.Fragment>
                }
            </dl>
        )
    }

    renderEmptyMessage() {
        return (
            <div className="px-3 py-5 text-center">
                <p className="text-muted">
                    <FormattedMessage id="EVENT_SELECT_EVENT_EMPTY_MESSAGE"/>
                </p>
            </div>
        )
    }

    render() {
        var { title, show, calendarEvent } = this.props
        
        return (
            <Modal
                fullscreen="md-down"
                show={show}
                onHide={this.props.onHide}
                onExited={this.props.onExited}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {calendarEvent == null && this.renderEmptyMessage()}
                    {calendarEvent && this.renderDescriptionList(calendarEvent)}
                </Modal.Body>
                <Modal.Footer>
                { calendarEvent &&
                    <Button onClick={(e) => this.props.onDownload(e)} variant="primary" className="w-100">
                        <FormattedMessage id="DOWNLOAD_BTN"/>
                    </Button>
                }
                </Modal.Footer>
            </Modal>
        )
    }
}

export default EventDetailsModal