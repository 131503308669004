class RegionalModeratorPolicy {
    canRemoveContent(user, type, object) {
        if (type.toUpperCase() == 'POST') {
            return this.canRemovePost(user, object)
        } else if (type.toUpperCase() == 'EVENT') {
            return this.canRemoveEvent(user, object)
        }
        return false
    }

    canRemovePost(user, post) {
        if (user.isModerator == false) {
            return false
        }
        if (user.regionCode == null) {
            return false
        }
        return user.regionCode === post.author.regionCode
    }

    canRemoveEvent(user, event) {
        if (user.isModerator == false) {
            return false
        }
        if (user.regionCode == null) {
            return false
        }
        return user.regionCode === event.author.regionCode
    }
}

export default RegionalModeratorPolicy