import { Component } from "react"
import { Col, Modal, Row, Tab, Nav} from "react-bootstrap"

import AppearancePane from "./components/AppearancePane"
import GeneralPane from "./components/GeneralPane"
import { FormattedMessage } from "react-intl"
import WorkPane from "./components/WorkPane"

class PreferencesModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isValidated: false,
            errors: []
        }

        this.tabs = [ "general", "work", "appearance" ]

        this.handleSaveChanges = this.handleSaveChanges.bind(this)
    }

    handleSaveChanges(e, sectionName, data) {
        if (e) {
            e.preventDefault()
        }
        this.props.onSubmit(e, sectionName, data)
    }

    renderNav(tabs) {
        return (
            <Nav variant="pills" className="flex-column sticky-top pt-0">
            {this.tabs.map((tab) => 
                <Nav.Item>
                    <Nav.Link eventKey={tab}>
                        <FormattedMessage id={`PREFERENCES_MODAL_NAV_ITEM_${tab.toUpperCase()}`}/>
                    </Nav.Link>
                </Nav.Item>
            )}
            </Nav>
        )
    }

    render() {
        var {show, currentUser, intl} = this.props
        return (
            <Modal
                fullscreen="lg-down"
                size="xl"
                show={show}
                onHide={this.props.onHide}
                onExited={this.props.onExited}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="PREFERENCES_MODAL_TITLE"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Tab.Container defaultActiveKey="general">
                        <Row>
                            <Col className="border-end-lg p-5" sm={3}>
                                {this.renderNav()}
                            </Col>
                            <Col className="ps-0" sm={{span: 9, offset: 0}}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        <GeneralPane
                                            intl={intl}
                                            currentUser={currentUser}
                                            onSaveChanges={this.handleSaveChanges}
                                        />
                                    </Tab.Pane>

                                    <Tab.Pane className="min-vh-100" eventKey="work">
                                        <WorkPane
                                            intl={intl}
                                            currentUser={currentUser}
                                            onSaveChanges={this.handleSaveChanges}
                                        />
                                    </Tab.Pane>

                                    <Tab.Pane className="min-vh-100" eventKey="appearance">
                                        <AppearancePane
                                            intl={intl}
                                            onSaveChanges={this.handleSaveChanges}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
        )
    }
}

export default PreferencesModal