import { Component } from "react"
import { Form, Col, Row } from 'react-bootstrap'
import { FormattedMessage } from "react-intl"
import CookiesService from "../../../../../services/CookiesService"

class AppearancePane extends Component {
    constructor(props) {
        super(props)

        this.cookies = new CookiesService()

        this.state = {
            theme: this.getTheme(),
            primaryColor: 'blue',
        }

        this.setTheme = this.setTheme.bind(this)
        this.setPrimaryColor = this.setPrimaryColor.bind(this)
    }

    getTheme() {
        let value = this.cookies.get('theme')
        if (value == "") {
            return "light"
        }
        var options = ['dark', 'light', 'auto']
        if (options.includes(value) == false) {
            return "light"
        }
        return value
    }

    setTheme(theme) {
        this.setState({ theme })
        this.props.onSaveChanges(null, 'theme', {theme})
    }

    setPrimaryColor(e) {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const primaryColor = name
        
        this.setState({primaryColor})
        this.props.onSaveChanges(e, 'primaryColor', {primaryColor})
    }

    renderForm() {
        var { theme, primaryColor } = this.state
        return (
            <Form>
                <Form.Group as={Row} className="mb-3 align-items-center">
                    <Form.Label column sm="3">Theme</Form.Label>
                    <Col sm="9">
                        {['light', 'dark', 'auto'].map((x) => (
                            <Form.Check
                                inline
                                type='radio'
                                name={x.toLowerCase()}
                                label={this.props.intl.formatMessage({id: `APPEARANCE_THEME_${x.toUpperCase()}`})}
                                checked={x.toLowerCase() === theme}
                                onChange={(e) => this.setTheme(x)}
                            />
                        ))}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="d-none mb-3 align-items-center">
                    <Form.Label column sm="3">Primary Color</Form.Label>
                    <Col sm="9">
                        {['Blue', 'Green', 'Orange', 'Dark', 'Red'].map((color) => (
                            <Form.Check
                                inline
                                type='radio'
                                name={color.toLowerCase()}
                                label={`${color}`}
                                checked={color.toLowerCase() === primaryColor}
                                onChange={this.setPrimaryColor}
                            />
                        ))}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="d-none mb-3 align-items-center">
                    <Form.Label column sm="3">Font size</Form.Label>
                    <Col sm="9">
                        <Form.Range />
                    </Col>
                </Form.Group>
            </Form>
        )
    }

    render() {
        return (
            <div>
                <div className="p-5">
                    <h4><FormattedMessage id="APPEARANCE_PANE"/></h4>
                    {this.renderForm()}
                </div>
            </div>
        )
    }
}

export default AppearancePane