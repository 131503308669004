import { Component } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

class DeleteAccountForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isValidated: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault()
        const form = e.currentTarget
        this.setState({
            isValidated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            return
        }
        this.props.onSaveChanges(e)
    }

    render() {
        var { isValidated } = this.state
        var { data } = this.props
        var { confirmation, currentPassword } = data

        return (
            <Form
                validated={isValidated}
                noValidate
                onSubmit={this.handleSubmit}
            >
                <Alert className="mb-4" variant='warning'>
                    <p className="mb-0">
                        <FormattedMessage id="DELETE_ACCOUNT_HELPER_TEXT"/>
                    </p>
                </Alert>

                <Form.Group className="mb-3">
                    <Form.Check
                        onChange={this.props.onChange}
                        required
                        name="confirmation"
                        checked={confirmation}
                        type="checkbox"
                        label={this.props.intl.formatMessage({id: "ARE_YOU_SURE_DELETE_ACCOUNT_LABEL"})}
                    />
                </Form.Group>

                { confirmation && 
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <FormattedMessage id="PASSWORD_INPUT_LABEL"/>
                        </Form.Label>
                        <Form.Control
                            onChange={this.props.onChange}
                            value={currentPassword}
                            required
                            type="password"
                            name="currentPassword"/>
                    </Form.Group>    
                }

                <div className="d-flex flex-column flex-sm-row justify-content-end pt-4 mt-sm-2 mt-md-3">
                    <Button variant="danger" type="submit">
                        <FormattedMessage id="DELETE_ACCOUNT_BTN"/>
                    </Button>
                </div>
            </Form>
        )
    }
}

export default DeleteAccountForm