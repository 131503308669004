class Report {
    constructor({reason, description, userId, objectType, objectId, isEnforced}) {
        let now = new Date()
        this.createdAt = now
        this.updatedAt = now

        this.reason = reason
        this.description = description
        this.userId = userId
        this.objectType = objectType
        this.objectId = objectId

        this.isEnforced = isEnforced || false
    }
}

export default Report