import React, { Component } from 'react';

import NavDrawer from '../../components/NavDrawer';

import DashboardNavbar from './Navbar';
import DashboardBody from './Body';
import DashboardContext from './Context';
import DashboardList from './List';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import authStateChanged from "../../redux/actions/auth_state_changed";
import createNotification from '../../redux/actions/create_notification';
import setModal from '../../redux/actions/set_modal';

import Bucket from '../../models/common/Bucket';
import { handlePreferences, handleNewEvent, handleKeyDown, handleSignOut, handleNewChat, handleNewPost, createNavDrawerFooter, createNavDrawerBrand, createNavDrawerList, createNavDrawerAction } from './dashboard-fns';

import navItems from '../../assets/navItems.json'
import Logo from '../../assets/logo.png'
import { Dropdown } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

/**
 * The Dashboard master scene
 * is used by other child scenes that wishes to
 * extend the functionality of the dashboard.
 * It exposes a set of useful (helper) functions most commonly needed
 * for a Dashboard child scene and provides default handling of common component rendering tasks.
 * This makes it easier for the children scenes to focus on what they are providing in their extension. 
 */
class Dashboard extends Component {
  static Body = DashboardBody
  static Context = DashboardContext
  static List = DashboardList
  static Navbar = DashboardNavbar

  constructor(props) {
    super(props);
    this.state = {}
  }

  renderNavDrawer() {
    var { currentUser, childScene } = this.props

    return (
      <NavDrawer>
        {createNavDrawerBrand({currentUser, logo: Logo})}
        {createNavDrawerList({currentUser, navItems})}
        {currentUser && createNavDrawerAction({onBehalfOfComponent: childScene, onNewPost: handleNewPost, onNewChat: handleNewChat, onNewEvent: handleNewEvent})}
        {createNavDrawerFooter({currentUser, onBehalfOfComponent: childScene, onSignOut: handleSignOut, onPreferences: handlePreferences})}
      </NavDrawer>
    )
  }

  render() {
    return (
      <div className="dashboard-content row d-flex justify-content-center mx-0">
        {this.renderNavDrawer()}
        {this.props.children}
      </div>
    )
  }
}
  
function BackwardsCompatibleProps(props) {
  let navigate = useNavigate();
  let intl = useIntl()
  return <Dashboard {...props} navigate={navigate} intl={intl} />
}

function mapStateToProps(state) {
  return {
    currentUser: state.changedAuthState
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    authStateChanged: authStateChanged,
    setModal: setModal,
    createNotification: createNotification
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
